import {
  ICity,
  IOrganization,
  ISettings,
  IVehicleCategory,
  MerchandiseCategory,
} from "@wetrans/helpers";

export const vehicleCategoriesSelector = (state: any): IVehicleCategory[] => {
  return state?.customReducer?.vehicleCategories as IVehicleCategory[];
};
export const citiesSelector = (state: any): ICity[] => {
  return state?.customReducer?.cities as ICity[];
};
export const organizationsSelector = (state: any): IOrganization[] => {
  return state?.customReducer?.organizations as IOrganization[];
};
export const settingsSelector = (state: any): ISettings => {
  return state?.customReducer?.settings as ISettings;
};
export const analyticsDateFiltersSelector = (state: any) => {
  return state?.customReducer?.analyticsDateFilters;
};

export const merchandiseCategoriesSelector = (
  state: any
): MerchandiseCategory[] => {
  return state?.customReducer?.merchandiseCategories as MerchandiseCategory[];
};
