import React from "react";
import { Filter, TextInput } from "react-admin";

// styles
import { primaryColor } from "@theme/colors";
import useStyles from "./ClientFiltersStyles";
import classnames from "classnames";
import { AccountStatusInput } from "@constants/accountStatus";

import { ClientTypeInput } from "@constants/clientType";
import CityInput from "@components/common/CityInput/CityInput";

const ClientFilters = (props: any) => {
  const classes = useStyles();

  return (
    <Filter
      {...props}
      style={{
        background: primaryColor,
        borderRadius: 4,
        marginRight: 20,
      }}
      className={classnames(classes.formRoot, "filters")}
    >
      <TextInput
        source="ICE"
        label="client.companyICE"
        resettable
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <TextInput
        source="email"
        label="client.email"
        resettable
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <TextInput
        source="name"
        label="client.name"
        resettable
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <AccountStatusInput label="client.accountStatus" source="accountStatus" />
      <ClientTypeInput label="client.type" source="clientType" />
      <CityInput label="client.cityOfOrigin" source="cityOfOrigin" />
    </Filter>
  );
};

export default ClientFilters;
