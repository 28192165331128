import firebase from "firebase";

export default class FunctionsProvider {
  firebaseApp: firebase.app.App;
  functions: firebase.functions.Functions;
  isEmailUnique: firebase.functions.HttpsCallable;
  isPhoneUnique: firebase.functions.HttpsCallable;
  isMatriculeUnique: firebase.functions.HttpsCallable;

  constructor(firebaseApp: firebase.app.App) {
    this.firebaseApp = firebaseApp;
    this.functions = firebase.functions();
    this.isEmailUnique = this.functions.httpsCallable("isEmailUnique");
    this.isPhoneUnique = this.functions.httpsCallable("isPhoneUnique");
    this.isMatriculeUnique = this.functions.httpsCallable("isMatriculeUnique");

    // this.functions.useEmulator("localhost", 5001);
  }
}
