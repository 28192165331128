import { ExpeditionStatus, IExpedition } from "@wetrans/helpers";
import React, { useState } from "react";
import DirectionMap from "./DirectionMap";
import { DriverLivePosition } from "./DriverLivePosition";

interface LiveDirectionMapProps {
  expedition: IExpedition;
}
export const LiveDirectionMap = (props: LiveDirectionMapProps) => {
  const { expedition } = props;
  const [driverPosition, setDriverPosition] = useState<{
    lat: number;
    lng: number;
  }>();
  return (
    <>
      {expedition &&
        expedition.driver &&
        expedition.status >= ExpeditionStatus.started &&
        expedition.status < ExpeditionStatus.delivered && (
          <DriverLivePosition
            driverId={expedition.driver}
            onChange={(newPosition) => {
              setDriverPosition(newPosition);
            }}
          />
        )}
      <DirectionMap
        encodedPolyline={expedition.directions?.encodedPolyline}
        startPointPos={{
          lat: expedition.from.latitude,
          lng: expedition.from.longitude,
        }}
        endPointPos={{
          lat: expedition.to.latitude,
          lng: expedition.to.longitude,
        }}
        currentPos={
          expedition.status >= ExpeditionStatus.started &&
          expedition.status < ExpeditionStatus.delivered
            ? driverPosition
            : undefined
        }
      />
    </>
  );
};
