export const firebaseConfig = {
  apiKey: "AIzaSyCzLsea9SCotvPjfTqAAiIkikgG6e9H_pE",
  authDomain: "wetransgroup.firebaseapp.com",
  databaseURL:
    "https://wetransgroup-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wetransgroup",
  storageBucket: "wetransgroup.appspot.com",
  messagingSenderId: "668441800709",
  appId: "1:668441800709:web:217aa365ce19a019e26c6c",
  measurementId: "G-T6PYXEBMHV",
};
