import React from "react";
import {
  ArrayInput,
  DateInput,
  FileField,
  FileInput,
  minLength,
  required,
  SimpleFormIterator,
  TextInput,
  useLocale,
} from "react-admin";

// component

// styles

// helper

const MultiFIleInputs = (props: any) => {
  const locale = useLocale();
  const validateDocumentName = [required(), minLength(3)];

  return (
    <ArrayInput {...props}>
      <SimpleFormIterator disableReordering>
        <TextInput
          source="name"
          validate={validateDocumentName}
          // classes={namesClasses}
          style={{ marginRight: 20 }}
          label="Nom"
        />
        <DateInput
          locales={locale}
          style={{ marginRight: 20 }}
          source="expirationDate"
          label="Date d'expiration"
        />
        <FileInput
          accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
          maxSize={10000000}
          source="file"
          label={"Document"}
          validate={[required()]}
        >
          <FileField source="src" title="title" target="_blank" />
        </FileInput>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default MultiFIleInputs;
