import React, { useEffect, useState } from "react";
import {
  Confirm,
  SaveButton,
  Toolbar,
  useEditController,
  useGetIdentity,
  useMutation,
  usePermissions,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useForm } from "react-final-form";
import { AccountStatus, AdminRole } from "@wetrans/helpers";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import { AdminApprovalButtons } from "@components/common/WithAdminApproval/AdminApprovalButtons";
import { DeleteButton } from "@components/common/WithAdminApproval/DeleteButton";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});
export const AdminEditApprovalToolbar = (props: any) => {
  const {
    setEditMode,
    editMode,
    resource,
    id,
    warningMessage,
    adminApproveWarningMessage,
    adminDisapproveWarningMessage,
    enableDelete,
    isDeletable,
    transformOnDelete,
    deleteConfirmationMessage,
  } = props;

  const form = useForm();
  const [updateRecord] = useMutation();
  const { loaded: identityLoaded, identity } = useGetIdentity();
  const classes = useStyles();
  const [deletable, setDeletable] = useState(false);

  const { record, loaded } = useEditController(props);
  useEffect(() => {
    async function checkIfIsDeletable(record: any) {
      const isDeletableBoolean = await isDeletable?.(record);
      setDeletable(isDeletableBoolean);
    }
    if (loaded && record) {
      if (enableDelete && !record.deleted) {
        checkIfIsDeletable(record);
      }
    }
  }, [record, loaded, isDeletable, enableDelete]);
  const onDelete = React.useCallback(async () => {
    await updateRecord({
      type: "update",
      resource: resource,
      payload: {
        data: transformOnDelete
          ? transformOnDelete(record, identity?.id)
          : {
              ...record,
              accountStatus: AccountStatus.suspended,
              deleted: true,
              deletedAt: new Date(),
              deletedBy: identity?.id,
            },
        id: id,
      },
    });
    // refresh();
  }, [id, identity, record, resource, transformOnDelete, updateRecord]);
  const onAccept = React.useCallback(async () => {
    await updateRecord({
      type: "update",
      resource: resource,
      payload: {
        data: {
          ...record,
          approvalStatus: ApprovalStatus.Approved,
          updateFromFrontend: true,
        },
        id: id,
      },
    });
    // refresh();
  }, [id, record, resource, updateRecord]);
  const onReject = React.useCallback(async () => {
    await updateRecord({
      type: "update",
      resource: resource,
      payload: {
        data: {
          ...record,
          approvalStatus: ApprovalStatus.Disapproved,
          updateFromFrontend: true,
        },
        id: id,
      },
    });
    // refresh();
  }, [id, record, resource, updateRecord]);

  const [isOpen, setIsOpen] = React.useState(false);
  const { permissions } = usePermissions();

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const handleConfirm = React.useCallback(() => {
    setIsOpen(false);
    setEditMode(true);
  }, [setEditMode]);
  return identityLoaded ? (
    <Toolbar {...props} classes={classes}>
      {permissions === AdminRole.super && (
        <>
          {loaded &&
            record &&
            record.role !== AdminRole.super &&
            !(record as any).deleted && (
              <AdminApprovalButtons
                acceptWarningMessage={adminApproveWarningMessage}
                rejectWarningMessage={adminDisapproveWarningMessage}
                hideReject={
                  record.approvalStatus === ApprovalStatus.Disapproved
                }
                hideAccept={record.approvalStatus === ApprovalStatus.Approved}
                onAccept={onAccept}
                onReject={onReject}
              />
            )}
          {loaded && record && record.role === AdminRole.super && (
            <>
              {editMode && (
                <>
                  <SaveButton
                    handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                    disabled={props.pristine}
                    invalid={props.invalid}
                    // onSuccess={onSuccess}
                    label="Sauvegarder"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEditMode(false);
                      form.reset();
                    }}
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    {"Annuler"}
                  </Button>
                </>
              )}
              {!editMode && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.reset();
                      setEditMode(true);
                    }}
                    style={
                      {
                        // width: "100%",
                        // color: "white",
                      }
                    }
                  >
                    {"Modifier"}
                  </Button>
                  {record && !(record as any).deleted && deletable && (
                    <DeleteButton
                      confirmTitle=""
                      deleteWarningMessage={deleteConfirmationMessage}
                      onDelete={onDelete}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {permissions === AdminRole.operation && (
        <>
          {editMode && (
            <>
              <SaveButton
                handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                disabled={props.pristine}
                invalid={props.invalid}
                // onSuccess={onSuccess}
                transform={(data) => {
                  return {
                    ...data,
                    approvalStatus: ApprovalStatus.Pending,
                    updateFromFrontend: true,
                  };
                }}
                redirect={props.disableRedirect}
                onSuccess={
                  props.disableRedirect === false
                    ? () => {
                        setEditMode(false);
                      }
                    : undefined
                }
                label="Sauvegarder"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setEditMode(false);
                  form.reset();
                }}
                style={{
                  marginLeft: 20,
                }}
              >
                {"Annuler"}
              </Button>
            </>
          )}
          {!editMode && (
            <>
              {record && !(record as any).deleted && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    (identity as any).approvalStatus !== ApprovalStatus.Approved
                  }
                  onClick={() => {
                    if (
                      (identity as any).approvalStatus ===
                      ApprovalStatus.Approved
                    ) {
                      form.reset();
                      setIsOpen(true);
                    }
                  }}
                  style={
                    {
                      // width: "100%",
                      // color: "white",
                    }
                  }
                >
                  {"Modifier"}
                </Button>
              )}
              {(identity as any).approvalStatus === ApprovalStatus.Approved &&
                record &&
                !(record as any).deleted &&
                deletable && (
                  <DeleteButton
                    confirmTitle=""
                    deleteWarningMessage={deleteConfirmationMessage}
                    onDelete={onDelete}
                  />
                )}
            </>
          )}

          <Confirm
            isOpen={isOpen}
            title={" "}
            content={warningMessage}
            onConfirm={handleConfirm}
            onClose={handleClose}
          />
        </>
      )}
    </Toolbar>
  ) : null;
};
