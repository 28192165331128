import { setAnalyticsDateFilters } from "@state/publicInfo";
import React, { useEffect } from "react";
import { useQuery } from "react-admin";
import { useDispatch } from "react-redux";

const AnalyticsDateFiltersFetcher = () => {
  const dispatch = useDispatch();
  const { data: analyticsFilters } = useQuery({
    resource: "analyticsFilters",
    type: "getList",
    payload: {
      sort: {
        field: "date",
        order: "desc",
      },
    },
  });

  useEffect(() => {
    if (analyticsFilters) {
      dispatch(setAnalyticsDateFilters(analyticsFilters));
    }
  }, [analyticsFilters, dispatch]);
  return <></>;
};

export default AnalyticsDateFiltersFetcher;
