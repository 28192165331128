import React from "react";
import { Redirect } from "react-router-dom";

export default {
  name: "vehicle",
  basePath: "/vehicle",
  resource: "vehicle",
};

export const customRoutes = [
  <Redirect exact from="/vehicle/:id/show" to="/vehicle/:id/show/info" />,
];
