import * as React from "react";
import {
  Edit,
  TextInput,
  maxLength,
  minLength,
  email,
  FileInput,
  FileField,
  SimpleForm,
  usePermissions,
  DateInput,
  useLocale,
  required,
  useTranslate,
  // @ts-ignore
} from "react-admin";
import MyTitle from "@components/common/View/Title";
import { AccountStatusInput } from "@constants/accountStatus";
import { AdminRole } from "@wetrans/helpers";
// import { useOrganization } from "@hooks/useOrganization";
import ExpirationWarning from "@components/common/ExpirationWarning";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import Card from "@components/common/card/Card";
import OrganizationInput from "@components/common/OrganizationInput/OrganizationInput";
import { fileInputStyles } from "@components/organization/OrganizationEdit";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";
// import { Alert } from "@material-ui/lab";

const validateName = [required(), minLength(3), maxLength(125)];
const validateEmail = [required(), email()];
const validatePhone = [required(), minLength(3), maxLength(125)];
const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export default function DriverEdit(props: any) {
  const { permissions } = usePermissions();
  // const { organization } = useOrganization();
  const t = useTranslate();
  const locale = useLocale();
  const classes = useStyles();
  const inputStyle = fileInputStyles();
  const { Toolbar } = props;
  return (
    <Edit
      {...props}
      hasShow={false}
      resource="users"
      title={<MyTitle resource={props.resource} descriptionAttribute="name" />}
      transform={(data) => {
        return {
          ...data,
          approvalStatus: ApprovalStatus.Pending,
          updateFromFrontend: true,
        };
      }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <Toolbar
            // mode="edit"
            {...props}
            style={{
              position: "absolute",
              top: 55,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 65,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              <Grid item sm={12}>
                <ExpirationWarning
                  source="licenseExpirationDate"
                  expiredMessage="La date d'expiration du permis est passée"
                  closeMessage="La date d'expiration du permis est proche"
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="CIN"
                  className={classes.fullWidth}
                  source="CIN"
                  disabled
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="driver.name"
                  source="name"
                  className={classes.fullWidth}
                  validate={validateName}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="driver.email"
                  source="email"
                  disabled
                  className={classes.fullWidth}
                  validate={validateEmail}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="driver.phone"
                  source="phone"
                  disabled
                  className={classes.fullWidth}
                  validate={validatePhone}
                />
              </Grid>
              <Grid item sm={6}>
                {permissions === AdminRole.super && (
                  <span>{t("driver.license")}</span>
                )}
                <FileInput
                  maxSize={10000000}
                  source="license"
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                  validate={[required()]}
                  classes={
                    permissions === AdminRole.super ? inputStyle : undefined
                  }
                  label={
                    permissions === AdminRole.super ? " " : "driver.license"
                  }
                  disabled={permissions === AdminRole.super}
                >
                  <FileField source="src" title="title" target="_blank" />
                </FileInput>
              </Grid>
              <Grid item sm={6}>
                <AccountStatusInput
                  label="driver.accountStatus"
                  disabled={permissions === AdminRole.support}
                  className={classes.fullWidth}
                  source="accountStatus"
                />
              </Grid>
              <Grid item sm={6}>
                <DateInput
                  locales={locale}
                  className={classes.fullWidth}
                  validate={[required()]}
                  source="licenseExpirationDate"
                  label="Date d'expiration du permis"
                />
              </Grid>
              {permissions === AdminRole.super && (
                <Grid item sm={6}>
                  <OrganizationInput
                    disabled
                    className={classes.fullWidth}
                    label="admin.organization"
                    source="organization"
                  />
                </Grid>
              )}

              <Grid item sm={12}>
                <MultiFIleInputs source="documents" label="Documents" />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Edit>
  );
}
