import React, { FC } from "react";
import { useSelector } from "react-redux";
import { organizationsSelector } from "@state/selectors";
import { AutocompleteInput, TextInputProps } from "react-admin";
import OptimizedOrganizationsFetcher from "./OptimizedOrganizationsFetcher";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";

const OrganizationInput: FC<TextInputProps> = (props: any) => {
  const { source, label, onlyApproved } = props;

  const organizations = useSelector(organizationsSelector).filter((o) =>
    onlyApproved ? o.approvalStatus === ApprovalStatus.Approved : o.id
  );

  return (
    <>
      <AutocompleteInput
        source={source}
        label={label}
        resettable
        choices={organizations}
        options={{
          suggestionsContainerProps: {
            style: {
              background: "white",
            },
          },
        }}
        {...props}
        allowEmpty={false}
      ></AutocompleteInput>
      <OptimizedOrganizationsFetcher />
    </>
  );
};

export default OrganizationInput;
