import { RatingField } from "@components/common/RatingField/RatingField";
import { Typography } from "@material-ui/core";
import { ExpeditionStatus, IExpedition } from "@wetrans/helpers";
import React from "react";
import { FunctionField } from "react-admin";
import Moment from "react-moment";
interface ExpeditionReviewSectionProps {
  expedition: IExpedition;
}
export const ExpeditionReviewSection = (
  props: ExpeditionReviewSectionProps
) => {
  const { expedition } = props;
  return expedition.status === ExpeditionStatus.confirmed &&
    (expedition.rating || expedition.rating === 0) ? (
    <>
      <div className="flex flex-wrap direction-column margin-b-10">
        <label className="margin-b-10">
          <strong>Note :</strong>
        </label>
        <FunctionField
          render={(record: any) => {
            return typeof expedition.rating === "number" ? (
              <>
                <RatingField value={expedition.rating} />{" "}
                <strong
                  style={{
                    display: "inline-flex",
                    marginLeft: 10,
                    position: "relative",
                    top: -5,
                  }}
                >{`${expedition.rating}/5`}</strong>
              </>
            ) : (
              "N/A"
            );
          }}
          label="Note :"
        />
        {expedition.ratedAt && (
          <Typography variant="caption" color="textSecondary">
            noté à{" "}
            <Moment
              format="DD/MM/YYYY HH:mm"
              date={
                (expedition.ratedAt as any).seconds
                  ? new Date((expedition?.ratedAt as any).seconds * 1000)
                  : expedition.ratedAt
              }
            />
          </Typography>
        )}
      </div>
      {expedition.ratingComment && (
        <div className="flex flex-wrap direction-column margin-b-10">
          <label className="margin-b-10">
            <strong>Commentaire :</strong>
          </label>
          <FunctionField
            render={(record: any) => expedition.ratingComment}
            label="Commentaire :"
          />
        </div>
      )}
    </>
  ) : null;
};
