import React from "react";

export const useFormatString = () => {
  function formatString(
    type: string,
    number: number,
    currency: string = "MAD"
  ) {
    switch (type) {
      case "count":
        return number.toLocaleString("en-EN", {
          maximumFractionDigits: 0,
        });
      case "decimal":
        return number.toLocaleString("en-EN", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      case "currency":
        return number.toLocaleString("en-EN", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: "currency",
          currency,
        });
      case "distance":
        return (
          (number / 1000).toLocaleString("en-EN", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "decimal",
          }) + " Km"
        );
      case "rating":
        return number.toLocaleString("en-EN", {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
          style: "decimal",
        });

      default:
        return "";
    }
  }
  return React.useCallback(formatString, []);
};
