import Card from "@components/common/card/Card";
import CardTitle from "@components/common/card/CardTitle";
import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Title } from "react-admin";
import "./style.css";
import EmailIcon from "@material-ui/icons/Email";

import PhoneIcon from "@material-ui/icons/Phone";
import { useSelector } from "react-redux";
import { settingsSelector } from "@state/selectors";

export const ContactSupport = () => {
  const settings = useSelector(settingsSelector);
  const email = settings?.emails[0];
  const phone = (settings as any)?.phone;
  return (
    <div style={{ marginTop: 50 }}>
      <Title title="Support" />
      <Grid container spacing={3}>
        <Grid item md={6} lg={6}>
          <Card>
            <div id="import-card">
              <Grid container spacing={3}>
                <Grid item md={8} lg={6}>
                  <div style={{ textAlign: "center" }}>
                    <CardTitle>
                      <h3>Pour plus d'information, contactez-nous:</h3>
                    </CardTitle>
                    {phone && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          let a = document.createElement("a");
                          a.setAttribute("href", `tel:${phone}`);
                          a.setAttribute("target", "_blank");
                          a.click();
                        }}
                      >
                        <PhoneIcon style={{ marginRight: 5 }} />
                        {phone}
                      </Button>
                    )}
                    {email && (
                      <Button
                        color="primary"
                        style={{ textTransform: "none", marginTop: 5 }}
                        onClick={async () => {
                          let a = document.createElement("a");
                          a.setAttribute("href", `mailto:${email}`);
                          a.setAttribute("target", "_blank");
                          a.click();
                        }}
                      >
                        <EmailIcon style={{ marginRight: 5 }} />
                        {email}
                      </Button>
                    )}
                    <Typography
                      style={{
                        color: "#354052",
                        fontSize: 12,
                      }}
                    >
                      Horaires d'ouverture du lundi au vendredi, de 9h a 18h
                      (GMT+1)
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
