import React from "react";

const SupportAgentIcon = (props: any) => (
  <svg
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    data-testid="SupportAgentIcon"
    {...props}
  >
    <path d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"></path>
    <circle cx="9" cy="13" r="1"></circle>
    <circle cx="15" cy="13" r="1"></circle>
    <path d="M18 11.03C17.52 8.18 15.04 6 12.05 6c-3.03 0-6.29 2.51-6.03 6.45 2.47-1.01 4.33-3.21 4.86-5.89 1.31 2.63 4 4.44 7.12 4.47z"></path>
  </svg>
);

export default SupportAgentIcon;
