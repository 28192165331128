import React from "react";

// Components, Helpers
// import NoDataPlaceholder from "../../NoDataPlaceholder";

import { ResponsivePieChart } from "./ResponsivePieChart";

export interface ExpeditionImprovedPieChartProps {
  data: any[];
}

export type OrderState =
  | "confirmed"
  | "arrived"
  | "matched"
  | "pickedup"
  | "cancelled"
  | "notDelivered"
  | "pending";

export enum OrderStateColor {
  confirmed = "#91eacb", //
  arrived = "#8bc34a",
  pickedup = "#cb9afd", //inProgress
  matched = "#e6ee9c",
  pending = "#ffc107",
  notDelivered = "#f8a96f", //
  cancelled = "#e67891", //
}
// const orderStateMap = {
//   confirmed: OrderStateColor.confirmed,
//   arrived: OrderStateColor.arrived,
//   matched: OrderStateColor.matched,
//   pickedup: OrderStateColor.pickedup,
//   cancelled: OrderStateColor.cancelled,
//   notDelivered: OrderStateColor.notDelivered,
//   pending: OrderStateColor.pending,
// };

export const expeditionColors = [
  {
    color: "#73B9EC",
    label: "Reservée",
    _id: "bookedCount",
  },
  {
    color: "#6aa84f",
    label: "Validé",
    _id: "validatedCount",
  },
  {
    color: "#FED152",
    label: "Annulé",
    _id: "canceledCount",
  },
  {
    color: "#EF545C",
    label: "Livré",
    _id: "deliveredCount",
  },
  {
    color: "#1f08b0",
    label: "Confirmé",
    _id: "confirmedCount",
  },
];
export type SimplifiedOrderState =
  | "confirmedCount"
  | "deliveredCount"
  | "canceledCount"
  | "validatedCount"
  | "bookedCount";

export enum SimplifiedOrderStateColor {
  confirmedCount = "#1f08b0",
  deliveredCount = "#EF545C",
  canceledCount = "#FED152",
  validatedCount = "#6aa84f",
  bookedCount = "#73B9EC",
}
const simplifiedOrderStateMap = {
  confirmedCount: SimplifiedOrderStateColor.confirmedCount,
  deliveredCount: SimplifiedOrderStateColor.deliveredCount,
  canceledCount: SimplifiedOrderStateColor.canceledCount,
  validatedCount: SimplifiedOrderStateColor.validatedCount,
  bookedCount: SimplifiedOrderStateColor.bookedCount,
};
const getSimplifiedColor = (simpleOrderState: SimplifiedOrderState): string => {
  return simplifiedOrderStateMap[simpleOrderState];
};

const ExpeditionImprovedPieChart = ({
  data,
}: ExpeditionImprovedPieChartProps) => {
  //   if (!data.length) {
  //     return <NoDataPlaceholder />;
  //   }
  return (
    <ResponsivePieChart
      inCercle={70}
      outCercle={100}
      stateSet={SimplifiedOrderStateColor}
      getColor={getSimplifiedColor}
      data={data}
      dataKey="value"
      type="count"
      fontSize={13}
      pieHeight={200}
    />
  );
};

export default ExpeditionImprovedPieChart;
