import {
  cancellationReport,
  ExpeditionStatus,
  IExpedition,
  UserType,
} from "@wetrans/helpers";
import React from "react";
import { FunctionField } from "react-admin";
interface ExpeditionCancellationSectionProps {
  expedition: IExpedition;
}
export const ExpeditionCancellationSection = (
  props: ExpeditionCancellationSectionProps
) => {
  const { expedition } = props;
  return expedition.status === ExpeditionStatus.cancelled ? (
    <>
      <div className="flex flex-wrap direction-column margin-b-10">
        <label>
          <strong>Annulé par :</strong>
        </label>
        <FunctionField
          render={(record: any) => {
            return expedition.cancellationReport
              ? getCancelledByLabel(expedition.cancellationReport)
              : "N/A";
          }}
          label="Annulé par :"
        />
      </div>
      <div className="flex flex-wrap direction-column margin-b-10">
        <label className="margin-b-10">
          <strong>Motif d'Annulation :</strong>
        </label>
        <FunctionField
          render={(record: any) =>
            expedition.cancellationReport?.reason
              ? expedition.cancellationReport?.reason
              : "N/A"
          }
          label="Motif d'Annulation:"
        />
      </div>
    </>
  ) : null;
};
const getCancelledByLabel = (rapport: cancellationReport<Date>) => {
  switch (rapport.cancelledBy) {
    case UserType.super:
      return "Administrateur de la plate-forme";
    case UserType.admin:
      return "Transporteur";
    case UserType.client:
      return "Client";
    default:
      return "N/A";
  }
};
