import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TopToolbar,
  useListContext,
  CreateButton,
  // ExportButton,
  sanitizeListRestProps,
  useTranslate,
  FileField,
  ReferenceField,
  TextField,
  usePermissions,
  // @ts-ignore
} from "react-admin";
import {
  //  Link,
  makeStyles,
} from "@material-ui/core";

// components
// import ExportExported from "@assets/vectors/ExportExported";
import VehicleFilters from "./VehicleFilters";
import StickyTableCell from "@components/common/StickyTableCell";
import DotsMenu from "@components/common/DotsMenu/DotsMenu";

import { isEmpty } from "lodash";
import { NoDataTable } from "@components/common/NoDataTable";
// styles
import { primaryColor } from "@theme/colors";
import "./styles.css";
import { tableRowStyle } from "@theme/styles/table";
import { AdminRole } from "@wetrans/helpers";
import { useOrganization } from "@hooks/useOrganization";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import VehicleCategoryField from "@components/common/vehicleCategoriesSelecter/VehicleCategoryField";
import { ApprovalStatusField } from "@constants/approvalStatus";
// import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    // total,
  } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      {permissions !== AdminRole.super && (
        <CreateButton
          basePath={basePath}
          className="createButtonAction"
          style={{
            background: primaryColor,
          }}
          label="ra.action.add"
        />
      )}

      {/* <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        className="createButtonAction"
        style={{
          background: primaryColor,
        }}
        icon={<ExportExported fill={"white"} />}
      /> */}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const VehicleList = (props: any) => {
  const listClasses = useListStyles();
  const { permissions } = usePermissions();
  const { organization } = useOrganization();

  const permanentFilter: any = {};
  if (organization && permissions !== AdminRole.super)
    permanentFilter.organization = organization;
  return (
    <div className="container">
      <List
        {...props}
        empty={false}
        actions={<ListActions />}
        filters={<VehicleFilters />}
        filter={permanentFilter}
        classes={listClasses}
        pagination={<RaPrevNextPagination />}
        title="Gestion de la Flotte"
        sort={{ field: "createdAt", order: "desc" }}
      >
        <div className="list-container">
          <CustomersDataGrid />
          {permissions === AdminRole.super ? (
            <NoDataTable
              grid={[
                "vehicle.matricule",
                "vehicle.weight",
                "vehicle.age",
                "vehicle.category",
                // "vehicle.driver",
                "vehicle.carteGrise",
                "vehicle.vignette",
                "vehicle.organization",
              ]}
            />
          ) : (
            <NoDataTable
              grid={[
                "vehicle.matricule",
                "vehicle.weight",
                "vehicle.age",
                "vehicle.category",
                // "vehicle.driver",
                "vehicle.carteGrise",
                "vehicle.vignette",
              ]}
            />
          )}
        </div>
      </List>
    </div>
  );
};

const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  const t = useTranslate();
  // const refresh = useRefresh();
  // const [deleteVehicle] = useMutation();
  const { permissions } = usePermissions();
  // const history = useHistory();
  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid rowClick="show" rowStyle={tableRowStyle} classes={classes}>
          {/* <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.id}</div>;
            }}
            source="id"
            label="vehicle.id"
          /> */}
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.matricule}</div>;
            }}
            sortable={false}
            source="matricule"
            label="vehicle.matricule"
          />
          {/* <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.weight}</div>;
            }}
            sortable={false}
            source="weight"
            label="vehicle.weight"
          /> */}
          {/* <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.height}</div>;
            }}
            source="height"
            label="vehicle.height"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.width}</div>;
            }}
            source="width"
            label="vehicle.width"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.length}</div>;
            }}
            source="length"
            label="vehicle.length"
          /> */}
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.age} ans</div>;
            }}
            sortable={false}
            source="age"
            label="vehicle.age"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  <VehicleCategoryField source="category" />
                </div>
              );
            }}
            sortable={false}
            label="vehicle.category"
          />
          <FunctionField
            sortable={false}
            render={(props: any) => {
              return <ApprovalStatusField value={props.approvalStatus} />;
            }}
            label="general.approvalStatus"
          />
          {/* <FunctionField
            label={"Chauffeur"}
            render={(record: any) => {
              return (
                <Link
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e: any) => {
                    history.push(`/driver/${record.driver}/show`);
                    e.stopPropagation();
                  }}
                >
                  <ReferenceField
                    linkType=""
                    link={false}
                    source="driver"
                    reference="users"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </Link>
              );
            }}
          /> */}

          <FileField
            target="_blank"
            source="carteGrise.src"
            title="carteGrise.title"
            label="vehicle.carteGrise"
            sortable={false}
          />

          <FileField
            source="vignette.src"
            target="_blank"
            title="vignette.title"
            sortable={false}
            label="vehicle.vignette"
          />

          {permissions === AdminRole.super ? (
            <ReferenceField
              link="show"
              label="vehicle.organization"
              source="organization"
              reference="organization"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
          ) : null}

          <StickyTableCell>
            <FunctionField
              render={(props: any) => {
                return (
                  <DotsMenu
                    record={props}
                    // delete={props.status === "active"}
                    //delete
                    edit
                    confirmTitle={t("vehicle.confirmDeleteTitle", {
                      matricule: props.matricule,
                    })}
                    confirmContent={t("vehicle.confirmDeleteContent")}
                    // deleteAction={() => {
                    //   deleteVehicle({
                    //     type: "delete",
                    //     resource: "vehicle",
                    //     payload: {
                    //       id: props.id,
                    //     },
                    //   });
                    //   refresh();
                    // }}
                  />
                );
              }}
            />
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};

export default VehicleList;
