import React from "react";
import { choices, SelectInput } from "react-admin";
import { clientType } from "./clientType";

export const ClientTypeInput = ({
  source,
  label,
  className,
}: {
  source: string;
  label: string;
  className?: string;
}) => {
  return (
    <SelectInput
      optionText="label"
      optionValue="id"
      source={source}
      label={label}
      choices={clientType}
      validate={choices(clientType.map((item) => item.id))}
      className={className}
    />
  );
};
