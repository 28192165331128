import * as React from "react";

import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
// import { IExpedition } from '@wetrans/helpers';
import { ExpeditionMatchTypes, IExpedition } from "@wetrans/helpers";
import Moment from "react-moment";
import { getPrice } from "../ExpeditionShow";
import { wetransConfig } from "@config/WETRANS_CONFIG";

Font.register({
  family: "Roboto",
  fonts: [
    { src: "https://fonts.cdnfonts.com/s/12165/Roboto-Regular.woff" },
    {
      src: "https://fonts.cdnfonts.com/s/12165/Roboto-Bold.woff",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 30,
  },
  header: {
    flexDirection: "row",
    marginBottom: 30,
  },
  headerCol: {
    flex: 1,
  },
  headerColRight: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
  },
  headerBlock: {
    width: "60%",
    padding: 5,
    position: "absolute",
    top: 0,
    right: 0,
    border: "1px solid #000",
  },
  headerText: {
    fontSize: 8,
  },
  headerTextLg: {
    fontSize: 11,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  headerTitleLg: {
    fontSize: 18,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  table: {
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    flex: 1,
    border: "1px solid #000",
    padding: 10,
    marginTop: -1,
    marginLeft: -1,
  },
  tableColWithNoBorder: {
    flex: 1,
    padding: 10,
  },
  tBlock: {
    marginTop: 20,
    marginBottom: 10,
  },
  thText: {
    fontSize: 9,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  text: {
    fontSize: 8,
  },
  textBold: {
    fontSize: 9,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  textBolder: {
    fontSize: 11,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  centeredBlock: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 20,
  },
  leftBlock: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 30,
  },
  signBlock: {
    width: "70%",
    padding: 10,
    height: 100,
    border: "1px solid #000",
  },
});

export interface IInvoiceProps {
  data: IExpedition; // should be IExpedition;
  getCity: (cityId: string) => string;
  user?: {
    uid: string;
    email: string;
    phone: string;
    name: string;
    companyName?: string;
  };
  tax: number;
  getMerchandiseCategory: (merchandiseCategoryId: string) => string;
}
const COMPANY = wetransConfig.COMPANY;
export default function BonLivraison({
  data,
  user,
  getCity,
  getMerchandiseCategory,
  tax,
}: IInvoiceProps) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerCol}>
            <Text style={styles.headerTextLg}>{COMPANY.name}</Text>
            <Text style={styles.headerText}>{COMPANY.description}</Text>
            <Text style={styles.headerText}>{COMPANY.phone}</Text>
            <Text style={styles.headerText}>{COMPANY.fax}</Text>
          </View>
          <View style={styles.headerColRight}>
            <View style={styles.headerBlock}>
              <Text style={styles.headerText}>
                Date:{" "}
                <Moment format="DD/MM/YYYY" date={new Date().toDateString()} />
              </Text>
              <Text style={styles.headerText}>
                Commande N° : {data ? data.humanReadableBillId : "N/A"}
              </Text>
              <Text style={styles.headerText}>
                Client: {user?.companyName ? user?.companyName : user?.name}
              </Text>
              <Text style={styles.headerText}>Téléphone: {user?.phone}</Text>
            </View>
          </View>
        </View>
        <View style={styles.centeredBlock}>
          <Text style={styles.headerTitleLg}>{"Bon de livraison"}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColWithNoBorder}>
              <Text style={styles.thText}>
                Commande N° : {data ? data.humanReadableBillId : "N/A"}
              </Text>
            </View>

            <View style={styles.tableColWithNoBorder}>
              <Text style={styles.thText}>
                Transporter : {data ? data.organizationName : "N/A"}
              </Text>
            </View>
            <View style={styles.tableColWithNoBorder}>
              <Text style={styles.thText}>
                Transporter tel : {data ? data.organizationPhone : "N/A"}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColWithNoBorder}>
              <Text style={styles.thText}>
                Vehicle : {data ? data.bookedMatch.vehicleMatricule : "N/A"}
              </Text>
            </View>
            {data.tractor && (
              <View style={styles.tableColWithNoBorder}>
                <Text style={styles.thText}>
                  Tracteur : {data ? data.bookedMatch.tractorMatricule : "N/A"}
                </Text>
              </View>
            )}
            <View style={styles.tableColWithNoBorder}>
              <Text style={styles.thText}>
                Chauffeur : {data.driverName ? data.driverName : "N/A"}
              </Text>
            </View>
            <View style={styles.tableColWithNoBorder}>
              <Text style={styles.thText}>
                Chauffeur CIN : {data.driverCIN ? data.driverCIN : "N/A"}
              </Text>
            </View>
            <View style={styles.tableColWithNoBorder}>
              <Text style={styles.thText}>
                {data && data.bookedMatch.type === ExpeditionMatchTypes.Custom
                  ? "Aller/Retour"
                  : "Aller"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.thText}>Prestation</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.thText}>Prix</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>
                {`Transport de ${getMerchandiseCategory(
                  data?.merchandise?.category
                )}
                de ${getCity(data?.from.city)} à ${getCity(data?.to.city)} le `}
                <Moment
                  format="DD/MM/YYYY"
                  date={
                    data?.bookedMatch.date ? data.bookedMatch.date : undefined
                  }
                />
              </Text>
              <Text style={styles.text}>
                - Catégorie:{" "}
                {getMerchandiseCategory(data?.merchandise?.category)}
              </Text>
              <Text style={styles.text}>
                - Tonnage (T): {data?.merchandise?.weight}
              </Text>
              <Text style={styles.text}>
                - Largeur (m): {data?.merchandise?.width}
              </Text>
              <Text style={styles.text}>
                - Longeur (m): {data?.merchandise?.length}
              </Text>
              <Text style={styles.text}>
                - Hauteur (m): {data?.merchandise?.height}
              </Text>
              {data?.loadingRepresentativePhone ? (
                <Text style={styles.text}>
                  - Avec chargement: {data?.loadingRepresentativePhone}
                </Text>
              ) : null}

              {data?.unloadingRepresentativePhone ? (
                <Text style={styles.text}>
                  - Avec déchargement: {data?.unloadingRepresentativePhone}
                </Text>
              ) : null}
              <Text style={styles.text}>
                - Je souhaite être contacté directement par tel:{" "}
                {(data as any)?.getContactedByPhone ? "Oui" : "Non"}
              </Text>
              {data?.description ? (
                <Text style={styles.text}>
                  - Description: {data?.description}
                </Text>
              ) : null}

              <View style={styles.tBlock}>
                <Text style={styles.text}>
                  Moyen de transport: {data?.bookedMatch?.vehicleCategoryName}
                </Text>
              </View>

              <View style={styles.tBlock}>
                <Text style={styles.textBold}>Conditions générales:</Text>
                <Text style={styles.text}>
                  Ce devis est valide pour 30 jours (autres)
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>
                {`${getPrice(
                  data?.bookedMatch?.priceBreakdown?.rawHTPrice.toString()
                )} Dhs`}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.text}> Commission wetrans</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{`${getPrice(
                (
                  data?.bookedMatch?.priceBreakdown
                    .platformCommissionFromClient * 100
                ).toString()
              )}%`}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Total HT</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{`${getPrice(
                (data?.bookedMatch?.priceBreakdown?.clientPriceHT
                  ? data?.bookedMatch?.priceBreakdown?.clientPriceHT
                  : 0
                ).toString()
              )} Dhs`}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Assurance</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{`${getPrice(
                (data?.bookedMatch.priceBreakdown.insurancePolicyPrice
                  ? data?.bookedMatch.priceBreakdown.insurancePolicyPrice
                  : 0
                ).toString()
              )} Dhs`}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>TVA</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{`${getPrice(
                (data?.bookedMatch.priceBreakdown.TVA * 100).toString()
              )}%`}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Total TTC</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.textBolder}>{`${getPrice(
                (data?.bookedMatch?.priceBreakdown.totalClientPrice
                  ? data?.bookedMatch?.priceBreakdown.totalClientPrice
                  : 0
                ).toString()
              )} Dhs`}</Text>
            </View>
          </View>
        </View>
        <View style={styles.header}>
          <View style={styles.headerCol}>
            <View style={styles.signBlock}>
              <Text style={styles.textBold}>Signature client:</Text>
            </View>
          </View>
          <View style={styles.headerColRight}>
            <View style={styles.signBlock}>
              <Text style={styles.textBold}>Signature Transporter:</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
