import { AdminRole } from "@wetrans/helpers";
import React from "react";
import { choices, required, SelectInput, usePermissions } from "react-admin";
import { adminRole } from "./adminRole";

export const AdminRoleInput = ({
  source,
  label,
  className,
  required: requirreed,
  disabled,
  mode,
}: {
  source: string;
  label: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  mode: "create" | "edit" | "filter";
}) => {
  const { permissions } = usePermissions();
  const roleChoices =
    permissions !== AdminRole.super
      ? adminRole.filter((item) => item.id !== AdminRole.super)
      : mode === "create"
      ? adminRole.filter((item) => item.id === AdminRole.super)
      : adminRole;

  return (
    <SelectInput
      optionText="label"
      optionValue="id"
      source={source}
      disabled={disabled}
      label={label}
      choices={roleChoices}
      validate={[required(), choices(adminRole.map((item) => item.id))]}
      className={className}
    />
  );
};
