import { primaryColor } from "../colors";

const RaConfirmOverrides = {
  MuiDialog: {
    paper: {
      borderRadius: "12px",
      padding: "32px 60px",
    },
    paperWidthSm: {
      background: "white",
    },
  },
  MuiDialogTitle: {
    root: {
      paddingTop: 0,
      paddingBottom: "20px",
      paddingRight: 0,
      paddingLeft: 0,
      "& .MuiTypography-root": {
        textAlign: "center",
        // fontFamily: "GoogleSans",
        fontSize: "18px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.28,
        letterSpacing: "normal",
        color: "#354052",
      },
    },
  },
  MuiDialogContent: {
    root: {
      paddingTop: 0,
      paddingBottom: "40px",
      paddingRight: 0,
      paddingLeft: 0,
      "& .MuiTypography-root": {
        opacity: "0.44",
        // fontFamily: "GoogleSans",
        fontSize: "18px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.28",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#354052",
      },
    },
  },
  MuiDialogContentText: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0,
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: "center",
      padding: 0,
      "& .MuiSvgIcon-root": {
        display: "none",
      },
      "& .MuiButton-root": {
        padding: "8px 20px",
        borderRadius: "10px",
        backgroundColor: "#d5d3d3",
        fontSize: "12px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#777777",
      },
    },
    spacing: {
      "&> :not(:first-child)": {
        marginLeft: "32px",
      },
    },
  },
  RaConfirm: {
    confirmPrimary: {
      backgroundColor: `${primaryColor} !important`,
      color: "white!important",
    },
  },
};

export default RaConfirmOverrides;
