import React from "react";
import { DAYS } from "../constants";
interface MultiDaysFieldProps {
  days: number[];
}
export const MultiDaysField = (props: MultiDaysFieldProps) => {
  const { days } = props;

  return (
    <div className="listField">
      {days?.map((day, index) => {
        const labeledDay = DAYS.find((labeledDay) => day === labeledDay.key);
        return labeledDay ? (
          <span>
            {labeledDay.label}
            {index !== days.length - 1 ? "," : null}
          </span>
        ) : null;
      })}
    </div>
  );
};
