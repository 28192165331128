import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  DateField,
  ReferenceField,
  TextField,
  usePermissions,
  // @ts-ignore
} from "react-admin";
import { makeStyles } from "@material-ui/core";

// components
import ExpeditionFilters from "./ExpeditionFilters";
// import StickyTableCell from "@components/common/StickyTableCell";
// import DotsMenu from "@components/common/DotsMenu/DotsMenu";

import { useLocale } from "react-admin";

import { ExpeditionStatusField } from "@constants/expeditionStatus";

import { isEmpty } from "lodash";
import { NoDataTable } from "@components/common/NoDataTable";
// styles
import "./styles.css";
import { tableRowStyle } from "@theme/styles/table";
import { AdminRole, ExpeditionStatus } from "@wetrans/helpers";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";

// hooks
import { useOrganization } from "@hooks/useOrganization";
import StickyTableCell from "@components/common/StickyTableCell";
import MerchandiseCategoryField from "@components/common/MerchandiseCategoryField/MerchandiseCategoryField";
import TimeFieldEta from "./TimeFieldEta";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      {/* <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        className="createButtonAction"
        style={{
          background: primaryColor,
        }}
        icon={<ExportExported fill={"white"} />}
      /> */}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const ExpeditionList = (props: any) => {
  const listClasses = useListStyles();
  const { organization } = useOrganization();
  const { permissions } = usePermissions();

  return (
    <div className="container">
      <List
        {...props}
        actions={<ListActions />}
        empty={false}
        filter={permissions === AdminRole.super ? {} : { organization }}
        filters={<ExpeditionFilters />}
        classes={listClasses}
        title="expedition.expedition"
        pagination={<RaPrevNextPagination />}
        sort={{ field: "bookedAt", order: "desc" }}
      >
        <div className="list-container">
          <CustomersDataGrid />
          {permissions === AdminRole.super ? (
            <NoDataTable
              grid={[
                "expedition.humanReadableBillId",
                "expedition.date",
                "Heures de départ",
                "expedition.status",
                "expedition.from",
                "expedition.to",
                "Catégorie",
                "Tonnage (T)",
                "expedition.vehicle",
                "vehicle.category",
                "expedition.driver",
                "route.organization",
                "Client",
              ]}
            />
          ) : (
            <NoDataTable
              grid={[
                "expedition.humanReadableBillId",
                "expedition.date",
                "Heures de départ",
                "expedition.status",
                "expedition.from",
                "expedition.to",
                "Catégorie",
                "Tonnage (T)",
                "expedition.vehicle",
                "vehicle.category",
                "expedition.driver",
              ]}
            />
          )}
        </div>
      </List>
    </div>
  );
};

const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  const setLocale = useLocale();
  const { permissions } = usePermissions();
  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid
          rowClick={(id, basePath, record) =>
            record.status !== ExpeditionStatus.pending
              ? `/expedition/${id}/show`
              : ""
          }
          rowStyle={tableRowStyle}
          classes={classes}
        >
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">{props.humanReadableBillId}</div>
              );
            }}
            sortable={false}
            source="humanReadableBillId"
            label="expedition.humanReadableBillId"
          />

          <DateField
            locales={setLocale}
            sortable={false}
            source="date"
            label="expedition.date"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  <TimeFieldEta source="bookedMatch.eta.estimatedDepartureDateTime" />
                </div>
              );
            }}
            label="Heure de départ"
          />
          <FunctionField
            sortable={false}
            render={({ status }: any) => (
              <ExpeditionStatusField status={status} />
            )}
            label="expedition.status"
          />

          <ReferenceField
            link={false}
            sortable={false}
            label="expedition.from"
            source="from.city"
            reference="city"
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField
            link={false}
            sortable={false}
            label="expedition.to"
            source="to.city"
            reference="city"
          >
            <TextField source="name" />
          </ReferenceField>

          {/* <ReferenceField
            link="show"
            label="expedition.route"
            source="route"
            sortable={false}
            reference="route"
          >
            <TextField source="id" />
          </ReferenceField> */}
          <MerchandiseCategoryField
            link={false}
            sortable={false}
            label="Catégorie"
            source="merchandise.category"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props?.merchandise?.weight
                    ? props?.merchandise?.weight
                    : "N/A"}
                </div>
              );
            }}
            sortable={false}
            label="Tonnage (T)"
            source="merchandise.weight"
          />
          <FunctionField
            render={(props: any) => {
              return props.vehicle ? (
                <ReferenceField
                  link="show"
                  label="expedition.vehicle"
                  source="vehicle"
                  reference="vehicle"
                  sortable={false}
                >
                  <TextField source="matricule" />
                </ReferenceField>
              ) : (
                <div className="listField">N/A</div>
              );
            }}
            sortable={false}
            label="expedition.vehicle"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props?.bookedMatch?.vehicleCategoryName
                    ? props?.bookedMatch?.vehicleCategoryName
                    : "N/A"}
                </div>
              );
            }}
            sortable={false}
            label="vehicle.category"
            source="bookedMatch?.vehicleCategoryName"
          />
          <FunctionField
            render={(props: any) => {
              return props.driver ? (
                <ReferenceField
                  sortable={false}
                  link={(expedition: any) =>
                    `/driver/${expedition.driver}/show`
                  }
                  label="expedition.driver"
                  source="driver"
                  reference="users"
                >
                  <TextField source="name" />
                </ReferenceField>
              ) : (
                <div className="listField">N/A</div>
              );
            }}
            sortable={false}
            label="expedition.driver"
          />

          {permissions === AdminRole.super && (
            <FunctionField
              render={(props: any) => {
                return props.organization ? (
                  <ReferenceField
                    sortable={false}
                    link="show"
                    label="route.organization"
                    source="organization"
                    reference="organization"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                ) : (
                  <div className="listField">N/A</div>
                );
              }}
              sortable={false}
              label="route.organization"
            />
          )}
          {permissions === AdminRole.super && (
            <FunctionField
              render={(props: any) => {
                return props.client ? (
                  <ReferenceField
                    sortable={false}
                    label="Client"
                    source="client"
                    reference="users"
                    link={() => `/client/${props.client}/show`}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                ) : (
                  <div className="listField">N/A</div>
                );
              }}
              sortable={false}
              label="Client"
            />
          )}
          <StickyTableCell>
            <></>
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};

export default ExpeditionList;
