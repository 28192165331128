import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

// @ts-ignore
import {
  Title,
  usePermissions,

  // useTranslate
} from "react-admin";

import // useHistory
"react-router-dom";
import "./style.css";

import { Grid } from "@material-ui/core";
import DashboardCountCard from "@components/common/DashboardCountCard";
import TruckExported from "@assets/vectors/TruckExported";
import TurnoverExported from "@assets/vectors/TurnoverExported";
import ClientExported from "@assets/vectors/ClientExported";
import RouteExported from "@assets/vectors/RouteExported";
import Card from "@components/common/card/Card";
import CardTitle from "@components/common/card/CardTitle";
import { primaryColor } from "@theme/colors";
import {
  AdminRole,
  AnalyticsType,
  IAnalytics,
  IGlobalAnalytics,
} from "@wetrans/helpers";
import DashboardCountCardWithBreakDown from "@components/common/DashboardCountCardWithBreakDown";
import { ExpeditionPieChart } from "./ExpeditionPieChart";
import NewExpeditionsOffre from "./NewExpeditionsOffre";
import TurnoverLineChart from "./TurnoverLineChart";
import ExpeditionLineChart from "./ExpeditionLineChart";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  card: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
interface IDashboardComponentProps {
  analytic: IAnalytics;
}
const DashboardComponent = (props: IDashboardComponentProps) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const { analytic } = props;
  return (
    <div className={classes.root}>
      <React.Fragment>
        <Title title="general.dashborad" />

        <div style={{ paddingTop: 20 }}>
          <Grid container spacing={4}>
            {permissions !== AdminRole.support && (
              <Grid item sm={3}>
                {analytic.type === AnalyticsType.Organization ? (
                  <DashboardCountCard
                    name={"Chiffre d'affaire"}
                    number={analytic.turnover}
                    icon={
                      <TurnoverExported
                        width={44}
                        height={44}
                        fill={primaryColor}
                      />
                    }
                  />
                ) : (
                  <DashboardCountCardWithBreakDown
                    name={"Chiffre d'affaire"}
                    wetransNumber={
                      (analytic as IGlobalAnalytics).turnoverWetrans
                    }
                    transporterNumber={
                      (analytic as IGlobalAnalytics).turnoverTransporter
                    }
                    number={analytic.turnover}
                    icon={
                      <TurnoverExported
                        width={44}
                        height={44}
                        fill={primaryColor}
                      />
                    }
                  />
                )}
              </Grid>
            )}
            <Grid item sm={3}>
              {analytic.type === AnalyticsType.Organization ? (
                <DashboardCountCard
                  name={"Expéditions"}
                  number={analytic.expeditionCount}
                  icon={
                    <TurnoverExported
                      width={44}
                      height={44}
                      fill={primaryColor}
                    />
                  }
                />
              ) : (
                <DashboardCountCardWithBreakDown
                  name={"Expeditions"}
                  wetransNumber={
                    (analytic as IGlobalAnalytics).expeditionCountWetrans
                  }
                  transporterNumber={
                    (analytic as IGlobalAnalytics).expeditionCountTransporter
                  }
                  number={analytic.expeditionCount}
                  icon={
                    <TurnoverExported
                      width={44}
                      height={44}
                      fill={primaryColor}
                    />
                  }
                />
              )}
            </Grid>

            {analytic.type === AnalyticsType.Global && (
              <Grid item sm={2}>
                <DashboardCountCard
                  name={"Client"}
                  number={(analytic as IGlobalAnalytics).clientCount}
                  icon={
                    <ClientExported
                      width={44}
                      height={44}
                      fill={primaryColor}
                    />
                  }
                />
              </Grid>
            )}
            <Grid
              item
              sm={analytic.type === AnalyticsType.Organization ? 3 : 2}
            >
              {analytic.type === AnalyticsType.Organization ? (
                <DashboardCountCard
                  name={"Flotes"}
                  number={analytic.vehicleCount}
                  icon={
                    <TruckExported width={44} height={44} fill={primaryColor} />
                  }
                />
              ) : (
                <DashboardCountCardWithBreakDown
                  name={"Flotes"}
                  wetransNumber={
                    (analytic as IGlobalAnalytics).vehicleCountWetrans
                  }
                  transporterNumber={
                    (analytic as IGlobalAnalytics).vehicleCountTransporter
                  }
                  number={analytic.vehicleCount}
                  icon={
                    <TruckExported width={44} height={44} fill={primaryColor} />
                  }
                />
              )}
            </Grid>
            <Grid
              item
              sm={analytic.type === AnalyticsType.Organization ? 3 : 2}
            >
              {analytic.type === AnalyticsType.Organization ? (
                <DashboardCountCard
                  name={"Trajets"}
                  number={analytic.routeCount}
                  icon={
                    <RouteExported width={44} height={44} fill={primaryColor} />
                  }
                />
              ) : (
                <DashboardCountCardWithBreakDown
                  name={"Trajets"}
                  number={analytic.routeCount}
                  wetransNumber={
                    (analytic as IGlobalAnalytics).routeCountWetrans
                  }
                  transporterNumber={
                    (analytic as IGlobalAnalytics).routeCountTransporter
                  }
                  icon={
                    <RouteExported width={44} height={44} fill={primaryColor} />
                  }
                />
              )}
            </Grid>
            <Grid item sm={8}>
              <Card style={{ height: 300 }}>
                <CardTitle size="md">Expeditions</CardTitle>
                <ExpeditionPieChart {...analytic.expeditionPieChart} />
              </Card>
            </Grid>
            <Grid item sm={4}>
              <Card style={{ padding: 10 }}>
                <CardTitle size="md">Offres publiées</CardTitle>
                <NewExpeditionsOffre />
              </Card>
            </Grid>
            {permissions !== AdminRole.support && (
              <Grid item sm={12}>
                <Card>
                  <CardTitle size="md">Chiffre d'affaire(Accumulé)</CardTitle>
                  <TurnoverLineChart data={analytic.turnoverLineChart} />
                </Card>
              </Grid>
            )}
            <Grid item sm={12}>
              <Card>
                <CardTitle size="md">Expéditions(Accumulé)</CardTitle>
                <ExpeditionLineChart data={analytic.expeditionLineChart} />
              </Card>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    </div>
  );
};

export default DashboardComponent;
