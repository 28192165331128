import CustomLineChart, {
  ICustomLineChartProps,
} from "@components/common/charts/CustomLineChart";
import { ITurnoverEntryChart } from "@wetrans/helpers";
import React from "react";

import { useEffect, useState } from "react";
import { useListFilterContext } from "react-admin";
import { DashCard, DashCardContent } from "./styled";
import { transformDate } from "./transformDate";
interface TurnoverLineChartProps {
  data: ITurnoverEntryChart[];
}

const TurnoverLineChart = (props: TurnoverLineChartProps) => {
  const { filterValues } = useListFilterContext();
  const [interval, setInterval] = useState<"today" | "week" | "month" | "all">(
    filterValues.timeline ? filterValues.timeline : "all"
  );
  React.useEffect(() => {
    setInterval(filterValues.timeline ? filterValues.timeline : "all");
  }, [filterValues.timeline]);
  const [lineChartConfiguration, setLineChartConfiguration] = useState<
    ICustomLineChartProps
  >({
    data: transformDate(props.data ? props.data : [], interval),
    fields: [{ label: "Chiffre d'affaire", key: "turnover", color: "#80DEEA" }],
  });
  useEffect(() => {
    setLineChartConfiguration({
      data: transformDate(props.data ? props.data : [], filterValues.timeline),
      fields: [
        { label: "Chiffre d'affaire", key: "turnover", color: "#80DEEA" },
      ],
    });
  }, [filterValues.timeline, props.data]);
  return (
    <DashCard className={"dash-card-lead"}>
      <DashCardContent>
        <CustomLineChart {...lineChartConfiguration} />
      </DashCardContent>
    </DashCard>
  );
};

export default TurnoverLineChart;
