// in src/MyAppBar.js
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AppBar from "./Ra-AppBar";
import { useSelector } from "react-redux";
import { ReduxState } from "react-admin";

import { useMediaQuery, Theme } from "@material-ui/core";
import { connect } from "react-redux";
// import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH } from "./Ra-Sidebar";
import { Badge, IconButton } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Link } from "react-router-dom";
import { DriverAppLink } from "@components/common/DriverAppLink/DriverAppLink";
import { INotification } from "@wetrans/helpers/out/entities/Notification";
// import BURGER from "../assets/vectors/burger";
const humanizeDuration = require("humanize-duration");
const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 0.3,
    display: "flex",
    justifyContent: "flex-end",
  },
  root: {
    position: "fixed",
    top: "0px",
    transition: theme.transitions.create(["padding-left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  notificationView: {
    background: "white",
    position: "absolute",
    overflowY: "scroll",
    boxShadow: "0px 0px 6px -1px rgba(0,0,0,0.38)",
    top: 50,
    width: 250,
    maxHeight: "60vh",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 18,
    borderRadius: 5,
    textAlign: "left",
  },
}));

const MyAppBar = (props: any) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const classes = useStyles();

  const open = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <AppBar
      {...props}
      className={classes.root}
      style={{
        // paddingLeft: open ? DRAWER_WIDTH + 10 : CLOSED_DRAWER_WIDTH + 10, //DEAMON
        paddingLeft: isXSmall
          ? 10
          : isSmall
            ? open
              ? 240
              : 100
            : open
              ? 240
              : 100,
        transform: "",
        background: "white",
        paddingTop: 6,
        paddingBottom: 6,
        boxShadow: "0px -3px 12px 0px rgba(171,171,171,0.63)",
      }}
    >
      <div
        style={{
          flex: 1,
        }}
      >
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
          style={{
            color: "#434343",
            fontWeight: "bold",
          }}
        />
      </div>

      <div className={classes.spacer}>
        <DriverAppLink />

        <IconButton
          onClick={() => {
            setShowNotifications(!showNotifications);
          }}
        >
          <Badge
            badgeContent={
              props.notificationCenter?.length
                ? props.notificationCenter.length
                : 0
            }
            color="primary"
          >
            <NotificationsIcon style={{ color: "gray" }} />
          </Badge>
        </IconButton>
        {!(
          showNotifications &&
          props.notificationCenter &&
          props.notificationCenter.length
        ) ? null : (
          <div className={classes.notificationView}>
            {props.notificationCenter.map((notification: INotification) => (
              <Button
                onClick={() => {
                  setShowNotifications(!showNotifications);
                }}
                style={{ textTransform: "none" }}
                color="primary"
                component={Link}
                to={{
                  pathname: `/${notification.intent ? notification.intent : "#"
                    }`,
                }}
              >
                <div className={`flex direction-column justify-between`}>
                  {notification.content}
                  <span
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="caption" color="textSecondary">
                      {"Il y a " +
                        humanizeDuration(
                          new Date().getTime() -
                          (notification.createdAt as Date).getTime(),
                          {
                            language: "fr",
                            round: true,
                            units: ["d", "h", "m"],
                          }
                        )}
                    </Typography>
                  </span>
                </div>
              </Button>
            ))}
          </div>
        )}
      </div>
    </AppBar>
  );
};

const NotificationCenter = connect((state: any) => {
  return {
    notificationCenter: state.customReducer.notificationsCentral,
  };
})(MyAppBar);

export default NotificationCenter;
