import React from "react";
import ErrorList from "./ErrorList/ErrorList";
import Field from "./field";
import { scrollToView, isMixedField, extractMixedField } from "./helper";
import { FormValidatorContext, IFormValidator } from "./FormValidatorContext";
class FormValidator extends React.Component {
  formValidator: IFormValidator = {
    register: (field: Field) => {
      this.register(field);
    },
    activate: () => {
      this.formValidator.active = true;
      this.errorsListRef.current?.refresh();
    },
    unregisterAll: () => {
      this.formValidator.fields.length = 0;
      this.formValidator.active = false;
      this.errorsListRef.current?.refresh();
    },
    change: (errors: any, touched: any) => {
      this.change(errors, touched);
    },

    registerAll: (
      fields: (string | Field)[],
      prepareInView?: (name: string) => Promise<HTMLElement>
    ) => {
      fields.forEach((f) => {
        if (typeof f === "string")
          this.formValidator.register({
            name: f,
            prepareInView: prepareInView,
          });
        else {
          f.prepareInView = prepareInView;
          this.formValidator.register(f);
        }
      });
    },
    active: true,
    fields: [],
  };
  private errorsListRef = React.createRef<ErrorList>();

  render() {
    return (
      <FormValidatorContext.Provider value={this.formValidator}>
        {this.props.children}
        <ErrorList ref={this.errorsListRef} onSelect={this.select}></ErrorList>
      </FormValidatorContext.Provider>
    );
  }

  register = (field: Field) => {
    let index = this.formValidator.fields.findIndex((f: Field) => {
      return f.name === field.name;
    });
    if (index !== -1) return;
    if (!field.label) this.prepare(field);
    this.formValidator.fields.push(field);
  };
  select = (field: Field) => {
    if (!field.prepareInView) {
      let input = document.querySelector(
        `[name="${field.name}"]`
      ) as HTMLElement;
      if (input) scrollToView(input);
    } else {
      field.prepareInView(field.name).then((element) => {
        if (element) scrollToView(element);
      });
    }
  };

  prepare = (field: Field) => {
    if (!field || field.label) return;
    let label = document.querySelector(`[for="${field?.name}"]`) as HTMLElement;
    if (!label) {
      field.label = field.name;
      return;
    }
    let text: string | null;
    if (label.childNodes[0].nodeType === 1)
      text = label.childNodes[0].textContent;
    else text = label.textContent;
    field.label = text?.replace("*", "");
  };

  change = (errors: any, touched: any) => {
    this.formValidator.fields.forEach((f) => {
      if (isMixedField(f.name)) {
        let mixedField = extractMixedField(f.name);
        let error;
        try {
          error = errors[mixedField.parent][mixedField.index];
          if (error && touched[f.name]) f.error = this.translate(error.name);
          else f.error = undefined;
        } catch (e) {
          f.error = error;
        }
      } else {
        let error = errors[f.name];
        if (Array.isArray(error)) {
          if (error[0] !== f.error && touched[f.name] === true)
            f.error = error[0];
        } else if (error !== f.error && touched[f.name] === true) {
          if (error) f.error = this.translate(error);
          else f.error = error;
        }
      }
    });
    this.errorsListRef.current?.refresh();
  };
  translate = (error: string | any) => {
    let message: string;
    let result: string;
    if (typeof error === "string") message = error;
    else message = error.message;
    switch (message) {
      case "ra.validation.required":
        result = "Required";
        break;
      case "ra.validation.minValue":
        result = "Must be at least " + error.args.min;
        break;
      case "ra.validation.maxValue":
        result = "Must be " + error.args.max + " or less";
        break;
      default:
        result = message;
        break;
    }
    return result;
  };
}

export default FormValidator;
