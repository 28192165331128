import { Alert } from "@material-ui/lab";
import React from "react";
import { FormDataConsumer } from "react-admin";
interface ExpirationWarning {
  source: string;
  expiredMessage: string;
  closeMessage: string;
}
const ExpirationWarning = (props: ExpirationWarning) => {
  const { source, expiredMessage, closeMessage } = props;
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          {formData[source] && isExpired(new Date(formData[source])) && (
            <Alert variant="standard" severity="warning">
              {expiredMessage}
            </Alert>
          )}
          {formData[source] &&
            !isExpired(new Date(formData[source])) &&
            isClose(new Date(formData[source])) && (
              <Alert variant="standard" severity="warning">
                {closeMessage}
              </Alert>
            )}
        </>
      )}
    </FormDataConsumer>
  );
};
const isExpired = (date: Date): boolean => {
  const today = new Date();
  return date.getTime() < today.getTime();
};
const isClose = (date: Date): boolean => {
  const today = new Date();
  const difference = date.getTime() - today.getTime();
  return difference > 0 && difference < 30 * 24 * 60 * 60 * 1000;
};

export default ExpirationWarning;
