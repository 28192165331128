const BASEPATH = "/client/:id/show";

export const CLIENT_TABS = [
  {
    index: 0,
    name: "Info",
    path: `${BASEPATH}/info`,
    default: true,
  },
  {
    index: 1,
    name: "Expeditions",
    path: `${BASEPATH}/expedition`,
  },
];
