import React from "react";
import DaysPicker from "./DaysPicker";

import { FieldArray } from "react-final-form-arrays";
interface DaysPickerFieldProps {
  source: string;
}
export const DaysPickerField = (props: DaysPickerFieldProps) => {
  const { source } = props;
  return (
    <>
      <FieldArray
        validate={(value: any[]) => {
          // if (!value || !value.length) return "Veuillez choisir un jour";
        }}
        name={source}
      >
        {({ fields, meta }) => (
          <>
            <DaysPicker source={source} days={fields.value} />
            {/* {<span>{fields.value}</span>} */}
          </>
        )}
      </FieldArray>
    </>
  );
};
