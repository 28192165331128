import { AccountStatus } from "@wetrans/helpers";

export const accountStatus = [
  {
    id: AccountStatus.pending,
    label: "accountStatus.pending",
    color: "#ffcc00",
  },

  { id: AccountStatus.active, label: "accountStatus.active", color: "#83f25e" },

  {
    id: AccountStatus.suspended,
    label: "accountStatus.suspended",
    color: "#de0000",
  },
];
