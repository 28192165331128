import React from "react";

export default function PenExported(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.224"
      height="16.224"
      viewBox="0 0 16.224 16.224"
      {...props}
    >
      <g opacity="0.688">
        <g>
          <path
            fill="#11141a"
            d="M15.719 1.974L14.249.5a1.725 1.725 0 0 0-2.436 0L.874 11.443a.484.484 0 0 0-.131.25l-.735 3.97a.475.475 0 0 0 .554.554l3.969-.735a.484.484 0 0 0 .25-.131l10.938-10.94a1.722 1.722 0 0 0 0-2.437zM1.069 15.156l.445-2.4 1.956 1.955zm3.376-.814l-2.562-2.563 9.355-9.355L13.8 4.986zm10.6-10.6l-.575.575-2.561-2.565.575-.575a.773.773 0 0 1 1.092 0l1.47 1.47a.772.772 0 0 1 .001 1.092z"
          />
        </g>
      </g>
    </svg>
  );
}
