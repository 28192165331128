import React, {
  HtmlHTMLAttributes,
  ReactNode,
  useRef,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Card, Theme, CardMedia } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import { StaticContext } from "react-router";
import { useHistory } from "react-router-dom";
import { useCheckAuth, TitleComponent } from "ra-core";
import { Notification } from "react-admin";
import whiteLogo from "@assets/images/logo-white.png";
import "./styles.css";
import LoginBgExported from "@assets/vectors/LoginExported";
import { primaryColor } from "@theme/colors";
import { wetransConfig } from "@config/WETRANS_CONFIG";

export interface LoginProps
  extends Omit<HtmlHTMLAttributes<HTMLDivElement>, "title"> {
  backgroundImage?: string;
  children?: ReactNode;
  classes?: object;
  className?: string;
  staticContext?: StaticContext;
  theme?: object;
  title?: TitleComponent;
  icon?: React.ReactNode;
  forgetPasswordMode?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage:
        "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    },
    card: {
      boxShadow: "none",
      // marginTop: "6em",
    },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {},
  }),
  { name: "RaLogin" }
);

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login: React.FunctionComponent<LoginProps> = (props) => {
  const {
    theme,
    title,
    classes: classesOverride,
    className,
    children,
    staticContext,
    backgroundImage,
    icon,
    forgetPasswordMode,
    ...rest
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  let backgroundImageLoaded = false;
  const checkAuth = useCheckAuth();
  const history = useHistory();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push("/");
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <ThemeProvider theme={muiTheme}>
      {/* <div
          className={classnames(classes.main, className)}
          {...rest}
          ref={containerRef}
        >
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <CardMedia className={classes.icon}>{icon}</CardMedia>
            </div>
            {children}
          </Card>
          <Notification />
        </div> */}
      <div className="blockContainer" {...rest} ref={containerRef}>
        <LoginBgExported />
        <div
          className="centeredElements"
          style={{ backgroundColor: primaryColor }}
        >
          <div className="logLeftArea">
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <a
                target="_blank"
                href={wetransConfig.websiteUrl}
                rel="noopener noreferrer"
              >
                <img
                  src={whiteLogo}
                  alt="logo"
                  style={{ width: "168px" }}
                  className="coloredLogo"
                />
              </a>
            </div>
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                paddingTop: "2.5rem",
                // paddingBottom: "2.5rem",
              }}
              className="text-white font-bold py-10"
            >
              {!forgetPasswordMode
                ? "Bon retour parmis nous "
                : "Réinitialiser mon mot de passe"}
            </p>

            <div
              style={{
                alignSelf: "center",
              }}
            >
              <Card className={classnames(classes.card, "logCard")}>
                <div className={classes.avatar}>
                  <CardMedia className={classes.icon}>{icon}</CardMedia>
                </div>
                {children}
              </Card>
              <Notification />
            </div>
          </div>

          {/* <div className="logRightArea">
            <div className="rightArea" />
          </div> */}
        </div>
      </div>
    </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

Login.defaultProps = {
  icon: <LockIcon />,
};

export default Login;
