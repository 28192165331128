import React from "react";
import classNames from "classnames";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

// helpers
import { defaultBoxShadow, defaultBorderRadius } from "@theme/theme";

const useStyles = makeStyles(
  {
    card: {
      backgroundColor: "white",
      padding: "20px",
      boxShadow: defaultBoxShadow,
      borderRadius: defaultBorderRadius,
    },
  },
  { name: "GRM-card" }
);

export interface CardProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Card = ({ children, style, className }: CardProps) => {
  const classes = useStyles();

  return (
    <Paper
      className={classNames(classes.card, className)}
      elevation={0}
      style={style ? style : {}}
    >
      {children}
    </Paper>
  );
};

export default Card;
