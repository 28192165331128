import Card from "@components/common/card/Card";
import styled from "styled-components";

// components

export const DashCard = styled(Card)`
  height: 100%;
`;

export const DashCardContent = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
`;

export const DashCardDL = styled.dl`
  display: flex;
  height: 50px;
  align-items: center;

  dt {
    flex: 1;
    padding: 0 10px;
    text-align: right;
    font-size: 14px;
    color: inherit;
  }

  dd {
    flex: 1;
    padding: 0 10px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: inherit;
  }

  @media (max-width: 1467px) {
    display: block;
    margin-bottom: 10px;

    dt {
      font-size: 14px;
      text-align: center;
      /* font-weight: bold; */
    }

    dd {
      font-size: 20px;
      text-align: center;
    }
  }
`;

export const DashCardSplitBox = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .iconBox {
    margin-right: 10px;
  }

  dd {
    font-weight: bold;
    display: block;
    font-size: 25px;

    &.md {
      font-size: 32px;
    }
    &.lg {
      font-size: 43px;
    }
  }
`;
