import React from "react";
import firebase from "firebase";
import { useEffect } from "react";

interface DriverLivePosition {
  driverId: string;
  onChange: (newPosition: IPosition) => void;
}
interface IPosition {
  lat: number;
  lng: number;
}
interface IBackendPosition {
  latitude: number;
  longitude: number;
}
export const DriverLivePosition = (props: DriverLivePosition) => {
  const { driverId, onChange } = props;
  const db = firebase.app().database();

  useEffect(() => {
    const doc = db.ref("drivers/" + driverId);
    doc.on(
      "value",
      (snapshot) => {
        const newData = snapshot.val() as IBackendPosition;
        onChange({ lat: newData.latitude, lng: newData.longitude });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};
