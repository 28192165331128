import React from "react";
import {
  Filter,
  TextInput,
  DateInput,
  usePermissions,
  // ReferenceInput,
  // AutocompleteInput,
} from "react-admin";

// styles
import { primaryColor } from "@theme/colors";
import useStyles from "./ExpeditionFiltersStyles";
import classnames from "classnames";
import { ExpeditionStatusInput } from "@constants/expeditionStatus";

// import { withStyles } from "@material-ui/styles";
// import { useOrganization } from "@hooks/useOrganization";
import CityInput from "@components/common/CityInput/CityInput";
import { AdminRole } from "@wetrans/helpers";
import OrganizationInput from "@components/common/OrganizationInput/OrganizationInput";
import MerchandiseCategoryInput from "@components/common/MerchandiseCategoryInput/MerchandiseCategoryInput";
import VehicleCategoryInput from "@components/common/vehicleCategoriesSelecter/VehicleCategoryInput";

// const AutocompleteInputMod = withStyles({
//   suggestionsContainer: {
//     "& [class*='root']": {
//       backgroundColor: "white",
//       maxWidth: 400,
//       whiteSpace: "normal",
//       boxShadow: "rgb(0 0 0 / 8%) 0px 2px 5px 0px",
//     },
//   },
// })(AutocompleteInput);
const ExpeditionFilters = (props: any) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  // const { organization } = useOrganization();
  return (
    <Filter
      {...props}
      style={{
        background: primaryColor,
        borderRadius: 4,
        marginRight: 20,
      }}
      className={classnames(classes.formRoot, "filters")}
    >
      <TextInput
        label="expedition.humanReadableBillId"
        resettable
        source="humanReadableBillId"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />

      <DateInput
        label="expedition.date"
        resettable
        source="date"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <ExpeditionStatusInput label="expedition.status" source="status" />

      <CityInput label="expedition.from" source="from.city" />

      <CityInput label="expedition.to" source="to.city" />
      <MerchandiseCategoryInput
        allowEmpty={false}
        label="Catégorie"
        source="merchandise.category"
      />
      <TextInput
        label="vehicle.matricule"
        resettable
        source="bookedMatch.vehicleMatricule"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <VehicleCategoryInput
        source="bookedMatch.vehicleCategory"
        label="vehicle.category"
        allowEmpty={false}
      />
      {permissions === AdminRole.super && (
        <OrganizationInput
          allowEmpty={false}
          label="route.organization"
          source="organization"
        />
      )}
    </Filter>
  );
};

export default ExpeditionFilters;
