import React from "react";
import firebase from "firebase";
import { useEffect } from "react";
interface LiveUpdateProps<T> {
  resource: string;
  docId: string;
  onChange: (data: T) => Promise<void> | void;
}

export function LiveUpdate<T>(props: LiveUpdateProps<T>) {
  const { resource, docId: id, onChange } = props;
  const db = firebase.app().firestore();
  useEffect(() => {
    const doc = db.collection(resource).doc(id);
    doc.onSnapshot(
      (docSnapshot) => {
        const newData = docSnapshot.data();
        if (newData) {
          newData.id = id;
          onChange(newData as T);
        }
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}
