import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TopToolbar,
  useListContext,
  CreateButton,
  // ExportButton,
  sanitizeListRestProps,
  // useTranslate,
  // FileField,
  ReferenceField,
  TextField,
  usePermissions,
  // @ts-ignore
} from "react-admin";
import { makeStyles } from "@material-ui/core";

// components
// import ExportExported from "@assets/vectors/ExportExported";
import DriverFilters from "./DriverFilters";
import StickyTableCell from "@components/common/StickyTableCell";
import DotsMenu from "@components/common/DotsMenu/DotsMenu";

import { AccountStatusField } from "@constants/accountStatus";

import { isEmpty } from "lodash";
import { NoDataTable } from "@components/common/NoDataTable";
// styles
import { primaryColor } from "@theme/colors";
import "./styles.css";
import { tableRowStyle } from "@theme/styles/table";
import { useOrganization } from "@hooks/useOrganization";
import { AdminRole, UserType } from "@wetrans/helpers";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import { ApprovalStatusField } from "@constants/approvalStatus";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    // total,
  } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      {permissions === AdminRole.operation && (
        <CreateButton
          basePath={basePath}
          className="createButtonAction"
          style={{
            background: primaryColor,
          }}
          label="ra.action.add"
        />
      )}

      {/* <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        className="createButtonAction"
        style={{
          background: primaryColor,
        }}
        icon={<ExportExported fill={"white"} />}
      /> */}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const DriverList = (props: any) => {
  const listClasses = useListStyles();
  const { permissions } = usePermissions();
  const { organization } = useOrganization();
  const permanentFilter: any = {
    type: UserType.driver,
  };
  if (organization && permissions !== AdminRole.super)
    permanentFilter.organization = organization;
  return (
    <div className="container">
      <List
        {...props}
        resource="users"
        actions={<ListActions />}
        empty={false}
        filter={permanentFilter}
        filters={<DriverFilters />}
        classes={listClasses}
        title="driver.driver"
        pagination={<RaPrevNextPagination />}
        sort={{ field: "createdAt", order: "desc" }}
      >
        <div className="list-container">
          <CustomersDataGrid />
          {permissions === AdminRole.super ? (
            <NoDataTable
              grid={[
                "CIN",
                "driver.name",
                "driver.email",
                "driver.phone",
                "driver.accountStatus",
                "driver.organization",
                // "driver.license",
                "",
              ]}
            />
          ) : (
            <NoDataTable
              grid={[
                "CIN",
                "driver.name",
                "driver.email",
                "driver.phone",
                "driver.accountStatus",
                // "driver.license",
                "",
              ]}
            />
          )}
        </div>
      </List>
    </div>
  );
};

const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  // const t = useTranslate();
  const { permissions } = usePermissions();
  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid rowClick="show" rowStyle={tableRowStyle} classes={classes}>
          {/* <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.id}</div>;
            }}
            sortable={false}
            source="id"
            label="driver.id"
          /> */}
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.CIN}</div>;
            }}
            sortable={false}
            source="CIN"
            label="CIN"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.name}</div>;
            }}
            sortable={false}
            source="name"
            label="driver.name"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.email}</div>;
            }}
            sortable={false}
            source="email"
            label="driver.email"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.phone}</div>;
            }}
            sortable={false}
            source="phone"
            label="driver.phone"
          />
          {/* <FunctionField
            sortable={false}
            render={(props: any) => {
              return (
                <div
                  className="listBoolean"
                  style={{
                    background: props.online === true ? "#5fd997" : "#E74C3C",
                  }}
                >
                  {props.online
                    ? t("driver.onlineTruthy")
                    : t("driver.onlineFalsy")}
                </div>
              );
            }}
            label="driver.online"
          /> */}

          {/* <FileField
            source="license.src"
            sortable={false}
            title="license.title"
            label="driver.license"
          /> */}

          <FunctionField
            sortable={false}
            render={(props: any) => {
              return <AccountStatusField value={props.accountStatus} />;
            }}
            label="driver.accountStatus"
          />
          <FunctionField
            sortable={false}
            render={(props: any) => {
              return <ApprovalStatusField value={props.approvalStatus} />;
            }}
            label="general.approvalStatus"
          />
          {permissions === AdminRole.super ? (
            <ReferenceField
              sortable={false}
              link="show"
              label="vehicle.organization"
              source="organization"
              reference="organization"
            >
              <TextField source="name" />
            </ReferenceField>
          ) : null}

          {/* <ReferenceField
            sortable={false}
            link="show"
            label="driver.vehicle"
            source="vehicle"
            reference="vehicle"
          >
            <TextField source="matricule" />
          </ReferenceField> */}

          <StickyTableCell>
            <FunctionField
              render={(props: any) => {
                return (
                  <DotsMenu
                    record={props}
                    // delete={props.status === "active"}
                    // delete
                    edit

                    // deleteAction={() => {
                    //   deleteDriver({
                    //     type: "delete",
                    //     resource: "driver",
                    //     payload: {
                    //       id: props.id,
                    //     },
                    //   });
                    //   refresh();
                    // }}
                  />
                );
              }}
            />
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};

export default DriverList;
