import React from "react";

import { organizationsSelector } from "@state/selectors";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import OrganizationsFetcher from "./OrganizationsFetcher";

const OptimizedOrganizationsFetcher = () => {
  const fetchedOrganizations = useSelector(organizationsSelector);

  return <>{isEmpty(fetchedOrganizations) && <OrganizationsFetcher />}</>;
};

export default OptimizedOrganizationsFetcher;
