import { TableCell } from "@material-ui/core";
import React from "react";

const PassPropsTableCell = (props: any) => {
  const { children, record, resource, basePath, ...rest } = props;
  return (
    <TableCell {...rest}>
      {record &&
        React.cloneElement(children, {
          record,
          resource,
          basePath,
        })}
    </TableCell>
  );
};

export default PassPropsTableCell;
