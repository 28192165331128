import { format, lastDayOfMonth, startOfMonth, isThisMonth } from "date-fns";
import { fr } from "date-fns/locale";

export function getLocalDate(date: Date): string {
  if (date) return date.toLocaleDateString("fr");
  else return "";
}

export function getLocalTime(date: Date): string {
  if (date)
    return date.toLocaleTimeString("fr").split(":").slice(0, -1).join(":");
  else return "";
}
export function getDay(date: Date): string {
  const days = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  return days[date.getDay()];
}
export function getLastThreeMonths() {
  const today: Date = new Date();
  const dates = [1, 2, 3].map((item: any, index: number) => {
    if (item === 0) {
      return today.setMonth(today.getMonth());
    }
    return today.setMonth(today.getMonth() - 1);
  });

  const formattedDates = dates.map((date) => ({
    value: format(date, "MM-yyyy"),
    label: format(date, "MMMM  yyyy", {
      locale: fr,
    }),
    rawDate: date,
  }));

  return formattedDates;
}

export function getFirstAndLastDayOfMonth(date: Date | string) {
  if (date === undefined) {
    return { startOfMonth: undefined, endOfMonth: undefined };
  }

  const dayOfmonth = new Date(date);
  if (isThisMonth(dayOfmonth)) {
    return { startOfMonth: startOfMonth(dayOfmonth), endOfMonth: dayOfmonth };
  }

  return {
    startOfMonth: startOfMonth(dayOfmonth),
    endOfMonth: lastDayOfMonth(dayOfmonth),
  };
}
