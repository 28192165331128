// AppBar theme
export const appBarBackground = "transparent";
export const appBarHeight = 90;

// Sidebar
export const sidebarWidth = 170;

// Spacing
export const sideBarMenuItemPadding = `10px 12px`;

// Font Family
export const GoogleSansNormal = `'Product Sans', sans-serif`;
export const GoogleSansMedium = `'Product Sans Medium', sans-serif`;
export const GoogleSansBold = `'Product Sans Black', sans-serif`;

// Border
export const defaultBorderRadius = 8;
export const imgBorderRadius = 12;

// shadow
export const defaultBoxShadow = `0 2px 5px 0 rgba(0, 0, 0, 0.08)`;
