import React from "react";
import {
  List,
  Filter,
  Loading,
  useListContext,
  Record,
  usePermissions,
} from "react-admin";
import InfoIcon from "@material-ui/icons/Info";

import firebase from "firebase";

import NoDataPlaceholder from "@components/common/NoDataPlaceholder";
import DateIndicator from "@components/common/dateFilter/DateIndicator";
import { Typography } from "@material-ui/core";
import { TimeCard } from "./TimeCard";
import { useOrganization } from "@hooks/useOrganization";
import { SmartDiv } from "./SmartDiv";
import MonthFilter from "./monthFilter/MonthFilter";
import {
  AdminRole,
  AnalyticsTimeline,
  AnalyticsType,
  IAnalytics,
} from "@wetrans/helpers";
import DashboardComponent from "./DashboardComponent";

const WithAnalyticsController = () => {
  const { ids, data, filterValues } = useListContext();

  const [analytic, setAnalytic] = React.useState<Record | undefined>();
  const [startDate, setStartDate] = React.useState<Date | undefined>();
  const [endDate, setEndDate] = React.useState<Date | undefined>();

  React.useEffect(() => {
    setAnalytic(data && ids && data[ids[0]] ? data[ids[0]] : undefined);
  }, [data, ids]);

  React.useEffect(() => {
    if (analytic) {
      setStartDate(
        analytic.from instanceof firebase.firestore.Timestamp
          ? analytic.from.toDate()
          : new Date(analytic.from)
      );
      setEndDate(
        analytic.to instanceof firebase.firestore.Timestamp
          ? analytic.to.toDate()
          : new Date(analytic.to)
      );
    }
  }, [analytic]);

  // console.log({ data, ids, filterValues });
  if (!analytic) return <NoDataPlaceholder />;
  return (
    <>
      {startDate && endDate && (
        <DateIndicator startDate={startDate} endDate={endDate}></DateIndicator>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <InfoIcon fontSize="small" color="action" />
        <Typography variant="caption" color="textSecondary">
          Les chiffres du dashboard sont mis à jour chaque heure
        </Typography>
      </div>
      <TimeCard month={filterValues.month} />
      <DashboardComponent analytic={analytic as IAnalytics} />
    </>
  );
};

const AnalyticsList = () => {
  const { permissions, loaded } = usePermissions();
  const { organization } = useOrganization();
  if (!loaded) return <Loading />;

  const initProps = {
    basePath: "/",
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "analytics",
    perPage: 1,
  };

  const DashFilters = (props: any) => {
    return (
      <Filter {...props} className="filters">
        <MonthFilter source="timeline" alwaysOn />
      </Filter>
    );
  };

  return (
    <List
      {...initProps}
      filters={<DashFilters />}
      exporter={false}
      title="&#8203;"
      component={SmartDiv}
      pagination={false}
      // @ts-ignore
      sort={{}}
      filterDefaultValues={
        permissions === AdminRole.super
          ? { timeline: AnalyticsTimeline.All, type: AnalyticsType.Global }
          : {
              timeline: AnalyticsTimeline.All,
              organization: organization,
              type: AnalyticsType.Organization,
            }
      }
    >
      <WithAnalyticsController />
    </List>
  );
};

export default AnalyticsList;
