import phone from "phone";

export function transformPhoneNumber(phoneNumber: string): string {
  let E164PhoneNumber;
  if (phoneNumber.startsWith("+")) {
    E164PhoneNumber = phone(phoneNumber).phoneNumber;
  } else {
    E164PhoneNumber = phone(phoneNumber, {
      country: "MAR",
    }).phoneNumber;
  }
  return E164PhoneNumber ? E164PhoneNumber : phoneNumber;
}
