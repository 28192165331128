import { RouteFrequency } from "@wetrans/helpers";

export const routeFrequency = [
  {
    id: RouteFrequency.regular,
    label: "routeFrequency.regular",
    color: "#5fd997",
  },

  {
    id: RouteFrequency.irregular,
    label: "routeFrequency.irregular",
    color: "#5fd997",
  },
  {
    id: RouteFrequency.oneTime,
    label: "routeFrequency.oneTime",
    color: "#5fd997",
  },
];
