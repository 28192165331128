import * as React from "react";
import { functionsProvider } from "@providers/provider";
import {
  Create,
  TextInput,
  maxLength,
  minLength,
  email,
  SimpleForm,
  required,
  usePermissions,
} from "react-admin";
import { AdminCreateEditToolbar } from "./AdminCreateEditToolbar";
import { AccountStatusInput } from "@constants/accountStatus";

import { AdminRoleInput } from "@constants/adminRole";
import { AdminRole, UserType } from "@wetrans/helpers";
import { useOrganization } from "@hooks/useOrganization";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import Card from "@components/common/card/Card";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";

const validateName = [required(), minLength(3), maxLength(125)];
const validateCIN = [required(), minLength(3), maxLength(10)];

const validatePhone = [required(), minLength(3), maxLength(125)];
const validatePassword = [required(), minLength(6), maxLength(125)];
const validateTitle = [required(), minLength(3), maxLength(125)];
const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
}));

export default function AdminCreate(props: any) {
  const { permissions } = usePermissions();
  const { organization } = useOrganization();

  const emailValidation = React.useCallback(async (email: string) => {
    const { data } = await functionsProvider.isEmailUnique(email);
    if (!data) return "Adresse e-Mail déjà utilisée";
    else return undefined;
  }, []);

  const validateEmail = [email(), required(), emailValidation];
  const classes = useStyles();
  const transform = (data: any) => {
    return permissions !== AdminRole.super
      ? {
          ...data,
          type: UserType.admin,
          organization: organization,
          approvalStatus: ApprovalStatus.Pending,
        }
      : {
          ...data,
          type: UserType.admin,
        };
  };
  return (
    <Create
      {...props}
      transform={transform}
      title="Ajouter un nouvel Utilisateur"
    >
      <SimpleForm
        warnWhenUnsavedChanges
        // save={save}
        redirect="show"
        toolbar={
          <AdminCreateEditToolbar
            mode="create"
            style={{
              position: "absolute",
              top: 55,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 70,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              {permissions !== AdminRole.super && (
                <Grid item sm={6}>
                  <TextInput
                    label="CIN"
                    source="CIN"
                    className={classes.fullWidth}
                    validate={validateCIN}
                  />
                </Grid>
              )}
              <Grid item sm={6}>
                <TextInput
                  label="admin.email"
                  className={classes.fullWidth}
                  source="email"
                  validate={validateEmail}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="admin.name"
                  className={classes.fullWidth}
                  source="name"
                  validate={validateName}
                />
              </Grid>

              <Grid item sm={6}>
                <TextInput
                  label="admin.phone"
                  className={classes.fullWidth}
                  source="phone"
                  validate={validatePhone}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="admin.password"
                  className={classes.fullWidth}
                  source="password"
                  validate={validatePassword}
                />
              </Grid>
              {permissions !== AdminRole.super && (
                <Grid item sm={6}>
                  <TextInput
                    label="admin.title"
                    className={classes.fullWidth}
                    source="title"
                    validate={validateTitle}
                  />
                </Grid>
              )}
              <Grid item sm={6}>
                <AccountStatusInput
                  label="admin.accountStatus"
                  required
                  className={classes.fullWidth}
                  source="accountStatus"
                />
              </Grid>
              <Grid item sm={6}>
                <AdminRoleInput
                  mode="create"
                  required
                  label="admin.role"
                  className={classes.fullWidth}
                  source="role"
                />
              </Grid>

              {permissions !== AdminRole.super && (
                <Grid item sm={12}>
                  <MultiFIleInputs source="documents" label="Documents" />
                </Grid>
              )}
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Create>
  );
}
