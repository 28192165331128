import * as React from "react";

import { handleDisplayNum } from "./HandleDisplayNum";

export type IndicatorType =
  | "currency"
  | "count"
  | "decimal"
  | "distance"
  | "rating";

const DashboardCountCardWithBreakDown = ({
  name,
  number,
  type = "count",
  wetransNumber,
  transporterNumber,
  currency,
  icon,
  variation,
}: {
  name: string;
  number: number;
  wetransNumber: number;
  transporterNumber: number;
  type?: IndicatorType;
  currency?: string;
  icon?: React.ReactNode;
  variation?: number;
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        display: "flex",
        borderRadius: 6,
        overflow: "hidden",
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1.3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon && icon}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 2,
          justifyContent: "space-around",
          alignItems: "flex-start",
          paddingTop: 25,
          paddingBottom: 25,
          paddingLeft: 20,
          paddingRight: 10,
          textAlign: "left",
        }}
      >
        <div
          style={{
            fontSize: "17px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.24,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#354052",
          }}
        >
          {name && name}
        </div>
        <div
          style={{
            fontSize: "41px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.29,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#11141a",
          }}
        >
          {handleDisplayNum(number, false, "count")}
        </div>
        <div
          style={{
            // fontWeight: "bold",
            marginTop: 5,
            fontSize: "12px",
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#354052",
            // color: "#11141a",
          }}
        >
          <div
            style={{
              marginTop: 5,
            }}
          >
            Wetrans:{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {handleDisplayNum(wetransNumber, false, "count")}
            </span>{" "}
            (
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {number ? ((wetransNumber / number) * 100).toFixed(0) : 0}
            </span>
            %)
          </div>
          <div
            style={{
              marginTop: 5,
            }}
          >
            Transporteur:{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {" "}
              {handleDisplayNum(transporterNumber, false, "count")}
            </span>{" "}
            (
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {number ? ((transporterNumber / number) * 100).toFixed(0) : 0}
            </span>
            %)
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCountCardWithBreakDown;
