import React from "react";

import classnames from "classnames";
import Moment from "react-moment";

import styles from "./Step.module.css";

const Step = ({
  label,
  date,
  condition,
  status = -1,
}: {
  label: string;
  condition: number;
  date?: Date;
  status?: number;
}) => {
  return (
    <div
      className={classnames(styles.step, {
        [styles.active as string]: status > condition,
      })}
    >
      <p className="font-bold">{label}</p>
      {date && status > condition && (
        <>
          <div>
            <Moment format="HH:mm" date={date} />
          </div>
          <div>
            <Moment format="DD/MM/YYYY" date={date} />
          </div>
        </>
      )}
    </div>
  );
};

export default Step;
