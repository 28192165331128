import React, { FC } from "react";
import { useSelector } from "react-redux";
import { merchandiseCategoriesSelector } from "@state/selectors";
import { AutocompleteInput, TextInputProps } from "react-admin";
import { MerchandiseCategory } from "@wetrans/helpers";

const MerchandiseCategoryInput: FC<TextInputProps> = (props: any) => {
  const { source, label } = props;
  const merchandiseCategories: MerchandiseCategory[] = useSelector(
    merchandiseCategoriesSelector
  );
  return (
    <>
      <AutocompleteInput
        source={source}
        label={label}
        allowEmpty={false}
        resettable
        choices={merchandiseCategories}
        options={{
          suggestionsContainerProps: {
            style: {
              background: "white",
            },
          },
        }}
        {...props}
      ></AutocompleteInput>
    </>
  );
};

export default MerchandiseCategoryInput;
