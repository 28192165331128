import React from "react";
import { Pagination, PaginationProps } from "react-admin";
import CustomPaginationActions from "./CustomPaginationActions";

const Empty = (props: any) => <div>{""}</div>;

export const RaPrevNextPagination = (props: PaginationProps) => (
  <Pagination
    {...props}
    rowsPerPageOptions={[10, 25, 50, 100]}
    actions={CustomPaginationActions}
    limit={<Empty />}
  />
);
