import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import frenchMessages from "ra-language-french";

import labels from "./en";
import frenchLabels from "./fr";

const messagesResolver = (locale) => {
  if (locale === "en") {
    return { ...englishMessages, ...labels };
  }
  if (locale === "fr") {
    return {
      ...frenchLabels,
      ...frenchMessages,
    };
  }

  return {};
};
const i18nProvider = polyglotI18nProvider(messagesResolver, "fr");
export default i18nProvider;
