import React from "react";

import { citiesSelector } from "@state/selectors";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import CitiesFetchers from "./CititesFetcher";

const OptimizedCitiesFetcher = () => {
  const fetchedCities = useSelector(citiesSelector);

  return <>{isEmpty(fetchedCities) && <CitiesFetchers />}</>;
};

export default OptimizedCitiesFetcher;
