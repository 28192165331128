import { AnalyticsTimeline } from "@wetrans/helpers";
import React from "react";

import { getTimeDiffString } from "./timeDifference";

export const TimeCard = ({ month }: { month: string }) => {
  const [awaitingTimeString, setAwaitingTimeString] = React.useState<
    String | undefined
  >();
  const [elapsedTimeRatio, setElapsedTimeRatio] = React.useState<
    number | undefined
  >();

  React.useEffect(() => {
    if (
      ([
        AnalyticsTimeline.Today,
        AnalyticsTimeline.Week,
        AnalyticsTimeline.Month,
      ] as string[]).includes(month)
    ) {
      const now = new Date();
      const start = new Date(now);
      const end = new Date(now);
      if (month === AnalyticsTimeline.Today) {
        start.setHours(0, 0, 0, 0);
        end.setDate(end.getDate() + 1);
        end.setHours(0, 0, 0, 0);
      }
      if (month === AnalyticsTimeline.Week) {
        start.setDate(start.getDate() - ((start.getDay() + 6) % 7));
        start.setHours(0, 0, 0, 0);
        end.setDate(end.getDate() + ((1 + 7 - end.getDay()) % 7 || 7));
        end.setHours(0, 0, 0, 0);
      }
      if (month === AnalyticsTimeline.Month) {
        start.setDate(1);
        start.setHours(0, 0, 0, 0);
        end.setMonth(end.getMonth() + 1);
        end.setDate(1);
        end.setHours(0, 0, 0, 0);
      }
      setAwaitingTimeString(getTimeDiffString(now, end));
      setElapsedTimeRatio(
        (now.getTime() - start.getTime()) / (end.getTime() - start.getTime())
      );
    } else {
      setAwaitingTimeString("");
      setElapsedTimeRatio(undefined);
    }
  }, [month]);

  return (
    <>
      <br />
      <div>
        {/* {!awaitingTimeString && (
          <dt>
            <b>Archive:</b> <small>Mois {month}</small>
          </dt>
        )} */}
        {awaitingTimeString && (
          <dt>
            <b>Temps Restant: </b>
            <span>
              {awaitingTimeString}
              {elapsedTimeRatio ? (
                <small>
                  {" "}
                  (&Eacute;coul&eacute;: {(elapsedTimeRatio * 100).toFixed(
                    0
                  )}{" "}
                  %)
                </small>
              ) : null}
            </span>
          </dt>
        )}
      </div>
    </>
  );
};
