import * as React from "react";
import {
  Edit,
  TextInput,
  maxLength,
  minLength,
  DateInput,
  // ReferenceInput,
  // AutocompleteInput,
  SimpleForm,
  usePermissions,
  FileInput,
  FileField,
  required,
  FunctionField,
  // ArrayInput,
  // SimpleFormIterator,
  // @ts-ignore
} from "react-admin";
import MyTitle from "@components/common/View/Title";
// import { AccountStatusInput } from "@constants/accountStatus";

import { OrganizationTypeInput } from "@constants/organizationType";
import { AdminRole } from "@wetrans/helpers";
import Card from "@components/common/card/Card";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import CityInput from "@components/common/CityInput/CityInput";
import { handleDisplayNum } from "@components/common/HandleDisplayNum";
import { RatingField } from "@components/common/RatingField/RatingField";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";

const validateName = [required(), minLength(3), maxLength(125)];
const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export const fileInputStyles = makeStyles((theme: Theme) => ({
  root: {},
  dropZone: {
    display: "none",
  },
  removeButton: {
    "& button": {
      display: "none",
    },
  },
}));
export default function OrganizationEdit(props: any) {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const inputStyle = fileInputStyles();
  const { Toolbar, setEditMode } = props;
  // const [enableWaring, setEnableWarning] = React.useState(true);
  return (
    <div className="editContainer">
      <Edit
        {...props}
        className="edit"
        transform={(data) => {
          return {
            ...data,
            approvalStatus: ApprovalStatus.Pending,
            updateFromFrontend: true,
          };
        }}
        hasShow={false}
        title={
          permissions === AdminRole.super ? (
            <MyTitle resource={props.resource} descriptionAttribute="name" />
          ) : (
            "Profil"
          )
        }
        // onSuccess={() => setEditMode(false)}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          // initialValues={}
          redirect={(record: any) => {
            setEditMode(false);
            return "show";
          }}
          toolbar={
            <Toolbar
              {...props}
              mode="edit"
              style={{
                position: "absolute",
                // background: "#f7f8fa",
                top: 55,
              }}
            />
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 70,
            }}
          >
            <Card>
              <Grid container className={classes.fullWidth} spacing={4}>
                <Grid item sm={6}>
                  <TextInput
                    label="organization.ICE"
                    disabled
                    source="ICE"
                    className={classes.fullWidth}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextInput
                    label="organization.name"
                    source="name"
                    disabled={
                      permissions === AdminRole.super ||
                      permissions === AdminRole.support
                    }
                    className={classes.fullWidth}
                    validate={validateName}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextInput
                    label="organization.phone"
                    source="phone"
                    disabled={
                      permissions === AdminRole.super ||
                      permissions === AdminRole.support
                    }
                    className={classes.fullWidth}
                    // validate={validateName} TODO: add phone validation
                  />
                </Grid>
                <Grid item sm={6}>
                  <DateInput
                    label="organization.createdAt"
                    disabled
                    className={classes.fullWidth}
                    source="createdAt"
                  />
                </Grid>

                <Grid item sm={6}>
                  <OrganizationTypeInput
                    label="organization.type"
                    className={classes.fullWidth}
                    source="type"
                    disabled={
                      permissions === AdminRole.super ||
                      permissions === AdminRole.support
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <CityInput
                    className={classes.fullWidth}
                    label="Ville d'origine"
                    source="cityOfOrigin"
                    disabled={
                      permissions === AdminRole.super ||
                      permissions === AdminRole.support
                    }
                    validate={[required()]}
                  />
                </Grid>
                <Grid item sm={6}>
                  {(permissions === AdminRole.super ||
                    permissions === AdminRole.support) && (
                    <span>Fichier status entreprise</span>
                  )}
                  <FileInput
                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                    maxSize={10000000}
                    validate={[required()]}
                    classes={
                      permissions === AdminRole.super ||
                      permissions === AdminRole.support
                        ? inputStyle
                        : undefined
                    }
                    source="fileStatus"
                    label={
                      permissions === AdminRole.super ||
                      permissions === AdminRole.support
                        ? " "
                        : "Fichier status entreprise"
                    }
                    disabled={
                      permissions === AdminRole.super ||
                      permissions === AdminRole.support
                    }
                  >
                    <FileField source="src" title="title" target="_blank" />
                  </FileInput>
                </Grid>

                <Grid item sm={6}>
                  <div className="flex flex-wrap direction-column margin-b-10">
                    <label className="margin-b-10 margin-t-10">
                      <strong>Note :</strong>
                    </label>
                    <FunctionField
                      render={(record: any) => {
                        return (
                          <>
                            <RatingField
                              value={record.rating ? record.rating : 0}
                            />{" "}
                            <span
                              style={{
                                display: "inline-flex",
                                marginLeft: 5,

                                position: "relative",
                                top: -5,
                              }}
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {`${handleDisplayNum(
                                  record.ratingCount ? record.ratingCount : 0,
                                  false,
                                  "count"
                                )} avis`}
                              </Typography>
                            </span>
                          </>
                        );
                      }}
                      sortable={false}
                      label="organization.rating"
                    />
                  </div>
                </Grid>
                <Grid item sm={12}>
                  <MultiFIleInputs source="documents" label="Documents" />
                </Grid>
              </Grid>
            </Card>
          </div>
        </SimpleForm>
      </Edit>
    </div>
  );
}
