import React from "react";
import Field from "./field";

const FormValidatorContext = React.createContext<IFormValidator>({
  register: (field: Field) => {},
  activate: () => {},
  unregisterAll: () => {},
  registerAll: (
    fields: (string | Field)[],
    prepareInView?: (name: string) => Promise<HTMLElement>
  ) => {},
  change: (errors: any) => {},
  active: false,
  fields: [],
});
export interface IFormValidator {
  register: (field: Field) => void;
  fields: Field[];
  active: boolean;
  activate: () => void;
  unregisterAll: () => void;
  change: (errors: any, touched: any) => void;
  registerAll: (
    fields: (string | Field)[],
    prepareInView?: (name: string) => Promise<HTMLElement>
  ) => void;
}
const useFormValidator = () => {
  const context = React.useContext(FormValidatorContext);
  if (context === undefined) {
    throw new Error(
      "useFormValidator must be used within a FormValidatorContext"
    );
  }
  return context;
};
export { FormValidatorContext, useFormValidator };
