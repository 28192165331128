import { GoogleSansMedium } from "@theme/theme";
import { makeStyles } from "@material-ui/core/styles";
export const tableRowStyle = (record?: any, index?: any) => ({
  backgroundColor: "white",
  padding: 20,
  fontFamily: GoogleSansMedium,
  fontSize: 13,
  color: "#354052",
  borderLeft:
    record && typeof record.persistence == "boolean"
      ? record.persistence
        ? "4px solid green"
        : "4px solid red"
      : "4px solid white",
});
export const customTableRowStyle = (record?: any, index?: any) => ({
  backgroundColor: "white",
  padding: 20,
  fontFamily: GoogleSansMedium,
  fontSize: 13,
  color: "#354052",
});

export const useTableStyles = makeStyles(
  {
    rightAlignCell: {
      textAlign: "right",
      display: "block",
    },
  },
  { name: "Custom-table" }
);
