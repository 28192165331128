import React from "react";

import { ExpeditionStatus } from "@wetrans/helpers";

import Step from "./Step";

const ProgressSteppers = ({ expedition }: { expedition: any }) => {
  return (
    <div>
      <div className="flex flex-wrap justify-between">
        {expedition?.status === ExpeditionStatus.cancelled ? (
          <Step
            label="Reservée"
            date={
              expedition?.bookedAt
                ? new Date(
                    expedition?.bookedAt.seconds
                      ? expedition?.bookedAt.seconds * 1000
                      : expedition?.bookedAt
                  )
                : undefined
            }
            condition={0}
            status={1}
          />
        ) : (
          <Step
            label="Reservée"
            date={
              expedition?.bookedAt
                ? new Date(
                    expedition?.bookedAt.seconds
                      ? expedition?.bookedAt.seconds * 1000
                      : expedition?.bookedAt
                  )
                : undefined
            }
            condition={0}
            status={expedition?.status}
          />
        )}

        {expedition?.status === ExpeditionStatus.cancelled &&
          expedition?.cancellationReport && (
            <Step
              label="Annulé"
              date={
                expedition?.cancellationReport.cancelledAt
                  ? new Date(
                      expedition?.cancellationReport.cancelledAt.seconds
                        ? expedition?.cancellationReport.cancelledAt.seconds *
                          1000
                        : expedition?.cancellationReport.cancelledAt
                    )
                  : undefined
              }
              condition={1}
              status={2}
            />
          )}
        <Step
          label="Validé"
          date={
            expedition?.validatedAt
              ? new Date(
                  expedition?.validatedAt.seconds
                    ? expedition?.validatedAt.seconds * 1000
                    : expedition?.validatedAt
                )
              : undefined
          }
          condition={1}
          status={expedition?.status}
        />
        <Step
          label="Départ"
          date={
            expedition?.startedAt
              ? new Date(
                  expedition?.startedAt.seconds
                    ? expedition?.startedAt.seconds * 1000
                    : expedition?.startedAt
                )
              : undefined
          }
          condition={2}
          status={expedition?.status}
        />
        <Step
          label="Point de chargement"
          date={
            expedition?.arrivedAtSourceLocationAt
              ? new Date(
                  expedition?.arrivedAtSourceLocationAt.seconds
                    ? expedition?.arrivedAtSourceLocationAt.seconds * 1000
                    : expedition?.arrivedAtSourceLocationAt
                )
              : undefined
          }
          condition={3}
          status={expedition?.status}
        />
        <Step
          label="Chargement"
          date={
            expedition?.loadedAt
              ? new Date(
                  expedition?.loadedAt.seconds
                    ? expedition?.loadedAt.seconds * 1000
                    : expedition?.loadedAt
                )
              : undefined
          }
          condition={4}
          status={expedition?.status}
        />
        <Step
          label="En route"
          date={
            expedition?.inRouteAt
              ? new Date(
                  expedition?.inRouteAt.seconds
                    ? expedition?.inRouteAt.seconds * 1000
                    : expedition?.inRouteAt
                )
              : undefined
          }
          condition={5}
          status={expedition?.status}
        />
        <Step
          label="Arrivé"
          date={
            expedition?.arrivedAtDestinationLocationAt
              ? new Date(
                  expedition?.arrivedAtDestinationLocationAt.seconds
                    ? expedition?.arrivedAtDestinationLocationAt.seconds * 1000
                    : expedition?.arrivedAtDestinationLocationAt
                )
              : undefined
          }
          condition={6}
          status={expedition?.status}
        />
        <Step
          label="Déchargement"
          date={
            expedition?.unloadedAt
              ? new Date(
                  expedition?.unloadedAt.seconds
                    ? expedition?.unloadedAt.seconds * 1000
                    : expedition?.unloadedAt
                )
              : undefined
          }
          condition={7}
          status={expedition?.status}
        />
        <Step
          label="Livré"
          date={
            expedition?.deliveredAt
              ? new Date(
                  expedition?.deliveredAt.seconds
                    ? expedition?.deliveredAt.seconds * 1000
                    : expedition?.deliveredAt
                )
              : undefined
          }
          condition={8}
          status={expedition?.status}
        />
        <Step
          label="Réception"
          date={
            expedition?.confirmedAt
              ? new Date(
                  expedition?.confirmedAt.seconds
                    ? expedition?.confirmedAt.seconds * 1000
                    : expedition?.confirmedAt
                )
              : undefined
          }
          condition={9}
          status={expedition?.status}
        />
      </div>
    </div>
  );
};

ProgressSteppers.defaultProps = {
  expedition: {},
};

export default ProgressSteppers;
