import { primaryColor, secondaryColor } from "./colors";
import AutocompleteOverride from "./overrides/autocomplete";
import RaConfirmOverrides from "./overrides/dialog";
import tableActions from "./overrides/table-actions";
import RaTablePagination from "./overrides/table-pagination";

const customTheme = {
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    type: "light", // Switching the dark mode on is a single property value change.
    background: {
      default: "#f7f8fa",
    },
  },
  overrides: {
    ...RaConfirmOverrides,
    ...RaTablePagination,
    ...tableActions,
    ...AutocompleteOverride,
  },
};

export default customTheme;
