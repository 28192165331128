import React from "react";
import Dialog from "@components/common/Dialog";

const emptyModal: ModalContent = {
  title: "",
  content: "",
};

const useDialogState: () => DialogContext = () => {
  const [modal, setModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<ModalContent>(
    emptyModal
  );
  const [actionWrapper, setActionWrapper] = React.useState<{
    action: (() => void) | null;
  }>({ action: null });

  const setAction = (action: () => void) => {
    setActionWrapper({ action });
  };

  const openModal = (content: ModalContent, action: () => void) => {
    setModal(true);
    setModalContent(content);
    setAction(action);
  };

  const closeModal = (success: boolean) => {
    setModal(!modal);
    if (success && actionWrapper && actionWrapper.action)
      actionWrapper.action();
  };

  return { modal, closeModal, modalContent, openModal };
};

interface ModalContent {
  title: string;
  content: string;
}

interface DialogContext {
  modal: boolean;
  modalContent: ModalContent;
  openModal: (
    content: DialogContext["modalContent"],
    action: () => void
  ) => void;
  closeModal: (success: boolean) => void;
}

let dialogContext: React.Context<DialogContext>;
const { Provider } = (dialogContext = React.createContext<DialogContext>({
  modal: false,
  modalContent: emptyModal,
  openModal: () => null,
  closeModal: () => null,
}));

const DialogProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { modal, modalContent, openModal, closeModal } = useDialogState();
  return (
    <Provider value={{ modal, closeModal, modalContent, openModal }}>
      <Dialog />
      {children}
    </Provider>
  );
};

export { dialogContext };
export default DialogProvider;
