import React, { useEffect, useState } from "react";
import {
  Confirm,
  SaveButton,
  Toolbar,
  useEditController,
  useGetIdentity,
  useMutation,
  usePermissions,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useForm } from "react-final-form";
import { AdminRole } from "@wetrans/helpers";
import { AdminApprovalButtons } from "./AdminApprovalButtons";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import { DeleteButton } from "./DeleteButton";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});
interface WithAdminApprovalProps {
  EditComponent: React.FC<EditComponentProps>;
  ShowComponent: React.FC<EditComponentProps>;
  Toolbar?: React.FC<EditComponentProps>;
  adminApproveWarningMessage: string;
  adminDisapproveWarningMessage: string;
  warningMessage: string;
  warningMessageTitle: string;
  enableDelete?: boolean;
  isDeletable?: (record: any) => Promise<void>;
  transformOnDelete?: (record: any, deletedById: string) => any;
  deleteConfirmationMessage?: string;
}
interface EditComponentProps {
  setEditMode: (value: boolean) => void;
  editMode: boolean;
  // onSuccess: () => void;
  setRecordApprovalStatus?: (value: ApprovalStatus) => void;
  recordApprovalStatus?: ApprovalStatus;
  Toolbar?: any;
}

export const WithAdminApproval = (props: WithAdminApprovalProps) => {
  const { EditComponent, ShowComponent, Toolbar } = props;
  const [editMode, setEditMode] = useState<boolean>(false);

  return editMode ? (
    <EditComponent
      setEditMode={setEditMode}
      {...props}
      editMode={editMode}
      Toolbar={Toolbar ? Toolbar : EditModeToolbar}
      // onSuccess={onSuccess}
    />
  ) : (
    <ShowComponent
      setEditMode={setEditMode}
      {...props}
      // onSuccess={onSuccess}
      // setRecordApprovalStatus={setRecordApprovalStatus}
      // recordApprovalStatus={recordApprovalStatus}
      editMode={editMode}
      Toolbar={Toolbar ? Toolbar : EditModeToolbar}
    />
  );
};

export const EditModeToolbar = (props: any) => {
  const {
    setEditMode,
    editMode,
    resource,
    id,
    warningMessage,
    adminApproveWarningMessage,
    adminDisapproveWarningMessage,
    enableDelete,
    isDeletable,
    transformOnDelete,
    deleteConfirmationMessage,
  } = props;
  const [recordApprovalStatus, setRecordApprovalStatus] = useState<
    ApprovalStatus | undefined
  >();
  const form = useForm();
  const [updateRecord] = useMutation();
  const { loaded: identityLoaded, identity } = useGetIdentity();
  const { record, loaded } = useEditController(props);
  const [deletable, setDeletable] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    async function checkIfIsDeletable(record: any) {
      const isDeletableBoolean = await isDeletable?.(record);
      setDeletable(isDeletableBoolean);
    }
    if (loaded && record) {
      setRecordApprovalStatus(record.approvalStatus);
      if (enableDelete && !record.deleted) {
        checkIfIsDeletable(record);
      }
    }
  }, [record, loaded, setRecordApprovalStatus, isDeletable, enableDelete]);
  const onDelete = React.useCallback(async () => {
    await updateRecord({
      type: "update",
      resource: resource,
      payload: {
        data: transformOnDelete
          ? transformOnDelete(record, identity?.id)
          : {
              ...record,
              deleted: true,
              deletedAt: new Date(),
              deletedBy: identity?.id,
            },
        id: id,
      },
    });
    // refresh();
  }, [id, identity, record, resource, transformOnDelete, updateRecord]);
  const onAccept = React.useCallback(async () => {
    await updateRecord({
      type: "update",
      resource: resource,
      payload: {
        data: {
          ...record,
          approvalStatus: ApprovalStatus.Approved,
          updateFromFrontend: true,
        },
        id: id,
      },
    });
    // refresh();
  }, [id, record, resource, updateRecord]);
  const onReject = React.useCallback(async () => {
    await updateRecord({
      type: "update",
      resource: resource,
      payload: {
        data: {
          ...record,
          approvalStatus: ApprovalStatus.Disapproved,
          updateFromFrontend: true,
        },
        id: id,
      },
    });
    // refresh();
  }, [id, record, resource, updateRecord]);

  const [isOpen, setIsOpen] = React.useState(false);
  const { permissions } = usePermissions();
  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const handleConfirm = React.useCallback(() => {
    setIsOpen(false);
    setEditMode(true);
  }, [setEditMode]);
  return identityLoaded ? (
    <Toolbar {...props} classes={classes}>
      {permissions === AdminRole.super ? (
        <>
          {recordApprovalStatus && record && !(record as any).deleted && (
            <AdminApprovalButtons
              acceptWarningMessage={adminApproveWarningMessage}
              rejectWarningMessage={adminDisapproveWarningMessage}
              hideReject={recordApprovalStatus === ApprovalStatus.Disapproved}
              hideAccept={recordApprovalStatus === ApprovalStatus.Approved}
              onAccept={onAccept}
              onReject={onReject}
            />
          )}
        </>
      ) : (
        <>
          {editMode && (
            <>
              <SaveButton
                handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                disabled={props.pristine}
                invalid={props.invalid}
                // onSuccess={onSuccess}
                transform={(data) => {
                  return {
                    ...data,
                    approvalStatus: ApprovalStatus.Pending,
                    updateFromFrontend: true,
                  };
                }}
                label="Sauvegarder"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setEditMode(false);
                  form.reset();
                }}
                style={{
                  marginLeft: 20,
                }}
              >
                {"Annuler"}
              </Button>
            </>
          )}
          {!editMode && (
            <>
              {record && !(record as any).deleted && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    (identity as any)?.approvalStatus !==
                    ApprovalStatus.Approved
                  }
                  onClick={() => {
                    if (
                      (identity as any)?.approvalStatus ===
                      ApprovalStatus.Approved
                    ) {
                      form.reset();
                      setIsOpen(true);
                    }
                  }}
                  style={
                    {
                      // width: "100%",
                      // color: "white",
                    }
                  }
                >
                  {"Modifier"}
                </Button>
              )}
              {(identity as any).approvalStatus === ApprovalStatus.Approved &&
                record &&
                !(record as any).deleted &&
                deletable && (
                  <DeleteButton
                    confirmTitle=""
                    deleteWarningMessage={deleteConfirmationMessage}
                    onDelete={onDelete}
                  />
                )}
            </>
          )}

          <Confirm
            isOpen={isOpen}
            title={" "}
            content={warningMessage}
            onConfirm={handleConfirm}
            onClose={handleClose}
          />
        </>
      )}
    </Toolbar>
  ) : null;
};
