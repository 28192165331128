import * as React from "react";
import {
  Edit,
  // @ts-ignore
} from "react-admin";
import MyTitle from "@components/common/View/Title";
import { getTabIndex } from "@components/common/helpers";
import { useHistory } from "react-router";
import { find } from "lodash";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@components/common/TabPanel";
import { CLIENT_TABS } from "./constants";
import { ExpeditionPortableList } from "@components/expedition/ExpeditionPortableList";
import ClientShowEdit from "./ClientShowEdit";

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
export default function ClientDetail(props: any) {
  // const { permissions } = usePermissions();
  // const { organization } = useOrganization();
  const history = useHistory();
  const {
    location,
    match: {
      params: { id },
    },
  } = props;
  const _tabIndex = getTabIndex(location.pathname, CLIENT_TABS, id);
  const [tabIndex, setTabIndex] = React.useState<Number>(_tabIndex);

  if (tabIndex !== _tabIndex) {
    setTabIndex(_tabIndex);
  }
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const tab = find(CLIENT_TABS, { index: newValue });
    tab && history.push(tab.path.replace(":id", id), props);
    setTabIndex(newValue);
  };

  return (
    <Edit
      {...props}
      // className="edit"
      title={<MyTitle resource={props.resource} descriptionAttribute="name" />}
    >
      <>
        {/* <OptimizedCitiesFetcher /> */}

        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          className="right-wrapper"
          scrollButtons="auto"
          variant="scrollable"
          style={{
            position: "fixed",
            background: "#f7f8fa",
            zIndex: 999,
            // top: 0,
            // right: 0,
            borderBottom: "1px solid #d8d9db",

            marginTop: -60,
            paddingTop: 7,
          }}
        >
          <Tab label={CLIENT_TABS[0].name} {...a11yProps(0)} />
          <Tab label={CLIENT_TABS[1].name} {...a11yProps(1)} />
        </Tabs>
        <TabPanel
          value={tabIndex}
          index={0}
          id={id}
          style={
            {
              // marginTop: 40,
            }
          }
          path={CLIENT_TABS[0].path.replace(":id", id)}
        >
          <ClientShowEdit {...props} />
        </TabPanel>
        <TabPanel
          value={tabIndex}
          index={1}
          id={id}
          style={
            {
              // marginTop: 40,
            }
          }
          path={CLIENT_TABS[1].path.replace(":id", id)}
        >
          <ExpeditionPortableList
            filter={{
              client: id,
            }}
          />
        </TabPanel>
      </>
    </Edit>
  );
}
