import React from "react";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { useState } from "react";
import { Confirm } from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);
interface AdminApprovalButtonsProps {
  onAccept: () => void;
  acceptWarningMessage: string;
  rejectWarningMessage: string;
  hideReject?: boolean;
  hideAccept?: boolean;
  rejectLabel?: string;
  acceptLabel?: string;

  onReject: () => void;
}
export const AdminApprovalButtons = (props: AdminApprovalButtonsProps) => {
  const [action, setAction] = React.useState<"accept" | "reject" | undefined>();
  const handleDialogClose = () => {
    setAction(undefined);
    setIsOpen(false);
  };
  const {
    rejectWarningMessage,
    acceptWarningMessage,
    hideReject,
    hideAccept,
    acceptLabel,
    rejectLabel,
  } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      {!hideReject && (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloseIcon />}
          onClick={(e) => {
            setAction("reject");
            setIsOpen(true);
          }}
        >
          {rejectLabel ? rejectLabel : "Rejeter"}
        </Button>
      )}
      {!hideAccept && (
        <Button
          variant="contained"
          style={{ backgroundColor: "#12824C", color: "#FFFFFF" }}
          className={classes.button}
          endIcon={<CheckIcon />}
          onClick={(e) => {
            setAction("accept");
            setIsOpen(true);
          }}
        >
          {acceptLabel ? acceptLabel : "Approuver"}
        </Button>
      )}
      <Confirm
        isOpen={isOpen}
        title={"Approbation de l'administrateur"}
        content={
          action === "accept" ? acceptWarningMessage : rejectWarningMessage
        }
        onConfirm={(e: any) => {
          switch (action) {
            case "accept":
              props.onAccept();
              break;
            case "reject":
              props.onReject();
              break;
          }
          handleDialogClose();
        }}
        onClose={handleDialogClose}
      />
    </div>
  );
};
