import React from "react";
import {
  Filter,
  TextInput,
  NumberInput,
  // AutocompleteInput,
  usePermissions,
} from "react-admin";

// styles
import { primaryColor } from "@theme/colors";
import useStyles from "./VehicleFiltersStyles";
import classnames from "classnames";
// import { withStyles } from "@material-ui/styles";
import { AdminRole } from "@wetrans/helpers";
// import { useOrganization } from "@hooks/useOrganization";
import OrganizationInput from "@components/common/OrganizationInput/OrganizationInput";
import { ApprovalStatusInput } from "@constants/approvalStatus";
import VehicleCategoryInput from "@components/common/vehicleCategoriesSelecter/VehicleCategoryInput";

// const AutocompleteInputMod = withStyles({
//   suggestionsContainer: {
//     "& [class*='root']": {
//       backgroundColor: "white",
//       maxWidth: 400,
//       whiteSpace: "normal",
//       boxShadow: "rgb(0 0 0 / 8%) 0px 2px 5px 0px",
//     },
//   },
// })(AutocompleteInput);
const VehicleFilters = (props: any) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  return (
    <Filter
      {...props}
      style={{
        background: primaryColor,
        borderRadius: 4,
        marginRight: 20,
      }}
      className={classnames(classes.formRoot, "filters")}
    >
      <TextInput
        label="vehicle.matricule"
        resettable
        source="matricule"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <VehicleCategoryInput
        source="category"
        label="vehicle.category"
        allowEmpty={false}
      />
      {/* <VehicleCategoriesFilter
        source="category"
        label="Category"
        allowEmpty={false}
      /> */}
      <ApprovalStatusInput
        label="general.approvalStatus"
        source="approvalStatus"
      />
      {/* <NumberInput
        label="vehicle.weight"
        resettable
        source="weight"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <NumberInput
        label="vehicle.height"
        resettable
        source="height"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <NumberInput
        label="vehicle.width"
        resettable
        source="width"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <NumberInput
        label="vehicle.length"
        resettable
        source="length"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      /> */}
      <NumberInput
        label="vehicle.age"
        resettable
        source="age"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      {permissions === AdminRole.super && (
        <OrganizationInput label="vehicle.organization" source="organization" />
      )}

      {/* <ReferenceInput
        label="vehicle.driver"
        source="driver"
        reference="driver"
        filter={organization ? { organization: organization } : {}}
      >
        <AutocompleteInputMod optionText="name" />
      </ReferenceInput> */}
    </Filter>
  );
};

export default VehicleFilters;
