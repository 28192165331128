import { vehicleCategoriesSelector } from "@state/selectors";
import { IVehicleCategory } from "@wetrans/helpers";
import React from "react";
import { useRecordContext } from "react-admin";
import { useSelector } from "react-redux";
const VehicleCategoryField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const categoryId = record[source];
  const vehicleCategories: IVehicleCategory[] = useSelector(
    vehicleCategoriesSelector
  );
  let category: IVehicleCategory | undefined;
  if (categoryId)
    category = vehicleCategories.find((category) => category.id === categoryId);
  return <span>{category ? category.name : "N/A"}</span>;
};
export default VehicleCategoryField;
