import React from "react";
import { useTranslate } from "react-admin";

import { ExpeditionStatusColors } from "@wetrans/helpers";

// styles
import styles from "./ExpeditionStatusField.module.css";
import { expeditionStatus } from ".";

export const ExpeditionStatusField = ({ status }: any) => {
  const t = useTranslate();
  const statusWithLabel = expeditionStatus.find((s) => s.id === status);
  return (
    <span
      className={styles.status}
      style={{
        backgroundColor: ExpeditionStatusColors[status],
      }}
    >
      {statusWithLabel ? t(statusWithLabel.label) : "N/A"}
    </span>
  );
};
