import { primaryColor } from "../colors";

const tableActions = {
  RaPaginationActions: {
    actions: {
      order: 1,
    },
    button: {
      minWidth: "40px",
      border: "1px solid #e8e9ec",
      margin: "5px",
    },
  },
  RaBulkActionsToolbar: {
    toolbar: {
      backgroundColor: primaryColor,
      color: "white",
    },
  },
  MuiTableRow: {
    head: {
      backgroundColor: "white",
      "& > th ": {
        color: "#9da3ab",
        height: 67,
        fontSize: 16,
        lineHeight: 1.2,
      },
    },
  },
  RaList: {
    content: {
      padding: "0 30px",
      marginTop: 25,
    },
  },
  RaButton: {
    label: {
      color: "white",
    },
    smallIcon: {
      color: "white",
    },
  },
  MuiButton: {
    textPrimary: {
      color: "gray",
    },
  },
  RaFilterForm: {
    form: {
      backgroundColor: "transparent",
    },
  },
  RaTopToolbar: {
    root: {
      paddingBottom: 0,
    },
  },
  RaListToolbar: {
    toolbar: {
      paddingTop: 25,
      alignItems: "center",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
    },
  },
  MuiPaper: {
    root: {
      backgroundColor: "none",
    },
    elevation1: {
      boxShadow: "none",
    },
    elevation8: {
      backgroundColor: "white",
    },
  },

  MuiFilledInput: {
    root: {
      backgroundColor: "none",
    },
  },
  MuiFormControl: {
    root: {
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  RaSimpleFormIterator: {
    line: {
      width: "100%",
    },
    form: {
      display: "inline-flex",
    },
  },
  RaImageInput: {
    root: {
      height: 290,
    },
    removeButton: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      maxWidth: 688,
      height: 229,
      margin: "auto",
      zIndex: 3,

      "& button": {
        opacity: 1,
        zIndex: 99,
      },
    },
    dropZone: {
      position: "absolute",
      zIndex: 2,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      outline: "none",
      width: "100%",
      maxWidth: 688,
      height: 229,
      minHeight: 229,
      margin: "auto",
      padding: 0,
      background: "#d6d6d6",
      borderRadius: 12,
      display: "none", // TODO: to change
      "& p": {
        display: "none",
      },
      "& + p": {
        position: "absolute",
        bottom: 0,
      },
    },
  },
  MuiTabs: {
    fixed: {
      position: "fixed",
      background: "#f7f8fa",
      zIndex: 999,
      borderBottom: "1px solid #d8d9db",
      marginTop: -22,
      paddingTop: 22,
    },
  },
  RaAutocompleteSuggestionList: {
    background: "white !important",
  },
};

export default tableActions;
