import * as React from "react";

import { handleDisplayNum } from "./HandleDisplayNum";

export type IndicatorType =
  | "currency"
  | "count"
  | "decimal"
  | "distance"
  | "rating";

// const useStyles = makeStyles((theme) => ({
//   card: {
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//     borderLeft: "6px solid #287dfa",
//   },
// }));

const DashboardCountCard = ({
  name,
  number,
  type = "count",
  currency,
  icon,
  variation,
}: {
  name: string;
  number: number;
  type?: IndicatorType;
  currency?: string;
  icon?: React.ReactNode;
  variation?: number;
}) => {
  // const classes = useStyles();

  // const [stringRepresentation, setStringRepresentation] = React.useState<
  //   string
  // >("N/A");

  // const formatString = useFormatString();
  // React.useEffect(() => {
  //   if (number != null) {
  //     setStringRepresentation(formatString(type, number, currency));
  //   }
  // }, [currency, formatString, number, type]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        display: "flex",
        borderRadius: 6,
        overflow: "hidden",
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1.3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon && icon}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 2,
          justifyContent: "space-around",
          alignItems: "flex-start",
          paddingTop: 25,
          paddingBottom: 25,
          paddingLeft: 20,
          paddingRight: 10,
          textAlign: "left",
        }}
      >
        <div
          style={{
            fontSize: "17px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.24,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#354052",
          }}
        >
          {name && name}
        </div>
        <div
          style={{
            fontSize: "41px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.29,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#11141a",
          }}
        >
          {handleDisplayNum(number, false, "count")}
        </div>
      </div>
    </div>
  );
};

export default DashboardCountCard;
