import { ETA, ICity, ISettings } from "@wetrans/helpers";
import { DateTime } from "luxon";

const isAfter = ({
  anchor,
  time,
  orEquals = true,
}: {
  anchor: Date;
  time: Date;
  orEquals?: boolean;
}): boolean => {
  const anchorTime = DateTime.fromJSDate(anchor);
  const timeToCheck = DateTime.fromJSDate(time);
  const diff = timeToCheck.diff(anchorTime).as("minutes");
  const verdict = orEquals ? diff >= 0 : diff > 0;
  return verdict;
};
export const daysBetweenTwoDate = (start: Date, end: Date): number => {
  return Number(
    ((end.getTime() - start.getTime()) / (1000 * 3600 * 24)).toFixed(0)
  );
};
// compute duration between two times hh:mm
const computeDuration = (start: string, end: string): number => {
  const startTime = DateTime.fromFormat(start, "HH:mm");
  const endTime = DateTime.fromFormat(end, "HH:mm");
  return endTime.diff(startTime).as("minutes");
};
export const addDurationToWorkdayDate = (
  base: Date,
  durationInMinutes: number,
  anchors: {
    // hh:mm
    dayStart: string;
    breakStart: string;
    breakEnd: string;
    dayEnd: string;
  }
): Date => {
  let baseDate = new Date(base);
  const baseDateTime = DateTime.fromJSDate(baseDate);
  let etaPointer: DateTime = baseDateTime.plus({ minutes: durationInMinutes });
  const { dayStart, breakStart, breakEnd, dayEnd } = anchors;
  const breakduration = computeDuration(breakStart, breakEnd);
  const downTime =
    computeDuration("00:00", dayStart) + computeDuration(dayEnd, "23:59") + 1;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (
      isAfter({
        anchor: baseDate,
        time: setTime(baseDate, breakStart),
      }) &&
      isAfter({
        anchor: setTime(baseDate, breakStart),
        time: etaPointer.toJSDate(),
        orEquals: false,
      })
    ) {
      etaPointer = etaPointer.plus({ minutes: breakduration });
    }
    if (
      isAfter({
        anchor: setTime(baseDate, dayEnd),
        time: etaPointer.toJSDate(),
        orEquals: false,
      })
    ) {
      etaPointer = etaPointer.plus({ minutes: downTime });
    }
    if (
      (isAfter({
        anchor: setTime(baseDate, dayStart),
        time: etaPointer.toJSDate(),
      }) &&
        isAfter({
          anchor: etaPointer.toJSDate(),
          time: setTime(baseDate, breakStart),
        })) ||
      (isAfter({
        anchor: setTime(baseDate, breakEnd),
        time: etaPointer.toJSDate(),
      }) &&
        isAfter({
          anchor: etaPointer.toJSDate(),
          time: setTime(baseDate, dayEnd),
        }))
    ) {
      break;
    }
    baseDate = amendBaseDate({
      base: baseDate,
      etaPointer,
      workdayStart: dayStart,
    });
  }

  return etaPointer.toJSDate();
};
const setTime = (
  d: Date,
  time: string // "hh:mm"
): Date => {
  const newDate = new Date(d);
  const [hour, minute] = time.split(":");
  newDate.setHours(parseInt(hour), parseInt(minute));
  return newDate;
};
const computeRawDuration = ({
  distance,
  avgSpeed,
}: {
  distance: number; // km
  avgSpeed: number; // km/h
}): number => {
  return (distance / avgSpeed) * 60;
};
export const computeRawRouteETAs = async ({
  citiesSequence,
  etaProps,
  computeDistanceBetweenCities,
  routeStartTime,
  routeStartDate,
}: {
  citiesSequence: ICity[];
  etaProps: ISettings["ETAProps"];
  computeDistanceBetweenCities: (from: ICity, to: ICity) => Promise<number>;
  routeStartTime: string; // hh:mm
  routeStartDate: Date;
}): Promise<{
  bestCaseETA: ETA;
  worstCaseETA: ETA;
}> => {
  const {
    workdayAnchors,
    avgSpeed,
    defaultLoadingTime,
    defaultUnloadingTime,
    defaultTimeErrorMarginBetweenExpeditions,
  } = etaProps;

  const routeStartDateTime = setTime(
    routeStartDate,
    routeStartTime ? routeStartTime : workdayAnchors.dayStart
  );

  // Best case scenario (The entire route is booked by one expedition)
  const bestCaseDistance = await computeDistanceBetweenCities(
    citiesSequence[0],
    citiesSequence[citiesSequence.length - 1]
  );
  const bestCaseDuration =
    computeRawDuration({
      distance: bestCaseDistance,
      avgSpeed,
    }) +
    defaultLoadingTime +
    defaultUnloadingTime;
  const bestCaseETA: ETA = {
    estimatedDepartureDateTime: routeStartDateTime,
    estimatedArrivalDateTime: addDurationToWorkdayDate(
      routeStartDateTime,
      bestCaseDuration,
      workdayAnchors
    ),
    timeOnTheRoadInMinutes: bestCaseDuration,
  };
  // Worst case scenario (Each segment in the route is booked by a different expedition)
  let worstCaseDistance = 0;
  let i = 0;
  for await (const city of citiesSequence) {
    if (i === citiesSequence.length - 1) break;
    worstCaseDistance += await computeDistanceBetweenCities(
      city,
      citiesSequence[i + 1]
    );
    i++;
  }
  const worstCaseDuration =
    computeRawDuration({
      distance: worstCaseDistance,
      avgSpeed,
    }) +
    citiesSequence.length * (defaultLoadingTime + defaultUnloadingTime) +
    (citiesSequence.length - 1) * defaultTimeErrorMarginBetweenExpeditions;
  const worstCaseETA: ETA = {
    estimatedDepartureDateTime: routeStartDateTime,
    estimatedArrivalDateTime: addDurationToWorkdayDate(
      routeStartDateTime,
      worstCaseDuration,
      workdayAnchors
    ),
    timeOnTheRoadInMinutes: worstCaseDuration,
  };
  console.log("done");
  return { bestCaseETA, worstCaseETA };
};
const amendBaseDate = ({
  base,
  etaPointer,
  workdayStart,
}: {
  base: Date;
  etaPointer: DateTime;
  workdayStart: string; // hh:mm
}): Date => {
  const sameDay = isSameDay(base, etaPointer.toJSDate());
  if (!sameDay) {
    const [hour, minute] = workdayStart.split(":");
    return DateTime.fromJSDate(base)
      .plus({ days: 1 })
      .set({ hour: parseInt(hour), minute: parseInt(minute) })
      .toJSDate();
  } else return base;
};
export const roundMinutes = (date: Date): Date => {
  const oneHourMinutes = 60;
  date.setHours(
    date.getHours() + Math.round(date.getMinutes() / oneHourMinutes)
  );
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
  return date;
};

export function isSameDay(d1: Date, d2: Date): boolean {
  const d1Copy = new Date(d1);
  const d2Copy = new Date(d2);
  roundMinutes(d1Copy);
  roundMinutes(d2Copy);
  return (
    d1Copy.getFullYear() === d2Copy.getFullYear() &&
    d1Copy.getMonth() === d2Copy.getMonth() &&
    d1Copy.getDate() === d2Copy.getDate()
  );
}
