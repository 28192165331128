import { Button } from "@mui/material";
import {
  AdminRole,
  ExpeditionStatus,
  IExpedition,
  UserType,
} from "@wetrans/helpers";
import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import {
  maxLength,
  minLength,
  required,
  SaveButton,
  TextInput,
  useGetIdentity,
  useMutation,
  useNotify,
  usePermissions,
} from "react-admin";
import { Form } from "react-final-form";

import firebase from "firebase";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";

interface ExpeditionCancellationProps {
  expedition: IExpedition;
}
const validateReason = [required(), minLength(3), maxLength(300)];

export const ExpeditionCancellationButton = (
  props: ExpeditionCancellationProps
) => {
  const { expedition } = props;
  const { permissions } = usePermissions();
  const [isOpen, setIsOpen] = useState(false);

  const notify = useNotify();
  const { identity, loaded: identityLoaded } = useGetIdentity();

  const onSuccess = React.useCallback(() => {
    notify(`Expédition annulée`);
  }, [notify]);
  const onFailure = React.useCallback(
    (error: any) => {
      notify(`${error.message}`);
    },
    [notify]
  );
  const [update] = useMutation();

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const cancelExpedition = async (values: any) => {
    if (expedition) {
      await update(
        {
          type: "update",
          resource: "expedition",
          payload: {
            id: expedition.id,
            data: {
              ...expedition,
              status: ExpeditionStatus.cancelled,
              cancellationReport: {
                cancelledAt: firebase.firestore.Timestamp.now(),
                reason: values.reason,
                cancelledBy:
                  permissions === AdminRole.super
                    ? UserType.super
                    : UserType.admin,
              },
            },
          },
        },
        { onFailure, onSuccess }
      );
    }
  };

  return expedition.status === ExpeditionStatus.booked &&
    identityLoaded &&
    identity ? (
    <>
      <Button
        variant="contained"
        className="background-red"
        disabled={
          permissions !== AdminRole.super &&
          identity?.approvalStatus !== ApprovalStatus.Approved
        }
        onClick={handleClickOpen}
      >
        Annuler
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Form
          initialValues={{}}
          onSubmit={async (values) => {
            await cancelExpedition(values);
            handleClose();
          }}
          render={(formProps) => {
            return (
              <>
                <DialogContent>
                  <TextInput
                    source="reason"
                    multiline={true}
                    validate={validateReason}
                    label={"Motif d'Annulation"}
                    type="text"
                    resettable
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Annuler
                  </Button>
                  <SaveButton
                    label="Confirmer Annulation"
                    disabled={!formProps.valid}
                    saving={formProps.submitting}
                    handleSubmitWithRedirect={formProps.handleSubmit}
                  />
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </>
  ) : null;
};
