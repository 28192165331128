import CustomLineChart, {
  ICustomLineChartProps,
} from "@components/common/charts/CustomLineChart";
import { IExpeditionEntryChart } from "@wetrans/helpers";
import React from "react";

import { useEffect, useState } from "react";
import { useListFilterContext } from "react-admin";
import { DashCard, DashCardContent } from "./styled";
import { transformDate } from "./transformDate";
interface ExpeditionLineChartProps {
  data: IExpeditionEntryChart[];
}

const ExpeditionLineChart = (props: ExpeditionLineChartProps) => {
  const { filterValues } = useListFilterContext();
  const [interval, setInterval] = useState<"today" | "week" | "month" | "all">(
    filterValues.timeline ? filterValues.timeline : "all"
  );
  React.useEffect(() => {
    setInterval(filterValues.timeline ? filterValues.timeline : "all");
  }, [filterValues.timeline]);

  const [lineChartConfiguration, setLineChartConfiguration] = useState<
    ICustomLineChartProps
  >({
    data: transformDate(props.data ? props.data : [], interval),
    fields: [
      { label: "Reservée", key: "bookedCount", color: "#73B9EC" },
      { label: "Validé", key: "validatedCount", color: "#6aa84f" },
      { label: "Annulé", key: "canceledCount", color: "#FED152" },
      { label: "Livré", key: "deliveredCount", color: "#EF545C" },
      { label: "Confirmé", key: "confirmedCount", color: "#1f08b0" },
    ],
  });
  useEffect(() => {
    setLineChartConfiguration({
      data: transformDate(props.data ? props.data : [], filterValues.timeline),
      fields: [
        { label: "Reservée", key: "bookedCount", color: "#73B9EC" },
        { label: "Validé", key: "validatedCount", color: "#6aa84f" },
        { label: "Annulé", key: "canceledCount", color: "#FED152" },
        { label: "Livré", key: "deliveredCount", color: "#EF545C" },
        { label: "Confirmé", key: "confirmedCount", color: "#1f08b0" },
      ],
    });
  }, [filterValues.timeline, props.data]);
  return (
    <DashCard className={"dash-card-lead"}>
      <DashCardContent>
        <CustomLineChart {...lineChartConfiguration} />
      </DashCardContent>
    </DashCard>
  );
};

export default ExpeditionLineChart;
