import { RouteTemplateStatus } from "@wetrans/helpers";

export const routeStatus = [
  {
    id: RouteTemplateStatus.deleted,
    label: "Supprimé",
    color: "#5fd997",
  },
  {
    id: RouteTemplateStatus.active,
    label: "Actif",
    color: "#5fd997",
  },

  {
    id: RouteTemplateStatus.cancelled,
    label: "Annulé",
    color: "#5fd997",
  },
  {
    id: RouteTemplateStatus.requested,
    label: "Demandé",
    color: "#5fd997",
  },
];
