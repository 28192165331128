import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { RoutesCreate } from "./RoutesCreate";
import { useState } from "react";
import { RouteList } from "./RouteList";
import { useHistory, useLocation } from "react-router-dom";

interface RoutesViewProps {
  vehicleId: string;
}
export const RoutesView = (props: RoutesViewProps) => {
  const { vehicleId } = props;
  const history = useHistory();
  const { search } = useLocation();
  const queryParser = new URLSearchParams(search);
  const mode = queryParser.get("mode");
  const [showCreate, setShowCreate] = useState<boolean>(mode === "create");
  useEffect(() => {
    const params = new URLSearchParams();
    if (showCreate) {
      params.append("mode", "create");
    } else {
      params.delete("mode");
    }
    history.replace({ search: params.toString() });
  }, [showCreate, history]);
  useEffect(() => {
    if (mode === "create") {
      setShowCreate(true);
    } else {
      setShowCreate(false);
    }
  }, [setShowCreate, mode]);

  return (
    <Grid container spacing={3}>
      {showCreate && (
        <Grid item sm={8}>
          <RoutesCreate
            vehicleId={vehicleId}
            onSuccess={() => setShowCreate(false)}
          />
        </Grid>
      )}
      {!showCreate && (
        <Grid item sm={12}>
          <RouteList
            onClickCreate={() => setShowCreate(true)}
            vehicleId={vehicleId}
          />
        </Grid>
      )}

      {/* <Grid item sm={4}>
      </Grid> */}
    </Grid>
  );
};
