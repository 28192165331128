/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  ReferenceField,
  TextField,
  SimpleShowLayout,
  DateField,
  FunctionField,
  usePermissions,
  useMutation,
  useNotify,
  Edit,
  FormWithRedirect,
  SaveButton,
  useEditController,
  required,
  useRefresh,
  useLocale,
  useGetIdentity,
  // @ts-ignore
} from "react-admin";
import { Alert } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";

import MyTitle from "@components/common/View/Title";
import MarkerExported from "@assets/vectors/MarkerExported";
import FlagExported from "@assets/vectors/FlagExported";
import { primaryColor } from "@theme/colors";
import ProgressSteppers from "./ProgressSteppers";
import styles from "./ExpeditionShow.module.css";
import {
  AdminRole,
  ExpeditionMatchTypes,
  ExpeditionPriceBreakdown,
  ExpeditionStatus,
  IExpedition,
  // IRoute,
  IVehicleCategory,
  // RouteStatus,
} from "@wetrans/helpers";
import DriverInput from "@components/common/DriverInput/DriverInput";
import { useState } from "react";
import { dataProvider } from "@providers/provider";
import MerchandiseCategoryField from "@components/common/MerchandiseCategoryField/MerchandiseCategoryField";
import { LiveUpdate } from "@components/common/LiveUpdate/LiveUpdate";
import { useEffect } from "react";
import { LiveDirectionMap } from "./LiveDirectionMap";
import OrganizationInput from "@components/common/OrganizationInput/OrganizationInput";
import VehicleInput from "@components/common/VehicleInput/VehicleInput";

import { ExpeditionCancellationButton } from "./cancellation/ExpeditionCancellationButton";
import { ExpeditionCancellationSection } from "./cancellation/ExpeditionCancellationSection";
import { ExpeditionReviewSection } from "./review/ExpeditionReviewSection";
import { ExpeditionPriceBreakDown } from "./ExpeditionPriceBreakDown";
import { useSelector } from "react-redux";
import { settingsSelector, vehicleCategoriesSelector } from "@state/selectors";
import { CircularProgress, Typography } from "@material-ui/core";
import { delay } from "lodash";
import { BonChargementButton } from "./bon-chargement/BonChargementButton";
import TimeFieldEta from "./TimeFieldEta";
import { BonLivraisonButton } from "./bon-livraison/BonLivraisonButton";
import TractorInput from "@components/common/TractorInput/TractorInput";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import {
  computeExpeditionPriceBreakdown,
  computeMatchPrice,
} from "./helpers/priceService";
import { convertMinsToHrsMins } from "@utils/convertMinsToHrsMins";

enum ExpeditionMode {
  OrganizationValidation,
  AdminValidation,
  CustomExpeditionOrganizationValidation,
  PreAdminValidation,
  Normal,
}
export const getPrice = (price?: string): number => {
  const result = price ? Number.parseFloat(price).toFixed(2) : 0;

  return Number(result);
};

export default function ExpeditionShow(props: any) {
  const { record: data, loaded } = useEditController(props);
  const [expedition, setExpedition] = useState<IExpedition<Date>>();
  const settings = useSelector(settingsSelector);
  const [update] = useMutation();
  const [validationErrorMessage, setValidationErrorMessage] = useState<
    string
  >();
  const { permissions } = usePermissions();
  const { identity, loaded: identityLoaded } = useGetIdentity();

  const [mode, setMode] = useState<ExpeditionMode>();
  const [vehicleCategory, setVehicleCategory] = useState<IVehicleCategory>();
  const [requiresTractor, setRequiresTractor] = useState<boolean>(false);

  // const [newPrice, setNewPrice] = useState<number>();
  const [newPriceBreakDown, setNewPriceBreakdown] = useState<
    ExpeditionPriceBreakdown
  >();

  const [showNoVehicleWarning, setShowNoVehicleWarning] = useState<boolean>(
    false
  );
  const [driverWarningMessage, setDriverWarningMessage] = useState<
    string | undefined
  >();
  const [vehicleWarningMessage, setVehicleWarningMessage] = useState<
    string | undefined
  >();
  const [tractorWarningMessage, setTractorWarningMessage] = useState<
    string | undefined
  >();

  const vehicleCategories: IVehicleCategory[] = useSelector(
    vehicleCategoriesSelector
  );
  const [priceLoading, setPriceLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  useEffect(() => {
    const fetchedExpedition = data as IExpedition;

    setExpedition(fetchedExpedition);
    if (
      loaded &&
      (mode === ExpeditionMode.OrganizationValidation ||
        mode === ExpeditionMode.CustomExpeditionOrganizationValidation)
    ) {
      const isRequiresTractors = fetchedExpedition?.bookedMatch?.vehicleCategory
        ? vehicleCategories.find(
            (cat) => cat.id === fetchedExpedition?.bookedMatch?.vehicleCategory
          )?.requiresTractor
        : false;
      if (isRequiresTractors) setRequiresTractor(isRequiresTractors);
    }
  }, [data, mode, loaded, setRequiresTractor]);

  const onSuccess = React.useCallback(
    (action: string) => {
      notify(`Expédition validée`);
      refresh();
    },
    [notify, refresh]
  );

  const onFailure = React.useCallback(
    (error: any) => {
      notify(`${error.message}`);
    },
    [notify]
  );
  useEffect(() => {
    if (vehicleCategory && expedition) {
      setPriceLoading(true);
      const vehicleCategoryGroup = settings.vehicleCategoryGroups.find(
        (g) => g.id === vehicleCategory.group
      );
      if (!vehicleCategoryGroup) return;
      const matchPrice = computeMatchPrice({
        distance: expedition.bookedMatch.distance,
        pricingProps: settings.pricingProps,
        vehicleConsumptionRate: vehicleCategory.consumptionRate,
        vehicleCategoryGroup,
        matchType: "standard",
      });

      const priceBreakdown = computeExpeditionPriceBreakdown({
        matchPrice,
        insurancePolicyPrice:
          expedition.bookedMatch.priceBreakdown.insurancePolicyPrice,
        TVA: expedition.bookedMatch.priceBreakdown.TVA,
      });

      delay(() => {
        setNewPriceBreakdown(priceBreakdown);
        setPriceLoading(false);
      }, 1000);
    }
  }, [vehicleCategory, expedition, settings]);
  useEffect(() => {
    if (!expedition) {
      setMode(ExpeditionMode.Normal);
      return;
    }
    if (expedition.status < ExpeditionStatus.pending) {
      setMode(ExpeditionMode.Normal);
      return;
    }
    if (
      expedition.bookedMatch.type === ExpeditionMatchTypes.Custom &&
      // expedition.status === ExpeditionStatus.booked &&
      (expedition as any).customExpeditionToVerifyByAdmin &&
      permissions === AdminRole.super
    ) {
      setMode(ExpeditionMode.PreAdminValidation);
    } else if (
      expedition.bookedMatch.type === ExpeditionMatchTypes.Custom &&
      expedition.status === ExpeditionStatus.booked &&
      !expedition.vehicle &&
      !(expedition as any).customExpeditionToVerifyByAdmin &&
      permissions !== AdminRole.super
    ) {
      setMode(ExpeditionMode.CustomExpeditionOrganizationValidation);
    } else if (
      !expedition.organizationValidationStamp &&
      expedition.vehicle &&
      expedition.status === ExpeditionStatus.booked &&
      permissions === AdminRole.operation
    ) {
      setMode(ExpeditionMode.OrganizationValidation);
    } else if (
      !expedition.platformValidationStamp &&
      expedition.organizationValidationStamp &&
      permissions === AdminRole.super &&
      expedition.status === ExpeditionStatus.booked
    ) {
      setMode(ExpeditionMode.AdminValidation);
    } else {
      setMode(ExpeditionMode.Normal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expedition,
    expedition?.organizationValidationStamp,
    expedition?.platformValidationStamp,
    expedition?.customExpeditionToVerifyByAdmin,
  ]);
  const checkIfTheResourceDontConflictWithEta = React.useCallback(
    async (
      id: string,
      field: string,
      date: Date,
      currenRouteId: string,
      expeditionId: string,
      etaDateInterval?: string[]
    ) => {
      if (!etaDateInterval) {
        return true;
      }
      const { data: fetchedData } = await dataProvider.getList("expedition", {
        filter: {
          etaDateInterval: {
            operator: "array-contains-any",
            value: etaDateInterval,
          },
        },
        pagination: {
          page: 1,
          perPage: 2000,
        },
      } as any);
      const expeditions = fetchedData.filter(
        (item) => item[field] === id
      ) as IExpedition[];

      const otherExpeditions = expeditions.filter(
        (r) =>
          r.id !== expeditionId &&
          r.bookedMatch.route !== currenRouteId &&
          r.status >= ExpeditionStatus.validated &&
          r.status < ExpeditionStatus.delivered
      );

      if (otherExpeditions.length > 0) {
        return false;
      }
      return true;
    },
    []
  );
  const checkIfTheDriverIsFree = React.useCallback(
    async (
      id: string,
      field: string,
      date: Date,
      currenRouteId: string,
      expeditionId: string
    ) => {
      const { data: fetchedData } = await dataProvider.getList("expedition", {
        filter: {
          date: date.toISOString(),
        },
        pagination: {
          page: 1,
          perPage: 2000,
        },
      } as any);

      const expeditions = fetchedData.filter(
        (item) => item[field] === id
      ) as IExpedition[];
      const otherExpeditions = expeditions.filter(
        (r) =>
          r.id !== expeditionId &&
          r.bookedMatch.route !== currenRouteId &&
          r.status >= ExpeditionStatus.validated &&
          r.status < ExpeditionStatus.delivered
      );
      if (otherExpeditions.length > 0) {
        return false;
      }
      return true;
    },
    []
  );
  const validateExpedition = React.useCallback(
    async (values, redirectTo, options) => {
      if (!expedition) return;
      if (
        mode === ExpeditionMode.AdminValidation ||
        mode === ExpeditionMode.OrganizationValidation
      ) {
        update(
          {
            type: "update",
            resource: "expedition",
            payload: {
              id: expedition.id,
              data: {
                ...values,
                ...(permissions === AdminRole.super
                  ? { platformValidationStamp: true }
                  : { organizationValidationStamp: true }),
              },
            },
          },
          {
            onSuccess,
            onFailure,
          }
        );
      } else if (mode === ExpeditionMode.PreAdminValidation) {
        update(
          {
            type: "update",
            resource: "expedition",
            payload: {
              id: expedition.id,
              data: {
                ...values,
                customExpeditionToVerifyByAdmin: false,
              },
            },
          },
          {
            onSuccess,
            onFailure,
          }
        );
      } else if (
        mode === ExpeditionMode.CustomExpeditionOrganizationValidation
      ) {
        update(
          {
            type: "update",
            resource: "expedition",
            payload: {
              id: expedition.id,
              data: {
                ...values,
                bookedMatch: {
                  ...expedition.bookedMatch,
                  vehicleCategoryName: vehicleCategory?.name,
                  priceBreakdown: newPriceBreakDown,
                },

                organizationValidationStamp: true,
              },
            },
          },
          {
            onSuccess,
            onFailure,
          }
        );
      }
    },
    [
      expedition,
      mode,
      update,
      permissions,
      onSuccess,
      onFailure,
      vehicleCategory,
      newPriceBreakDown,
      expedition?.organizationValidationStamp,
      expedition?.platformValidationStamp,
      expedition?.customExpeditionToVerifyByAdmin,
    ]
  );

  const validateDriver = async (driver: string, values: IExpedition) => {
    if (validationErrorMessage) setValidationErrorMessage(undefined);
    const errors: any = {};
    if (!values.driver) {
      setValidationErrorMessage(
        "Veuillez assigner un chauffeur avant de valider"
      );
      errors.driver = "Veuillez assigner un chauffeur avant de valider";
    } else {
      // request;
      if (driverWarningMessage) {
        setDriverWarningMessage(undefined);
      }
      const isDriverFree = await checkIfTheDriverIsFree(
        values.driver,
        "driver",
        values.date,
        values.bookedMatch.route,
        values.id
      );
      const isDriverDontConflictWithEta = await checkIfTheResourceDontConflictWithEta(
        values.driver,
        "driver",
        values.date,
        values.bookedMatch.route,
        values.id,
        expedition?.etaDateInterval
      );
      if (!isDriverFree) {
        setValidationErrorMessage(
          "Le chauffeur sélectionné n'est pas libre le jour de cette expédition"
        );
        errors.driver =
          "Le chauffeur sélectionné n'est pas libre le jour de cette expédition";
      }
      if (!isDriverDontConflictWithEta) {
        setDriverWarningMessage(
          "Le chauffeur sélectionné peut ne pas être libre les jours d'expédition"
        );
      }
    }
    return errors.driver;
  };
  const validateVehicle = async (driver: string, values: IExpedition) => {
    if (validationErrorMessage) setValidationErrorMessage(undefined);
    const errors: any = {};
    if (!values.vehicle) {
      setValidationErrorMessage(
        "Veuillez assigner un vehicle avant de valider"
      );
      errors.vehicle = "Veuillez assigner un vehicle avant de valider";
    } else {
      // request;
      if (vehicleWarningMessage) {
        setVehicleWarningMessage(undefined);
      }
      const isDriverFree = await checkIfTheDriverIsFree(
        values.vehicle,
        "vehicle",
        values.date,
        values.bookedMatch.route,
        values.id
      );
      const isVehicleDontConflictWithEta = await checkIfTheResourceDontConflictWithEta(
        values.vehicle,
        "vehicle",
        values.date,
        values.bookedMatch.route,
        values.id,
        expedition?.etaDateInterval
      );
      if (!isDriverFree) {
        setValidationErrorMessage(
          "Le vehicle sélectionné n'est pas libre le jour de cette expédition"
        );
        errors.vehicle =
          "Le vehicle sélectionné n'est pas libre le jour de cette expédition";
      }
      if (!isVehicleDontConflictWithEta) {
        setVehicleWarningMessage(
          "Le vehicle sélectionné peut ne pas être libre les jours d'expédition"
        );
      }
    }
    return errors.vehicle;
  };
  const validateTractor = async (driver: string, values: IExpedition) => {
    if (validationErrorMessage) setValidationErrorMessage(undefined);
    const errors: any = {};
    if (!values.tractor) {
      setValidationErrorMessage(
        "Veuillez assigner un tracteur avant de valider"
      );
      errors.tractor = "Veuillez assigner un tracteur avant de valider";
    } else {
      // request;
      if (tractorWarningMessage) {
        setTractorWarningMessage(undefined);
      }
      const isDriverFree = await checkIfTheDriverIsFree(
        values.tractor,
        "tractor",
        values.date,
        values.bookedMatch.route,
        values.id
      );
      const isTractorDontConflictWithEta = await checkIfTheResourceDontConflictWithEta(
        values.tractor,
        "tractor",
        values.date,
        values.bookedMatch.route,
        values.id,
        expedition?.etaDateInterval
      );
      if (!isDriverFree) {
        setValidationErrorMessage(
          "Le tracteur sélectionné n'est pas libre le jour de cette expédition"
        );
        errors.tractor =
          "Le tracteur sélectionné n'est pas libre le jour de cette expédition";
      }
      if (!isTractorDontConflictWithEta) {
        setTractorWarningMessage(
          "Le tracteur sélectionné peut ne pas être libre les jours d'expédition"
        );
      }
    }
    return errors.tractor;
  };
  useEffect(() => {
    return () => {
      setRequiresTractor(false);
      setVehicleCategory(undefined);
      refresh();
    }; // cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setLocale = useLocale();
  return (
    <div className="editContainer">
      <Edit
        {...props}
        hasShow={false}
        className="edit"
        title={
          <MyTitle
            resource={props.resource}
            descriptionAttribute="humanReadableBillId"
          />
        }
      >
        <SimpleShowLayout>
          <FormWithRedirect
            {...props}
            warnWhenUnsavedChanges
            save={validateExpedition}
            redirect={() => {}}
            render={(formProps) => (
              <form>
                <>
                  <div className="flex flex-wrap row-reverse card">
                    <div
                      className={`${styles.left} flex direction-column justify-between`}
                    >
                      <div>
                        {expedition && (
                          <>
                            <LiveDirectionMap expedition={expedition} />
                            <div style={{ marginBottom: "3.125rem" }}>
                              <ProgressSteppers expedition={expedition} />
                            </div>
                          </>
                        )}

                        {mode !== ExpeditionMode.PreAdminValidation &&
                          expedition?.organization && (
                            <>
                              <div className="flex flex-wrap direction-column margin-b-10">
                                <label>
                                  <strong>Transporteur :</strong>
                                </label>
                                <ReferenceField
                                  link={
                                    permissions === AdminRole ? "show" : false
                                  }
                                  sortable={false}
                                  label="Transporteur :"
                                  source="organization"
                                  reference="organization"
                                >
                                  <TextField source="name" />
                                </ReferenceField>
                              </div>
                              <div className="flex flex-wrap direction-column margin-b-10">
                                <label>
                                  <strong>Téléphone :</strong>
                                </label>
                                <ReferenceField
                                  link={false}
                                  sortable={false}
                                  source="organization"
                                  reference="organization"
                                >
                                  <TextField source="phone" />
                                </ReferenceField>
                              </div>
                            </>
                          )}
                        {mode !== ExpeditionMode.PreAdminValidation &&
                          expedition?.bookedMatch?.vehicleCategoryName &&
                          mode !==
                            ExpeditionMode.CustomExpeditionOrganizationValidation && (
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Transporté par :</strong>
                              </label>
                              <label>
                                {expedition?.bookedMatch?.vehicleCategoryName}
                              </label>
                            </div>
                          )}
                        {mode ===
                          ExpeditionMode.CustomExpeditionOrganizationValidation && (
                          <div className="flex flex-wrap direction-column margin-b-10">
                            <label>
                              <strong>Transporté par :</strong>
                            </label>
                            <label>
                              {vehicleCategory
                                ? vehicleCategory.name
                                : "Pas encore décidé"}
                            </label>
                          </div>
                        )}
                        {mode === ExpeditionMode.PreAdminValidation && (
                          <div className="flex flex-wrap direction-column margin-b-10">
                            <OrganizationInput
                              onlyApproved
                              validate={[required()]}
                              label="admin.organization"
                              source="organization"
                            />
                          </div>
                        )}
                        {mode ===
                          ExpeditionMode.CustomExpeditionOrganizationValidation &&
                          expedition && (
                            <>
                              <div className="flex flex-wrap direction-column">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <InfoIcon
                                    fontSize="small"
                                    color="action"
                                    style={{ marginRight: 5 }}
                                  />
                                  {!showNoVehicleWarning && (
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      Choisissez l'un des véhicules pouvant
                                      transporter cette expédition
                                    </Typography>
                                  )}
                                  {showNoVehicleWarning && (
                                    <Typography variant="caption" color="error">
                                      Vous n'avez pas de véhicule compatible
                                      avec cette expédition
                                    </Typography>
                                  )}
                                </div>
                                <VehicleInput
                                  source="vehicle"
                                  label="Vehicle"
                                  validate={validateVehicle}
                                  onEmpty={() => setShowNoVehicleWarning(true)}
                                  vehicleCategoryOnVehicleChange={(
                                    c: IVehicleCategory | undefined
                                  ) => {
                                    setVehicleCategory(c);
                                  }}
                                  compatibleVehicleCategories={
                                    expedition.bookedMatch
                                      .compatibleVehicleCategories
                                  }
                                  customFilter={{
                                    approvalStatus: {
                                      operator: "==",
                                      value: ApprovalStatus.Approved,
                                    },
                                  }}
                                />
                                {vehicleWarningMessage && (
                                  <Alert variant="standard" severity="warning">
                                    {vehicleWarningMessage}
                                  </Alert>
                                )}
                              </div>
                            </>
                          )}
                        {expedition?.vehicle && (
                          <>
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Vehicle :</strong>
                              </label>
                              <ReferenceField
                                link={"show"}
                                sortable={false}
                                label="Vehicle :"
                                source="vehicle"
                                reference="vehicle"
                              >
                                <TextField source="matricule" />
                              </ReferenceField>
                            </div>
                          </>
                        )}
                        <div className="flex flex-wrap direction-column margin-b-10">
                          {(mode === ExpeditionMode.Normal ||
                            mode === ExpeditionMode.AdminValidation) &&
                            expedition?.tractor && (
                              <>
                                <label>
                                  <strong>Tracteur :</strong>
                                </label>

                                <ReferenceField
                                  link={"show"}
                                  sortable={false}
                                  label="Tracteur :"
                                  source="tractor"
                                  reference="tractor"
                                >
                                  <TextField source="matricule" />
                                </ReferenceField>
                              </>
                            )}
                          {(mode === ExpeditionMode.OrganizationValidation ||
                            mode ===
                              ExpeditionMode.CustomExpeditionOrganizationValidation) &&
                            (requiresTractor ||
                              (vehicleCategory &&
                                vehicleCategory.requiresTractor)) && (
                              <>
                                <TractorInput
                                  source="tractor"
                                  disabled={!!expedition?.tractor}
                                  label="tractor.tractor"
                                  validate={validateTractor}
                                  customFilter={{
                                    approvalStatus: {
                                      operator: "==",
                                      value: ApprovalStatus.Approved,
                                    },
                                  }}
                                />
                                {tractorWarningMessage && (
                                  <Alert variant="standard" severity="warning">
                                    {tractorWarningMessage}
                                  </Alert>
                                )}
                              </>
                            )}
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          {(mode === ExpeditionMode.Normal ||
                            mode === ExpeditionMode.AdminValidation) &&
                            expedition?.driver && (
                              <>
                                <label>
                                  <strong>Chauffeur :</strong>
                                </label>

                                <ReferenceField
                                  sortable={false}
                                  label=""
                                  source="driver"
                                  reference="users"
                                  link={() =>
                                    `/driver/${expedition.driver}/show`
                                  }
                                >
                                  <TextField source="name" />
                                </ReferenceField>
                              </>
                            )}
                          {(mode === ExpeditionMode.OrganizationValidation ||
                            mode ===
                              ExpeditionMode.CustomExpeditionOrganizationValidation) && (
                            <>
                              <DriverInput
                                source="driver"
                                disabled={!!expedition?.driver}
                                label="Chauffeur"
                                validate={validateDriver}
                                customFilter={{
                                  approvalStatus: {
                                    operator: "==",
                                    value: ApprovalStatus.Approved,
                                  },
                                }}
                              />
                              {driverWarningMessage && (
                                <Alert variant="standard" severity="warning">
                                  {driverWarningMessage}
                                </Alert>
                              )}
                            </>
                          )}
                        </div>
                        {expedition && (
                          <ExpeditionCancellationSection
                            expedition={expedition}
                          />
                        )}
                        {expedition && (
                          <ExpeditionReviewSection expedition={expedition} />
                        )}
                      </div>
                      <div className="text-center">
                        {expedition &&
                          expedition.status >= ExpeditionStatus.validated && (
                            <div className="flex flex-wrap justify-between">
                              <BonChargementButton expedition={expedition} />
                              <BonLivraisonButton expedition={expedition} />
                            </div>
                          )}
                      </div>
                    </div>
                    <div className={`${styles.right} justify-between`}>
                      <h2>Trajet</h2>
                      <div>
                        <div>
                          <div className="flex justify-between">
                            <MarkerExported
                              color={primaryColor}
                              style={{ width: "18px" }}
                            />
                            <FlagExported
                              color={primaryColor}
                              style={{ width: "20px" }}
                            />
                          </div>
                          <div className={styles.stepper} />
                        </div>
                        <div className="flex flex-wrap justify-between margin-b-15">
                          <div>
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Point de depart :</strong>
                              </label>
                              <ReferenceField
                                link={false}
                                sortable={false}
                                label="Point de depart :"
                                source="from.city"
                                reference="city"
                              >
                                <TextField source="name" />
                              </ReferenceField>
                            </div>

                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Adresse :</strong>
                              </label>
                              <TextField
                                label="Adresse :"
                                source="from.address"
                              />
                            </div>
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Date :</strong>
                              </label>
                              {(expedition?.bookedMatch as any)?.eta
                                ?.estimatedDepartureDateTime && (
                                <DateField
                                  label="Date :"
                                  locales={setLocale}
                                  source="bookedMatch.eta.estimatedDepartureDateTime"
                                />
                              )}
                              {(expedition?.bookedMatch as any)?.eta
                                ?.estimatedDepartureDateTime && (
                                <TimeFieldEta source="bookedMatch.eta.estimatedDepartureDateTime" />
                              )}
                            </div>
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Durée du trajet :</strong>
                              </label>
                              {convertMinsToHrsMins(
                                (expedition?.bookedMatch as any)?.eta?.timeOnTheRoadInMinutes.toFixed(
                                  0
                                )
                              )}
                              {" (estimé)"}
                            </div>
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Distance :</strong>
                              </label>
                              {expedition?.bookedMatch?.distance.toFixed(0)}
                              {" km"}
                            </div>
                          </div>
                          <div>
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Point d’arrivée :</strong>
                              </label>
                              <ReferenceField
                                link={false}
                                sortable={false}
                                label="Point d’arrivée :"
                                source="to.city"
                                reference="city"
                              >
                                <TextField source="name" />
                              </ReferenceField>
                            </div>
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Adresse :</strong>
                              </label>
                              <TextField
                                label="Adresse :"
                                source="to.address"
                              />
                            </div>
                            <div className="flex flex-wrap direction-column margin-b-10">
                              <label>
                                <strong>Date :</strong>
                              </label>
                              {(expedition?.bookedMatch as any)?.eta
                                ?.estimatedDepartureDateTime && (
                                <DateField
                                  locales={setLocale}
                                  label="Date :"
                                  source="bookedMatch.eta.estimatedArrivalDateTime"
                                />
                              )}
                              {(expedition?.bookedMatch as any)?.eta
                                ?.estimatedDepartureDateTime && (
                                <TimeFieldEta source="bookedMatch.eta.estimatedArrivalDateTime" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>Catégorie :</strong>
                          </label>
                          {expedition && (
                            <MerchandiseCategoryField
                              link={false}
                              sortable={false}
                              label="Catégorie :"
                              source="merchandise.category"
                            />
                          )}
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>Tonnage (T) :</strong>
                          </label>
                          <TextField
                            label="Tonnage (T) :"
                            source="merchandise.weight"
                          />
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>Largeur (m) :</strong>
                          </label>
                          <TextField
                            label="Largeur (m) :"
                            source="merchandise.width"
                          />
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>Hauteur (m) :</strong>
                          </label>
                          <TextField
                            label="Hauteur (m) :"
                            source="merchandise.height"
                          />
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>Longeur (m) :</strong>
                          </label>
                          <TextField
                            label="Longeur (m) :"
                            source="merchandise.length"
                          />
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>Assurance marchandise :</strong>
                          </label>
                          <label>{expedition?.insurancePolicyLabel}</label>
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>Avec chargement :</strong>
                          </label>
                          <FunctionField
                            render={({ loadingRepresentativePhone }: any) => (
                              <span>
                                {loadingRepresentativePhone
                                  ? `Oui: ${loadingRepresentativePhone}`
                                  : `Non`}
                              </span>
                            )}
                            label="Avec chargement :"
                          />
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>Avec déchargement :</strong>
                          </label>
                          <FunctionField
                            render={({ unloadingRepresentativePhone }: any) => (
                              <span>
                                {unloadingRepresentativePhone
                                  ? `Oui: ${unloadingRepresentativePhone}`
                                  : `Non`}
                              </span>
                            )}
                            label="Avec déchargement :"
                          />
                        </div>
                        <div className="flex flex-wrap direction-column margin-b-10">
                          <label>
                            <strong>
                              Je souhaite être contacté directement par tel :
                            </strong>
                          </label>
                          <FunctionField
                            render={({ getContactedByPhone }: any) => (
                              <span>{getContactedByPhone ? `Oui` : `Non`}</span>
                            )}
                            label="Je souhaite être contacté directement par tel :"
                          />
                        </div>
                        {expedition?.description && (
                          <div className="flex flex-wrap direction-column margin-b-10">
                            <label>
                              <strong>Description :</strong>
                            </label>
                            <TextField
                              label="Description :"
                              source="description"
                            />
                          </div>
                        )}
                        {mode !==
                          ExpeditionMode.CustomExpeditionOrganizationValidation && (
                          <ExpeditionPriceBreakDown
                            {...expedition}
                            platformCommissionFromClient={
                              expedition?.bookedMatch?.priceBreakdown
                                ?.platformCommissionFromClient
                            }
                            showPriceMayChangeMessage={
                              expedition?.bookedMatch?.type ===
                                ExpeditionMatchTypes.Custom &&
                              !expedition?.bookedMatch?.priceIsFinale &&
                              expedition.status === ExpeditionStatus.booked
                            }
                            TVA={expedition?.bookedMatch?.priceBreakdown.TVA}
                            price={
                              expedition?.bookedMatch?.priceBreakdown
                                ?.rawHTPrice
                            }
                            totalHTExpeditionPrice={
                              expedition?.bookedMatch?.priceBreakdown
                                ?.clientPriceHT
                            }
                            totalExpeditionPrice={
                              expedition?.bookedMatch?.priceBreakdown
                                .totalClientPrice
                            }
                          />
                        )}
                        {!vehicleCategory &&
                          mode ===
                            ExpeditionMode.CustomExpeditionOrganizationValidation && (
                            <ExpeditionPriceBreakDown
                              {...expedition}
                              TVA={expedition?.bookedMatch?.priceBreakdown.TVA}
                              price={
                                expedition?.bookedMatch?.priceBreakdown
                                  ?.rawHTPrice
                              }
                              totalHTExpeditionPrice={
                                expedition?.bookedMatch?.priceBreakdown
                                  ?.clientPriceHT
                              }
                              totalExpeditionPrice={
                                expedition?.bookedMatch?.priceBreakdown
                                  .totalClientPrice
                              }
                              showPriceMayChangeMessage={
                                expedition?.bookedMatch?.type ===
                                  ExpeditionMatchTypes.Custom &&
                                !expedition?.bookedMatch?.priceIsFinale &&
                                expedition.status === ExpeditionStatus.booked
                              }
                            />
                          )}
                        {vehicleCategory &&
                          expedition &&
                          mode ===
                            ExpeditionMode.CustomExpeditionOrganizationValidation && (
                            <>
                              {priceLoading && (
                                <div className="flex flex-wrap margin-b-10 align-baseline">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <CircularProgress
                                      // fontSize="small"
                                      size={18}
                                      style={{
                                        color: primaryColor,
                                        marginRight: 5,
                                      }}
                                      // thickness={2}
                                    />
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      Recalcul du prix
                                    </Typography>
                                  </div>
                                </div>
                              )}
                              <ExpeditionPriceBreakDown
                                platformCommissionFromClient={
                                  expedition?.bookedMatch?.priceBreakdown
                                    ?.platformCommissionFromClient
                                }
                                priceLoading={priceLoading}
                                TVA={
                                  expedition?.bookedMatch?.priceBreakdown.TVA
                                }
                                price={newPriceBreakDown?.rawHTPrice}
                                totalHTExpeditionPrice={
                                  newPriceBreakDown?.clientPriceHT
                                }
                                totalExpeditionPrice={
                                  newPriceBreakDown?.totalClientPrice
                                }
                                showPriceMayChangeMessage={
                                  expedition?.bookedMatch?.type ===
                                    ExpeditionMatchTypes.Custom &&
                                  !expedition?.bookedMatch?.priceIsFinale &&
                                  expedition.status === ExpeditionStatus.booked
                                }
                              />
                            </>
                          )}
                        {mode !== ExpeditionMode.Normal &&
                          validationErrorMessage && (
                            <div className="flex flex-wrap margin-b-10 margin-r-10 align-baseline">
                              <Alert variant="standard" severity="error">
                                {validationErrorMessage}
                              </Alert>
                            </div>
                          )}
                      </div>
                      <div className="flex flex-wrap justify-between">
                        {mode !== ExpeditionMode.Normal && identityLoaded && (
                          <SaveButton
                            label="Valider"
                            disabled={
                              !formProps.valid ||
                              formProps.validating ||
                              !identity ||
                              (permissions !== AdminRole.super &&
                                identity.approvalStatus ===
                                  ApprovalStatus.Disapproved)
                            }
                            invalid={!formProps.valid || formProps.validating}
                            saving={formProps.saving || formProps.validating}
                            handleSubmitWithRedirect={
                              formProps.handleSubmitWithRedirect
                            }
                          />
                        )}
                        {expedition && (
                          <ExpeditionCancellationButton
                            expedition={expedition}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </form>
            )}
          />
          {expedition && expedition.id && (
            <LiveUpdate<IExpedition>
              resource="expedition"
              docId={expedition.id.toString()}
              onChange={(newData) => {
                if (
                  !expedition.organizationValidationStamp &&
                  newData.organizationValidationStamp
                )
                  refresh(true);
                if (
                  !expedition.bookedMatch.priceIsFinale &&
                  newData.bookedMatch.priceIsFinale
                )
                  notify("Le prix de l'expédition est finalisé");
                setExpedition(newData);

                //
              }}
            />
          )}
        </SimpleShowLayout>
      </Edit>
    </div>
  );
}
