import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TopToolbar,
  useListContext,
  CreateButton,
  // ExportButton,
  sanitizeListRestProps,
  useTranslate,
  // useRefresh,
  // DateField,
  ReferenceField,
  TextField,
  usePermissions,
  // @ts-ignore
} from "react-admin";
import { makeStyles } from "@material-ui/core";

// components
// import ExportExported from "@assets/vectors/ExportExported";
import AdminFilters from "./AdminFilters";
import StickyTableCell from "@components/common/StickyTableCell";
import DotsMenu from "@components/common/DotsMenu/DotsMenu";

// import { useLocale } from "react-admin";

import { AccountStatusField } from "@constants/accountStatus";

import { AdminRoleField } from "@constants/adminRole";

import { isEmpty } from "lodash";
import { NoDataTable } from "@components/common/NoDataTable";
// styles
import { primaryColor } from "@theme/colors";
import "./styles.css";
import { tableRowStyle } from "@theme/styles/table";
import { AdminRole, UserType } from "@wetrans/helpers";
import { useOrganization } from "@hooks/useOrganization";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import { ApprovalStatusField } from "@constants/approvalStatus";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    // total,
  } = useListContext();

  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      <CreateButton
        basePath={basePath}
        className="createButtonAction"
        style={{
          background: primaryColor,
        }}
        label="ra.action.add"
      />

      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const AdminList = (props: any) => {
  const listClasses = useListStyles();
  const { permissions } = usePermissions();
  const { organization } = useOrganization();
  const permanentFilter: any = {
    type: UserType.admin,
  };
  if (organization && permissions !== AdminRole.super)
    permanentFilter.organization = organization;
  return (
    <div className="container">
      <List
        {...props}
        empty={false}
        actions={<ListActions />}
        filters={<AdminFilters />}
        filter={permanentFilter}
        classes={listClasses}
        title="admin.admins"
        pagination={<RaPrevNextPagination />}
        sort={{ field: "createdAt", order: "desc" }}
      >
        <div className="list-container">
          <CustomersDataGrid />
          {permissions === AdminRole.super ? (
            <NoDataTable
              grid={[
                "CIN",
                "admin.name",
                "admin.email",
                "admin.phone",
                "admin.accountStatus",
                // "admin.createdAt",
                "admin.organization",
                "admin.role",
                "admin.title",
                "",
              ]}
            />
          ) : (
            <NoDataTable
              grid={[
                "CIN",
                "admin.name",
                "admin.email",
                "admin.phone",
                "admin.accountStatus",
                // "admin.createdAt",
                "admin.role",
                "admin.title",
                "",
              ]}
            />
          )}
        </div>
      </List>
    </div>
  );
};

const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  const t = useTranslate();
  const { permissions } = usePermissions();

  // const refresh = useRefresh();
  // const [disableAdmin] = useMutation();

  // const setLocale = useLocale();

  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid rowClick="show" rowStyle={tableRowStyle} classes={classes}>
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props.role !== AdminRole.super
                    ? props.CIN
                      ? props.CIN
                      : "N/A"
                    : "N/A"}
                </div>
              );
            }}
            sortable={false}
            source="CIN"
            label="CIN"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.name}</div>;
            }}
            sortable={false}
            source="name"
            label="admin.name"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.email}</div>;
            }}
            source="email"
            sortable={false}
            label="admin.email"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.phone}</div>;
            }}
            sortable={false}
            source="phone"
            label="admin.phone"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props.role !== AdminRole.super ? props.title : "N/A"}
                </div>
              );
            }}
            sortable={false}
            source="title"
            label="admin.title"
          />
          {/* 
          <DateField
            locales={setLocale}
            source="createdAt"
            label="admin.createdAt"
          /> */}

          <FunctionField
            render={(props: any) => {
              return <AccountStatusField value={props.accountStatus} />;
            }}
            sortable={false}
            label="admin.accountStatus"
          />
          <FunctionField
            render={(props: any) => {
              return props.role !== AdminRole.super ? (
                <ApprovalStatusField value={props.approvalStatus} />
              ) : (
                <div className="listField">{"N/A"}</div>
              );
            }}
            label="general.approvalStatus"
          />
          <FunctionField
            render={(props: any) => {
              return <AdminRoleField value={props.role} />;
            }}
            label="admin.role"
          />
          {permissions === AdminRole.super ? (
            <FunctionField
              label={"admin.organization"}
              sortable={false}
              render={(props: any) => {
                return props.role !== AdminRole.super ? (
                  <ReferenceField
                    link="show"
                    source="organization"
                    reference="organization"
                    label={"admin.organization"}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                ) : (
                  <div className="listField">{"N/A"}</div>
                );
              }}
            />
          ) : null}

          <StickyTableCell>
            <FunctionField
              render={(props: any) => {
                return (
                  <DotsMenu
                    record={props}
                    edit
                    // disable
                    confirmTitle={t("admin.confirmDeleteTitle", {
                      name: props.name,
                    })}
                    confirmContent={t("admin.confirmDeleteContent")}
                  />
                );
              }}
            />
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};

export default AdminList;
