/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import firebase from "firebase";
import { useGetIdentity } from "ra-core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setNotifications } from "@state/publicInfo";
import {
  NotificationType,
  INotification,
} from "@wetrans/helpers/out/entities/Notification";
import { transformTimestampToDate } from "@components/common/transformTimestampToDate";
export const NotificationListener = (props: any) => {
  const { loaded, identity } = useGetIdentity();
  const dispatch = useDispatch();
  const db = firebase.app().firestore();

  useEffect(() => {
    if (loaded && identity) {
      let unsubscribeValue = db
        .collection("notifications")
        .where("type", "==", NotificationType.onDashboard)
        .where("users", "array-contains", identity.id.toString())
        .where("isExpired", "==", false)
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          let notifications: INotification[] = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            transformTimestampToDate(data);
            notifications.push(data as INotification);
          });
          dispatch(setNotifications(notifications));
        });
      return () => {
        unsubscribeValue?.();
      };
    }
  }, [loaded, identity, db, dispatch]);

  return <></>;
};
