import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { getPrice } from "./ExpeditionShow";
import InfoIcon from "@material-ui/icons/Info";
import { primaryColor } from "@theme/colors";

interface ExpeditionPriceBreakDown {
  showPriceMayChangeMessage: boolean;
  insurancePolicyCost?: number;
  totalHTExpeditionPrice?: number;
  totalExpeditionPrice?: number;
  platformCommissionFromClient?: number;
  TVA?: number;
  priceLoading?: boolean;
  price?: number;
}
export const ExpeditionPriceBreakDown = (props: ExpeditionPriceBreakDown) => {
  const {
    showPriceMayChangeMessage,
    insurancePolicyCost,
    totalExpeditionPrice,
    priceLoading,
    platformCommissionFromClient,
    totalHTExpeditionPrice,
    TVA,
    price,
  } = props;
  return (
    <>
      <div className="flex flex-wrap margin-b-10 align-baseline">
        <label className="margin-r-10">Frais transport :</label>
        {price && !priceLoading && (
          <label>
            <strong className="font-size-16">
              {`${getPrice(price.toString())} Dhs`}
            </strong>
          </label>
        )}
        {priceLoading && (
          <CircularProgress
            // fontSize="small"
            size={18}
            style={{
              color: primaryColor,
              marginRight: 2,
            }}
            // thickness={2}
          />
        )}
      </div>
      {showPriceMayChangeMessage && (
        <div className="flex flex-wrap margin-b-10 align-baseline">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <InfoIcon
              fontSize="small"
              color="action"
              style={{ marginRight: 5 }}
            />
            <Typography variant="caption" color="textSecondary">
              Frais transport peut être modifié
            </Typography>
          </div>
        </div>
      )}

      <div className="flex flex-wrap margin-b-10 margin-r-10 align-baseline">
        <label className="margin-r-10">Commission wetrans :</label>
        {platformCommissionFromClient && (
          <strong className="font-size-16">
            {`${getPrice((platformCommissionFromClient * 100).toString())}%`}
          </strong>
        )}
      </div>
      <div className="flex flex-wrap margin-b-10 margin-r-10 align-baseline">
        <label className="margin-r-10">Total HT :</label>
        {totalHTExpeditionPrice && !priceLoading && (
          <strong className="font-size-16">
            {`${getPrice(totalHTExpeditionPrice.toString())} Dhs`}
          </strong>
        )}

        {priceLoading && (
          <CircularProgress
            // fontSize="small"
            size={18}
            style={{
              color: primaryColor,
              marginRight: 2,
            }}
            // thickness={2}
          />
        )}
      </div>
      <div className="flex flex-wrap margin-b-10 margin-r-10 align-baseline">
        <label className="margin-r-10">TVA :</label>
        {TVA && (
          <strong className="font-size-16">
            {`${getPrice((TVA * 100).toString())}%`}
          </strong>
        )}
      </div>
      <div className="flex flex-wrap margin-b-10 margin-r-10 align-baseline">
        <label className="margin-r-10">Frais assurance :</label>

        <strong className="font-size-16">
          {`${getPrice(
            insurancePolicyCost
              ? insurancePolicyCost.toString()
              : Number(0).toString()
          )} Dhs`}
        </strong>
      </div>
      <div className="flex flex-wrap margin-b-10 margin-r-10 align-baseline">
        <label className="margin-r-10">Total TTC :</label>
        {totalExpeditionPrice && !priceLoading && (
          <strong className="red font-size-24">
            {`${getPrice(totalExpeditionPrice.toString())} Dhs`}
          </strong>
        )}
        {priceLoading && (
          <CircularProgress
            // fontSize="small"
            size={18}
            style={{
              color: primaryColor,
              marginRight: 2,
            }}
            // thickness={2}
          />
        )}
      </div>
    </>
  );
};
