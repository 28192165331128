import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TopToolbar,
  useListContext,
  // ExportButton,
  sanitizeListRestProps,
  DateField,
  ReferenceField,
  TextField,
  usePermissions,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
  CreateButton,
  // @ts-ignore
} from "react-admin";
import { makeStyles } from "@material-ui/core";

// components
// import ExportExported from "@assets/vectors/ExportExported";
import RouteFilters from "./RouteFilters";
import StickyTableCell from "@components/common/StickyTableCell";

import { useLocale } from "react-admin";

import { RouteFrequencyField } from "@constants/routeFrequency";

import { isEmpty } from "lodash";
import { NoDataTable } from "@components/common/NoDataTable";
// styles
// import { primaryColor } from "@theme/colors";
import "./styles.css";
import { tableRowStyle } from "@theme/styles/table";
import { useOrganization } from "@hooks/useOrganization";
import { AdminRole, RouteFrequency } from "@wetrans/helpers";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import { MultiDaysField } from "@components/vehicle/Edit/daysPicker/MultiDaysField";
import { transformTimestampToDate } from "@components/common/transformTimestampToDate";
import { MultiDateField } from "@components/vehicle/Edit/multiDatePicker/multiDateField";
import { primaryColor } from "@theme/colors";
import VehicleCategoryField from "@components/common/vehicleCategoriesSelecter/VehicleCategoryField";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    // total,
  } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      {permissions === AdminRole.operation && (
        <CreateButton
          basePath={basePath}
          className="createButtonAction"
          style={{
            background: primaryColor,
          }}
          label="ra.action.add"
        />
      )}

      {/* <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        className="createButtonAction"
        style={{
          background: primaryColor,
        }}
        icon={<ExportExported fill={"white"} />}
      /> */}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const RouteList = (props: any) => {
  const listClasses = useListStyles();
  const { permissions } = usePermissions();
  const { organization } = useOrganization();
  const permanentFilter: any = {};
  if (organization && permissions !== AdminRole.super)
    permanentFilter.organization = organization;
  return (
    <div className="container">
      <List
        {...props}
        actions={<ListActions />}
        filters={<RouteFilters />}
        filter={permanentFilter}
        empty={false}
        classes={listClasses}
        title="route.route"
        pagination={<RaPrevNextPagination />}
        sort={{ field: "createdAt", order: "desc" }}
      >
        <div className="list-container">
          <CustomersDataGrid />
          {permissions === AdminRole.super ? (
            <NoDataTable
              grid={[
                "route.from",
                "route.to",
                "Escales",
                "route.frequency",
                "route.dates",
                "Heure de départ",
                "route.createdAt",
                "route.vehicle",
                "vehicle.category",
                "route.organization",
              ]}
            />
          ) : (
            <NoDataTable
              grid={[
                "route.from",
                "route.to",
                "Escales",
                "route.frequency",
                "Heure de départ",
                "route.dates",
                "route.createdAt",
                "route.vehicle",
                "vehicle.category",
              ]}
            />
          )}
        </div>
      </List>
    </div>
  );
};

const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  // const t = useTranslate();
  // const refresh = useRefresh();
  // const [deleteRoute] = useMutation();

  const setLocale = useLocale();
  const { permissions } = usePermissions();

  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid rowClick="show" rowStyle={tableRowStyle} classes={classes}>
          <ReferenceField
            link={false}
            linkType=""
            label="route.from"
            source="from"
            reference="city"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField
            sortable={false}
            link={false}
            linkType=""
            label="route.to"
            source="to"
            reference="city"
          >
            <TextField source="name" />
          </ReferenceField>

          <FunctionField
            sortable={false}
            render={(props: any) => {
              transformTimestampToDate(props);
              return <RouteFrequencyField value={props.frequency} />;
            }}
            label="route.frequency"
          />
          <FunctionField
            sortable={false}
            render={(record: any) => {
              return record.frequency === RouteFrequency.regular ? (
                <MultiDaysField days={record.operationWeekDays} />
              ) : (
                <MultiDateField dates={record.dates} />
              );
            }}
            label="route.dates"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props.departureDateTime ? props.departureDateTime : "N/A"}
                </div>
              );
            }}
            label="Heures de départ"
          />
          <DateField
            showTime
            options={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }}
            sortable={false}
            locales={setLocale}
            source="createdAt"
            label="route.createdAt"
          />
          <FunctionField
            sortable={false}
            render={(props: any) => {
              return !isEmpty(props.waypoints) ? (
                <ReferenceArrayField
                  source="waypoints"
                  sortable={false}
                  reference="city"
                  label="Escales"
                >
                  <SingleFieldList>
                    <ChipField
                      // linkType="false"
                      // onClick={(e) => e.preventDefault()}
                      source="name"
                    />
                  </SingleFieldList>
                </ReferenceArrayField>
              ) : (
                <div className="listField">Pas d'escale</div>
              );
            }}
            label="Escales"
          />

          <FunctionField
            render={(props: any) => {
              return props.vehicle ? (
                <ReferenceField
                  link="show"
                  label="expedition.vehicle"
                  source="vehicle"
                  reference="vehicle"
                  sortable={false}
                >
                  <TextField source="matricule" />
                </ReferenceField>
              ) : (
                <div className="listField">N/A</div>
              );
            }}
            sortable={false}
            label="expedition.vehicle"
          />
          <FunctionField
            render={(props: any) => {
              return props.vehicleCategory ? (
                <VehicleCategoryField source="vehicleCategory" />
              ) : (
                <div className="listField">N/A</div>
              );
            }}
            sortable={false}
            label="vehicle.category"
          />
          {permissions === AdminRole.super && (
            <ReferenceField
              sortable={false}
              link="show"
              label="route.organization"
              source="organization"
              reference="organization"
            >
              <TextField source="name" />
            </ReferenceField>
          )}
          <StickyTableCell>
            <>
              {/* <FunctionField
              render={(props: any) => {
                return (
                  <DotsMenu
                    record={props}
                    // delete={props.status === "active"}
                    delete
                    edit
                    confirmTitle={t("route.confirmDeleteTitle", {
                      id: props.id,
                    })}
                    confirmContent={t("route.confirmDeleteContent")}
                    deleteAction={() => {
                      deleteRoute({
                        type: "delete",
                        resource: "route",
                        payload: {
                          id: props.id,
                        },
                      });
                      refresh();
                    }}
                  />
                );
              }}
            /> */}
            </>
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};

export default RouteList;
