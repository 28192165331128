const RaTablePagination = {
  MuiTablePagination: {
    toolbar: {
      justifyContent: "center",
    },
    spacer: {
      flex: 0,
    },
    caption: {
      display: "none",
    },
    input: {
      order: 2,
    },
  },
};

export default RaTablePagination;
