import React from "react";
import { Redirect } from "react-router-dom";

export default {
  name: "tractor",
  basePath: "/tractor",
  resource: "tractor",
};

export const customRoutes = [
  <Redirect exact from="/tractor/:id/show" to="/tractor/:id/show/info" />,
];
