import _ from "lodash";

type Tab = {
  index: number;
  name: string;
  path: string;
};

export const getTabIndex = (
  pathname: string,
  tabs: Array<Tab>,
  id?: string
): number => {
  const tab = _.find(tabs, ({ path }) => {
    if (id) {
      if (
        tabs.length > 2 &&
        pathname.includes(tabs[2].path.replace(":id", id)) &&
        tabs[2].path === path
      ) {
        return true;
      }

      return pathname === path.replace(":id", id);
    }

    return path.includes(pathname);
  });

  return tab ? tab.index : 0;
};

export const formatOptions = (
  options: Array<Object>,
  platformCurrency: string,
  t: Function
): Array<Object> => {
  return options
    ? options.map(({ id, name, pricePerUnit }: any) => ({
        id,
        name: `${name} ${
          pricePerUnit && pricePerUnit > 0
            ? "(+" + pricePerUnit + " " + platformCurrency + ")"
            : `(${t("general.free")})`
        }`,
      }))
    : [];
};
