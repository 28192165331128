import {
  ICity,
  IOrganization,
  ISettings,
  IVehicleCategory,
  MerchandiseCategory,
  VehicleCategoryGroup,
} from "@wetrans/helpers";
import { INotification } from "@wetrans/helpers/out/entities/Notification";
import actionsTypes from "./actionType";
const initialState: {
  notificationsCentral: any[];
  menuState: string;
  vehicleCategories: IVehicleCategory[];
  merchandiseCategories: MerchandiseCategory[];
  cities: ICity[];
  settings?: ISettings;

  organizations: IOrganization[];
  analyticsDateFilters: any[];
} = {
  notificationsCentral: [],
  menuState: "",
  vehicleCategories: [],
  organizations: [],
  merchandiseCategories: [],
  cities: [],
  analyticsDateFilters: [],
};
export const setAnalyticsDateFilters = (analyticsDateFilters: any[]) => {
  return {
    type: actionsTypes.SET_ANALYTICS_DATE_FILTERS,
    data: analyticsDateFilters,
  };
};
export const setVehicleCategories = (vehicleCategories: IVehicleCategory[]) => {
  return {
    type: actionsTypes.SET_VehicleCategories,
    data: vehicleCategories,
  };
};

export const setMerchandiseCategories = (
  merchandiseCategories: MerchandiseCategory[]
) => {
  return {
    type: actionsTypes.SET_MerchandiseCategories,
    data: merchandiseCategories,
  };
};
export const setVehicleCategoryGroups = (
  vehicleCategoryGroups: VehicleCategoryGroup[]
) => {
  return {
    type: actionsTypes.SET_VehicleCategoryGroups,
    data: vehicleCategoryGroups,
  };
};
export const setCities = (cities: ICity[]) => {
  return {
    type: actionsTypes.SET_CITIES,
    data: cities,
  };
};
export const setSettings = (sitting: ISettings) => {
  return {
    type: actionsTypes.SET_SETTINGS,
    data: sitting,
  };
};
export const setOrganizations = (organizations: IOrganization[]) => {
  return {
    type: actionsTypes.SET_Organization,
    data: organizations,
  };
};
export const setNotifications = (notifications: INotification[]) => {
  return {
    type: actionsTypes.NOTIFICATIONS_CENTRAL,
    data: notifications,
  };
};
const CustomReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionsTypes.NOTIFICATIONS_CENTRAL: {
      return { ...state, notificationsCentral: action.data };
    }
    case actionsTypes.SET_SETTINGS: {
      return { ...state, settings: action.data };
    }
    case actionsTypes.SET_MerchandiseCategories: {
      return { ...state, merchandiseCategories: action.data };
    }
    case actionsTypes.CLEAR_NOTIFICATIONS_CENTRAL: {
      return { ...state, notificationsCentral: [] };
    }
    case actionsTypes.MENU_STATE:
      return {
        ...state,
        menuState: action.data,
      };
    case actionsTypes.SET_VehicleCategories:
      return {
        ...state,
        vehicleCategories: action.data,
      };
    case actionsTypes.SET_CITIES:
      return {
        ...state,
        cities: action.data,
      };
    case actionsTypes.SET_ANALYTICS_DATE_FILTERS:
      return { ...state, analyticsDateFilters: action.data };
    case actionsTypes.SET_Organization:
      return {
        ...state,
        organizations: action.data,
      };
    default:
      return state;
  }
};

export default CustomReducer;
