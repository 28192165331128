/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import {
  Create,
  TextInput,
  maxLength,
  minLength,
  email,
  FileInput,
  FileField,
  SimpleForm,
  usePermissions,
  DateInput,
  useLocale,
  required,
  // @ts-ignore
} from "react-admin";
import { DriverCreateEditToolbar } from "./DriverCreateEditToolbar";
import { AccountStatusInput } from "@constants/accountStatus";
import { useOrganization } from "@hooks/useOrganization";
import { AdminRole, UserType } from "@wetrans/helpers";
import { functionsProvider } from "@providers/provider";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import Card from "@components/common/card/Card";
import { transformPhoneNumber } from "@utils/tranformPhone";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";
// import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";

const validateName = [required(), minLength(3), maxLength(125)];
const validateCIN = [required(), minLength(3), maxLength(10)];

const validateEmail = [required(), email()];
const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export default function DriverCreate(props: any) {
  const { permissions } = usePermissions();
  const { organization } = useOrganization();
  const phoneValidation = React.useCallback(async (phone: string) => {
    try {
      const { data } = await functionsProvider.isPhoneUnique(phone);
      if (!data) return "Numéro de téléphone est déjà utilisé";
      else return undefined;
    } catch (e) {
      return "invalid";
    }
  }, []);
  const validatePhone = [
    required(),
    minLength(3),
    maxLength(125),
    phoneValidation,
  ];
  const locale = useLocale();
  const classes = useStyles();

  const transform = (data: any) => ({
    ...data,
    alreadyAssigned: false,
    type: UserType.driver,
    phone: transformPhoneNumber(data.phone),
    approvalStatus: ApprovalStatus.Pending,
    organization:
      permissions !== AdminRole.super && organization
        ? organization
        : data.organization,
  });

  return (
    <Create
      {...props}
      transform={transform}
      title="Ajouter un nouveau chauffeur"
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <DriverCreateEditToolbar
            mode="create"
            style={{
              position: "absolute",
              top: 55,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 70,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              <Grid item sm={6}>
                <TextInput
                  label="CIN"
                  className={classes.fullWidth}
                  source="CIN"
                  validate={validateCIN}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="driver.name"
                  className={classes.fullWidth}
                  source="name"
                  validate={validateName}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="driver.email"
                  className={classes.fullWidth}
                  source="email"
                  validate={validateEmail}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="driver.phone"
                  className={classes.fullWidth}
                  source="phone"
                  validate={validatePhone}
                />
              </Grid>
              <Grid item sm={6}>
                <FileInput
                  source="license"
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                  maxSize={10000000}
                  // accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  validate={[required()]}
                  label="driver.license"
                >
                  <FileField source="src" title="title" target="_blank" />
                </FileInput>
              </Grid>
              <Grid item sm={6}>
                <AccountStatusInput
                  required
                  label="driver.accountStatus"
                  className={classes.fullWidth}
                  source="accountStatus"
                />
              </Grid>
              <Grid item sm={6}>
                <DateInput
                  locales={locale}
                  className={classes.fullWidth}
                  validate={[required()]}
                  source="licenseExpirationDate"
                  label="Date d'expiration du permis"
                />
              </Grid>

              <Grid item sm={12}>
                <MultiFIleInputs source="documents" label="Documents" />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Create>
  );
}
