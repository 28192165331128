import * as React from "react";

import { secondaryColor } from "@theme/colors";

interface IFlagExportedProps {
  color?: string;
  style?: React.CSSProperties;
}

export default function FlagExported(props: IFlagExportedProps) {
  const { color = secondaryColor, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.431"
      height="36.003"
      viewBox="0 0 35.431 36.003"
      {...rest}
    >
      <path
        id="flag"
        d="M17.1,13.352v4.788c1.835.415,3.466,1.1,5.175,1.568v-4.8c-1.828-.408-3.473-1.09-5.175-1.561ZM32.8,4.7a20.532,20.532,0,0,1-8.227,2.243C20.813,6.947,17.7,4.5,12.959,4.5a13.671,13.671,0,0,0-4.781.844A3.938,3.938,0,1,0,2.25,7.165V34.313A1.683,1.683,0,0,0,3.938,36H5.063A1.683,1.683,0,0,0,6.75,34.313V27.675a19.6,19.6,0,0,1,8.044-1.554c3.769,0,6.877,2.447,11.616,2.447a14.692,14.692,0,0,0,8.613-2.876,2.243,2.243,0,0,0,.97-1.856V6.743A2.247,2.247,0,0,0,32.8,4.7ZM11.925,22.887A22.151,22.151,0,0,0,6.75,24.054V19.1a20.115,20.115,0,0,1,5.175-1.223Zm20.7-9.457a22.428,22.428,0,0,1-5.175,1.68v5a13.068,13.068,0,0,0,5.175-1.828v4.957a11.361,11.361,0,0,1-5.175,1.905V20.109a11.893,11.893,0,0,1-5.175-.394v4.739a41.024,41.024,0,0,0-5.175-1.5V18.141a15.629,15.629,0,0,0-5.175-.267V12.952a24.812,24.812,0,0,0-5.175,1.47V9.464a20.135,20.135,0,0,1,5.175-1.547v5.034a11.96,11.96,0,0,1,5.175.4V8.613a40.028,40.028,0,0,0,5.175,1.5V14.92a13.394,13.394,0,0,0,5.175.19V10.055a24.878,24.878,0,0,0,5.175-1.582Z"
        transform="translate(-0.563 0.003)"
        fill={color}
      />
    </svg>
  );
}
