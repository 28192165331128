const BASEPATH = "/vehicle/:id/show";

export const VEHICLE_TABS = [
  {
    index: 0,
    name: "Info",
    path: `${BASEPATH}/info`,
    default: true,
  },
  {
    index: 1,
    name: "Expéditions",
    path: `${BASEPATH}/expeditions`,
  },
  {
    index: 2,
    name: "Trajets",
    path: `${BASEPATH}/routes`,
  },
];
export const DAYS = [
  {
    key: 1,
    label: "Lun",
  },
  {
    key: 2,
    label: "Mar",
  },
  {
    key: 3,
    label: "Mer",
  },
  {
    key: 4,
    label: "Jeu",
  },
  {
    key: 5,
    label: "Ven",
  },
  {
    key: 6,
    label: "Sam",
  },
  {
    key: 7,
    label: "Dim",
  },
];
