import React from "react";
import styles from "../expedition/ExpeditionShow.module.css";
import MarkerExported from "@assets/vectors/MarkerExported";
import FlagExported from "@assets/vectors/FlagExported";
import {
  ChipField,
  Edit,
  FormWithRedirect,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  Title,
  usePermissions,
  useTranslate,
} from "react-admin";
import { primaryColor } from "@theme/colors";
// import { transformTimestampToDate } from "@components/common/transformTimestampToDate";
import { RouteFrequencyField } from "@constants/routeFrequency";
import { AdminRole, IRouteTemplate, RouteFrequency } from "@wetrans/helpers";
import { MultiDaysField } from "@components/vehicle/Edit/daysPicker/MultiDaysField";
import { MultiDateField } from "@components/vehicle/Edit/multiDatePicker/multiDateField";
import { isEmpty } from "lodash";
import { RouteCancellationButton } from "./RouteCancellationButton";
import { RouteStatusField } from "@constants/RouteStatus";
import { RouteDeleteButton } from "./RouteDeleteButton";
// import { getLocalTime } from "@utils/dateHelper";

export const RouteDetail = (props: any) => {
  const t = useTranslate();
  const { permissions } = usePermissions();
  return (
    <div className="editContainer">
      <Edit
        {...props}
        hasShow={false}
        className="edit"
        title={<Title title={`Trajet - Infos`} />}
      >
        <SimpleShowLayout>
          <FormWithRedirect
            {...props}
            warnWhenUnsavedChanges
            save={() => {}}
            redirect={() => {}}
            render={(formProps) => (
              <form>
                <>
                  <div className={`${styles.right} card justify-between`}>
                    <div>
                      <div>
                        <div className="flex justify-between">
                          <MarkerExported
                            color={primaryColor}
                            style={{ width: "18px" }}
                          />
                          <FlagExported
                            color={primaryColor}
                            style={{ width: "20px" }}
                          />
                        </div>
                        <div className={styles.stepper} />
                      </div>
                      <div className="flex flex-wrap justify-between margin-b-15">
                        <div>
                          <div className="flex flex-wrap direction-column margin-b-10">
                            <label>
                              <strong>Point de depart :</strong>
                            </label>
                            <ReferenceField
                              link={false}
                              sortable={false}
                              label="Point de depart :"
                              source="from"
                              reference="city"
                            >
                              <TextField source="name" />
                            </ReferenceField>
                          </div>

                          {/* <div className="flex flex-wrap direction-column margin-b-10">
                            <label>
                              <strong>Starting Time :</strong>
                            </label>
                            <TextField
                              label="Adresse :"
                              source="from.address"
                            />
                          </div> */}
                          {/* <div className="flex flex-wrap direction-column margin-b-10">
                            <label>
                              <strong>Date :</strong>
                            </label>
                            <DateField label="Date :" source="bookedMatch.date" />
                          </div> */}
                        </div>
                        <div>
                          <div className="flex flex-wrap direction-column margin-b-10">
                            <label>
                              <strong>Point d’arrivée :</strong>
                            </label>
                            <ReferenceField
                              link={false}
                              sortable={false}
                              label="Point d’arrivée :"
                              source="to"
                              reference="city"
                            >
                              <TextField source="name" />
                            </ReferenceField>
                          </div>
                          {/* <div className="flex flex-wrap direction-column margin-b-10">
                <label>
                  <strong>Adresse :</strong>
                </label>
                <TextField label="Adresse :" source="to.address" />
              </div> */}
                          {/* <div className="flex flex-wrap direction-column margin-b-10">
                <label>
                  <strong>Date :</strong>
                </label>
                <DateField label="Date :" source="bookedMatch.date" />
              </div> */}
                        </div>
                      </div>

                      <div className="flex flex-wrap direction-row margin-b-10">
                        <label style={{ marginRight: 10 }}>
                          <strong>{t("route.frequency")} :</strong>
                        </label>
                        <FunctionField
                          sortable={false}
                          render={(props: any) => {
                            //   transformTimestampToDate(props);
                            return (
                              <RouteFrequencyField value={props.frequency} />
                            );
                          }}
                          label="route.frequency"
                        />
                      </div>
                      <div className="flex flex-wrap direction-row margin-b-10">
                        <label style={{ marginRight: 10 }}>
                          <strong>{"Statut"} :</strong>
                        </label>
                        <FunctionField
                          sortable={false}
                          render={(props: any) => {
                            //   transformTimestampToDate(props);
                            return <RouteStatusField value={props.status} />;
                          }}
                          label="route.frequency"
                        />
                      </div>
                      <div className="flex flex-wrap direction-row margin-b-10">
                        <label style={{ marginRight: 10 }}>
                          <strong>{t("route.dates")} :</strong>
                        </label>
                        <FunctionField
                          sortable={false}
                          render={(record: any) => {
                            return record.frequency ===
                              RouteFrequency.regular ? (
                              <MultiDaysField days={record.operationWeekDays} />
                            ) : (
                              <MultiDateField dates={record.dates} />
                            );
                          }}
                          label="route.dates"
                        />
                      </div>
                      <div className="flex flex-wrap direction-row margin-b-10">
                        <label style={{ marginRight: 10 }}>
                          <strong>{"Heures de départ"} :</strong>
                        </label>
                        <FunctionField
                          render={(props: any) => {
                            return (
                              <div className="listField">
                                {props.departureDateTime
                                  ? props.departureDateTime
                                  : //    getLocalTime(
                                    // //       props.departureDateTime.seconds
                                    // //         ? props.departureDateTime.toDate()
                                    // //         : props.departureDateTime
                                    //     )
                                    "N/A"}
                              </div>
                            );
                          }}
                          label="route.organization"
                        />
                      </div>
                      <div className="flex flex-wrap direction-row margin-b-10">
                        <label style={{ marginRight: 10, marginBottom: 10 }}>
                          <strong>{"Escales"} :</strong>
                        </label>
                        <FunctionField
                          sortable={false}
                          render={(props: any) => {
                            return !isEmpty(props.waypoints) ? (
                              <ReferenceArrayField
                                source="waypoints"
                                sortable={false}
                                reference="city"
                                label="Escales"
                              >
                                <SingleFieldList>
                                  <ChipField
                                    // linkType="false"
                                    // onClick={(e) => e.preventDefault()}
                                    source="name"
                                  />
                                </SingleFieldList>
                              </ReferenceArrayField>
                            ) : (
                              <div className="listField">Pas d'escale</div>
                            );
                          }}
                          label="Escales"
                        />
                      </div>
                      <div className="flex flex-wrap direction-row margin-b-10">
                        <label style={{ marginRight: 10 }}>
                          <strong>{t("expedition.vehicle")} :</strong>
                        </label>
                        <FunctionField
                          render={(props: any) => {
                            return props.vehicle ? (
                              <ReferenceField
                                link="show"
                                label="expedition.vehicle"
                                source="vehicle"
                                reference="vehicle"
                                sortable={false}
                              >
                                <TextField source="matricule" />
                              </ReferenceField>
                            ) : (
                              <div className="listField">N/A</div>
                            );
                          }}
                          sortable={false}
                          label="expedition.vehicle"
                        />
                      </div>
                      {permissions === AdminRole.super && (
                        <div className="flex flex-wrap direction-row margin-b-10">
                          <label style={{ marginRight: 10 }}>
                            <strong>{t("route.organization")} :</strong>
                          </label>
                          <FunctionField
                            render={(props: any) => {
                              return props.vehicle ? (
                                <ReferenceField
                                  sortable={false}
                                  link="show"
                                  label="route.organization"
                                  source="organization"
                                  reference="organization"
                                >
                                  <TextField source="name" />
                                </ReferenceField>
                              ) : (
                                <div className="listField">N/A</div>
                              );
                            }}
                            label="route.organization"
                          />
                        </div>
                      )}

                      <FunctionField
                        render={(route: any) => {
                          return (
                            <RouteCancellationButton
                              route={route as IRouteTemplate}
                            />
                          );
                        }}
                      />
                      <FunctionField
                        render={(route: any) => {
                          return (
                            <RouteDeleteButton
                              route={route as IRouteTemplate}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              </form>
            )}
          />
        </SimpleShowLayout>
      </Edit>
    </div>
  );
};
