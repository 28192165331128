import React, { useEffect } from "react";
import { useQuery } from "react-admin";
import { useDispatch } from "react-redux";
import {
  setMerchandiseCategories,
  setSettings,
  setVehicleCategories,
  setVehicleCategoryGroups,
} from "@state/publicInfo";
import { ISettings } from "@wetrans/helpers";

const SettingsFetcher = () => {
  const dispatch = useDispatch();
  const { data: settings } = useQuery({
    resource: "settings",
    type: "getOne",
    payload: {
      id: "settings",
    },
  });

  useEffect(() => {
    if (settings) {
      const sett = settings as ISettings;
      const vehicleCategories = sett.vehicleCategories;
      const merchandiseCategories = sett.merchandiseCategories;
      const vehicleCategoryGroups = sett.vehicleCategoryGroups;
      dispatch(setSettings(sett));
      dispatch(setVehicleCategories(vehicleCategories));
      dispatch(setMerchandiseCategories(merchandiseCategories));
      dispatch(setVehicleCategoryGroups(vehicleCategoryGroups));
    }
  }, [settings, dispatch]);
  return <></>;
};

export default SettingsFetcher;
