import React from "react";
import { choices, SelectInput } from "react-admin";
import { expeditionStatus } from "./expeditionStatus";

export const ExpeditionStatusInput = ({
  source,
  label,
  className,
}: {
  source: string;
  label: string;
  className?: string;
}) => {
  return (
    <SelectInput
      optionText="label"
      optionValue="id"
      allowEmpty={false}
      source={source}
      label={label}
      choices={expeditionStatus}
      validate={choices(expeditionStatus.map((item) => item.id))}
      className={className}
    />
  );
};
