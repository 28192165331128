import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import React from "react";
import { SaveButton, Toolbar, useGetIdentity } from "react-admin";

const RouteCreateToolbar = (props: any) => {
  const { identity, loaded: identityLoaded } = useGetIdentity();
  const { vehicleDeleted } = props;
  return identityLoaded ? (
    <Toolbar {...props}>
      <SaveButton
        disabled={
          (identity as any).approvalStatus !== ApprovalStatus.Approved ||
          props.pristine ||
          vehicleDeleted
        }
        invalid={props.invalid}
        {...props}
      />
    </Toolbar>
  ) : null;
};
export default RouteCreateToolbar;
