import React, { useEffect, useState } from "react";
import { useQuery } from "react-admin";
import styles from "./ExpeditionOffre.module.css";

import { IExpedition, MerchandiseCategory } from "@wetrans/helpers";
import MarkerExported from "@assets/vectors/MarkerExported";
import { primaryColor } from "@theme/colors";
import { useSelector } from "react-redux";
import {
  citiesSelector,
  merchandiseCategoriesSelector,
} from "@state/selectors";
import { isEmpty } from "lodash";
import { getPrice } from "@components/expedition/ExpeditionShow";

const NewExpeditionsOffre = () => {
  const [expeditions, setExpeditions] = useState<IExpedition[]>();
  const cities = useSelector(citiesSelector);
  const getCity = (cityId: string) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "N/A";
  };

  const { data, loaded } = useQuery({
    resource: "expedition",
    type: "getList",
    payload: {
      sort: {
        field: "bookedAt",
        order: "desc",
      },
      pagination: {
        perPage: 2,
        page: 1,
      },
    },
  });
  const merchandiseCategories: MerchandiseCategory[] = useSelector(
    merchandiseCategoriesSelector
  );
  const getMerchandiseCategory = (merchandiseCategoryId: string) => {
    const merch = merchandiseCategories.find(
      (merch) => merch.id === merchandiseCategoryId
    );
    return merch ? merch.name : "N/A";
  };
  useEffect(() => {
    if (loaded && data) {
      setExpeditions(data as IExpedition[]);
    }
  }, [data, loaded]);
  return (
    <>
      {expeditions &&
        !isEmpty(expeditions) &&
        expeditions.map((expedition) => {
          return (
            <div
              className={`card justify-between `}
              style={{ padding: "0.7rem", marginBottom: 10 }}
            >
              <div>
                <div>
                  <div className="flex justify-between">
                    <MarkerExported
                      color={primaryColor}
                      style={{ width: "12px" }}
                    />
                    <MarkerExported
                      color={primaryColor}
                      style={{ width: "12px" }}
                    />
                  </div>
                  <div className={styles.stepper} />
                </div>
                <div className="flex flex-wrap justify-between">
                  <div>
                    <div className="flex flex-wrap direction-column">
                      <label>{getCity(expedition.from.city)}</label>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-wrap direction-column">
                      <label>{getCity(expedition.to.city)}</label>
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-wrap direction-row"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <label style={{ marginRight: 10, marginTop: 10 }}>
                    <strong>
                      {getPrice(
                        expedition.totalExpeditionPrice
                          ? expedition.totalExpeditionPrice.toString()
                          : undefined
                      )}{" "}
                      DH
                    </strong>
                  </label>
                  <label style={{ marginRight: 10, marginTop: 10 }}>
                    <strong>
                      {getMerchandiseCategory(expedition.merchandise.category)}
                    </strong>
                  </label>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default NewExpeditionsOffre;
