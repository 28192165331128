import React from "react";
import {
  Toolbar,
  useEditController,
  useGetIdentity,
  useMutation,
  usePermissions,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import { AccountStatus, AdminRole } from "@wetrans/helpers";
import { AdminApprovalButtons } from "@components/common/WithAdminApproval/AdminApprovalButtons";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});
export const AdminActivateClientAccountToolbar = (props: any) => {
  const {
    resource,
    id,
    adminApproveWarningMessage,
    adminDisapproveWarningMessage,
  } = props;

  const [updateRecord] = useMutation();
  const { loaded: identityLoaded } = useGetIdentity();
  const classes = useStyles();

  const { record, loaded } = useEditController(props);
  const onAccept = React.useCallback(async () => {
    await updateRecord({
      type: "update",
      resource: resource,
      payload: {
        data: {
          accountStatus: AccountStatus.active,
        },
        id: id,
      },
    });
    // refresh();
  }, [id, resource, updateRecord]);
  const onReject = React.useCallback(async () => {
    await updateRecord({
      type: "update",
      resource: resource,
      payload: {
        data: {
          ...record,
          accountStatus: AccountStatus.suspended,
        },
        id: id,
      },
    });
    // refresh();
  }, [id, record, resource, updateRecord]);

  const { permissions } = usePermissions();

  return identityLoaded ? (
    <Toolbar {...props} classes={classes}>
      {permissions === AdminRole.super && (
        <>
          {loaded && record && (
            <AdminApprovalButtons
              acceptWarningMessage={adminApproveWarningMessage}
              rejectWarningMessage={adminDisapproveWarningMessage}
              acceptLabel="Activer"
              rejectLabel="Désactiver"
              hideReject={record.accountStatus === AccountStatus.suspended}
              hideAccept={record.accountStatus === AccountStatus.active}
              onAccept={onAccept}
              onReject={onReject}
            />
          )}
        </>
      )}
    </Toolbar>
  ) : null;
};
