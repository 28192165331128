import React from "react";
import classNames from "classnames";

// Material UI
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  {
    title: {
      fontWeight: "bold",
      color: "#354052",
      marginBottom: 20,
    },
    md: {
      fontSize: 18,
      fontWeight: "bold",
    },
    lg: {
      fontSize: 24,
      fontWeight: "bold",
    },
  },
  { name: "GRM-title" }
);

export interface CardTitleProps {
  size?: "md" | "lg";
  children?: React.ReactNode;
  className?: string;
  style?: any;
}

const CardTitle = ({ children, size, className, ...rest }: CardTitleProps) => {
  const classes = useStyles();

  return (
    <Typography
      className={classNames(
        classes.title,
        { [classes.md]: size === "md", [classes.lg]: size === "lg" },
        className
      )}
      component="h6"
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default CardTitle;
