import { Button } from "@material-ui/core";
import React from "react";
import PhoneAndroidTwoToneIcon from "@material-ui/icons/PhoneAndroidTwoTone";
import { useSelector } from "react-redux";
import { settingsSelector } from "@state/selectors";
import { ISettings } from "@wetrans/helpers";

export const DriverAppLink = (props: any) => {
  const settings: ISettings = useSelector(settingsSelector);
  return (
    <div
      style={{
        marginTop: 5,
      }}
    >
      <Button
        onClick={async () => {
          let a = document.createElement("a");
          a.setAttribute("href", settings.driverAppLink);
          a.setAttribute("target", "_blank");
          a.click();
        }}
        color="primary"
        style={{ fontSize: 12 }}
      >
        <PhoneAndroidTwoToneIcon />
        <span>Application mobile</span>
      </Button>
    </div>
  );
};
