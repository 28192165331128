import { customRoutes as dashboardCustomRoutes } from "./components/dashboard";
import { customRoutes as vehicleRoutes } from "@components/vehicle";
import { customRoutes as tractorRoutes } from "@components/tractor";

import { customRoutes as clientRoutes } from "@components/client";
import { customRoutes as supportRoutes } from "@components/contact";
export default [
  ...dashboardCustomRoutes,
  ...vehicleRoutes,
  ...clientRoutes,
  ...supportRoutes,
  ...tractorRoutes,
];
