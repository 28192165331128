import * as React from "react";
import {
  Edit,
  FileField,
  SimpleForm,
  usePermissions,
  useLocale,
  TextField,
  Labeled,
  DateField,
  ReferenceField,
  FunctionField,
  // @ts-ignore
} from "react-admin";
import MyTitle from "@components/common/View/Title";

import { AdminRole } from "@wetrans/helpers";
// import { useOrganization } from "@hooks/useOrganization";
import ExpirationWarning from "@components/common/ExpirationWarning";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import Card from "@components/common/card/Card";

import { AccountStatusField } from "@constants/accountStatus";
import { MultiFileField } from "@components/common/MultiFileField/MultiFileField";
import { ApprovalStatusField } from "@constants/approvalStatus";
import { DeletedField } from "@components/common/WithAdminApproval/DeletedField";
// import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export default function DriverShow(props: any) {
  const { permissions } = usePermissions();
  // const { organization } = useOrganization();

  const locale = useLocale();
  const classes = useStyles();
  const { Toolbar } = props;
  return (
    <Edit
      resource="users"
      {...props}
      hasShow={false}
      title={<MyTitle resource={props.resource} descriptionAttribute="name" />}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <Toolbar
            // mode="edit"
            {...props}
            style={{
              position: "absolute",
              top: 55,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 65,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              <Grid item sm={12}>
                <DeletedField deletedMessage="driver.deletedMessage" />
              </Grid>
              <Grid item sm={12}>
                <ExpirationWarning
                  source="licenseExpirationDate"
                  expiredMessage="La date d'expiration du permis est passée"
                  closeMessage="La date d'expiration du permis est proche"
                />
              </Grid>
              <Grid item sm={6}>
                <Labeled label="CIN">
                  <TextField
                    source="CIN"
                    className={classes.fullWidth}
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item sm={6}>
                <Labeled label="driver.name">
                  <TextField
                    source="name"
                    className={classes.fullWidth}
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item sm={6}>
                <Labeled label="driver.email">
                  <TextField
                    source="email"
                    className={classes.fullWidth}
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item sm={6}>
                <Labeled label="driver.phone">
                  <TextField
                    source="phone"
                    className={classes.fullWidth}
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>

              <Grid item sm={6}>
                <FunctionField
                  render={(props: any) => {
                    return (
                      <Labeled label="driver.license">
                        {props.license ? (
                          <FileField
                            source="license.src"
                            title="license.title"
                            sortable={false}
                            target="_blank"
                            label="driver.license"
                          />
                        ) : (
                          <span>N/A</span>
                        )}
                      </Labeled>
                    );
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <FunctionField
                  render={(props: any) => {
                    return (
                      <Labeled label="driver.accountStatus">
                        <AccountStatusField value={props.accountStatus} />
                      </Labeled>
                    );
                  }}
                  label="driver.accountStatus"
                />
              </Grid>
              <Grid item sm={6}>
                <Labeled label="Date d'expiration du permis">
                  <DateField
                    locales={locale}
                    className={classes.fullWidth}
                    source="licenseExpirationDate"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>

              {permissions === AdminRole.super && (
                <Grid item sm={6}>
                  <Labeled label="vehicle.organization">
                    <ReferenceField
                      className={classes.fullWidth}
                      link="show"
                      source="organization"
                      reference="organization"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Labeled>
                </Grid>
              )}
              <Grid item sm={6}>
                <FunctionField
                  render={(props: any) => {
                    return (
                      <Labeled label="general.approvalStatus">
                        <ApprovalStatusField value={props.approvalStatus} />
                      </Labeled>
                    );
                  }}
                  label="general.approvalStatus"
                />
              </Grid>
              <Grid item sm={12}>
                <MultiFileField source="documents" label="Documents" />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Edit>
  );
}
