import React from "react";
import { Layout } from "react-admin";
import MyAppBar from "./AppBar";
import Menu from "./Menu";
import MySidebar from "./Sidebar";

const MyLayout = (props: any) => (
  <Layout {...props} sidebar={MySidebar} appBar={MyAppBar} menu={Menu} />
);

export default MyLayout;
