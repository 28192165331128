import React from "react";

export default function ThreeDots(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="7"
      viewBox="0 0 25 7"
      {...props}
    >
      <g transform="translate(-1815 -343)">
        <circle cx="3.5" cy="3.5" r="3.5" transform="translate(1833 343)" />
        <circle cx="3.5" cy="3.5" r="3.5" transform="translate(1824 343)" />
        <circle cx="3.5" cy="3.5" r="3.5" transform="translate(1815 343)" />
      </g>
    </svg>
  );
}
