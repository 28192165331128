import { Button } from "@mui/material";
import { dataProvider } from "@providers/provider";
import {
  AdminRole,
  IRouteTemplate,
  RouteTemplateStatus,
} from "@wetrans/helpers";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import React, { useState } from "react";

import {
  Confirm,
  useGetIdentity,
  useMutation,
  useNotify,
  usePermissions,
} from "react-admin";

interface ExpeditionCancellationProps {
  route: IRouteTemplate;
}

export const RouteCancellationButton = (props: ExpeditionCancellationProps) => {
  const { route } = props;
  const { permissions } = usePermissions();
  const { identity, loaded: identityLoaded } = useGetIdentity();

  const [isOpen, setIsOpen] = useState(false);
  const notify = useNotify();
  const onSuccess = React.useCallback(() => {
    notify(`Trajet annulée`);
  }, [notify]);
  const onFailure = React.useCallback(
    (error: any) => {
      notify(`${error.message}`);
    },
    [notify]
  );
  const [update] = useMutation();

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const cancelRoute = async (values: any) => {
    const currentRouteRecord = await dataProvider.getOne("routeTemplates", {
      id: route.id,
    });
    const currentRouteStatus = (currentRouteRecord.data as IRouteTemplate)
      .status;
    if (route && currentRouteStatus === RouteTemplateStatus.active) {
      await update(
        {
          type: "update",
          resource: "routeTemplates",
          payload: {
            id: route.id,
            data: {
              ...route,
              status: RouteTemplateStatus.cancelled,
            },
          },
        },
        { onFailure, onSuccess }
      );
    }
  };

  return (permissions === AdminRole.support ||
    permissions === AdminRole.operation) &&
    identityLoaded &&
    route.status === RouteTemplateStatus.active ? (
    <>
      <div className="flex flex-wrap row-reverse justify-between">
        <Button
          variant="contained"
          className="background-red"
          disabled={
            permissions === AdminRole.support ||
            (identity as any).approvalStatus !== ApprovalStatus.Approved
          }
          onClick={handleClickOpen}
        >
          Annuler
        </Button>
      </div>
      <Confirm
        isOpen={isOpen}
        title={"Annuler Trajet"}
        content={"Êtes-vous sûr d'annuler ce trajet ?"}
        onConfirm={cancelRoute}
        onClose={handleClose}
      />
    </>
  ) : null;
};
