import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dateRoot: {
    background: "#e3e3e3",
    borderRadius: 4,
    paddingBottom: 0,

    "& p": {
      display: "none",
    },
  },
  formRoot: {
    "& span": {
      textTransform: "capitalize",
    },
  },
  booleanRoot: {
    color: "gray",
  },
}));

export default useStyles;
