import React from "react";
import { choices, required, SelectInput } from "react-admin";
import { accountStatus } from "./accountStatus";

export const AccountStatusInput = ({
  source,
  label,
  className,
  disabled,
  required: requiired,
}: {
  source: string;
  label: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}) => {
  return (
    <SelectInput
      optionText="label"
      disabled={disabled}
      optionValue="id"
      source={source}
      label={label}
      choices={accountStatus}
      validate={[required(), choices(accountStatus.map((item) => item.id))]}
      className={className}
    />
  );
};
