import { WithAdminApproval } from "@components/common/WithAdminApproval";
import * as React from "react";
import { VehicleForm } from "./VehicleForm";
import { VehicleShow } from "./VehicleShow";
import firebase from "firebase";
import {
  ExpeditionStatus,
  IVehicle,
  RouteStatus,
  RouteTemplateStatus,
} from "@wetrans/helpers";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";

export default function VehicleEditShow(props: any) {
  const checkIfTheVehicleIsDeleteble = React.useCallback(
    async (record: IVehicle) => {
      const db = firebase.app().firestore();
      try {
        const { empty: emptyExpeditions } = await db
          .collection("expedition")
          .where("vehicle", "==", record.id)
          .orderBy("status", "desc")
          .startAt(ExpeditionStatus.unloading)
          .endAt(ExpeditionStatus.booked)
          .limit(1)
          .get();
        const { empty: emptyRoutes } = await db
          .collection("route")
          .where("vehicle", "==", record.id)
          .orderBy("status", "desc")
          .startAt(RouteStatus.fullyBooked)
          .endAt(RouteStatus.booked)
          .limit(1)
          .get();
        const { empty: emptyRoutesTemplate } = await db
          .collection("routeTemplates")
          .where("vehicle", "==", record.id)
          .where("status", "in", [
            RouteTemplateStatus.active,
            RouteTemplateStatus.requested,
          ])

          .limit(1)
          .get();
        return emptyExpeditions && emptyRoutes && emptyRoutesTemplate;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    []
  );
  return (
    <WithAdminApproval
      {...props}
      adminApproveWarningMessage="tractor.adminApproveWarningMessage"
      adminDisapproveWarningMessage="tractor.adminDisapproveWarningMessage"
      warningMessage="vehicle.warningMessage"
      warningMessageTitle="vehicle.warningMessageTitle"
      EditComponent={VehicleForm}
      ShowComponent={VehicleShow}
      enableDelete
      deleteConfirmationMessage="vehicle.deleteConfirmationMessage"
      isDeletable={checkIfTheVehicleIsDeleteble}
      transformOnDelete={(record: IVehicle, deletedById: string) => {
        return {
          ...record,
          approvalStatus: ApprovalStatus.Disapproved,
          deleted: true,
          deletedAt: new Date(),
          deletedBy: deletedById,
        };
      }}
    />
  );
}
