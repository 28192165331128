import { makeStyles } from "@material-ui/core";
import { tableRowStyle } from "@theme/styles/table";
import { isEmpty } from "lodash";
import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  FileField,
  FormDataConsumer,
  FunctionField,
  Labeled,
  useLocale,
  // Labeled,
  // SingleFieldList,
} from "react-admin";
import { NoDataTable } from "../NoDataTable";
import StickyTableCell from "../StickyTableCell";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
export const MultiFileField = (props: any) => {
  const { source, label } = props;
  const classes = useStyles();
  const locale = useLocale();

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          <Labeled label={label ? label : "Documents"}>
            <></>
          </Labeled>
          {formData[source] && !isEmpty(formData[source]) && (
            <>
              <ArrayField {...props}>
                <Datagrid rowStyle={tableRowStyle} classes={classes}>
                  <FunctionField
                    render={(props: any) => {
                      return <div className="listField">{props.name}</div>;
                    }}
                    sortable={false}
                    source="name"
                    label="Nom"
                  />
                  <DateField
                    locales={locale}
                    source="expirationDate"
                    label={"Date d'expiration"}
                    emptyText="N/A"
                  />
                  <FunctionField
                    label={"Document"}
                    render={(props: any) => {
                      return (
                        <>
                          {props.file ? (
                            <FileField
                              source="file.src"
                              title="file.title"
                              sortable={false}
                              target="_blank"
                            />
                          ) : (
                            <span>N/A</span>
                          )}
                        </>
                      );
                    }}
                  />
                  <StickyTableCell>
                    <></>
                  </StickyTableCell>
                </Datagrid>
              </ArrayField>
            </>
          )}
          {(!formData[source] || isEmpty(formData[source])) && (
            <NoDataTable
              label="Aucun document"
              grid={["Nom", "Date d'expiration", "Document"]}
            />
          )}
        </>
      )}
    </FormDataConsumer>
  );
};
// {

// }
