import React from "react";
import {
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
  useLocale,
  useTranslate,
} from "react-admin";
import { Alert } from "@material-ui/lab";
interface DeletedFieldProps {
  deletedMessage: string;
}

export const DeletedField = ({ deletedMessage }: DeletedFieldProps) => {
  const t = useTranslate();
  const locale = useLocale();
  return (
    <FunctionField
      render={(props: any) => {
        return props.deleted ? (
          <Alert variant="standard" severity="error">
            {t(deletedMessage)}
            <DateField
              showTime
              options={{
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }}
              locales={locale}
              source={"deletedAt"}
            />
            {" par "}
            <ReferenceField
              link="show"
              sortable={false}
              // label="organization.createdBy"
              source="deletedBy"
              reference="users"
            >
              <TextField source="name" />
            </ReferenceField>
          </Alert>
        ) : null;
      }}
    />
  );
};
