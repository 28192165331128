import Card from "@components/common/card/Card";
// import DriverInput from "@components/common/DriverInput/DriverInput";
import ExpirationWarning from "@components/common/ExpirationWarning";
import { MultiFileField } from "@components/common/MultiFileField/MultiFileField";
import VehicleCategoriesSelector from "@components/common/vehicleCategoriesSelecter/VehicleCategoriesSelector";
import { DeletedField } from "@components/common/WithAdminApproval/DeletedField";
import { ApprovalStatusField } from "@constants/approvalStatus";
// import { useOrganization } from "@hooks/useOrganization";
import Grid from "@material-ui/core/Grid";
import { AdminRole } from "@wetrans/helpers";
import React from "react";
import {
  FileField,
  SimpleForm,
  useEditController,
  useLocale,
  usePermissions,
  Labeled,
  TextField,
  NumberField,
  FunctionField,
  DateField,
  ReferenceField,
} from "react-admin";
import { useStyles } from "./style";

export const VehicleShow = (props: any) => {
  const { permissions } = usePermissions();
  // const { organization } = useOrganization();
  const classes = useStyles();
  const locale = useLocale();

  const controllerProps = useEditController({
    ...props,
  });
  const {
    basePath, // deduced from the location, useful for action buttons
    record, // record fetched via dataProvider.getOne() based on the id from the location
    redirect, // the default redirection route. Defaults to 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
    version, // integer used by the refresh feature
  } = controllerProps;
  const { Toolbar } = props;
  return (
    <SimpleForm
      warnWhenUnsavedChanges
      className={classes.fullWidth}
      {...{
        basePath,
        record,
        redirect,
        resource,
        save,
        saving,
        version,
      }}
      toolbar={
        <Toolbar
          {...props}
          mode="edit"
          style={{
            position: "absolute",
            top: 120,
          }}
        />
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: 50,
        }}
      >
        <Card>
          <Grid container className={classes.fullWidth} spacing={4}>
            <Grid item sm={12}>
              <DeletedField deletedMessage="vehicle.deletedMessage" />
            </Grid>
            <Grid item sm={12}>
              <ExpirationWarning
                source="carteGriseExpirationDate"
                expiredMessage="La date d'expiration du carte grise est passée"
                closeMessage="La date d'expiration du carte grise est proche"
              />
            </Grid>
            <Grid item sm={12}>
              <ExpirationWarning
                source="vignetteExpirationDate"
                expiredMessage="La date d'expiration du vignette est passée"
                closeMessage="La date d'expiration du vignette est proche"
              />
            </Grid>
            <Grid item sm={6}>
              <Labeled label="vehicle.matricule">
                <TextField
                  source="matricule"
                  className={classes.fullWidth}
                  emptyText="N/A"
                />
              </Labeled>
            </Grid>

            <Grid item sm={3}>
              <Labeled label="vehicle.age">
                <NumberField
                  source="age"
                  className={classes.fullWidth}
                  emptyText="N/A"
                />
              </Labeled>
            </Grid>
            <Grid item sm={3}>
              <VehicleCategoriesSelector
                source="category"
                disabled
                label="Category"
              />
            </Grid>

            <Grid item sm={6}>
              <FunctionField
                render={(props: any) => {
                  return (
                    <Labeled label="vehicle.carteGrise">
                      {props.carteGrise ? (
                        <FileField
                          source="carteGrise.src"
                          title="carteGrise.title"
                          target="_blank"
                        />
                      ) : (
                        <span>N/A</span>
                      )}
                    </Labeled>
                  );
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <FunctionField
                render={(props: any) => {
                  return (
                    <Labeled label="vehicle.vignette">
                      {props.vignette ? (
                        <FileField
                          source="vignette.src"
                          title="vignette.title"
                          target="_blank"
                        />
                      ) : (
                        <span>N/A</span>
                      )}
                    </Labeled>
                  );
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <Labeled label="Date d'expiration du carte grise">
                <DateField
                  locales={locale}
                  className={classes.fullWidth}
                  emptyText="N/A"
                  source="carteGriseExpirationDate"
                />
              </Labeled>
            </Grid>
            <Grid item sm={6}>
              <Labeled label="Date d'expiration du vignette">
                <DateField
                  locales={locale}
                  className={classes.fullWidth}
                  emptyText="N/A"
                  source="vignetteExpirationDate"
                />
              </Labeled>
            </Grid>

            {permissions === AdminRole.super && (
              <Grid item sm={6}>
                <Labeled label="vehicle.organization">
                  <ReferenceField
                    className={classes.fullWidth}
                    link="show"
                    source="organization"
                    reference="organization"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </Labeled>
              </Grid>
            )}

            <Grid item sm={6}>
              <FunctionField
                render={(props: any) => {
                  return (
                    <Labeled label="general.approvalStatus">
                      <ApprovalStatusField value={props.approvalStatus} />
                    </Labeled>
                  );
                }}
                label="general.approvalStatus"
              />
            </Grid>
            <Grid item sm={12}>
              <MultiFileField source="documents" label="Documents" />
            </Grid>
          </Grid>
        </Card>
      </div>
    </SimpleForm>
  );
};
