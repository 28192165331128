import React from "react";
import { Filter, TextInput, usePermissions } from "react-admin";

// styles
import { primaryColor } from "@theme/colors";
import useStyles from "./TractorFiltersStyles";
import classnames from "classnames";
import { AdminRole } from "@wetrans/helpers";
import OrganizationInput from "@components/common/OrganizationInput/OrganizationInput";
import { ApprovalStatusInput } from "@constants/approvalStatus";

const TractorFilters = (props: any) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  return (
    <Filter
      {...props}
      style={{
        background: primaryColor,
        borderRadius: 4,
        marginRight: 20,
      }}
      className={classnames(classes.formRoot, "filters")}
    >
      <TextInput
        label="vehicle.matricule"
        resettable
        source="matricule"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <ApprovalStatusInput
        label="general.approvalStatus"
        source="approvalStatus"
      />
      {permissions === AdminRole.super && (
        <OrganizationInput label="vehicle.organization" source="organization" />
      )}
    </Filter>
  );
};

export default TractorFilters;
