/* eslint-disable no-undef */
import React from "react";
import {
  ArrayInput,
  Edit,
  email,
  FormTab,
  ImageField,
  ImageInput,
  number,
  NumberInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  minValue,
  minLength,
  BooleanInput,
  maxValue,
} from "react-admin";
import SettingsToolbar from "./SettingsToolbar";
import "./styles.css";
const validateEmail = [required(), email()];
const validateTVA = [required(), minValue(0), maxValue(1)];

const validateVehicleCategories = [required(), number(), minValue(0)];
const validateVehicleCategoriesName = [required(), minLength(3)];

export const Settings = (props: any) => {
  return (
    <>
      <Edit {...props} title="Paramètres" redirect={false} className="edit">
        <TabbedForm
          style={{
            position: "fixed",
            background: "#f7f8fa",
            zIndex: 999,
            // marginTop: -50,
            // paddingTop: 7,
          }}
          warnWhenUnsavedChanges
          toolbar={
            <SettingsToolbar
              mode="edit"
              style={{
                background: "#f7f8fa",
                position: "absolute",
                top: 50,
              }}
            />
          }
        >
          <FormTab label="Général" path="general">
            <div className="createContainer">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 20px",
                  overflowY: "scroll",
                  height: "calc(100vh - 276px)",
                }}
              >
                <NumberInput source="TVA" label="TVA" validate={validateTVA} />
                <NumberInput
                  source="pricingProps.platformCommissionFromClient"
                  validate={validateTVA}
                  label="Commission wetrans client"
                />
                <NumberInput
                  source="pricingProps.platformCommissionFromOrganization"
                  validate={validateTVA}
                  label="Commission wetrans transporteur"
                />
                <NumberInput
                  source="pricingProps.gasPrice"
                  validate={required()}
                  label="Prix Gasoil (MAD/L)"
                />

                {/* <NumberInput
                  source="oneWayExpeditionBasePrice"
                  validated={required()}
                  label="Fixe 1 (MAD)"
                />

                <NumberInput
                  source="twoWayExpeditionBasePrice"
                  validated={required()}
                  label="Fixe 2 (MAD)"
                />
                <NumberInput
                  source="threeWayExpeditionBasePrice"
                  validated={required()}
                  label="Fixe 3 (MAD)"
                /> */}
                <TextInput
                  source="phone"
                  validated={required()}
                  label="Téléphone (Support technique)"
                />
                <ArrayInput source="emails" label="Contactez-nous">
                  <SimpleFormIterator
                    disableReordering
                    disableRemove
                    disableAdd
                    getItemLabel={(index) => ``}
                  >
                    <TextInput
                      source=""
                      validated={required()}
                      label="Email"
                      validate={validateEmail}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            </div>
          </FormTab>
          <FormTab label="Moyens de Transport" path="vehicleTypes">
            <div className="createContainer">
              <div
                style={{
                  display: "flex",
                  padding: "15px 20px",
                  overflowY: "scroll",
                  overflowX: "scroll",
                  width: "1200px",
                  height: "calc(100vh - 276px)",
                }}
              >
                <ArrayInput source="vehicleCategories" label="">
                  <SimpleFormIterator disableReordering disableRemove>
                    <TextInput
                      source="name"
                      // classes={namesClasses}
                      validate={validateVehicleCategoriesName}
                      style={{ marginRight: 20 }}
                      label="Nom"
                    />
                    <NumberInput
                      style={{
                        width: 170,
                        // gridArea: "1 / 2 / 2 / 3",
                        marginRight: 20,
                      }}
                      validate={validateVehicleCategories}
                      source="TKU"
                      label="TKU (Tonne-kilomètre)"
                    />
                    <NumberInput
                      style={{
                        // gridArea: "2 / 2 / 3 / 3",
                        width: 300,
                        marginRight: 20,
                      }}
                      source="allowedCargoWeight"
                      validate={validateVehicleCategories}
                      label="Poids de la cargaison autorisée (kg)"
                    />
                    <NumberInput
                      style={{
                        width: 280,
                        // gridArea: "3 / 1 / 4 / 2",
                        marginRight: 20,
                      }}
                      source="consumptionRate"
                      validate={validateVehicleCategories}
                      label="Taux de consommation (L/km)"
                    />
                    <NumberInput
                      style={{
                        width: 170,
                        minWidth: 170,
                        // gridArea: "4 / 1 / 5 / 2",
                        marginRight: 20,
                      }}
                      validate={validateVehicleCategories}
                      label="vehicle.height"
                      // className={classes.fullWidth}
                      source="height"
                      // validate={validateHeight}
                    />
                    <NumberInput
                      style={{
                        width: 170,
                        // gridArea: "4 / 2 / 5 / 3",
                        marginRight: 20,
                      }}
                      validate={validateVehicleCategories}
                      label="vehicle.width"
                      // className={classes.fullWidth}
                      source="width"

                      // validate={validateWidth}
                    />

                    <NumberInput
                      style={{
                        width: 170,
                        // gridArea: "4 / 2 / 5 / 3",
                        marginRight: 20,
                      }}
                      validate={validateVehicleCategories}
                      label="vehicle.length"
                      // className={classes.fullWidth}
                      source="length"
                      // validate={validateLength}
                    />
                    <BooleanInput
                      style={{
                        // gridArea: "4 / 2 / 5 / 3",
                        marginRight: 20,
                      }}
                      label="vehicle.requiresTractor"
                      // className={classes.fullWidth}
                      source="requiresTractor"
                      // validate={validateLength}
                    />

                    <ImageInput
                      style={
                        {
                          // gridArea: "1 / 3 / 5 / 5",
                        }
                      }
                      source="picture"
                      disabled
                      label="Image"
                      accept="image/*"
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            </div>
          </FormTab>
          <FormTab label="Prix Groupes" path="vehicleCategoryGroups">
            <div className="createContainer">
              <div
                style={{
                  display: "flex",
                  padding: "15px 20px",
                  width: "1050px",
                  height: "calc(100vh - 276px)",
                }}
              >
                <ArrayInput source="vehicleCategoryGroups" label="">
                  <SimpleFormIterator
                    disableReordering
                    disableAdd
                    disableRemove
                  >
                    <TextInput
                      source="name"
                      // classes={namesClasses}
                      disabled={true}
                      style={{ marginRight: 20 }}
                      label="Nom"
                    />
                    <NumberInput
                      style={{
                        // gridArea: "2 / 2 / 3 / 3",
                        width: 300,
                        marginRight: 20,
                      }}
                      source="standardUnitPrice"
                      validate={validateVehicleCategories}
                      label="Prix standard unitaire(MAD)"
                    />
                    <NumberInput
                      style={{
                        width: 340,
                        // gridArea: "3 / 1 / 4 / 2",
                        marginRight: 20,
                      }}
                      source="customMatchUnitPrice"
                      validate={validateVehicleCategories}
                      //validate={validateVehicleCategories}
                      label="Prix expédition personnalisée unitaire(MAD)"
                    />
                    <NumberInput
                      style={{
                        width: 170,
                        minWidth: 170,
                        // gridArea: "4 / 1 / 5 / 2",
                        marginRight: 20,
                      }}
                      validate={validateVehicleCategories}
                      source="basePrice"
                      label="Prix de base(MAD)"
                      // className={classes.fullWidth}

                      // validate={validateHeight}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            </div>
          </FormTab>
          {/* <FormTab label="Avancé" path="advanced"></FormTab> */}
        </TabbedForm>
      </Edit>
    </>
  );
};
export default Settings;
