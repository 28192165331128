/* eslint-disable indent */
/* eslint-disable max-len */
import {
  VehicleCategoryGroup,
  ISettings,
  ExpeditionPriceBreakdown,
} from "@wetrans/helpers";
const assertObjectProps = ({
  label,
  obj,
  keys,
}: {
  label: string;
  obj: Record<any, any>;
  keys: string[];
}): void => {
  const missing = keys.filter((k) => !(k in obj));
  if (missing.length) {
    throw new Error(
      `Entity ${label} is missing the following props: ${missing.join(", ")}`
    );
  }
};
interface StandardMatchPriceParams {
  distance: number;
  vehicleConsumptionRate: number;
  vehicleCategoryGroup: VehicleCategoryGroup;
  pricingProps: ISettings["pricingProps"];
  matchType: "standard" | "custom";
}
export interface MatchPriceOutput {
  rawHTPrice: number;
  clientHTPrice: number;
  organizationHTPrice: number;
  platformCommissionFromClient: number;
  platformCommissionFromOrganization: number;
}

export const computeMatchPrice = ({
  distance,
  vehicleConsumptionRate,
  vehicleCategoryGroup,
  pricingProps,
  matchType,
}: StandardMatchPriceParams): MatchPriceOutput => {
  assertObjectProps({
    label: "pricingProps",
    obj: pricingProps,
    keys: [
      "gasPrice",
      "platformCommissionFromClient",
      "platformCommissionFromOrganization",
    ],
  });
  const {
    gasPrice,
    platformCommissionFromClient,
    platformCommissionFromOrganization,
  } = pricingProps;
  const { id, standardUnitPrice, customMatchUnitPrice } = vehicleCategoryGroup;
  assertObjectProps({
    label: `vehicle category group ${id}`,
    obj: vehicleCategoryGroup,
    keys: ["standardUnitPrice", "customMatchUnitPrice", "basePrice"],
  });
  const amendedDistance = amendDistance({ distance, pricingProps });
  let price = 0;
  switch (matchType) {
    case "standard":
      price = computeRawStandardMatchPrice({
        distance: amendedDistance,
        vehicleConsumptionRate,
        gasPrice,
        unitPrice: standardUnitPrice,
      });
      break;
    case "custom":
      price = computRawCustomMatchPrice({
        distance: amendedDistance,
        unitPrice: customMatchUnitPrice,
      });
      break;
  }

  const amendedprice = amendPrice({ price, vehicleCategoryGroup });
  return {
    rawHTPrice: amendedprice,
    clientHTPrice: amendedprice * (1 + platformCommissionFromClient),
    organizationHTPrice:
      amendedprice * (1 - platformCommissionFromOrganization),
    platformCommissionFromClient,
    platformCommissionFromOrganization,
  };
};
// standard match & geo-radius match raw price formula
const computeRawStandardMatchPrice = ({
  distance,
  vehicleConsumptionRate,
  gasPrice,
  unitPrice,
}: {
  distance: number;
  vehicleConsumptionRate: number;
  gasPrice: number;
  unitPrice: number;
}) => {
  return distance * vehicleConsumptionRate * gasPrice + distance * unitPrice;
};

// custom expedition match raw price formula
const computRawCustomMatchPrice = ({
  distance,
  unitPrice,
}: {
  distance: number;
  unitPrice: number;
}) => {
  return distance * unitPrice;
};

const amendDistance = ({
  distance,
  pricingProps,
}: {
  distance: number;
  pricingProps: ISettings["pricingProps"];
}): number => {
  const { extraUrbanSpaceDistanceThreshold } = pricingProps;
  if (distance >= extraUrbanSpaceDistanceThreshold) {
    return distance;
  } else return extraUrbanSpaceDistanceThreshold;
};

const amendPrice = ({
  price,
  vehicleCategoryGroup,
}: {
  price: number;
  vehicleCategoryGroup: VehicleCategoryGroup;
}): number => {
  const { basePrice } = vehicleCategoryGroup;
  if (price >= basePrice) return price;
  else return basePrice;
};

export const computeExpeditionPriceBreakdown = ({
  matchPrice,
  insurancePolicyPrice,
  TVA,
}: {
  matchPrice: MatchPriceOutput;
  insurancePolicyPrice: number;
  TVA: number;
}): ExpeditionPriceBreakdown => {
  const {
    rawHTPrice,
    clientHTPrice,
    organizationHTPrice,
    platformCommissionFromClient,
    platformCommissionFromOrganization,
  } = matchPrice;
  return {
    rawHTPrice,
    clientPriceHT: clientHTPrice,
    organizationPriceHT: organizationHTPrice,
    platformCommissionFromClient,
    platformCommissionFromOrganization,
    TVA,
    insurancePolicyPrice,
    totalClientPrice: (clientHTPrice + insurancePolicyPrice) * (1 + TVA),
    totalOrganizationPrice: organizationHTPrice * (1 + TVA),
  };
};
