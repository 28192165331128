import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { primaryColor, primaryColorTint } from "@theme/colors";
import { useFieldArray } from "react-final-form-arrays";
import { DAYS } from "../constants";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),
    padding: theme.spacing(0, 1),
    "&:not(:first-child)": {
      border: "1px solid",
      borderColor: primaryColor,
      borderRadius: "50%",
    },
    "&:first-child": {
      border: "1px solid",
      borderColor: primaryColor,
      borderRadius: "50%",
    },
  },
}))(ToggleButtonGroup);

const StyledToggle = withStyles({
  root: {
    color: primaryColor,
    "&$selected": {
      color: "white",
      background: primaryColor,
    },
    "&:hover": {
      borderColor: primaryColorTint,
      color: "white",
      background: primaryColorTint,
    },
    "&:hover$selected": {
      borderColor: primaryColorTint,
      color: "white",
      background: primaryColorTint,
    },
    minWidth: 50,
    maxWidth: 50,
    height: 50,
    textTransform: "unset",
    fontSize: "0.75rem",
  },
  selected: {},
})(ToggleButton);

const DaysPicker = (props) => {
  const [days, setDays] = useState(
    props.days
      ? props.days.map((key) => DAYS.findIndex((day) => day.key === key))
      : []
  );
  const { fields } = useFieldArray(props.source);
  const handleChange = React.useCallback(
    (event, value) => {
      for (let i = 0; i < fields.length; i++) {
        fields.pop();
      }
      DAYS.forEach((day, index) =>
        value.includes(index) ? fields.push(day.key) : null
      );
      // console.log(value)
      // console.log(fields)

      setDays(value);
      // onChange(value)
      // / props.setDays(value)
    },
    [fields, setDays]
  );
  return (
    <>
      <StyledToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={days}
        onChange={handleChange}
      >
        {DAYS.map((day, index) => (
          <StyledToggle key={day.key} value={index} aria-label={day.key}>
            {day.label}
          </StyledToggle>
        ))}
      </StyledToggleButtonGroup>
    </>
  );
};

export default DaysPicker;
