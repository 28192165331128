import React from "react";

export default function ViewExported(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.724"
      height="8.65"
      viewBox="0 0 16.724 8.65"
      {...props}
    >
      <defs>
        <style></style>
      </defs>
      <g id="witness" opacity="0.688" transform="translate(0 -5.793)">
        <path
          id="Tracé_2548"
          d="M16.612 127.12a11.471 11.471 0 0 0-8.25-4.013 11.476 11.476 0 0 0-8.25 4.013.492.492 0 0 0 0 .624 11.46 11.46 0 0 0 8.25 4.013 11.482 11.482 0 0 0 8.25-4.013.492.492 0 0 0 0-.624zm-4.909.312a3.341 3.341 0 1 1-3.341-3.341 3.345 3.345 0 0 1 3.338 3.341zm-10.547 0a11.88 11.88 0 0 1 3.762-2.614 4.314 4.314 0 0 0 0 5.227 11.664 11.664 0 0 1-3.762-2.613zm10.644 2.614a4.314 4.314 0 0 0 0-5.227 11.665 11.665 0 0 1 3.762 2.613 11.879 11.879 0 0 1-3.762 2.614z"
          className="cls-2"
          transform="translate(0 -117.314)"
        />
        <path
          id="Tracé_2549"
          d="M204 205.672a1.672 1.672 0 1 0 1.672-1.672 1.674 1.674 0 0 0-1.672 1.672zm2.361 0a.689.689 0 1 1-.689-.689.689.689 0 0 1 .689.689z"
          className="cls-2"
          transform="translate(-197.311 -195.554)"
        />
      </g>
    </svg>
  );
}
