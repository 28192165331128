import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  ReferenceField,
  TextField,
  // useTranslate,
  // useRefresh,

  // @ts-ignore
} from "react-admin";
import { makeStyles } from "@material-ui/core";

// components
// import ExportExported from "@assets/vectors/ExportExported";
import ClientFilters from "./ClientFilters";
// import StickyTableCell from "@components/common/StickyTableCell";
// import DotsMenu from "@components/common/DotsMenu/DotsMenu";

import { AccountStatusField } from "@constants/accountStatus";

import { isEmpty } from "lodash";
import { NoDataTable } from "@components/common/NoDataTable";
// styles
// import { primaryColor } from "@theme/colors";
import "./styles.css";
import { tableRowStyle } from "@theme/styles/table";
import { ClientType, UserType } from "@wetrans/helpers";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import StickyTableCell from "@components/common/StickyTableCell";
// import DotsMenu from "@components/common/DotsMenu/DotsMenu";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    // total,
  } = useListContext();

  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const ClientList = (props: any) => {
  const listClasses = useListStyles();
  const permanentFilter: any = {
    type: UserType.client,
  };
  return (
    <div className="container">
      <List
        {...props}
        actions={<ListActions />}
        filters={<ClientFilters />}
        filter={permanentFilter}
        classes={listClasses}
        empty={false}
        pagination={<RaPrevNextPagination />}
        title="client.client"
        sort={{ field: "createdAt", order: "desc" }}
      >
        <div className="list-container">
          <CustomersDataGrid />
          <NoDataTable
            grid={[
              "client.name",
              "client.email",
              "client.phone",
              "client.type",
              "client.companyICE",
              "client.companyName",
              "client.cityOfOrigin",
              "client.accountStatus",
              "",
            ]}
          />
        </div>
      </List>
    </div>
  );
};

const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  // const t = useTranslate();

  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid rowClick="show" rowStyle={tableRowStyle} classes={classes}>
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.name}</div>;
            }}
            sortable={false}
            source="name"
            label="client.name"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.email}</div>;
            }}
            sortable={false}
            source="email"
            label="client.email"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.phone}</div>;
            }}
            sortable={false}
            source="phone"
            label="client.phone"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props.clientType === ClientType.company
                    ? "Société"
                    : "Individuel"}
                </div>
              );
            }}
            label="client.type"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props.clientType === ClientType.company ? props.ICE : "N/A"}
                </div>
              );
            }}
            source="ICE"
            label="client.companyICE"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props.clientType === ClientType.company
                    ? props.companyName
                    : "N/A"}
                </div>
              );
            }}
            source="companyName"
            label="client.companyName"
          />
          <ReferenceField
            link={false}
            label="client.cityOfOrigin"
            source="cityOfOrigin"
            reference="city"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            render={(props: any) => {
              return <AccountStatusField value={props.accountStatus} />;
            }}
            label="client.accountStatus"
          />
          <StickyTableCell>
            <></>
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};

export default ClientList;
