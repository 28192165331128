import Card from "@components/common/card/Card";
import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";
// import DriverInput from "@components/common/DriverInput/DriverInput";
import { useOrganization } from "@hooks/useOrganization";
import { Grid } from "@material-ui/core";
import { functionsProvider } from "@providers/provider";
import { AdminRole } from "@wetrans/helpers";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import * as React from "react";

import {
  Create,
  TextInput,
  maxLength,
  minLength,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  usePermissions,
  required,
  // @ts-ignore
} from "react-admin";
import { useStyles } from "./style";
import { TractorCreateEditToolbar } from "./TractorCreateEditToolbar";

export default function TractorCreate(props: any) {
  const matriculeValidation = React.useCallback(async (matricule: string) => {
    try {
      const { data } = await functionsProvider.isMatriculeUnique(matricule);
      if (!data) return "Matricule déjà utilisée";
      else return undefined;
    } catch (e) {
      return "invalid";
    }
  }, []);
  const validateMatricule = [
    required(),
    minLength(3),
    maxLength(125),
    matriculeValidation,
  ];

  const { permissions } = usePermissions();
  const { organization } = useOrganization();
  const classes = useStyles();

  const transform = (data: any) => ({
    ...data,
    // id: (data.matricule as string).trim(),
    approvalStatus: ApprovalStatus.Pending,
    organization:
      permissions !== AdminRole.super && organization
        ? organization
        : data.organization,
  });

  return (
    <Create
      {...props}
      transform={transform}
      title="Ajouter un nouveau tracteur"
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <TractorCreateEditToolbar
            mode="create"
            style={{
              position: "absolute",
              top: 55,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 70,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              <Grid item sm={6}>
                <TextInput
                  label="vehicle.matricule"
                  className={classes.fullWidth}
                  source="matricule"
                  validate={validateMatricule}
                />
              </Grid>

              {permissions === AdminRole.super && (
                <Grid item sm={6}>
                  <ReferenceInput
                    label="vehicle.organization"
                    source="organization"
                    reference="organization"
                  >
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                </Grid>
              )}
              <Grid item sm={12}>
                <MultiFIleInputs source="documents" label="Documents" />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Create>
  );
}
