import Card from "@components/common/card/Card";
import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";
// import DriverInput from "@components/common/DriverInput/DriverInput";

// import { useOrganization } from "@hooks/useOrganization";
import Grid from "@material-ui/core/Grid";
import { AdminRole } from "@wetrans/helpers";
import React from "react";
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
  usePermissions,
} from "react-admin";
import { useStyles } from "./style";

export const TractorForm = (props: any) => {
  const { permissions } = usePermissions();
  // const { organization } = useOrganization();
  const classes = useStyles();
  const { Toolbar } = props;

  return (
    <Edit {...props} hasShow={false} title={" "}>
      <SimpleForm
        warnWhenUnsavedChanges
        className={classes.fullWidth}
        toolbar={
          <Toolbar
            {...props}
            mode="edit"
            style={{
              position: "absolute",
              top: 120,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 50,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              <Grid item sm={6}>
                <TextInput
                  label="vehicle.matricule"
                  className={classes.fullWidth}
                  disabled
                  source="matricule"
                />
              </Grid>

              {permissions === AdminRole.super && (
                <Grid item sm={6}>
                  <ReferenceInput
                    label="vehicle.organization"
                    source="organization"
                    reference="organization"
                    className={classes.fullWidth}
                    disabled
                  >
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                </Grid>
              )}
              <Grid item sm={12}>
                <MultiFIleInputs source="documents" label="Documents" />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Edit>
  );
};
