import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import _ from "lodash";

// @ts-ignore
import {
  Loading,
  useAuthState,
  // useTranslate
} from "react-admin";

// import TabPanel from "../common/TabPanel";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import {
  Redirect,
  // useHistory
} from "react-router-dom";
import "./style.css";
// import { DASHBOARD_TABS } from "./constants";

// helpers
// import { getTabIndex } from "../common/helpers";

import AnalyticsList from "./AnalyticsList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  card: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Dashboard = (props: any) => {
  // const { location } = props;
  const classes = useStyles();

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }

  if (!authenticated) {
    return <Redirect to="/login" />;
  } else
    return (
      <div className={classes.root}>
        <React.Fragment>
          <AnalyticsList />
        </React.Fragment>
      </div>
    );
};

export default Dashboard;
