import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// component
import { LineChartLabels, LineChartLabel } from "./styled";

// styles
const PULLED_RIGHT = 25;
const useStyles = makeStyles(
  {
    container: {
      position: "relative",
      left: -PULLED_RIGHT,
    },
  },
  { name: "GRM-charts" }
);

const tickStyles = {
  fontSize: 8,
  fontWeight: "bold",
};

export interface ICustomLineChartProps {
  data: any[];
  fields: Field[];
  // data?: any;
  // height?: number;
  // field: any;
  // type: IndicatorType;
}
interface Field {
  key: string;
  label: string;
  color: string;
}
const CustomLineChart = (props: ICustomLineChartProps) => {
  const classes = useStyles();
  return (
    <>
      <LineChartLabels>
        {props.fields.map((field) => (
          <LineChartLabel>
            <i style={{ borderColor: field.color }} />
            {field.label}
          </LineChartLabel>
        ))}
      </LineChartLabels>

      <div className={classes.container}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={props.data}>
            {/* <CartesianGrid horizontal={false} /> */}
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="date"
              // tickFormatter={getFormattedDate}
              domain={["dataMin", "dataMax"]}
              // label={}
              tick={tickStyles}
            />
            <YAxis tick={tickStyles} />
            <Tooltip />
            {props.fields.map((field) => (
              <Line
                type="monotone"
                name={field.label}
                dataKey={field.key}
                stroke={field.color}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
// const CustomToolTip = (props: any) => {
//   const { active, payload, label } = props;
//   if (!active || !payload) {
//     return null;
//   }
//   return (
//     <div className="custom-tooltip">
//       <p>
//         <strong>{label}</strong>
//       </p>
//       {payload.map((item: any, i: any) => (
//         <p key={i}>
//           {item.name}: <strong>{item.value.toLocaleString()}</strong>
//         </p>
//       ))}
//     </div>
//   );
// };
// const XAxisLabel = (props:any)=>{
//   ret
// }
export default CustomLineChart;
