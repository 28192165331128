import React from "react";
import { choices, SelectInput } from "react-admin";
import { organizationType } from "./organizationType";

export const OrganizationTypeInput = ({
  source,
  label,
  className,
  disabled,
  required,
}: {
  source: string;
  label: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}) => {
  return (
    <SelectInput
      optionText="label"
      optionValue="id"
      disabled={disabled}
      source={source}
      required={required}
      label={label}
      choices={organizationType}
      validate={choices(organizationType.map((item) => item.id))}
      className={className}
    />
  );
};
