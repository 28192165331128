import React, { FC, useState, useEffect } from "react";
import {
  AutocompleteInput,
  TextInputProps,
  useInput,
  useQuery,
} from "react-admin";
import { useOrganization } from "@hooks/useOrganization";
import { IDriver, UserType } from "@wetrans/helpers";

const DriverInput: FC<TextInputProps> = (props: any) => {
  const { source, customFilter } = props;
  const addedFilter = customFilter ? customFilter : {};
  const { organization } = useOrganization();
  const [drivers, setDrivers] = useState<IDriver[]>([]);
  const { input } = useInput(source);
  const { data } = useQuery({
    resource: "users",
    type: "getList",
    payload: {
      filter: organization
        ? {
            type: UserType.driver,
            organization: organization,
            ...addedFilter,
          }
        : input?.value?.driver
        ? {
            id: input?.value?.driver,
            ...addedFilter,
          }
        : {},
      pagination: {
        page: 1,
        perPage: 200,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setDrivers(data as IDriver[]);
    }
  }, [data]);

  return (
    <>
      {
        <AutocompleteInput
          {...props}
          choices={drivers}
          // selectedItem={defaultDriver}
          resettable
          optionText="name"
          options={{
            suggestionsContainerProps: {
              style: {
                background: "white",
              },
            },
          }}
        ></AutocompleteInput>
      }
    </>
  );
};

export default DriverInput;
