import React from "react";
import styled from "styled-components";

// helpers
import { getLocalDate, getLocalTime } from "@utils/dateHelper";

const DateIndicatorContainer = styled.div`
  white-space: nowrap;
`;

const DateItem = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-size: 12px;
  strong {
    font-size: inherit;
  }
`;

const DateIndicator = (props: DateIndicatorProps) => {
  const { startDate, endDate } = props;

  return (
    <DateIndicatorContainer>
      <DateItem>
        <strong>De : </strong> {getLocalDate(startDate)}{" "}
        {getLocalTime(startDate)}
      </DateItem>
      <DateItem>
        <strong>A :</strong> {getLocalDate(endDate)} {getLocalTime(endDate)}
      </DateItem>
    </DateIndicatorContainer>
  );
};

interface DateIndicatorProps {
  startDate: Date;
  endDate: Date;
}

export default DateIndicator;
