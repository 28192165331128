import React from "react";
import {
  SaveButton,
  Toolbar,
  useGetIdentity,
  // useGetIdentity,
  // usePermissions,
  // DeleteWithConfirmButton,
  // useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const VehicleCreateEditToolbar = (props: any) => {
  const { identity, loaded: identityLoaded } = useGetIdentity();
  const classes = useStyles();
  return identityLoaded ? (
    <Toolbar {...props} classes={classes}>
      <SaveButton
        disabled={
          (identity as any).approvalStatus !== ApprovalStatus.Approved ||
          props.pristine
        }
        invalid={props.invalid}
      />
    </Toolbar>
  ) : null;
};

export default VehicleCreateEditToolbar;
