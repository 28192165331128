import * as React from "react";

import { secondaryColor } from "@theme/colors";

interface IMarkerExportedProps {
  color?: string;
  style?: React.CSSProperties;
}

export default function MarkerExported(props: IMarkerExportedProps) {
  const { color = secondaryColor, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="36"
      viewBox="0 0 27 36"
      {...rest}
    >
      <path
        id="marker"
        d="M12.113,35.274C1.9,20.463,0,18.943,0,13.5a13.5,13.5,0,0,1,27,0c0,5.443-1.9,6.963-12.113,21.774a1.688,1.688,0,0,1-2.775,0ZM13.5,19.125A5.625,5.625,0,1,0,7.875,13.5,5.625,5.625,0,0,0,13.5,19.125Z"
        fill={color}
      />
    </svg>
  );
}
