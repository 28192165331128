import MerchandiseCategoryField from "@components/common/MerchandiseCategoryField/MerchandiseCategoryField";
import { NoDataTable } from "@components/common/NoDataTable";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import StickyTableCell from "@components/common/StickyTableCell";
import ExpeditionFilters from "@components/expedition/ExpeditionFilters";
import { ExpeditionStatusField } from "@constants/expeditionStatus";
import { makeStyles } from "@material-ui/core";
import { tableRowStyle } from "@theme/styles/table";
import { isEmpty } from "lodash";
import React from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useListContext,
  useLocale,
} from "react-admin";
import "./styles.css";

interface ExpeditionPortableListProps {
  filter: any;
  hideVehicle?: boolean;
}
const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
  fullWidth: {
    width: "100%",
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});

export const ExpeditionPortableList = (props: ExpeditionPortableListProps) => {
  const { filter, hideVehicle } = props;
  const initProps: any = {
    basePath: "/",
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: true,
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "expedition",
    perPage: 10,
  };
  const listClasses = useListStyles();

  return (
    <List
      {...initProps}
      filter={filter}
      pagination={<RaPrevNextPagination />}
      filters={<ExpeditionFilters />}
      empty={false}
      classes={listClasses}
      exporter={false}
      className="remove-list-padding"
      title=" "
      sort={{ field: "bookedAt", order: "desc" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingBottom: 20,
        }}
      >
        <CustomersDataGrid {...props} />
        {hideVehicle ? (
          <NoDataTable
            grid={[
              "expedition.humanReadableBillId",
              "expedition.date",
              "expedition.status",
              "expedition.from",
              "expedition.to",
              "Catégorie",
              "expedition.driver",
            ]}
          />
        ) : (
          <NoDataTable
            grid={[
              "expedition.humanReadableBillId",
              "expedition.date",
              "expedition.status",
              "expedition.from",
              "expedition.to",
              "Catégorie",
              "expedition.vehicle",
              "expedition.driver",
            ]}
          />
        )}
      </div>
    </List>
  );
};

const CustomersDataGrid = (props: ExpeditionPortableListProps) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  const { hideVehicle } = props;

  const setLocale = useLocale();

  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid
          rowStyle={tableRowStyle}
          rowClick={(id) => `/expedition/${id}/show`}
          classes={classes}
        >
          <FunctionField
            render={(props: any) => {
              return `${props.humanReadableBillId}`;
            }}
            sortable={false}
            source="humanReadableBillId"
            label="expedition.humanReadableBillId"
          />

          <DateField
            locales={setLocale}
            sortable={false}
            source="date"
            label="expedition.date"
          />

          <FunctionField
            sortable={false}
            render={({ status }: any) => (
              <ExpeditionStatusField status={status} />
            )}
            label="expedition.status"
          />

          <ReferenceField
            link={false}
            sortable={false}
            label="expedition.from"
            source="from.city"
            reference="city"
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField
            link={false}
            sortable={false}
            label="expedition.to"
            source="to.city"
            reference="city"
          >
            <TextField source="name" />
          </ReferenceField>

          <MerchandiseCategoryField
            link={false}
            sortable={false}
            label="Catégorie"
            source="merchandise.category"
          />
          {!hideVehicle && (
            <FunctionField
              render={(props: any) => {
                return props.vehicle ? (
                  <ReferenceField
                    link="show"
                    label="expedition.vehicle"
                    source="vehicle"
                    reference="vehicle"
                    sortable={false}
                  >
                    <TextField source="matricule" />
                  </ReferenceField>
                ) : (
                  <div className="listField">N/A</div>
                );
              }}
              sortable={false}
              label="expedition.vehicle"
            />
          )}
          <FunctionField
            render={(props: any) => {
              return props.driver ? (
                <ReferenceField
                  sortable={false}
                  link={(expedition: any) =>
                    `/driver/${expedition.driver}/show`
                  }
                  label="expedition.driver"
                  source="driver"
                  reference="users"
                >
                  <TextField source="name" />
                </ReferenceField>
              ) : (
                <div className="listField">N/A</div>
              );
            }}
            sortable={false}
            label="expedition.driver"
          />
          <StickyTableCell>
            <></>
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};
