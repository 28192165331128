import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";

export const approvalStatus = [
  {
    id: ApprovalStatus.Pending,
    label: "approvalStatus.pending",
    color: "#ffcc00",
  },

  {
    id: ApprovalStatus.Approved,
    label: "approvalStatus.approved",
    color: "#83f25e",
  },

  {
    id: ApprovalStatus.Disapproved,
    label: "approvalStatus.disapproved",
    color: "#de0000",
  },
];
