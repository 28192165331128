import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FieldArray } from "react-final-form-arrays";

import MultipleDatesPicker from "@randex/material-ui-multiple-dates-picker";
import { getLocalDate } from "@utils/dateHelper";
import "./style.css";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));

export const MultiDatePickers = (props) => {
  const classes = useStyles();
  const { source } = props;
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.container}>
      <TextField
        id="date"
        label="Dates"
        placeholder="dd/mm/yyyy"
        type="date"
        className={classes.textField}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {/* <MultipleDatesPicker
        open={open}
        // selectedDates={fields.value ? fields.value : []}
        onCancel={() => setOpen(false)}
        selectedDatesTitle={"Dates sélectionnées"}
        onSubmit={(dates) => {
          console.log("selected dates", dates);
          // fields.change(dates);
          setOpen(false);
        }}
        // disabledDates={[new Date()]}
        submitButtonText="Enregistrer"
        cancelButtonText="Annuler"
      /> */}
      <FieldArray
        validate={(value) => {
          if (!value || !value.length) return "Veuillez choisir un date";
        }}
        name={source}
      >
        {({ fields }) => (
          <>
            <MultipleDatesPicker
              open={open}
              selectedDates={fields.value ? fields.value : []}
              onCancel={() => setOpen(false)}
              selectedDatesTitle={"Dates sélectionnées"}
              onSubmit={(dates) => {
                for (let i = 0; i < fields.length; i++) {
                  fields.pop();
                }
                dates.forEach((date) => {
                  fields.push(date);
                });
                // fields.change(dates);
                setOpen(false);
              }}
              // disabledDates={[new Date()]}
              submitButtonText="Enregistrer"
              cancelButtonText="Annuler"
            />
            <div className="dates-container">
              {fields.value?.map((date) => (
                <div className="date-item">{getLocalDate(date)}</div>
              ))}
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};
