import React, { FC } from "react";
import { useSelector } from "react-redux";
import { citiesSelector } from "@state/selectors";
import { AutocompleteInput, TextInputProps } from "react-admin";
import OptimizedCitiesFetcher from "../CitiesFetcher/OptimizedCitiesFetcher";

const CityInput: FC<TextInputProps> = (props: any) => {
  const { source, label } = props;
  const cities = useSelector(citiesSelector);

  return (
    <>
      <AutocompleteInput
        source={source}
        label={label}
        choices={cities}
        options={{
          suggestionsContainerProps: {
            style: {
              background: "white",
            },
          },
        }}
        {...props}
        resettable
        allowEmpty={false}
      ></AutocompleteInput>
      <OptimizedCitiesFetcher />
    </>
  );
};

export default CityInput;
