import { withStyles } from "@material-ui/core";

import PassPropsTableCell from "./PassPropsTableCell";

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    right: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    // marginTop: -60,
    right: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 60,
    height: "100%",
  },
}))(PassPropsTableCell);

export default StickyTableCell;
