import firebase from "firebase";

export const transformTimestampToDate = (theObject: any) => {
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      transformTimestampToDate(theObject[i]);
    }
  } else {
    for (let prop in theObject) {
      if (theObject[prop] instanceof firebase.firestore.Timestamp) {
        theObject[prop] = (theObject[
          prop
        ] as firebase.firestore.Timestamp).toDate();
      } else if (
        theObject[prop] instanceof Array &&
        theObject[prop].length > 0 &&
        theObject[prop][0] instanceof firebase.firestore.Timestamp
      ) {
        theObject[prop] = theObject[prop].map((item: any) => {
          if (item instanceof firebase.firestore.Timestamp) {
            return (item as firebase.firestore.Timestamp).toDate();
          } else return item;
        });
      } else if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        transformTimestampToDate(theObject[prop]);
      }
    }
  }
};
export const transformDateToTimestamp = (theObject: any) => {
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      transformDateToTimestamp(theObject[i]);
    }
  } else {
    for (let prop in theObject) {
      if (isDate(theObject[prop])) {
        theObject[prop] = firebase.firestore.Timestamp.fromDate(
          new Date(theObject[prop])
        );
      } else if (
        theObject[prop] instanceof Array &&
        theObject[prop].length > 0 &&
        isDate(theObject[prop][0])
      ) {
        theObject[prop] = theObject[prop].map((item: any) => {
          if (isDate(item)) {
            return firebase.firestore.Timestamp.fromDate(new Date(item));
          } else return item;
        });
      } else if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        transformDateToTimestamp(theObject[prop]);
      }
    }
  }
};

function isDate(dateStr: any) {
  return !isNaN(new Date(dateStr).getDate());
}
