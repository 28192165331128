import React, { useEffect } from "react";
import { useQuery } from "react-admin";
import { useDispatch } from "react-redux";
import { setCities } from "@state/publicInfo";
import { ICity } from "@wetrans/helpers";

const CitiesFetchers = () => {
  const dispatch = useDispatch();
  const { data } = useQuery({
    resource: "city",
    type: "getList",
    payload: {
      sort: {
        field: "name",
        order: "ASC",
      },
    },
  });

  useEffect(() => {
    if (data) {
      const cities = data as ICity[];
      dispatch(setCities(cities));
    }
  }, [data, dispatch]);
  return <></>;
};

export default CitiesFetchers;
