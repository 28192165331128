import React from "react";
import firebase from "firebase";
import { useGetIdentity, useLogout } from "ra-core";
import { useEffect } from "react";
import { AccountStatus, IUser, UserType } from "@wetrans/helpers";
export const DisableAccountListener = (props: any) => {
  const { loaded, identity } = useGetIdentity();
  const logout = useLogout();
  const db = firebase.app().firestore();
  useEffect(() => {
    if (loaded && identity) {
      const userDoc = db.collection("users").doc(identity.id.toString());
      userDoc.onSnapshot(
        (docSnapshot) => {
          const newData = docSnapshot.data() as IUser;
          if (
            newData?.accountStatus !== AccountStatus.active ||
            (newData?.type !== UserType.admin &&
              newData?.type !== UserType.super)
          ) {
            logout();
          }
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    }
  }, [loaded, identity, db, logout]);
  return <></>;
};
