import { Button } from "@mui/material";
import { IExpedition, MerchandiseCategory } from "@wetrans/helpers";
import React from "react";

import { useQuery } from "react-admin";
import BonChargement from "./BonChargement";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import "./styles.css";
import {
  citiesSelector,
  merchandiseCategoriesSelector,
} from "@state/selectors";
import { primaryColor } from "@theme/colors";

interface BonChargementButtonProps {
  expedition: IExpedition;
}

export const BonChargementButton = (props: BonChargementButtonProps) => {
  const { expedition } = props;
  const { data: client } = useQuery({
    resource: "users",
    type: "getOne",
    payload: {
      id: expedition.client,
    },
  });
  const merchandiseCategories: MerchandiseCategory[] = useSelector(
    merchandiseCategoriesSelector
  );
  const cities = useSelector(citiesSelector);

  return client ? (
    <div className="bon-chargment">
      <PDFDownloadLink
        document={
          <BonChargement
            getCity={(cityId) => {
              const city = cities.find((city) => city.id === cityId);
              return city ? city.name : "N/A";
            }}
            data={expedition}
            user={client}
            tax={expedition?.TVA ? expedition?.TVA : 0}
            getMerchandiseCategory={(merchandiseCategoryId) => {
              const merch = merchandiseCategories.find(
                (merch) => merch.id === merchandiseCategoryId
              );
              return merch ? merch.name : "N/A";
            }}
          />
        }
        fileName="bon de chargement.pdf"
      >
        {() => (
          <Button
            variant="contained"
            style={{
              background: primaryColor,
              color: "white !important",
            }}
            className="mb-2 w-full md:w-auto capitalize"
            // onClick={handleShippingAdd}
          >
            Telecharger bon de Chargement
          </Button>
        )}
      </PDFDownloadLink>
    </div>
  ) : null;
};
