import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from "recharts";
import { useFormatString } from "@utils/useFormatString";
import { IndicatorType } from "./IndicatorCard";
import { expeditionColors } from "./ExpeditionImprovedPieChart";

export function ResponsivePieChart<T>({
  inCercle,
  outCercle,
  fontSize,
  pieHeight,
  data,
  dataKey,
  type,
  stateSet,
  getColor,
  column,
  total,
  totalColor,
  defaultActive,
}: {
  inCercle?: number;
  outCercle?: number;
  fontSize?: number;
  pieHeight?: number;
  data: any[];
  dataKey: string;
  type: IndicatorType;
  stateSet: { [k: number]: string };
  getColor: (state: T) => string;
  column?: boolean;
  total?: number;
  totalColor?: string;
  defaultActive?: string;
}) {
  const [selectedData, setSelectedData] = React.useState<any[]>(data);

  const [activeIndex, setActiveIndex] = React.useState<number | undefined>(
    undefined
  );

  React.useEffect(() => {
    setActiveIndex(
      (selectedData.findIndex((x) => x._id === defaultActive) !== -1
        ? selectedData.findIndex((x) => x._id === defaultActive)
        : undefined) || (total ? undefined : 0)
    );
  }, [selectedData, defaultActive, total]);
  const [totalActiveIndex, setTotalActiveIndex] = React.useState<
    number | undefined
  >(undefined);

  React.useEffect(() => {
    setTotalActiveIndex(defaultActive ? undefined : 0);
  }, [defaultActive]);

  const [activeMap, setActiveMap] = React.useState(
    Object.fromEntries(Object.keys(stateSet).map((key) => [key, true]))
  );

  const formatString = useFormatString();

  React.useEffect(() => {
    setSelectedData(data.filter((entry: any) => activeMap[entry._id]));
  }, [activeMap, data]);

  const makeOnClick = (state: string) => () => {
    const newActiveMap: {
      [k: string]: boolean;
    } = { ...activeMap, [state]: !activeMap[state] };
    setActiveMap(newActiveMap);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: column ? "column" : "row",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          flex: 1,
        }}
      >
        <ResponsiveContainer width="100%" height={250}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={makeRenderActiveShape(
                type,
                formatString,
                true,
                total
              )}
              onMouseEnter={(_, index) => {
                setActiveIndex(index);
                setTotalActiveIndex(undefined);
              }}
              data={selectedData}
              dataKey={dataKey}
              nameKey="_id"
              paddingAngle={1}
              innerRadius={inCercle ? inCercle : 65}
              outerRadius={outCercle ? outCercle : 100}
              startAngle={0}
              endAngle={
                total == null
                  ? 360
                  : (selectedData.reduce(
                      (accumulator, entry) => accumulator + entry[dataKey],
                      0
                    ) /
                      total) *
                    360
              }
            >
              {(selectedData as any[]).map((entry: any, index) => {
                return (
                  <Cell
                    key={`cell-${entry._id}`}
                    fill={getColor(entry._id as T)}
                  />
                );
              })}
            </Pie>
            {total && (
              <Pie
                activeIndex={totalActiveIndex}
                activeShape={makeRenderActiveShape(
                  type,
                  formatString,
                  false,
                  total
                )}
                onMouseEnter={(_, index) => {
                  setTotalActiveIndex(index);
                  setActiveIndex(undefined);
                }}
                data={[{ [dataKey]: total, _id: "total" }]}
                dataKey={dataKey}
                nameKey="_id"
                paddingAngle={1}
                innerRadius={outCercle ? outCercle + 3 : 105}
                outerRadius={outCercle ? outCercle + 23 : 120}
              >
                <Cell key={`total`} fill={totalColor || "#ddd"} />
              </Pie>
            )}
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          flex: 0.6,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <CustomLegend
          makeOnClick={makeOnClick}
          activeMap={activeMap}
          stateSet={stateSet}
          fontSize={fontSize}
        />
      </div>
    </div>
  );
}
const getLabel = (name: string) =>
  expeditionColors.find((item) => item._id === name)?.label;
const CustomLegend = ({
  activeMap,
  makeOnClick,
  stateSet,
  fontSize,
}: {
  activeMap: { [k: string]: boolean };
  makeOnClick: (
    entry: any
  ) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  stateSet: { [k: number]: string };
  fontSize?: number;
}) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {Object.entries(stateSet).map(([state, color]) => (
        <div
          key={state}
          className={`recharts-custom-legend ${
            !activeMap[state] ? "inactive" : ""
          }`}
          onClick={makeOnClick(state)}
          style={{
            width: "100%",
            cursor: "pointer",
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: color,
              height: 10,
              width: 10,
              marginRight: "3px",
              borderRadius: "100%",
              fontSize: fontSize ? fontSize : 16,
            }}
          />
          <div style={{ fontSize: 14 }}>{getLabel(state)}</div>
        </div>
      ))}
    </div>
  );
};

const makeRenderActiveShape = (
  type: IndicatorType,
  formatString: (type: string, number: number, currency?: string) => string,
  isInside: boolean,
  total?: number
) => (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    name,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={
          total == null || !isInside ? outerRadius + 6 : outerRadius + 26
        }
        outerRadius={
          total == null || !isInside ? outerRadius + 10 : outerRadius + 30
        }
        fill={fill}
      />
      <text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fill="#333"
        fontSize={12}
      >{`${getLabel(name)} :`}</text>
      <text
        x={cx}
        y={cy}
        dy={14}
        textAnchor="middle"
        fill="#333"
        fontSize={11}
      >{`${formatString(type, value)}`}</text>
      <text x={cx} y={cy} dy={26} textAnchor="middle" fill="#999" fontSize={10}>
        {`(Fréq ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
