import React, { useEffect } from "react";
import Card from "@components/common/card/Card";
import {
  ArrayInput,
  Create,
  required,
  SimpleForm,
  SimpleFormIterator,
  useMutation,
  useQuery,
} from "react-admin";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useState } from "react";
import { useOrganization } from "@hooks/useOrganization";
import CityInput from "@components/common/CityInput/CityInput";
import { Alert } from "@mui/material";
import {
  ICity,
  IOrganization,
  IVehicle,
  RouteTemplateStatus,
} from "@wetrans/helpers";
import { MultiDatePickers } from "@components/vehicle/Edit/multiDatePicker/MultiDatePickers";
import { DaysPickerField } from "@components/vehicle/Edit/daysPicker/DaysPickerField";
import RoutesCreateToolbar from "./RoutesCreateToolbar";
import { useStyles } from "./style";
import VehicleInput from "@components/common/VehicleInput/VehicleInput";
import { TimeInput } from "@components/common/TimeInput/TimeInput";
import { useHistory } from "react-router-dom";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import { useSelector } from "react-redux";
import { citiesSelector, settingsSelector } from "@state/selectors";
import { computeRawRouteETAs, daysBetweenTwoDate } from "./helpers";
import { isEmpty } from "lodash";
import { dataProvider } from "@providers/provider";
import { isSameDay } from "date-fns";
// import { DirectionsService } from "@react-google-maps/api";

export const RoutesCreate = (props: any) => {
  const { organization } = useOrganization();

  const classes = useStyles();
  const [IsRegularRoute, setIsRegularRoute] = useState<boolean>(true);
  const [organizationDoc, setOrganizationDoc] = useState<IOrganization>();
  const [ETAWarningMessage, setETAWarningMessage] = useState<string>();
  const history = useHistory();
  const cities = useSelector(citiesSelector);
  const settings = useSelector(settingsSelector);

  const { data } = useQuery({
    resource: "organization",
    type: "getOne",
    payload: {
      id: organization,
    },
  });
  useEffect(() => {
    if (data) {
      const dataDocument = data as IOrganization;
      setOrganizationDoc(dataDocument);
    }
  }, [data]);
  const [mutate] = useMutation();

  const save = React.useCallback(
    async (values) => {
      try {
        if (
          IsRegularRoute &&
          (!values.operationWeekDays || !values.operationWeekDays.length)
        ) {
          return { operationWeekDays: "Veuillez choisir un jour" };
        }
        if (!IsRegularRoute) {
          delete values.operationWeekDays;
          const dates = values.dates as Date[];
          const today = new Date();
          const invalidDate = dates.find(
            (date) => date.getTime() < today.getTime()
          );
          if (invalidDate) {
            return { dates: "date invalide" };
          }
          dates.map((date) => date.setHours(12, 0, 0, 0));
        } else {
          delete values.dates;
        }
        const vehicleDoc = await dataProvider.getOne<IVehicle>("vehicle", {
          id: values.vehicle,
        });
        const result = await mutate(
          {
            type: "create",
            resource: "routeTemplates",
            payload: {
              data: {
                ...values,
                organization: organization,
                status: RouteTemplateStatus.active,
                vehicleCategory: vehicleDoc.data.category,
                frequency: IsRegularRoute ? "regular" : "irregular",
              },
            },
          },
          { returnPromise: true }
        );
        history.push("/routeTemplates/" + result.data.id + "/show");
      } catch (error) {
        if ((error as any).body?.errors) {
          return (error as any).body.errors;
        }
      }
    },
    [IsRegularRoute, mutate, organization, history]
  );
  // const transform = React.useCallback(
  //   (data: any) => {
  //     if (!IsRegularRoute) {
  //       delete data.operationWeekDays;
  //       const dates = data.dates as Date[];
  //       dates.map((date) => date.setHours(12, 0, 0, 0));
  //     } else delete data.dates;
  //     return {
  //       ...data,
  //       organization: organization,
  //       frequency: IsRegularRoute
  //         ? RouteFrequency.regular
  //         : RouteFrequency.irregular,
  //     };
  //   },
  //   [IsRegularRoute, organization]
  // );
  // const directionServiceCallback = React.useCallback((response: any) => {
  //   if (response !== null) {
  //     if (response.status === "OK") {
  //     } else {
  //       console.log("response: ", response);
  //     }
  //   }
  // }, []);
  // const getDistance = React.useCallback(
  //   (destination: string, origin: string) => {
  //     const directionsService = new window.google.maps.DirectionsService();
  //     directionsService.route(
  //       {
  //         origin: origin,
  //         destination: destination,
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //         waypoints: [],
  //       },
  //       (result, status) => {
  //         if (status === window.google.maps.DirectionsStatus.OK) {
  //           console.log(result?.routes[0].legs[0].distance);
  //         }
  //       }
  //     );
  //   },
  //   []
  // );
  const getDistanceBetweenCities = React.useCallback(
    (from: ICity, to: ICity): Promise<number> => {
      const directionsService = new window.google.maps.DirectionsService();
      return new Promise((resolve, reject) => {
        directionsService.route(
          {
            destination: `${to?.latitude},${to?.longitude}`,
            origin: `${from?.latitude},${from?.longitude}`,
            travelMode: window.google.maps.TravelMode.DRIVING,
            waypoints: [],
          },
          function (response, status) {
            if (status === window.google.maps.DirectionsStatus.OK && response) {
              if (response?.routes[0]?.legs[0]?.distance?.value)
                resolve(response.routes[0].legs[0].distance?.value / 1000);
              else reject();
            } else {
              reject();
            }
          }
        );
      });
    },
    []
  );

  const validateRouteCreation = React.useCallback(
    async (values: any) => {
      setETAWarningMessage(undefined);
      if (!IsRegularRoute) {
        const dates = values.dates as Date[];
        const today = new Date();
        const invalidDate = dates?.find(
          (date) => date.getTime() < today.getTime() && !isSameDay(date, today)
        );
        if (invalidDate) {
          return { dates: "date invalide" };
        }
        dates?.map((date) => date.setHours(12, 0, 0, 0));
      }
      if (values.from && values.to && values.departureDateTime) {
        const from = cities.find((city) => city.id === values.from);
        const to = cities.find((city) => city.id === values.to);

        if (from && to) {
          const citiesSequence: ICity[] = [from];
          if (values.waypoints && !isEmpty(values.waypoints)) {
            // console.log(values.waypoints);
            // eslint-disable-next-line array-callback-return
            values.waypoints.map((waypoint: string): void => {
              // eslint-disable-next-line array-callback-return
              if (!waypoint) return;
              const city = cities.find((city) => city.id === waypoint);
              if (city) {
                citiesSequence.push(city);
              }
            });
          }
          citiesSequence.push(to);
          try {
            const { bestCaseETA, worstCaseETA } = await computeRawRouteETAs({
              citiesSequence: citiesSequence,
              computeDistanceBetweenCities: getDistanceBetweenCities,
              etaProps: settings.ETAProps,
              routeStartDate: new Date(),
              routeStartTime: values.departureDateTime,
            });
            const worst = daysBetweenTwoDate(
              worstCaseETA.estimatedDepartureDateTime,
              worstCaseETA.estimatedArrivalDateTime
            );
            const best = daysBetweenTwoDate(
              bestCaseETA.estimatedDepartureDateTime,
              bestCaseETA.estimatedArrivalDateTime
            );
            if (worst > 0) {
              setETAWarningMessage(
                `Ce Trajet peut prendre jusqu'à ${
                  worst + 1
                } jours (dans le cas normal ${best + 1} jour)`
              );
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
      if (
        IsRegularRoute &&
        (!values.operationWeekDays || !values.operationWeekDays.length)
      ) {
        return { operationWeekDays: "Veuillez choisir un jour" };
      }
      if (!IsRegularRoute && (!values.dates || !values.dates.length)) {
        return { dates: "Veuillez choisir un date" };
      }
      return {};
    },
    [IsRegularRoute, cities, getDistanceBetweenCities, settings]
  );
  return (
    <Create {...props} title="Ajouter un nouveau trajet">
      <SimpleForm
        warnWhenUnsavedChanges
        redirect="show"
        className={classes.fullWidth}
        validate={validateRouteCreation}
        save={save}
        toolbar={
          <RoutesCreateToolbar
            style={{
              position: "absolute",
              top: 55,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 70,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              {ETAWarningMessage && (
                <Grid item sm={12}>
                  <Alert variant="standard" severity="warning">
                    {ETAWarningMessage}
                  </Alert>
                </Grid>
              )}
              {organizationDoc && (
                <Grid item sm={12}>
                  {organizationDoc?.approvalStatus !==
                    ApprovalStatus.Approved && (
                    <Alert variant="standard" severity="warning">
                      {
                        "Votre compte est en attente de la validation de weTrans. Même si vous pouvez ajoutez des trajets, vous ne pouvez pas accepter des expéditions."
                      }
                    </Alert>
                  )}
                </Grid>
              )}

              <Grid item sm={6}>
                <VehicleInput
                  source="vehicle"
                  className={classes.fullWidth}
                  label="Vehicle"
                  validate={[required()]}
                />
              </Grid>
              <Grid item sm={6}></Grid>
              <Grid item sm={6}>
                <CityInput
                  className={classes.fullWidth}
                  label="route.from"
                  source="from"
                  validate={[required()]}
                />
              </Grid>
              <Grid item sm={6}>
                <CityInput
                  validate={[required()]}
                  className={classes.fullWidth}
                  label="route.to"
                  source="to"
                />
              </Grid>
              <Grid item sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="medium"
                      checked={IsRegularRoute}
                      onChange={(e) => setIsRegularRoute(e.target.checked)}
                    />
                  }
                  label="Trajet régulier"
                />
              </Grid>
              <Grid item sm={6}>
                {!IsRegularRoute && <MultiDatePickers source="dates" />}
              </Grid>
              {IsRegularRoute && (
                <>
                  <Grid item sm={6}>
                    <DaysPickerField source="operationWeekDays" />
                  </Grid>
                  <Grid item sm={6}></Grid>
                </>
              )}

              <Grid item sm={6}>
                <ArrayInput source="waypoints" label="Escales">
                  <SimpleFormIterator
                    disableReordering
                    getItemLabel={(index) => `Ville ${index + 1}`}
                  >
                    <CityInput
                      className={classes.fullWidth}
                      source=""
                      label=""
                      validate={[required()]}
                      reference="city"
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
              <Grid item sm={6}>
                <TimeInput
                  validate={required()}
                  source="departureDateTime"
                  label="Heure de départ"
                  defaultValue="08:00"
                  className={classes.fullWidth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 3600, // 1 hour
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Create>
  );
};
