import ExpeditionImprovedPieChart from "@components/common/charts/ExpeditionImprovedPieChart";
import { IExpeditionAnalytics } from "@wetrans/helpers";
import React from "react";

export const ExpeditionPieChart = (pieChart: IExpeditionAnalytics) => {
  const {
    bookedCount,
    canceledCount,
    deliveredCount,
    confirmedCount,
    validatedCount,
  } = pieChart;
  const data = [
    {
      color: "#73B9EC",
      value: bookedCount,
      label: "Reservée",
      _id: "bookedCount",
    },
    {
      color: "#6aa84f",
      value: validatedCount,
      label: "Validé",
      _id: "validatedCount",
    },
    {
      color: "#FED152",
      value: canceledCount,
      label: "Annulé",
      _id: "canceledCount",
    },
    {
      color: "#EF545C",
      value: deliveredCount,
      label: "Livré",
      _id: "deliveredCount",
    },
    {
      color: "#1f08b0",
      value: confirmedCount,
      label: "Confirmé",
      _id: "confirmedCount",
    },
  ];
  return <ExpeditionImprovedPieChart data={data} />;
};
