import Card from "@components/common/card/Card";
// import DriverInput from "@components/common/DriverInput/DriverInput";
import ExpirationWarning from "@components/common/ExpirationWarning";
import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";
import VehicleCategoriesSelector from "@components/common/vehicleCategoriesSelecter/VehicleCategoriesSelector";
// import { useOrganization } from "@hooks/useOrganization";
import Grid from "@material-ui/core/Grid";
import { AdminRole } from "@wetrans/helpers";
import React from "react";
import {
  AutocompleteInput,
  DateInput,
  FileField,
  FileInput,
  number,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useLocale,
  usePermissions,
  minValue,
  required,
  maxValue,
  Edit,
} from "react-admin";
import { useStyles } from "./style";
const validateAge = [required(), number(), minValue(0), maxValue(20)];

export const VehicleForm = (props: any) => {
  const { permissions } = usePermissions();
  // const { organization } = useOrganization();
  const classes = useStyles();
  const locale = useLocale();

  const { Toolbar } = props;
  return (
    <Edit
      {...props}
      hasShow={false}
      title={" "}
      // transform={(data) => {
      //   return {
      //     ...data,
      //     approvalStatus: ApprovalStatus.Pending,
      //     updateFromFrontend: true,
      //   };
      // }}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        className={classes.fullWidth}
        // {...{
        //   basePath,
        //   record,
        //   redirect,
        //   resource,
        //   save,
        //   saving,
        //   version,
        // }}
        toolbar={
          <Toolbar
            {...props}
            mode="edit"
            style={{
              position: "absolute",
              top: 120,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 50,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              <Grid item sm={12}>
                <ExpirationWarning
                  source="carteGriseExpirationDate"
                  expiredMessage="La date d'expiration du carte grise est passée"
                  closeMessage="La date d'expiration du carte grise est proche"
                />
              </Grid>
              <Grid item sm={12}>
                <ExpirationWarning
                  source="vignetteExpirationDate"
                  expiredMessage="La date d'expiration du vignette est passée"
                  closeMessage="La date d'expiration du vignette est proche"
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="vehicle.matricule"
                  className={classes.fullWidth}
                  disabled
                  source="matricule"
                />
              </Grid>

              <Grid item sm={3}>
                <NumberInput
                  label="vehicle.age"
                  validate={validateAge}
                  className={classes.fullWidth}
                  source="age"
                />
              </Grid>
              <Grid item sm={3}>
                <VehicleCategoriesSelector
                  source="category"
                  disabled
                  label="Category"
                />
              </Grid>

              <Grid item sm={6}>
                <FileInput
                  maxSize={10000000}
                  source="carteGrise"
                  // classes={
                  //   permissions === AdminRole.super ? inputStyle : undefined
                  // }
                  validate={[required()]}
                  label={"vehicle.carteGrise"}
                  disabled={permissions === AdminRole.super}
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                >
                  <FileField source="src" title="title" target="_blank" />
                </FileInput>
              </Grid>
              <Grid item sm={6}>
                <FileInput
                  source="vignette"
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                  maxSize={10000000}
                  // classes={
                  //   permissions === AdminRole.super ? inputStyle : undefined
                  // }
                  validate={[required()]}
                  label={"vehicle.vignette"}
                  disabled={permissions === AdminRole.super}
                >
                  <FileField source="src" title="title" target="_blank" />
                </FileInput>
              </Grid>
              <Grid item sm={6}>
                <DateInput
                  locales={locale}
                  className={classes.fullWidth}
                  source="carteGriseExpirationDate"
                  validate={[required()]}
                  label="Date d'expiration du carte grise"
                />
              </Grid>
              <Grid item sm={6}>
                <DateInput
                  locales={locale}
                  validate={[required()]}
                  className={classes.fullWidth}
                  source="vignetteExpirationDate"
                  label="Date d'expiration du vignette"
                />
              </Grid>

              {permissions === AdminRole.super && (
                <Grid item sm={6}>
                  <ReferenceInput
                    label="vehicle.organization"
                    source="organization"
                    reference="organization"
                    className={classes.fullWidth}
                    disabled
                  >
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                </Grid>
              )}

              <Grid item sm={12}>
                <MultiFIleInputs source="documents" label="Documents" />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Edit>
  );
};
