import { IAdmin } from "@wetrans/helpers";
import firebase from "firebase";
import omit from "lodash/omit";

export default class AuthProvider {
  private firebaseApp: any;
  private auth: firebase.auth.Auth;
  private db: any;
  private RAFirebaseAuthProvider: any;

  constructor(firebaseApp: any, RAFirebaseAuthProvider: any) {
    this.firebaseApp = firebaseApp;
    this.auth = this.firebaseApp.auth();
    this.db = this.firebaseApp.firestore();
    this.RAFirebaseAuthProvider = RAFirebaseAuthProvider;
    this.auth.onAuthStateChanged(async (user) => {
      let sessionTimeout = null;
      if (user === null) {
        // User is logged out.
        // Clear the session timeout.
        sessionTimeout && clearTimeout(sessionTimeout);
        sessionTimeout = null;
      } else {
        // User is logged in.

        // Fetch the decoded ID token and create a session timeout which signs the user out.
        user.getIdTokenResult().then((idTokenResult) => {
          // Make sure all the times are in milliseconds!
          const authTime = idTokenResult.claims.auth_time * 1000;
          const rememberMe = idTokenResult.claims.rememberMe;
          if (!rememberMe) {
            const sessionDuration = 1000 * 60 * 60 * 2; // the same duration in the backend
            const millisecondsUntilExpiration =
              sessionDuration - (Date.now() - authTime);
            sessionTimeout = setTimeout(
              () => this.auth.signOut(),
              millisecondsUntilExpiration
            );
          }
        });
      }
    });
  }
  resetPassword = (email: string) => {
    return this.auth.sendPasswordResetEmail(email);
  };
  logout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("organization");
    return Promise.resolve();
  };
  getIdentity = async () => {
    try {
      const { uid } = await this.RAFirebaseAuthProvider.getAuthUser();
      const user = await this.db.collection("users").doc(uid).get();
      const identity = {
        ...omit(user.data(), ["password"]),
        id: uid,
      };
      return identity;
    } catch (e) {
      console.log(e);
      throw new Error("Login error: invalid credentials");
    }
  };

  getPermissions = () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  };
  login = async ({ username, password, rememberMe }: any) => {
    if (username && password) {
      try {
        const rememberMeSetter = firebase
          .functions()
          .httpsCallable("rememberMeSetter");
        const authUser = await this.auth.signInWithEmailAndPassword(
          username,
          password
        );
        const userData = (await this.getIdentity()) as IAdmin;
        localStorage.setItem("role", userData.role);
        if (userData.organization)
          localStorage.setItem("organization", userData.organization);
        else localStorage.removeItem("organization");
        await rememberMeSetter(rememberMe);
        return authUser;
      } catch (e) {
        throw new Error("Login error: invalid credentials");
      }
    }
  };
}
