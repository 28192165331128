import { WithAdminApproval } from "@components/common/WithAdminApproval";
import { AccountStatus, ExpeditionStatus, IDriver } from "@wetrans/helpers";
import * as React from "react";
import DriverEdit from "./DriverEdit";
import DriverShow from "./DriverShow";
import firebase from "firebase";

export default function DriverShowEdit(props: any) {
  const checkIfTheDriverIsDeleteble = React.useCallback(
    async (record: IDriver) => {
      const db = firebase.app().firestore();
      try {
        const { empty: emptyExpeditions } = await db
          .collection("expedition")
          .where("driver", "==", record.id)
          .orderBy("status", "desc")
          .startAt(ExpeditionStatus.unloading)
          .endAt(ExpeditionStatus.booked)
          .limit(1)
          .get();
        return emptyExpeditions;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    []
  );
  return (
    <WithAdminApproval
      {...props}
      adminApproveW
      warningMessage="driver.warningMessage"
      arningMessage="driver.adminApproveWarningMessage"
      adminDisapproveWarningMessage="driver.adminDisapproveWarningMessage"
      warningMessageTitle="driver.warningMessageTitle"
      enableDelete
      deleteConfirmationMessage="driver.deleteConfirmationMessage"
      isDeletable={checkIfTheDriverIsDeleteble}
      transformOnDelete={(record: IDriver, deletedById: string) => {
        return {
          ...record,
          accountStatus: AccountStatus.suspended,
          deleted: true,
          deletedAt: new Date(),
          deletedBy: deletedById,
        };
      }}
      EditComponent={DriverEdit}
      ShowComponent={DriverShow}
    />
  );
}
