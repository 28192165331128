// import DotsMenu from "@components/common/DotsMenu/DotsMenu";
import { NoDataTable } from "@components/common/NoDataTable";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import StickyTableCell from "@components/common/StickyTableCell";
import { transformTimestampToDate } from "@components/common/transformTimestampToDate";
import RouteFilters from "@components/route/RouteFilters";
import { RouteFrequencyField } from "@constants/routeFrequency";
import { makeStyles } from "@material-ui/core";
import { primaryColor } from "@theme/colors";
import { tableRowStyle } from "@theme/styles/table";
import { AdminRole, RouteFrequency } from "@wetrans/helpers";
import { isEmpty } from "lodash";
import React, { cloneElement } from "react";
import {
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceArrayField,
  ReferenceField,
  sanitizeListRestProps,
  SingleFieldList,
  TextField,
  TopToolbar,
  useListContext,
  useLocale,
  usePermissions,

  // useMutation,
  // useRefresh,
  // useTranslate,
} from "react-admin";
import "../styles.css";
import { MultiDaysField } from "./daysPicker/MultiDaysField";
import { MultiDateField } from "./multiDatePicker/multiDateField";
interface RouteListProps {
  vehicleId: string;
  onClickCreate: () => void;
}
const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
  fullWidth: {
    width: "100%",
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
export const RouteList = (props: RouteListProps) => {
  const { vehicleId, onClickCreate } = props;
  const initProps: any = {
    basePath: "/",
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/route", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "routeTemplates",
    perPage: 10,
  };
  const listClasses = useListStyles();

  const { permissions } = usePermissions();
  return (
    <List
      {...initProps}
      actions={<ListActions onClickCreate={onClickCreate} />}
      filters={<RouteFilters />}
      filter={{ vehicle: vehicleId }}
      pagination={<RaPrevNextPagination />}
      empty={false}
      classes={listClasses}
      exporter={false}
      className="remove-list-padding"
      title=" "
      sort={{ field: "createdAt", order: "desc" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingBottom: 20,
        }}
      >
        <CustomersDataGrid />
        {permissions === AdminRole.super ? (
          <NoDataTable
            grid={[
              "route.from",
              "route.to",
              "route.frequency",
              "route.dates",
              "Heure de départ",
              "route.createdAt",
              "Escales",
              "route.organization",
            ]}
          />
        ) : (
          <NoDataTable
            grid={[
              "route.from",
              "route.to",
              "route.frequency",
              "route.dates",
              "Heure de départ",
              "route.createdAt",
              "Escales",
            ]}
          />
        )}
      </div>
    </List>
  );
};
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { permissions } = usePermissions();
  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    // total,
  } = useListContext();
  const { onClickCreate } = props;
  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      {permissions === AdminRole.operation && (
        <CreateButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickCreate();
          }}
          className="createButtonAction"
          style={{
            background: primaryColor,
          }}
          label="ra.action.add"
        />
      )}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  // const t = useTranslate();
  // const refresh = useRefresh();
  // const [deleteRoute] = useMutation();

  const setLocale = useLocale();
  const { permissions } = usePermissions();

  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid
          rowStyle={tableRowStyle}
          rowClick={(id) => `/routeTemplates/${id}/show`}
          classes={classes}
        >
          <ReferenceField
            link={false}
            linkType=""
            label="route.from"
            source="from"
            reference="city"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField
            sortable={false}
            link={false}
            linkType=""
            label="route.to"
            source="to"
            reference="city"
          >
            <TextField source="name" />
          </ReferenceField>

          <FunctionField
            sortable={false}
            render={(props: any) => {
              transformTimestampToDate(props);
              return <RouteFrequencyField value={props.frequency} />;
            }}
            label="route.frequency"
          />
          <FunctionField
            sortable={false}
            render={(record: any) => {
              return record.frequency === RouteFrequency.regular ? (
                <MultiDaysField days={record.operationWeekDays} />
              ) : (
                <MultiDateField dates={record.dates} />
              );
            }}
            label="route.dates"
          />
          <FunctionField
            render={(props: any) => {
              return (
                <div className="listField">
                  {props.departureDateTime ? props.departureDateTime : "N/A"}
                </div>
              );
            }}
            label="Heures de départ"
          />
          <DateField
            showTime
            options={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }}
            sortable={false}
            locales={setLocale}
            source="createdAt"
            label="route.createdAt"
          />
          <FunctionField
            sortable={false}
            render={(props: any) => {
              return !isEmpty(props.waypoints) ? (
                <ReferenceArrayField
                  source="waypoints"
                  sortable={false}
                  reference="city"
                  label="Escales"
                >
                  <SingleFieldList>
                    <ChipField
                      // linkType="false"
                      // onClick={(e) => e.preventDefault()}
                      source="name"
                    />
                  </SingleFieldList>
                </ReferenceArrayField>
              ) : (
                <div className="listField">Pas d'escale</div>
              );
            }}
            label="Escales"
          />
          {permissions === AdminRole.super && (
            <ReferenceField
              sortable={false}
              link="show"
              label="route.organization"
              source="organization"
              reference="organization"
            >
              <TextField source="name" />
            </ReferenceField>
          )}

          <StickyTableCell>
            <>
              {/* <FunctionField
              sortable={false}
              render={(props: any) => {
                return (
                  <DotsMenu
                    record={props}
                    // delete={props.status === "active"}
                    delete
                    edit
                    confirmTitle={t("route.confirmDeleteTitle", {
                      id: props.id,
                    })}
                    confirmContent={t("route.confirmDeleteContent")}
                    deleteAction={() => {
                      deleteRoute({
                        type: "delete",
                        resource: "route",
                        payload: {
                          id: props.id,
                        },
                      });
                      refresh();
                    }}
                  />
                );
              }}
            /> */}
            </>
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};
