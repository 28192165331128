import React from "react";
import { Route } from "react-router-dom";
import Box from "@material-ui/core/Box";

type TabPanelProps = {
  children: React.ReactNode;
  value: Number;
  index: number;
  path?: string;
  style?: object;
  id?: string;
};

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, path, ...other } = props;
  if (value !== index) return null;
  return path ? (
    <Route path={path}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    </Route>
  ) : (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
