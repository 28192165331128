import Card from "@components/common/card/Card";
// import DriverInput from "@components/common/DriverInput/DriverInput";
import { AccountStatusField } from "@constants/accountStatus";
// import { useOrganization } from "@hooks/useOrganization";
import Grid from "@material-ui/core/Grid";
import { ClientType } from "@wetrans/helpers";
import React from "react";
import {
  SimpleForm,
  useEditController,
  Labeled,
  TextField,
  FunctionField,
  ReferenceField,
} from "react-admin";
import { useStyles } from "./style";

export const ClientShow = (props: any) => {
  const classes = useStyles();

  const controllerProps = useEditController({
    ...props,
  });
  const {
    basePath, // deduced from the location, useful for action buttons
    record, // record fetched via dataProvider.getOne() based on the id from the location
    redirect, // the default redirection route. Defaults to 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
    version, // integer used by the refresh feature
  } = controllerProps;
  const { Toolbar } = props;
  return (
    <SimpleForm
      warnWhenUnsavedChanges
      className={classes.fullWidth}
      {...{
        basePath,
        record,
        redirect,
        resource,
        save,
        saving,
        version,
      }}
      toolbar={
        <Toolbar
          {...props}
          mode="edit"
          style={{
            position: "absolute",
            top: 120,
          }}
        />
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: 50,
        }}
      >
        <Card>
          <Grid container className={classes.fullWidth} spacing={4}>
            <Grid item sm={6}>
              <Labeled label="client.name">
                <TextField
                  source="name"
                  className={classes.fullWidth}
                  emptyText="N/A"
                />
              </Labeled>
            </Grid>

            <Grid item sm={6}>
              <Labeled label="client.email">
                <TextField
                  source="email"
                  className={classes.fullWidth}
                  emptyText="N/A"
                />
              </Labeled>
            </Grid>

            <Grid item sm={6}>
              <Labeled label="client.phone">
                <TextField
                  source="phone"
                  className={classes.fullWidth}
                  emptyText="N/A"
                />
              </Labeled>
            </Grid>
            <Grid item sm={6}>
              <Labeled label="client.type">
                <FunctionField
                  render={(props: any) => {
                    return (
                      <div className="listField">
                        {props.clientType === ClientType.company
                          ? "Société"
                          : "Individuel"}
                      </div>
                    );
                  }}
                />
              </Labeled>
            </Grid>
            <Grid item sm={6}>
              <FunctionField
                render={(props: any) => {
                  return props?.clientType === ClientType.company ? (
                    <Labeled label="client.companyICE">
                      <TextField
                        source="ICE"
                        className={classes.fullWidth}
                        emptyText="N/A"
                      />
                    </Labeled>
                  ) : null;
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <FunctionField
                render={(props: any) => {
                  return props?.clientType === ClientType.company ? (
                    <Labeled label="client.companyName">
                      <TextField
                        source="companyName"
                        className={classes.fullWidth}
                        emptyText="N/A"
                      />
                    </Labeled>
                  ) : null;
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <Labeled label="client.cityOfOrigin">
                <ReferenceField
                  sortable={false}
                  link={false}
                  linkType=""
                  label="client.cityOfOrigin"
                  source="cityOfOrigin"
                  reference="city"
                  emptyText="N/A"
                >
                  <TextField source="name" />
                </ReferenceField>
              </Labeled>
            </Grid>
            <Grid item sm={12}>
              <Labeled label="client.accountStatus">
                <FunctionField
                  sortable={false}
                  render={(props: any) => {
                    return <AccountStatusField value={props.accountStatus} />;
                  }}
                />
              </Labeled>
            </Grid>
          </Grid>
        </Card>
      </div>
    </SimpleForm>
  );
};
