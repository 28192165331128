import * as React from "react";
import {
  Edit,
  TextInput,
  maxLength,
  minLength,
  email,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  useTranslate,
  usePermissions,
  required,
  FunctionField,
  // @ts-ignore
} from "react-admin";
import MyTitle from "@components/common/View/Title";
import { AccountStatusInput } from "@constants/accountStatus";

import { AdminRoleInput } from "@constants/adminRole";
import { AdminRole } from "@wetrans/helpers";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import Card from "@components/common/card/Card";
import MultiFIleInputs from "@components/common/MultiFilesInputs/MultiFIleInputs";

const validateName = [required(), minLength(3), maxLength(125)];
const validateEmail = [required(), email()];
const validatePhone = [required(), minLength(3), maxLength(125)];
const validateTitle = [required(), minLength(3), maxLength(125)];
const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export default function AdminEdit(props: any) {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const classes = useStyles();
  const { Toolbar } = props;
  return (
    <Edit
      {...props}
      hasShow={false}
      title={
        <MyTitle
          resource={props.resource}
          label={t("admin.admin")}
          descriptionAttribute="name"
        />
      }
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <Toolbar
            {...props}
            mode="edit"
            style={{
              position: "absolute",
              top: 55,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 65,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              {permissions !== AdminRole.super && (
                <Grid item sm={6}>
                  <TextInput
                    label="CIN"
                    source="CIN"
                    disabled
                    className={classes.fullWidth}
                  />
                </Grid>
              )}
              <Grid item sm={6}>
                <TextInput
                  label="admin.email"
                  source="email"
                  disabled
                  className={classes.fullWidth}
                  validate={validateEmail}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="admin.name"
                  source="name"
                  className={classes.fullWidth}
                  validate={validateName}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="admin.phone"
                  source="phone"
                  disabled
                  className={classes.fullWidth}
                  validate={validatePhone}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  label="admin.title"
                  source="title"
                  className={classes.fullWidth}
                  disabled
                  validate={validateTitle}
                />
              </Grid>
              <Grid item sm={6}>
                <DateInput
                  label="admin.createdAt"
                  className={classes.fullWidth}
                  source="createdAt"
                  disabled
                />
              </Grid>
              <Grid item sm={6}>
                <AccountStatusInput
                  label="admin.accountStatus"
                  className={classes.fullWidth}
                  source="accountStatus"
                />
              </Grid>
              <Grid item sm={6}>
                <AdminRoleInput
                  mode="edit"
                  label="admin.role"
                  className={classes.fullWidth}
                  disabled={permissions === AdminRole.super}
                  source="role"
                />
              </Grid>
              <Grid item sm={6}>
                {permissions === AdminRole.super ? (
                  <FunctionField
                    render={(props: any) => {
                      return props.role !== AdminRole.super ? (
                        <ReferenceInput
                          label="admin.organization"
                          source="organization"
                          disabled
                          reference="organization"
                        >
                          <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                      ) : null;
                    }}
                  />
                ) : null}
              </Grid>
              <Grid item sm={12}>
                <FunctionField
                  render={(props: any) => {
                    return props.role !== AdminRole.super ? (
                      <MultiFIleInputs source="documents" label="Documents" />
                    ) : null;
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Edit>
  );
}
