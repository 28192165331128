import React, { FunctionComponent } from "react";
// @ts-ignore
import {
  useLogin,
  useNotify,
  Notification,
  required,
  useTranslate,
  email,
  PasswordInput,
} from "react-admin";
import { Checkbox, CircularProgress, InputLabel } from "@material-ui/core";
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import { Field, Form } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import Login from "./Login";
import { primaryColor, primaryTextColor } from "@theme/colors";
import { Link } from "@mui/material";
import { authProvider } from "@providers/provider";

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      // width: "100%",
      background: primaryColor,
      color: "white",
      padding: 10,
      borderRadius: 10,
      width: 140,
      "&:hover": {
        background: primaryColor,
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    footer: {
      fontSize: "0.9rem",
      color: primaryColor,
      padding: "0.5rem 0",
    },
  }),
  { name: "RaLoginForm" }
);

const CssPasswordInput = styled(PasswordInput)({
  "& .MuiInputLabel-shrink": {
    display: "none",
  },
  "& input": {
    background: "white",
    padding: 10,
    borderRadius: 5,
  },
  "& .MuiInput-underline:before": {
    border: 0,
  },

  "& .MuiInputBase-root": {
    background: "white",
    "& fieldset": {
      borderColor: "white",
    },
  },
  "& p.MuiFormHelperText-root": {
    color: "white",
  },
});
const CssTextField = styled(TextField)({
  "& label": {
    color: `${primaryTextColor} !important`,
    background: "white",
    padding: "2px 5px 0 5px",
    borderRadius: 5,
    zIndex: 15,
  },
  "& input": {
    background: "white",
    padding: 10,
    borderRadius: 5,
  },
  "& .MuiInput-underline:before": {
    border: 0,
  },
  "& .MuiOutlinedInput-root": {
    background: "white",
    "& fieldset": {
      borderColor: "white",
    },
  },
  "& p.MuiFormHelperText-root": {
    color: "white",
  },
});

const Input = ({
  // @ts-ignore
  meta: { touched, error },
  // @ts-ignore
  input: inputProps,
  ...props
}) => (
  <CssTextField
    error={!!(touched && error)}
    helperText={
      touched && error ? error : touched && !error ? undefined : undefined
    }
    {...inputProps}
    {...props}
    fullWidth
    // color="secondary"
  />
);

// endAdornment={
//   <InputAdornment position="end">
//     <IconButton
//       aria-label="toggle password visibility"
//       onClick={handleClickShowPassword}
//       onMouseDown={handleMouseDownPassword}
//     >
//       {values.showPassword ? <Visibility /> : <VisibilityOff />}
//     </IconButton>
//   </InputAdornment>
// }

const Switch = ({
  // @ts-ignore
  meta: { touched, error },
  // @ts-ignore
  input: inputProps,
  ...props
}) => (
  <>
    <Checkbox
      error={!!(touched && error)}
      helperText={touched && error}
      color="secondary"
      {...inputProps}
      {...props}
      displayEmpty
      fullWidth
      variant="outlined"
    />
    <InputLabel style={{ color: "white" }} shrink>
      {props.label}
    </InputLabel>
  </>
);

interface TwoFactorLoginPage {
  theme?: object;
}

const TwoFactorLoginPage: FunctionComponent<TwoFactorLoginPage> = ({
  theme,
}) => {
  const login = useLogin();
  const notify = useNotify();
  const t = useTranslate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [rememberMe, setRememberMe] = React.useState<boolean>(false);
  const [forgetPasswordMode, setForgetPasswordMode] = React.useState<boolean>(
    false
  );
  const [username, setUsername] = React.useState<string>("");
  const classes = useStyles({ theme });
  const emailValidator = email("Adresse e-mail invalide");

  const handleLogin = ({ username, password, rememberMe }: any): void => {
    // console.log({ username, password, rememberMe });
    setLoading(true);
    setUsername(username);
    setRememberMe(rememberMe);
    if (!emailValidator(username, {})) {
      login({ username, password, rememberMe })
        .then(() => {
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
          return notify(e.message, "warning");
        });
    } else {
      setLoading(false);
    }
  };
  const handleForgetPassword = ({ username }: any): void => {
    // console.log({ username, password, rememberMe });
    setLoading(true);
    setUsername(username);
    setRememberMe(rememberMe);
    if (!emailValidator(username, {})) {
      authProvider
        .resetPassword(username)
        .then(() => {
          setLoading(false);
          notify(
            "Nous vous avons envoyé par e-mail un lien que vous pouvez utiliser pour réinitialiser votre mot de passe",
            "success"
          );
          setForgetPasswordMode(false);
        })
        .catch((e: Error) => {
          setLoading(false);
          return notify(e.message, "warning");
        });
    } else {
      setLoading(false);
    }
  };
  const LoginForm = () => (
    <Form
      onSubmit={!forgetPasswordMode ? handleLogin : handleForgetPassword}
      initialValues={{ username, rememberMe }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div style={{ marginBottom: 50 }}>
            {/* <LoginTextField name="email" label="Email" /> */}
            {forgetPasswordMode && (
              <p style={{ color: "white", textAlign: "center" }}>
                Entrez votre adresse Email ci-dessous et nous vous enverrons un
                lien pour réinitialiser votre mot de passe.
              </p>
            )}
            <Field
              validate={(email, values) => {
                const errorMessage = emailValidator(email, values);
                if (typeof errorMessage === "string") {
                  return errorMessage;
                } else if (errorMessage) {
                  return errorMessage.message;
                }

                return undefined;
              }}
              id="username"
              name="username"
              component={Input}
              label={t("auth.username")}
              type="string"
              // defaultValue={""}
            />
            {!forgetPasswordMode && (
              <div>
                <CssPasswordInput
                  source="password"
                  fullWidth
                  label={t("auth.password")}
                  validate={required(t("auth.passwordRequired"))}
                />
                {/* <Field
                  validate={required(t("auth.passwordRequired"))}
                  type="password"
                  id="password"
                  name="password"
                  component={Password}
                  label={t("auth.password")}
                /> */}
              </div>
            )}
            {!forgetPasswordMode && (
              <div
                className={classes.input}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 0,
                  marginLeft: -12,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Field
                    id="rememberMe"
                    name="rememberMe"
                    component={Switch}
                    label={t("auth.rememberMe")}
                    type="checkbox"
                  />
                </div>
                <div
                  style={{
                    color: "white",
                  }}
                >
                  <Link
                    // variant="body2"
                    color={"inherit"}
                    onClick={() => {
                      setForgetPasswordMode(true);
                    }}
                  >
                    Mot de passe oublié
                  </Link>
                </div>
              </div>
            )}
          </div>
          <CardActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                size="medium"
                style={{
                  background: "white",
                  color: primaryColor,
                  fontWeight: "bold",
                  padding: "10px 18px",
                }}
                disabled={loading}
                type="submit"
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    style={{ color: primaryColor }}
                    thickness={2}
                  />
                )}
                <span>{!forgetPasswordMode ? "Se connecter" : "Envoyer"}</span>
              </Button>
              {/* <Button
                variant="contained"
                type="submit"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                {t("auth.signIn")}
              </Button> */}
            </div>
          </CardActions>
          {/* <Typography align="center" className={classes.footer}>
            © 2021 Wetrans - V1.0.0
          </Typography> */}
        </form>
      )}
    />
  );
  return (
    <Login theme={theme} icon={false} forgetPasswordMode={forgetPasswordMode}>
      <LoginForm />
      <Notification />
    </Login>
  );
};

export default TwoFactorLoginPage;
