import { ClientType } from "@wetrans/helpers";

export const clientType = [
  { id: ClientType.company, label: "clientType.company", color: "#5fd997" },

  {
    id: ClientType.individual,
    label: "clientType.individual",
    color: "#5fd997",
  },
];
