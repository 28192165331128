import * as React from "react";
import { Children } from "react";
import PropTypes from "prop-types";
import MenuIcon from "@material-ui/icons/Menu";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  useMediaQuery,
  Theme,
  IconButton,
} from "@material-ui/core";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { AppBarProps as MuiAppBarProps } from "@material-ui/core/AppBar";

import { makeStyles } from "@material-ui/core/styles";
import {
  ClassesOverride,
  LoadingIndicator,
  UserMenu as DefaultUserMenu,
  toggleSidebar,
  useLogout,
  useTranslate,
} from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: "0.5em",
      marginRight: "0.5em",
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(0deg)",
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(180deg)",
    },
    title: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  }),
  { name: "RaAppBar" }
);

/**
 * The AppBar component renders a custom MuiAppBar.
 *
 * @param {ReactNode} children React node/s to be render as children of the AppBar
 * @param {Object} classes CSS class names
 * @param {string} className CSS class applied to the MuiAppBar component
 * @param {string} color The color of the AppBar
 * @param {Component} logout The logout button component that will be pass to the UserMenu component
 * @param {boolean} open State of the <Admin/> Sidebar
 * @param {Element | boolean} userMenu A custom user menu component for the AppBar. <UserMenu/> component by default. Pass false to disable.
 *
 * @example
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props}>
 *           <Typography
 *               variant="h6"
 *               color="inherit"
 *               className={classes.title}
 *               id="react-admin-title"
 *           />
 *       </AppBar>
 *   );
 *};
 *
 * @example Without a user menu
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props} userMenu={false} />
 *   );
 *};
 */
const AppBar = (props: AppBarProps): JSX.Element => {
  const {
    children,
    classes: classesOverride,
    className,
    color = "secondary",
    open,
    title,
    userMenu,
    ...rest
  } = props;
  const classes = useStyles(props);
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const dispatch = useDispatch();
  const logout = useLogout();
  const t = useTranslate();
  return (
    <MuiAppBar className={className} color={color} {...rest}>
      <Toolbar
        disableGutters
        variant={isXSmall ? "regular" : "dense"}
        className={classes.toolbar}
      >
        <IconButton
          color="inherit"
          onClick={() => dispatch(toggleSidebar())}
          className={classNames(classes.menuButton)}
        >
          <MenuIcon
            classes={{
              root: open
                ? classes.menuButtonIconOpen
                : classes.menuButtonIconClosed,
            }}
            color="primary"
          />
        </IconButton>
        {Children.count(children) === 0 ? (
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
        ) : (
          children
        )}

        <div
          style={{
            color: "gray",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 27,
          }}
        >
          <div
            style={{
              marginRight: 10,
            }}
          >
            <LoadingIndicator />
          </div>

          <button
            onClick={() => {
              logout();
            }}
            style={{
              width: "81px",
              height: "33px",
              margin: "2px 0 3px 35px",
              padding: "9px 21px 8px 20px",
              borderRadius: "10px",
              backgroundColor: " #fd4a4a",
              border: "none",
              color: "white",
              cursor: "pointer",
            }}
          >
            {t("auth.logout")}
          </button>

          {/* <Logout
            button
            style={{
              color: 'white',
              background: '#fd4b4a',
              borderRadius: 7,
              paddingLeft: 27
            }} /> */}
          {/* {userMenu !== undefined && typeof userMenu === "boolean"
            ? userMenu === true
              ? cloneElement(<DefaultUserMenu />, { logout })
              : null
            : cloneElement(userMenu as JSX.Element, { logout })} */}
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  // @ts-ignore
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "transparent",
  ]),
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

AppBar.defaultProps = {
  userMenu: <DefaultUserMenu />,
};

export interface AppBarProps extends Omit<MuiAppBarProps, "title" | "classes"> {
  classes?: ClassesOverride<typeof useStyles>;
  logout?: JSX.Element;
  open?: boolean;
  title?: string | JSX.Element;
  userMenu?: JSX.Element | boolean;
}

export default AppBar;
