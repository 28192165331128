import React, { FC, useState, useEffect } from "react";
import {
  AutocompleteInput,
  TextInputProps,
  //   useInput,
  useQuery,
} from "react-admin";
import { useOrganization } from "@hooks/useOrganization";
import {
  ITractor,
  //  IDriver,
} from "@wetrans/helpers";

const TractorInput: FC<TextInputProps> = (props: any) => {
  const { onEmpty, customFilter } = props;
  const { organization } = useOrganization();
  const [tractors, setTractors] = useState<ITractor[]>([]);

  const { data } = useQuery({
    resource: "tractor",
    type: "getList",
    payload: {
      filter: {
        organization: organization,
        ...customFilter,
      },
      pagination: {
        page: 1,
        perPage: 200,
      },
    },
  });

  useEffect(() => {
    if (data) {
      let tractors = data as ITractor[];

      setTractors(tractors);
    }
  }, [data, setTractors, onEmpty]);

  return (
    <>
      {
        <AutocompleteInput
          {...props}
          choices={tractors}
          resettable
          optionText="matricule"
          options={{
            suggestionsContainerProps: {
              style: {
                background: "white",
              },
            },
          }}
        ></AutocompleteInput>
      }
    </>
  );
};

export default TractorInput;
