import React from "react";

const TruckIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.636"
    height="22.109"
    viewBox="0 0 27.636 22.109"
  >
    <path
      id="Icon_awesome-truck"
      data-name="Icon awesome-truck"
      d="M26.946,15.2h-.691V10.532a2.073,2.073,0,0,0-.609-1.464L21.332,4.754a2.073,2.073,0,0,0-1.464-.609h-1.9V2.073A2.073,2.073,0,0,0,15.891,0H2.073A2.073,2.073,0,0,0,0,2.073V15.891a2.073,2.073,0,0,0,2.073,2.073h.691a4.145,4.145,0,1,0,8.291,0h5.527a4.145,4.145,0,0,0,8.291,0h2.073a.693.693,0,0,0,.691-.691V15.891A.693.693,0,0,0,26.946,15.2ZM6.909,20.036a2.073,2.073,0,1,1,2.073-2.073A2.073,2.073,0,0,1,6.909,20.036Zm13.818,0A2.073,2.073,0,1,1,22.8,17.964,2.073,2.073,0,0,1,20.727,20.036Zm3.455-8.982H17.964V6.218h1.9l4.314,4.314Z"
    />
  </svg>
);

export default TruckIcon;
