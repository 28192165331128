import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TopToolbar,
  useListContext,
  // ExportButton,
  sanitizeListRestProps,
  useTranslate,
  DateField,
  ReferenceField,
  TextField,
  // @ts-ignore
} from "react-admin";
import { makeStyles, Typography } from "@material-ui/core";

// components
// import ExportExported from "@assets/vectors/ExportExported";
import OrganizationFilters from "./OrganizationFilters";
import StickyTableCell from "@components/common/StickyTableCell";
import DotsMenu from "@components/common/DotsMenu/DotsMenu";

import { useLocale } from "react-admin";

import { OrganizationTypeField } from "@constants/organizationType";

import { isEmpty } from "lodash";
import { NoDataTable } from "@components/common/NoDataTable";
// styles
// import { primaryColor } from "@theme/colors";
import "./styles.css";
import { tableRowStyle } from "@theme/styles/table";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import { RatingField } from "@components/common/RatingField/RatingField";
import { handleDisplayNum } from "@components/common/HandleDisplayNum";
import { ApprovalStatusField } from "@constants/approvalStatus";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    // total,
  } = useListContext();

  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      {/* <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        className="createButtonAction"
        style={{
          background: primaryColor,
        }}
        icon={<ExportExported fill={"white"} />}
      /> */}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const OrganizationList = (props: any) => {
  const listClasses = useListStyles();

  return (
    <div className="container">
      <List
        {...props}
        actions={<ListActions />}
        empty={false}
        filters={<OrganizationFilters />}
        classes={listClasses}
        title="organization.organization"
        pagination={<RaPrevNextPagination />}
        sort={{ field: "createdAt", order: "desc" }}
      >
        <div className="list-container">
          <CustomersDataGrid />
          <NoDataTable
            grid={[
              "organization.ICE",
              "organization.name",
              "organization.createdAt",
              "general.approvalStatus",
              "organization.type",
              "Ville d'origine",
              "organization.createdBy",
              // "organization.admin",
              "organization.rating",
              "",
            ]}
          />
        </div>
      </List>
    </div>
  );
};

const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  const t = useTranslate();
  // const refresh = useRefresh();
  // const [deleteOrganization] = useMutation();

  const setLocale = useLocale();

  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid rowClick="show" rowStyle={tableRowStyle} classes={classes}>
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.ICE}</div>;
            }}
            source="ICE"
            sortable={false}
            label="organization.ICE"
          />
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.name}</div>;
            }}
            sortable={false}
            source="name"
            label="organization.name"
          />

          <DateField
            locales={setLocale}
            source="createdAt"
            label="organization.createdAt"
            sortable={false}
          />

          <FunctionField
            sortable={false}
            render={(props: any) => {
              return <ApprovalStatusField value={props.approvalStatus} />;
            }}
            label="general.approvalStatus"
          />
          <ReferenceField
            link={false}
            label="Ville d'origine"
            source="cityOfOrigin"
            reference="city"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            render={(props: any) => {
              return <OrganizationTypeField value={props.type} />;
            }}
            sortable={false}
            label="organization.type"
          />
          <ReferenceField
            link="show"
            sortable={false}
            label="organization.createdBy"
            source="createdBy"
            reference="users"
          >
            <TextField source="name" />
          </ReferenceField>

          {/* <ReferenceField
            sortable={false}
            link="show"
            label="organization.admin"
            source="admin"
            reference="users"
          >
            <TextField source="name" />
          </ReferenceField> */}
          <FunctionField
            render={(record: any) => {
              return (
                <>
                  <RatingField value={record.rating ? record.rating : 0} />{" "}
                  <span
                    style={{
                      display: "inline-flex",
                      marginLeft: 5,
                      position: "relative",
                      top: -5,
                    }}
                  >
                    <Typography variant="caption" color="textSecondary">
                      {`${handleDisplayNum(
                        record.ratingCount ? record.ratingCount : 0,
                        false,
                        "count"
                      )} avis`}
                    </Typography>
                  </span>
                </>
              );
            }}
            sortable={false}
            label="organization.rating"
          />
          <StickyTableCell>
            <FunctionField
              render={(props: any) => {
                return (
                  <DotsMenu
                    record={props}
                    // delete={props.status === "active"}
                    // delete
                    edit
                    confirmTitle={t("organization.confirmDeleteTitle", {
                      name: props.name,
                    })}
                    confirmContent={t("organization.confirmDeleteContent")}
                    // deleteAction={() => {
                    //   deleteOrganization({
                    //     type: "delete",
                    //     resource: "organization",
                    //     payload: {
                    //       id: props.id,
                    //     },
                    //   });
                    //   refresh();
                    // }}
                  />
                );
              }}
            />
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};

export default OrganizationList;
