const BASEPATH = "/tractor/:id/show";

export const TRACTOR_TABS = [
  {
    index: 0,
    name: "Info",
    path: `${BASEPATH}/info`,
    default: true,
  },
  {
    index: 1,
    name: "Expéditions",
    path: `${BASEPATH}/expeditions`,
  },
];
