import React, { FC, useState, useEffect } from "react";
import {
  AutocompleteInput,
  TextInputProps,
  //   useInput,
  useQuery,
} from "react-admin";
import { useOrganization } from "@hooks/useOrganization";
import {
  //  IDriver,
  IVehicle,
  IVehicleCategory,
} from "@wetrans/helpers";
import { useSelector } from "react-redux";
import { vehicleCategoriesSelector } from "@state/selectors";
import { isEmpty } from "lodash";

const VehicleInput: FC<TextInputProps> = (props: any) => {
  const { onEmpty, compatibleVehicleCategories, customFilter } = props;

  const { organization } = useOrganization();
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const vehicleCategories: IVehicleCategory[] = useSelector(
    vehicleCategoriesSelector
  );
  const { data } = useQuery({
    resource: "vehicle",
    type: "getList",
    payload: {
      filter: {
        organization: organization,
        ...customFilter,
      },
      pagination: {
        page: 1,
        perPage: 200,
      },
    },
  });

  useEffect(() => {
    if (data) {
      let vehicles = data as IVehicle[];

      if (compatibleVehicleCategories && !isEmpty(compatibleVehicleCategories))
        vehicles = vehicles.filter((vehicle) =>
          (compatibleVehicleCategories as string[]).includes(vehicle.category)
        );

      if (isEmpty(vehicles)) onEmpty?.();

      setVehicles(vehicles);
    }
  }, [data, setVehicles, onEmpty, compatibleVehicleCategories]);

  return (
    <>
      {
        <AutocompleteInput
          {...props}
          choices={vehicles}
          resettable
          onChange={(id: string) => {
            if (id) {
              const vehicle = vehicles.find((v) => v.id === id);
              if (vehicle) {
                const categoryId = vehicle.category;
                const category = vehicleCategories.find(
                  (c) => c.id === categoryId
                );

                props.vehicleCategoryOnVehicleChange?.(category);
                return;
              }
              props.vehicleCategoryOnVehicleChange?.();
              return;
            }
            props.vehicleCategoryOnVehicleChange?.();
          }}
          optionText="matricule"
          options={{
            suggestionsContainerProps: {
              style: {
                background: "white",
              },
            },
          }}
        ></AutocompleteInput>
      }
    </>
  );
};

export default VehicleInput;
