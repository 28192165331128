import React from "react";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { Confirm } from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);
interface IDeleteButtonProps {
  confirmTitle: string;
  deleteWarningMessage: string;
  onDelete: () => void;
  deleteLabel?: string;
}
export const DeleteButton = (props: IDeleteButtonProps) => {
  const handleDialogClose = () => {
    setIsOpen(false);
  };
  const { deleteWarningMessage, confirmTitle, deleteLabel, onDelete } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<CloseIcon />}
        onClick={(e) => {
          setIsOpen(true);
        }}
      >
        {deleteLabel ? deleteLabel : "Supprimer"}
      </Button>
      <Confirm
        isOpen={isOpen}
        title={confirmTitle}
        content={deleteWarningMessage}
        onConfirm={(e: any) => {
          onDelete();
          handleDialogClose();
        }}
        onClose={handleDialogClose}
      />
    </>
  );
};
