import React from "react";
import {
  Filter,
  TextInput,
  // NullableBooleanInput,
  // AutocompleteInput,
  usePermissions,
} from "react-admin";

// styles
import { primaryColor } from "@theme/colors";
import useStyles from "./DriverFiltersStyles";
import classnames from "classnames";
import { AccountStatusInput } from "@constants/accountStatus";

// import { withStyles } from "@material-ui/styles";
import { AdminRole } from "@wetrans/helpers";
// import { useOrganization } from "@hooks/useOrganization";
import OrganizationInput from "@components/common/OrganizationInput/OrganizationInput";
import { ApprovalStatusInput } from "@constants/approvalStatus";

// const AutocompleteInputMod = withStyles({
//   suggestionsContainer: {
//     "& [class*='root']": {
//       backgroundColor: "white",
//       maxWidth: 400,
//       whiteSpace: "normal",
//       boxShadow: "rgb(0 0 0 / 8%) 0px 2px 5px 0px",
//     },
//   },
// })(AutocompleteInput);
const DriverFilters = (props: any) => {
  const classes = useStyles();

  const { permissions } = usePermissions();
  // const { organization } = useOrganization();

  return (
    <Filter
      {...props}
      style={{
        background: primaryColor,
        borderRadius: 4,
        marginRight: 20,
      }}
      className={classnames(classes.formRoot, "filters")}
    >
      {/* <TextInput
        label="driver.id"
        resettable
        source="id"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
    
      */}
      {/* <NullableBooleanInput
        label="driver.online"
        source="online"
        trueLabel="driver.onlineTruthy"
        falseLabel="driver.onlineFalsy"
      /> */}

      <AccountStatusInput label="driver.accountStatus" source="accountStatus" />
      <ApprovalStatusInput
        label="general.approvalStatus"
        source="approvalStatus"
      />
      <TextInput
        label="driver.name"
        resettable
        source="name"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <TextInput
        label="CIN"
        resettable
        source="CIN"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <TextInput
        label="driver.phone"
        resettable
        source="phone"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      {permissions === AdminRole.super && (
        <OrganizationInput label="admin.organization" source="organization" />
      )}
    </Filter>
  );
};

export default DriverFilters;
