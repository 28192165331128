import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import "firebase/functions";

// helpers, configs, theme
import { firebaseConfig as config } from "@config/FIREBASE_CONFIG";
import DataProvider from "./DataProvider";
import FunctionsProvider from "./FunctionsProvider";
import AuthProvider from "./AuthProvider";

const options = {
  lazyLoading: {
    enabled: true,
  },
  renameMetaFields: {
    created_at: "createdAt",
    created_by: "createdBy",
    updated_at: "updatedAt",
    updated_by: "updatedBy",
  },
};

const RAFirebaseDataProvider = FirebaseDataProvider(config, options);
const RAFirebaseAuthProvider = FirebaseAuthProvider(config, options);
const firebaseApp = RAFirebaseDataProvider.app;
const customDataProvider = new DataProvider(
  firebaseApp,
  RAFirebaseDataProvider
);
const customGetList = customDataProvider.getList;
const customToOne = customDataProvider.getOne;
const customUpdate = customDataProvider.update;
const customCreate = customDataProvider.create;
const customGetManyReference = customDataProvider.getManyReference;

const customAuthProvider = new AuthProvider(
  firebaseApp,
  RAFirebaseAuthProvider
);
const functionsProvider = new FunctionsProvider(firebaseApp);
const authProvider = {
  ...RAFirebaseAuthProvider,
  getIdentity: customAuthProvider.getIdentity,
  login: customAuthProvider.login,
  getPermissions: customAuthProvider.getPermissions,
  resetPassword: customAuthProvider.resetPassword,
};

const dataProvider = {
  ...RAFirebaseDataProvider,
  getList: customGetList,
  getOne: customToOne,
  update: customUpdate,
  create: customCreate,
  customGetList,
  getManyReference: customGetManyReference,
};

export { dataProvider, authProvider, functionsProvider };
