import React from "react";
import Sidebar from "./Ra-Sidebar";
import { makeStyles } from "@material-ui/core/styles";

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: "white",
    zIndex: 1200,
    height: "100%",
    marginTop: "-50px",
    paddingBottom: "50px",
    marginRight: "15px",
  },
});

const MySidebar = (props: any) => {
  const classes = useSidebarStyles();
  return <Sidebar classes={classes} {...props} PaperProps={{ elevation: 0 }} />;
};

export default MySidebar;
