import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
  container: {
    // background: "aqua !important",
    width: "100%",
    "& label": {
      // display: "none",
    },
    "& :hover": {
      backgroundColor: "#fff0 !important",
    },
    "& :focused": {
      backgroundColor: "white !important",
    },
    "& div": {
      marginTop: 30,
      "&::before": {
        borderBottom: "none",
      },

      "& div div": {
        backgroundColor: "rgba(0, 0, 0, 0.08) !important",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        cursor: "pointer",
        position: "relative",
        "& > svg": {
          color: "black",
          position: "absolute",
          left: 0,
          top: -12,
        },
      },
    },
    "& p": {
      display: "none",
    },
    "& div input": {
      // background: "white",
      position: "absolute",
      left: 0,
      top: -20,
      width: "100%",
      marginBottom: 20,
    },
  },

  image: {
    height: 260,
    overflow: "hidden",
    background: "#d6d6d6",
    width: "100%",
    borderRadius: 12,
    "& img": {
      // border: "2px solid aqua",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
      width: "100%",
      height: "auto",
      margin: 0,
      borderRadius: 12,
    },
  },
}));
