import {
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  PaginationPayload,
  Record,
  SortPayload,
  UpdateParams,
} from "react-admin";
import firebase from "firebase";
import { transformTimestampToDate } from "@components/common/transformTimestampToDate";
import {
  GetManyReferenceParams,
  GetManyReferenceResult,
} from "react-admin-firebase/dist/misc/react-admin-models";
import { parse } from "date-fns";

export default class DataProvider {
  firebaseApp: firebase.app.App;
  db: firebase.firestore.Firestore;
  raDataProvider: any;
  resources: string[] = [
    //generator: declare resource
    "organization",
    "analyticsFilters",
    "city",
    "admin",
    "users",
    "analytics",
    "tractor",
    "vehicle",
    "client",
    "driver",
    "client",
    "category",
    "route",
    "expedition",
    "settings",
    "routeTemplates",
    //endgenerator
  ];
  snapshotsIndexStore: Map<string, string[]> = new Map();
  snapshotsStore: Map<
    string,
    firebase.firestore.QueryDocumentSnapshot[]
  > = new Map();
  // snapshots: firebase.firestore.QueryDocumentSnapshot[] = [];

  constructor(firebaseApp: firebase.app.App, raDataProvider: any) {
    this.firebaseApp = firebaseApp;
    this.db = this.firebaseApp.firestore();
    this.resources.forEach((resource) => this.clearSnapshots(resource));
    this.raDataProvider = raDataProvider;
  }

  getQueryWithFilters = ({
    resource,
    query,
    filters,
    base,
  }: {
    resource: string;
    query: firebase.firestore.Query;
    filters: any;
    base?: string;
  }) => {
    if (filters) {
      Object.keys(filters).forEach((fieldName) => {
        if (typeof filters[fieldName] !== "object") {
          if (isDate(filters[fieldName])) {
            console.log(fieldName);
            query = query
              .where(
                `${fieldName}`,
                "<=",
                firebase.firestore.Timestamp.fromMillis(
                  new Date(filters[fieldName]).getTime() + 24 * 60 * 60 * 1000
                )
              )
              .where(
                `${fieldName}`,
                ">=",
                firebase.firestore.Timestamp.fromMillis(
                  new Date(filters[fieldName]).getTime()
                )
              )
              .orderBy(fieldName);

            return;
          }
          console.log(filters[fieldName]);

          query = query.where(
            `${(base ? base + "." : undefined) || ""}${fieldName}`,
            "==",
            filters[fieldName]
          );

          return;
        }
        if (
          filters[fieldName] &&
          filters[fieldName].operator &&
          (filters[fieldName].value || filters[fieldName].value === 0)
        ) {
          query = query.where(
            `${(base ? base + "." : undefined) || ""}${fieldName}`,
            filters[fieldName].operator,
            filters[fieldName].value
          );
          return;
        }
        query = this.getQueryWithFilters({
          query,
          filters: filters[fieldName],
          base: fieldName,
          resource,
        });
      });
    }
    return query;
  };

  getQueryWithSort = (query: firebase.firestore.Query, sort: SortPayload) => {
    const { field, order } = sort;
    if (sort && sort.field && sort.order) {
      const parsedOrder = order.toLocaleLowerCase();
      if (!["asc", "desc"].includes(parsedOrder))
        throw new Error(`Unexpected sort order value: ${order}`);
      query = query.orderBy(field, parsedOrder as "asc" | "desc");
    }
    return query;
  };

  getQueryWithPagination = (
    query: firebase.firestore.Query,
    pagination: PaginationPayload,
    snapshots: firebase.firestore.QueryDocumentSnapshot[]
  ) => {
    const { perPage, page } = pagination;
    // console.log(
    //   `perPage: ${perPage} Page: ${page}, snapshots: ${snapshots.length}`
    // );
    if (page === 1) {
      query = query.limit(perPage);
    } else if (page > snapshots.length) {
      if (page !== snapshots.length + 1) {
        throw new Error(`Unexpected page ${page}`);
      }
      query = query.startAfter(snapshots[snapshots.length - 1]).limit(perPage);
    } else {
      query = query.startAfter(snapshots[page - 2]).limit(perPage);
    }

    return query;
  };

  private getSnapshots(key: {
    resource: string;
    params: GetListParams;
  }): firebase.firestore.QueryDocumentSnapshot[] {
    const snapshots = this.snapshotsStore.get(btoa(JSON.stringify(key)));
    if (!snapshots) return [];
    return snapshots;
  }

  private setSnapshots(
    key: {
      resource: string;
      params: Omit<GetListParams, "pagination">;
    },
    snapshots: firebase.firestore.QueryDocumentSnapshot[]
  ): void {
    const snapshotKey = btoa(JSON.stringify(key));
    this.snapshotsStore.set(snapshotKey, snapshots);
    const allSnapshots = this.snapshotsIndexStore.get(
      this.getAllSnapshotsKey(key.resource)
    );

    if (!allSnapshots) {
      throw new Error(
        `${this.getAllSnapshotsKey(key.resource)} accidentally deleted!`
      );
    }
    if (!allSnapshots.find((key) => key === snapshotKey)) {
      allSnapshots.push(snapshotKey);
      this.snapshotsIndexStore.set(
        this.getAllSnapshotsKey(key.resource),
        allSnapshots
      );
    }
  }

  private getAllSnapshotsKey(resource: string) {
    return `RA_FIREBASE_TODO_${resource}`;
  }

  private clearSnapshots(resource: string) {
    const allSnapshots = this.snapshotsIndexStore.get(
      this.getAllSnapshotsKey(resource)
    );
    this.snapshotsIndexStore.set(this.getAllSnapshotsKey(resource), []);
    if (!allSnapshots) return;
    allSnapshots.forEach((snapshotKey) => {
      this.snapshotsIndexStore.delete(snapshotKey);
    });
  }

  private extractParams({
    resource,
    params,
  }: {
    resource: string;
    params: GetListParams;
  }) {
    return {
      resource,
      params: {
        sort: params.sort,
        filter: params.filter,
        pagination: { perPage: params?.pagination?.perPage, page: Infinity },
      },
    };
  }
  getOne = async <RecordType extends Record = Record>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> => {
    if (resource === "driver" || resource === "client") resource = "users";
    const record = await this.raDataProvider.getOne(resource, params);
    return { ...record, id: params.id.toString() };
  };

  update = async <RecordType extends Record = Record>(
    resource: string,
    params: UpdateParams
  ): Promise<GetOneResult<RecordType>> => {
    if (resource === "driver" || resource === "client") resource = "users";
    return this.raDataProvider.update(resource, params);
  };
  create = async <RecordType extends Record = Record>(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<RecordType>> => {
    if (resource === "driver") resource = "users";

    return this.raDataProvider.create(resource, params);
  };
  getList = async <RecordType extends Record = Record>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> => {
    let total = Infinity;
    console.log(parse("2022-04-05", "yyyy-mm-dd", new Date()).getTime(), "yey");
    if (resource === "driver" || resource === "client") resource = "users";
    const snapshots = this.getSnapshots(
      this.extractParams({ resource, params })
    );

    const { sort, filter, pagination } = params;
    let query = await this.db.collection(resource);
    // transformDateToTimestamp(filter);

    const queryWithFilter = filter
      ? this.getQueryWithFilters({
          query,
          filters: filter,
          resource,
        })
      : query;
    if (sort && sort.field && !sort?.order) {
      sort.order = "desc";
    }

    const queryWithSort = sort
      ? this.getQueryWithSort(queryWithFilter, sort)
      : queryWithFilter;
    const queryWithPagination = pagination
      ? this.getQueryWithPagination(queryWithSort, pagination, snapshots)
      : queryWithSort;

    const collection = await queryWithPagination.get();

    const data = collection.docs
      .map((doc: any) => ({
        ...doc.data(),
        id: doc.id,
        // formattedDate: doc.data().lastupdate.toDate(),
      }))
      .filter((data) => !data.deleted);
    transformTimestampToDate(data);
    if (pagination && collection.docs.length > 0) {
      const last = collection.docs[collection.docs.length - 1];
      const nextQuery = this.db.collection(resource);
      const nextQueryWithFilters = filter
        ? await this.getQueryWithFilters({
            query: nextQuery,
            filters: filter,
            resource,
          })
        : nextQuery;
      const nextQueryWithSort = sort
        ? await this.getQueryWithSort(nextQueryWithFilters, sort)
        : nextQueryWithFilters;
      const next = await nextQueryWithSort.startAfter(last).limit(1).get();

      if (next.docs.length === 0) {
        total =
          collection.docs.length + (pagination.page - 1) * pagination.perPage;
      }
    }

    if (pagination && pagination.page === 1 && collection.docs.length === 0) {
      total = 0;
    }

    if (pagination && pagination.page > snapshots.length) {
      snapshots.push(collection.docs[collection.docs.length - 1]);
    }

    this.setSnapshots(this.extractParams({ resource, params }), snapshots);

    const result: GetListResult<RecordType> = {
      data,
      total,
    };

    return result;
  };
  getManyReference = async <RecordType extends Record = Record>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> => {
    let total = Infinity;
    if (resource === "driver" || resource === "client") resource = "users";
    const snapshots = this.getSnapshots(
      this.extractParams({ resource, params })
    );

    const { sort, filter, pagination } = params;
    let query = await this.db.collection(resource);

    const queryWithFilter = filter
      ? this.getQueryWithFilters({ query, filters: filter, resource })
      : query;

    const queryWithSort = sort
      ? this.getQueryWithSort(queryWithFilter, sort)
      : queryWithFilter;

    const queryWithPagination = pagination
      ? this.getQueryWithPagination(queryWithSort, pagination, snapshots)
      : queryWithSort;

    const collection = await queryWithPagination.get();

    const data = collection.docs.map((doc: any) => ({
      ...doc.data(),
      id: doc.id,
      // formattedDate: doc.data().lastupdate.toDate(),
    }));
    transformTimestampToDate(data);
    if (pagination && collection.docs.length > 0) {
      const last = collection.docs[collection.docs.length - 1];
      const nextQuery = this.db.collection(resource);
      const nextQueryWithFilters = filter
        ? await this.getQueryWithFilters({
            query: nextQuery,
            filters: filter,
            resource,
          })
        : nextQuery;
      const nextQueryWithSort = sort
        ? await this.getQueryWithSort(nextQueryWithFilters, sort)
        : nextQueryWithFilters;

      const next = await nextQueryWithSort.startAfter(last).limit(1).get();

      if (next.docs.length === 0) {
        total =
          collection.docs.length + (pagination.page - 1) * pagination.perPage;
      }
    }

    if (pagination && pagination.page === 1 && collection.docs.length === 0) {
      total = 0;
    }

    if (pagination && pagination.page > snapshots.length) {
      snapshots.push(collection.docs[collection.docs.length - 1]);
    }

    this.setSnapshots(this.extractParams({ resource, params }), snapshots);

    const result: GetManyReferenceResult<RecordType> = {
      data,
      total: total,
    };
    return result;
  };
}
// const addDateRange = (filter: any) => {
//   for (let prop in filter) {
//     if (filter[prop] instanceof Date) {
//       filter[]
//     }
//   }
// };

function isDate(dateStr: any) {
  return (
    typeof dateStr === "string" &&
    checkIfValidString(dateStr) &&
    !isNaN(Date.parse(dateStr))
  );
}
const checkIfValidString = (value: string) => {
  // TODO: find a better way to check if date is string
  const invalidStringsDate = ["city", "category", "merch"];
  return (
    invalidStringsDate.filter((invalidString) => value.includes(invalidString))
      .length === 0 &&
    value.includes("-") &&
    (!isNaN(parse(value, "mm-dd-yyyy", new Date()).getTime()) ||
      !isNaN(parse(value, "dd-mm-yyyy", new Date()).getTime()) ||
      !isNaN(parse(value, "yyyy-mm-dd", new Date()).getTime()))
  );
};
