import { WithAdminApproval } from "@components/common/WithAdminApproval";
import { ExpeditionStatus, ITractor } from "@wetrans/helpers";
import * as React from "react";
import { TractorForm } from "./TractorForm";
import { TractorShow } from "./TractorShow";
import firebase from "firebase";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";

export default function DriverShowEdit(props: any) {
  const checkIfTheTractorIsDeleteble = React.useCallback(
    async (record: ITractor) => {
      const db = firebase.app().firestore();
      try {
        const { empty: emptyExpeditions } = await db
          .collection("expedition")
          .where("tractor", "==", record.id)
          .orderBy("status", "desc")
          .startAt(ExpeditionStatus.unloading)
          .endAt(ExpeditionStatus.booked)
          .limit(1)
          .get();
        return emptyExpeditions;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    []
  );
  return (
    <WithAdminApproval
      {...props}
      adminApproveWarningMessage="tractor.adminApproveWarningMessage"
      adminDisapproveWarningMessage="tractor.adminDisapproveWarningMessage"
      warningMessage="tractor.warningMessage"
      warningMessageTitle="tractor.warningMessageTitle"
      EditComponent={TractorForm}
      ShowComponent={TractorShow}
      enableDelete
      deleteConfirmationMessage="tractor.deleteConfirmationMessage"
      isDeletable={checkIfTheTractorIsDeleteble}
      transformOnDelete={(record: ITractor, deletedById: string) => {
        return {
          ...record,
          approvalStatus: ApprovalStatus.Disapproved,
          deleted: true,
          deletedAt: new Date(),
          deletedBy: deletedById,
        };
      }}
    />
  );
}
