import React, { FC } from "react";
import { useSelector } from "react-redux";
import { vehicleCategoriesSelector } from "@state/selectors";
import { AutocompleteInput, TextInputProps } from "react-admin";
import { IVehicleCategory } from "@wetrans/helpers";
import styled from "styled-components";

const CategoryOption = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;

  img {
    display: inline-block;
    width: 62px;
    height: 45px;
    object-fit: cover;
    border-radius: 7px;
    margin-right: 10px;
  }
`;
const VehicleCategoryInput: FC<TextInputProps> = (props: any) => {
  const { source, label } = props;
  const vehicleCategories: IVehicleCategory[] = useSelector(
    vehicleCategoriesSelector
  );
  const OptionRenderer = (input: any) => {
    const { record: vehicleCategory } = input;
    return (
      <CategoryOption>
        <img src={vehicleCategory.picture.src} alt={vehicleCategory.name} />
        {vehicleCategory.name} ({vehicleCategory.allowedCargoWeight} kg)
      </CategoryOption>
    );
  };
  const inputText = (vehicleCategory: any) =>
    `${vehicleCategory.name} (${vehicleCategory.allowedCargoWeight} kg)`;

  return (
    <>
      <AutocompleteInput
        source={source}
        label={label}
        allowEmpty={false}
        resettable
        choices={vehicleCategories}
        optionText={<OptionRenderer />}
        matchSuggestion={(
          filterValue: string,
          suggestion: IVehicleCategory
        ) => {
          return suggestion.name
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        }}
        inputText={inputText}
        options={{
          suggestionsContainerProps: {
            style: {
              background: "white",
            },
          },
        }}
        {...props}
      ></AutocompleteInput>
    </>
  );
};

export default VehicleCategoryInput;
