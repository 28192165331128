import React from "react";

const SettingsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.667"
    height="22.287"
    viewBox="0 0 25.667 22.287"
    {...props}
  >
    <g opacity="0.43">
      <path
        d="M22.246 33.707H3.421A3.425 3.425 0 0 0 0 37.128v15.446a3.425 3.425 0 0 0 3.421 3.421h18.825a3.425 3.425 0 0 0 3.421-3.421V37.128a3.425 3.425 0 0 0-3.421-3.421zm1.917 18.867a1.919 1.919 0 0 1-1.917 1.917H3.421A1.919 1.919 0 0 1 1.5 52.574V37.128a1.919 1.919 0 0 1 1.917-1.917h18.829a1.919 1.919 0 0 1 1.917 1.917z"
        transform="translate(0 -33.707)"
      />
      <path
        d="M213.454 102.193v-7.734a.752.752 0 1 0-1.5 0v7.734a2.285 2.285 0 0 0 0 4.315v2.719a.752.752 0 0 0 1.5 0v-2.719a2.285 2.285 0 0 0 0-4.315zm-.751 2.939a.781.781 0 1 1 .782-.781.782.782 0 0 1-.785.781z"
        transform="translate(0 -33.707) translate(-199.869 -56.992)"
      />
      <path
        d="M82.954 97.177v-2.718a.752.752 0 1 0-1.5 0v2.718a2.284 2.284 0 0 0 0 4.315v7.735a.752.752 0 1 0 1.5 0v-7.735a2.284 2.284 0 0 0 0-4.315zm-.752 2.939a.781.781 0 0 1 0-1.562.781.781 0 0 1 0 1.562z"
        transform="translate(0 -33.707) translate(-75.911 -56.992)"
      />
      <path
        d="M343.954 97.177v-2.718a.752.752 0 1 0-1.5 0v2.718a2.285 2.285 0 0 0 0 4.315v7.735a.752.752 0 1 0 1.5 0v-7.735a2.284 2.284 0 0 0 0-4.315zm-.752 2.939a.781.781 0 0 1 0-1.562.781.781 0 0 1 0 1.562z"
        transform="translate(0 -33.707) translate(-323.827 -56.992)"
      />
    </g>
  </svg>
);
export default SettingsIcon;
