import React from "react";
import { choices, SelectInput } from "react-admin";
import { routeFrequency } from "./routeFrequency";

export const RouteFrequencyInput = ({
  source,
  label,
  className,
  disabled,
}: {
  source: string;
  label: string;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <SelectInput
      optionText="label"
      optionValue="id"
      disabled={disabled}
      source={source}
      label={label}
      choices={routeFrequency}
      validate={choices(routeFrequency.map((item) => item.id))}
      className={className}
    />
  );
};
