import React from "react";
import { useDispatch, connect } from "react-redux";
import { Confirm } from "react-admin";
import DeleteExported from "@assets/vectors/DeleteExported";
import PenExported from "@assets/vectors/PenExported";
import ThreeDots from "@assets/vectors/ThreeDotsExported";
import TrashExported from "@assets/vectors/TrashExported";
import ViewExported from "@assets/vectors/ViewExported";
import { primaryColor } from "@theme/colors";
import "./style.css";
import { useTranslate } from "react-admin";

const DotsMenu = (props: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClick = (action: Function) => (
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    action && action();
  };
  const t = useTranslate();
  const handleDialogOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      className="dotsMenuContainer"
      style={{
        width: "50px",
        height: "100%",
        position: "absolute",
        top: 0,
        right: -1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "aqua",
        zIndex: 99999,
        ...props?.style,
      }}
    >
      {props.menuState === props.record.id ? (
        <div className="dotsMenuMenu">
          {props.action && (
            <div className="dotsMenuItem" onClick={handleClick(props.action)}>
              <div className="dotsMenuIcon">
                <PenExported />
              </div>
              <div className="dotsMenuText">{props.text}</div>
            </div>
          )}

          {props.view && (
            <div className="dotsMenuItem">
              <div className="dotsMenuIcon">
                <ViewExported />
              </div>
              <div className="dotsMenuText">{t("general.view")}</div>
            </div>
          )}

          {props.edit && (
            <div className="dotsMenuItem">
              <div className="dotsMenuIcon">
                <PenExported />
              </div>
              <div className="dotsMenuText">{t("general.edit")}</div>
            </div>
          )}

          {props.disable && (
            <div className="dotsMenuItem" onClick={handleDialogOpen}>
              <div className="dotsMenuIcon">
                <DeleteExported />
              </div>
              <div className="dotsMenuText">{t("general.disable")}</div>
              <Confirm
                isOpen={isOpen}
                title={props.confirmTitle}
                content={props.confirmContent}
                onConfirm={props.disableAction}
                onClose={handleDialogClose}
              />
            </div>
          )}

          {props.delete && (
            <div className="dotsMenuItem" onClick={handleDialogOpen}>
              <div className="dotsMenuIcon">
                <TrashExported />
              </div>
              <div className="dotsMenuText">{t("general.delete")}</div>

              <Confirm
                isOpen={isOpen}
                title={props.confirmTitle}
                content={props.confirmContent}
                onConfirm={props.deleteAction}
                onClose={handleDialogClose}
              />
            </div>
          )}
        </div>
      ) : null}

      <button
        className="dotsMenuButton"
        onClick={(e) => {
          e.stopPropagation();

          dispatch({
            type: "MENU_STATE",
            data: props.menuState === props.record.id ? "" : props.record.id,
          });
        }}
      >
        <ThreeDots
          fill={props.menuState === props.record.id ? primaryColor : "black"}
        />
      </button>
    </div>
  );
};

const menuState = connect((state: any) => {
  return {
    menuState: state.customReducer.menuState,
  };
})(DotsMenu);

export default menuState;
