import React from "react";
import { SaveButton, Toolbar } from "react-admin";
import { useFormValidator } from "@components/common/FormValidator";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const SettingsToolbar = (props: any) => {
  const formValidator = useFormValidator();
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton
        label="Mettre à jour"
        style={{ width: 200 }}
        redirect={false}
        disabled={props.pristine}
        invalid={props.invalid}
        onClick={formValidator.activate}
      />
    </Toolbar>
  );
};

export default SettingsToolbar;
