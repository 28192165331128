import * as React from "react";
import {
  Edit,
  SimpleForm,
  useTranslate,
  usePermissions,
  TextField,
  Labeled,
  useLocale,
  DateField,
  FunctionField,
  ReferenceField,
  // @ts-ignore
} from "react-admin";
import MyTitle from "@components/common/View/Title";
import { AccountStatusField } from "@constants/accountStatus";

import { AdminRoleField } from "@constants/adminRole";
import { AdminRole } from "@wetrans/helpers";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import Card from "@components/common/card/Card";
import { ApprovalStatusField } from "@constants/approvalStatus";
import { MultiFileField } from "@components/common/MultiFileField/MultiFileField";
import { DeletedField } from "@components/common/WithAdminApproval/DeletedField";

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export default function AdminShow(props: any) {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const classes = useStyles();
  const locale = useLocale();
  const { Toolbar } = props;
  return (
    <Edit
      {...props}
      hasShow={false}
      title={
        <MyTitle
          resource={props.resource}
          label={t("admin.admin")}
          descriptionAttribute="name"
        />
      }
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <Toolbar
            {...props}
            mode="edit"
            style={{
              position: "absolute",
              top: 55,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 65,
          }}
        >
          <Card>
            <Grid container className={classes.fullWidth} spacing={4}>
              <Grid item sm={12}>
                <DeletedField deletedMessage="admin.deletedMessage" />
              </Grid>
              <Grid item sm={6}>
                <FunctionField
                  render={(props: any) => {
                    return props.role !== AdminRole.super ? (
                      <Labeled label="CIN">
                        <TextField
                          source="CIN"
                          className={classes.fullWidth}
                          emptyText="N/A"
                        />
                      </Labeled>
                    ) : null;
                  }}
                  label="general.approvalStatus"
                />
              </Grid>
              <Grid item sm={6}>
                <Labeled label="admin.email">
                  <TextField
                    source="email"
                    className={classes.fullWidth}
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item sm={6}>
                <Labeled label="admin.name">
                  <TextField
                    source="name"
                    className={classes.fullWidth}
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item sm={6}>
                <Labeled label="admin.phone">
                  <TextField
                    source="phone"
                    className={classes.fullWidth}
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item sm={6}>
                <Labeled label="admin.title">
                  <TextField
                    source="title"
                    className={classes.fullWidth}
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item sm={6}>
                <Labeled label="admin.createdAt">
                  <DateField
                    locales={locale}
                    className={classes.fullWidth}
                    source="createdAt"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item sm={6}>
                <FunctionField
                  render={(props: any) => {
                    return (
                      <Labeled label="admin.accountStatus">
                        <AccountStatusField value={props.accountStatus} />
                      </Labeled>
                    );
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <FunctionField
                  render={(props: any) => {
                    return (
                      <Labeled label="admin.role">
                        <AdminRoleField value={props.role} />
                      </Labeled>
                    );
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                {permissions === AdminRole.super ? (
                  <FunctionField
                    render={(props: any) => {
                      return props.role !== AdminRole.super ? (
                        <Labeled label="admin.organization">
                          <ReferenceField
                            className={classes.fullWidth}
                            link="show"
                            source="organization"
                            reference="organization"
                          >
                            <TextField source="name" />
                          </ReferenceField>
                        </Labeled>
                      ) : null;
                    }}
                  />
                ) : null}
              </Grid>
              <Grid item sm={6}>
                <FunctionField
                  render={(props: any) => {
                    return props.role !== AdminRole.super ? (
                      <Labeled label="general.approvalStatus">
                        <ApprovalStatusField value={props.approvalStatus} />
                      </Labeled>
                    ) : null;
                  }}
                  label="general.approvalStatus"
                />
              </Grid>
              <Grid item sm={12}>
                <FunctionField
                  render={(props: any) => {
                    return props.role !== AdminRole.super ? (
                      <MultiFileField source="documents" label="Documents" />
                    ) : null;
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
      </SimpleForm>
    </Edit>
  );
}
