import React from "react";

export default function DatshboadExported(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.156"
      height="22.156"
      viewBox="0 0 22.156 22.156"
    >
      <defs>
        <style>{/* .cls-2{fill:none;stroke:#000;stroke-width:1.5px} */}</style>
      </defs>
      <g id="dashboard" opacity="0.43" transform="translate(.75 .75)">
        <path
          id="Tracé_2514"
          d="M7.961 0H1.506A1.508 1.508 0 0 0 0 1.506v3.873a1.508 1.508 0 0 0 1.506 1.506h6.455a1.508 1.508 0 0 0 1.506-1.506V1.506A1.508 1.508 0 0 0 7.961 0zm0 0"
          className="cls-2"
        />
        <path
          id="Tracé_2515"
          d="M7.961 213.332H1.506A1.508 1.508 0 0 0 0 214.838v9.037a1.508 1.508 0 0 0 1.506 1.506h6.455a1.508 1.508 0 0 0 1.506-1.506v-9.037a1.508 1.508 0 0 0-1.506-1.506zm0 0"
          className="cls-2"
          transform="translate(0 -204.725)"
        />
        <path
          id="Tracé_2516"
          d="M285.293 341.332h-6.455a1.508 1.508 0 0 0-1.506 1.506v3.873a1.508 1.508 0 0 0 1.506 1.506h6.455a1.508 1.508 0 0 0 1.506-1.506v-3.873a1.508 1.508 0 0 0-1.506-1.506zm0 0"
          className="cls-2"
          transform="translate(-266.143 -327.561)"
        />
        <path
          id="Tracé_2517"
          d="M285.293 0h-6.455a1.508 1.508 0 0 0-1.506 1.506v9.037a1.508 1.508 0 0 0 1.506 1.506h6.455a1.508 1.508 0 0 0 1.506-1.506V1.506A1.508 1.508 0 0 0 285.293 0zm0 0"
          className="cls-2"
          transform="translate(-266.143)"
        />
      </g>
    </svg>
  );
}
