import React from "react";
import NoDataPlaceholder from "./NoDataPlaceholder";

interface NoDataTableProps {
  grid?: string[];
  size?: "small" | "medium";
  label?: string;
}
export const NoDataTable = (props: NoDataTableProps) => {
  return (
    <>
      <NoDataPlaceholder
        label={props.label}
        grid={props.grid}
        size={props.size}
      />
    </>
  );
};
