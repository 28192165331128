import React from "react";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useTranslate } from "react-admin";

const MyTitle = ({
  record,
  resource,
  disableDescription,
  description,
  label,
  path,
  descriptionAttribute,
}: {
  record?: any;
  resource: string;
  disableDescription?: boolean;
  description?: string;
  label?: string;
  path?: string;
  descriptionAttribute?: string;
}) => {
  const t = useTranslate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "black",
        textDecoration: "none",
      }}
    >
      <Link
        component={RouterLink}
        to={path ? path : `/${resource}`}
        color="inherit"
      >
        {label ? label : t(`${resource}.${resource}`)}
      </Link>
      {!disableDescription && (
        <>
          <ArrowRightIcon />
          {description
            ? description
            : record
            ? `"${
                descriptionAttribute && record[descriptionAttribute]
                  ? record[descriptionAttribute]
                  : record.name
                  ? record.name
                  : record.id
              }"`
            : ""}
        </>
      )}
    </div>
  );
};

export default MyTitle;
