import React from "react";
import {
  Filter,
  TextInput,
  // DateInput,
  // ReferenceInput,
  // AutocompleteInput,
} from "react-admin";

// styles
import { primaryColor } from "@theme/colors";
import useStyles from "./OrganizationFiltersStyles";
import classnames from "classnames";

import { OrganizationTypeInput } from "@constants/organizationType";
import { ApprovalStatusInput } from "@constants/approvalStatus";
import CityInput from "@components/common/CityInput/CityInput";

// import { withStyles } from "@material-ui/styles";

// const AutocompleteInputMod = withStyles({
//   suggestionsContainer: {
//     "& [class*='root']": {
//       backgroundColor: "white",
//       maxWidth: 400,
//       whiteSpace: "normal",
//       boxShadow: "rgb(0 0 0 / 8%) 0px 2px 5px 0px",
//     },
//   },
// })(AutocompleteInput);
const OrganizationFilters = (props: any) => {
  const classes = useStyles();

  return (
    <Filter
      {...props}
      style={{
        background: primaryColor,
        borderRadius: 4,
        marginRight: 20,
      }}
      className={classnames(classes.formRoot, "filters")}
    >
      {/* <TextInput
        label="organization.name"
        resettable
        source="name"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      /> */}
      <TextInput
        label="organization.ICE"
        resettable
        source="ICE"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />

      {/* <DateInput
        label="organization.createdAt"
        resettable
        source="createdAt"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      /> */}

      <TextInput
        source="name"
        label="organization.name"
        resettable
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <ApprovalStatusInput
        label="general.approvalStatus"
        source="approvalStatus"
      />
      <OrganizationTypeInput label="organization.type" source="type" />
      <CityInput label="Ville d'origine" source="cityOfOrigin" />

      {/* <ReferenceInput
        label="organization.createdBy"
        source="createdBy"
        reference="admin"
      >
        <AutocompleteInputMod optionText="name" />
      </ReferenceInput> */}

      {/* <ReferenceInput
        label="organization.admin"
        source="admin"
        reference="admin"
      >
        <AutocompleteInputMod optionText="name" />
      </ReferenceInput> */}
    </Filter>
  );
};

export default OrganizationFilters;
