import styled from "styled-components";

// Line Chart
export const LineChartLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px 10px;
`;

export const LineChartLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  i {
    width: 15px;
    height: 15px;
    border: 3px solid #fff;
    border-radius: 50%;
    background: #fff;
    margin-right: 5px;
    display: inline-block;
  }
`;

// Pie Chart
interface PieChartProps {
  direction?: "row";
}

export const PieChartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: ${(props: PieChartProps) => "column"};

  .pieChartHolder {
    flex: 1;
  }
`;

export const PieChartLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  top: ${(props: PieChartProps) => (props.direction === "row" ? "0" : "41px")};
  flex-direction: ${(props: PieChartProps) => "row"};
`;

export const PieChartLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  i {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    margin-right: 5px;
    display: inline-block;
  }
`;
