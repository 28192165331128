import * as React from "react";
import "./error-list.css";
import errorsLogo from "./errors.png";
import errorLogo from "./error.png";
import Field from "../field";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormValidatorContext } from "../FormValidatorContext";

interface IProps {
  onSelect: (e: Field) => void;
}
interface IState {
  open: boolean;
  searchByName: string;
}

class ErrorList extends React.Component<IProps, IState> {
  static contextType = FormValidatorContext;
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: true,
      searchByName: "",
    };
  }
  public render() {
    if (!this.context.active || this.isAllFieldValid(this.context.fields))
      return null;
    else
      return (
        <aside className="error-overlay-container">
          <div
            tabIndex={-1}
            className={
              this.state.open
                ? "error-overlay-list-bubble"
                : "error-overlay-list-bubble error-overlay-list-bubble--is-minimized"
            }
          >
            <header
              className="error-overlay-bubble-header"
              onClick={this.closeOrOpenList}
            >
              <section className="error-overlay-bubble-header__details">
                <div className="error-img-ctn">
                  <img src={errorsLogo} alt="" />
                </div>
                <button className="error-overlay-bubble-header__button">
                  <h4>Errors</h4>
                </button>
              </section>
              <section className="error-overlay-bubble-header__controls">
                <IconButton size="small" color="inherit">
                  {this.state.open ? (
                    <ExpandLessIcon color="inherit" fontSize="large" />
                  ) : (
                    <ExpandMoreIcon color="inherit" fontSize="large" />
                  )}
                </IconButton>
              </section>
            </header>
            <div className="error-overlay-list-bubble-search">
              <div className="error-overlay-list-bubble-search__input-container">
                <SearchIcon
                  fontSize="inherit"
                  className="error-overlay-list-search__search-icon"
                />
                <input
                  type="text"
                  value={this.state.searchByName}
                  onChange={this.handleChangeOnSearch}
                  className="error-overlay-list-bubble-search__search-typeahead-input"
                  placeholder="Search Error"
                />
                {/* <i className="pi pi-sliders-h error-overlay-list-filter-icon"></i> */}
              </div>
            </div>

            <div className="scroll-panel-container">
              {this.context.fields
                .filter((field: Field) => {
                  return (
                    this.search(field.name) &&
                    field.error !== "" &&
                    field.error !== undefined &&
                    field.error !== null
                  );
                })
                .map((field: Field) => (
                  <div
                    key={field.name}
                    className="error-overlay-list-item"
                    onClick={(e) => {
                      this.props.onSelect(field);
                    }}
                  >
                    <div className="error-overlay-list-card">
                      <div className="error-selectable-div">
                        <div className="error-icon-grid">
                          <img src={errorLogo} alt="" />
                        </div>
                      </div>
                      <div className="error-overlay-list-bubble__card-content">
                        <div className="error-overlay-list-bubble__convo-card-content-wrapper">
                          <div className="error-card__row">
                            <span className="listitem__error-names">
                              {field.label} is invalid
                            </span>
                          </div>
                          <div className="error-card__row">
                            <span className="listitem__error-message">
                              {field.error}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </aside>
      );
  }
  public refresh = () => {
    this.setState(this.state);
  };
  private isAllFieldValid = (fields: Field[]) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].error && fields[i].error !== "") return false;
    }
    return true;
  };
  private search = (name: string): Boolean => {
    if (
      name.startsWith(this.state.searchByName) ||
      this.state.searchByName === ""
    )
      return true;
    else return false;
  };
  private handleChangeOnSearch = (evt: any) => {
    this.setState({ ...this.state, searchByName: evt.target.value });
  };
  private closeOrOpenList = () => {
    this.setState({ ...this.state, open: !this.state.open });
  };
}

export default ErrorList;
