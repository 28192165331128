import React, { FC } from "react";
import { TextInputProps, useInput } from "react-admin";
import Select from "react-select";

// component

// styles
import "./selector-styles.css";
import { useSelector } from "react-redux";
import styled from "styled-components";

// helper
import { vehicleCategoriesSelector } from "@state/selectors";
import { IVehicleCategory } from "@wetrans/helpers";
import { FormHelperText } from "@mui/material";

const VehicleCategoriesSelector: FC<TextInputProps> = (props: any) => {
  //   const { filterValues, setFilters, displayedFilters } = useListFilterContext();
  const {
    input: { onChange, value },
    meta: { error, touched },
    // isRequired,
  } = useInput(props);
  // styled
  const POPFilterContainer = styled.div`
    position: relative;
  `;

  const POPOption = styled.div`
    padding: 5px 10px;
    display: flex;
    align-items: center;

    img {
      display: inline-block;
      width: 62px;
      height: 45px;
      object-fit: cover;
      border-radius: 7px;
      margin-right: 10px;
    }
  `;

  const handleSelectChange = (option: any, actionMeta: any) => {
    // setStartDate(startOfMonth);
    // setEndDate(endOfMonth);
    onChange(option.value);
  };

  const PlaceHolder = () => <>Category</>;
  let vehicleCategories: IVehicleCategory[] = useSelector(
    vehicleCategoriesSelector
  );

  const optionsCategory = vehicleCategories.map((category) => {
    return {
      value: category.id,
      label:
        category.name +
        (category.allowedCargoWeight
          ? ` (${category.allowedCargoWeight} kg)`
          : ""),
      rawDate: category,
    };
  });

  const CustomOption = ({ innerProps, isDisabled, children, data }: any) => {
    return !isDisabled ? (
      <POPOption {...innerProps}>
        <img src={data.rawDate?.picture?.src} loading="lazy" alt="" />
        {children}
      </POPOption>
    ) : null;
  };
  let defaultValue;

  if (value && optionsCategory && optionsCategory.length > 0)
    defaultValue = optionsCategory.find((opt) => opt.value === value);

  return (
    <POPFilterContainer>
      <Select
        options={optionsCategory}
        isDisabled={props.disabled}
        placeholder={<PlaceHolder />}
        defaultValue={defaultValue}
        onChange={handleSelectChange}
        className="category-selector-container"
        classNamePrefix="category-selector"
        isSearchable={false}
        components={{ Option: CustomOption }}
      />
      {touched && (
        <FormHelperText error id="my-helper-text">
          {error}
        </FormHelperText>
      )}
    </POPFilterContainer>
  );
};

export default VehicleCategoriesSelector;
