import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  {
    loader: {
      padding: 30,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  { name: "GRM" }
);

export interface LoaderProps {
  children?: React.ReactNode;
}

const Loader = ({ children }: LoaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
