import { useHistory } from "react-router-dom";
export function scrollToView(input: HTMLElement) {
  if (window.getComputedStyle(input).display === "none")
    (input.parentNode as HTMLElement).focus({ preventScroll: false });
  else input.focus({ preventScroll: false });
}
export function isMixedField(name: string): boolean {
  return name.includes("[");
}
export function extractMixedField(name: string): any {
  let str = name.split("[");
  return { parent: str[0], index: str[1][0] };
}
const UseTabs = () => {
  let history = useHistory();
  return {
    open: (number: number) => {
      let path = history.location.pathname;
      if (!Number.isNaN(Number(path[path.length - 1])))
        path = path.slice(0, path.length - 2);
      if (number > 0) history.push(path + "/" + number);
      else history.push(path);
    },
  };
};

export { UseTabs };
