import { merchandiseCategoriesSelector } from "@state/selectors";
import { MerchandiseCategory } from "@wetrans/helpers";
import { isEmpty } from "lodash";
import React from "react";
import { useRecordContext } from "react-admin";
import { useSelector } from "react-redux";
const MerchandiseCategoryField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const sources = (source as string).split(".");
  const categoryId = isEmpty(sources)
    ? record[source]
    : record[sources[0]][sources[1]];
  const merchandiseCategories: MerchandiseCategory[] = useSelector(
    merchandiseCategoriesSelector
  );
  let category: MerchandiseCategory | undefined;
  if (categoryId)
    category = merchandiseCategories.find(
      (category) => category.id === categoryId
    );
  return <span>{category ? category.name : categoryId}</span>;
};
export default MerchandiseCategoryField;
