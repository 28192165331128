import { getLocalDate } from "@utils/dateHelper";
import React from "react";

interface MultiDateFieldProps {
  dates: Date[];
}
export const MultiDateField = (props: MultiDateFieldProps) => {
  const { dates } = props;
  return (
    <div className="listField">
      {dates?.map((date) => (
        <div>{getLocalDate(date)}</div>
      ))}
    </div>
  );
};
