import React from "react";
import {
  Filter,
  TextInput,
  // TextInput,
  // ReferenceInput,
  // AutocompleteInput,
  usePermissions,
} from "react-admin";

// styles
import { primaryColor } from "@theme/colors";
import useStyles from "./AdminFiltersStyles";
import classnames from "classnames";
import { AccountStatusInput } from "@constants/accountStatus";

import { AdminRoleInput } from "@constants/adminRole";

// import { withStyles } from "@material-ui/styles";
import { AdminRole } from "@wetrans/helpers";
import OrganizationInput from "@components/common/OrganizationInput/OrganizationInput";
import { ApprovalStatusInput } from "@constants/approvalStatus";

// const AutocompleteInputMod = withStyles({
//   suggestionsContainer: {
//     "& [class*='root']": {
//       backgroundColor: "white",
//       maxWidth: 400,
//       whiteSpace: "normal",
//       boxShadow: "rgb(0 0 0 / 8%) 0px 2px 5px 0px",
//     },
//   },
// })(AutocompleteInput);
const AdminFilters = (props: any) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  return (
    <Filter
      {...props}
      style={{
        background: primaryColor,
        borderRadius: 4,
        marginRight: 20,
      }}
      className={classnames(classes.formRoot, "filters")}
    >
      {/* <TextInput
        label="admin.name"
        resettable
        source="name"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <TextInput
        label="admin.phone"
        resettable
        source="phone"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      /> */}
      <AccountStatusInput label="admin.accountStatus" source="accountStatus" />
      <ApprovalStatusInput
        label="general.approvalStatus"
        source="approvalStatus"
      />
      <TextInput
        label="CIN"
        resettable
        source="CIN"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <AdminRoleInput mode="filter" label="admin.role" source="role" />
      {permissions === AdminRole.super && (
        <OrganizationInput label="admin.organization" source="organization" />
      )}
    </Filter>
  );
};

export default AdminFilters;
