import React, { useEffect } from "react";
import Card from "@components/common/card/Card";
import CardTitle from "@components/common/card/CardTitle";
import {
  ArrayInput,
  Create,
  required,
  SimpleForm,
  SimpleFormIterator,
  useMutation,
  useQuery,
} from "react-admin";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import { useState } from "react";
import { DaysPickerField } from "./daysPicker/DaysPickerField";
import { MultiDatePickers } from "./multiDatePicker/MultiDatePickers";
import { useOrganization } from "@hooks/useOrganization";
import CityInput from "@components/common/CityInput/CityInput";
import RouteCreateToolbar from "./RoutesCreateToolbar";
import { Alert } from "@mui/material";
import { IOrganization, IVehicle, RouteTemplateStatus } from "@wetrans/helpers";
import { TimeInput } from "@components/common/TimeInput/TimeInput";
import { ApprovalStatus } from "@wetrans/helpers/out/entities/ApprovalInfo";
import { isSameDay } from "date-fns";

interface RoutesFormProps {
  vehicleId: string;
  onSuccess: () => void;
}
export const RoutesCreate = (props: RoutesFormProps) => {
  const { vehicleId, onSuccess } = props;
  const { organization } = useOrganization();
  const initProps = {
    resource: "routeTemplates",
    hasEdit: false,
    hasShow: false,
    hasList: false,
    basePath: "/",
    onSuccess: () => {
      onSuccess();
    },
  };

  const classes = useStyles();
  const [IsRegularRoute, setIsRegularRoute] = useState<boolean>(true);
  const [mutate] = useMutation();
  const [organizationDoc, setOrganizationDoc] = useState<IOrganization>();
  const [vehicleDoc, setVehicleDoc] = useState<any>(); // TODO: use helper

  const validateRouteCreation = (values: any) => {
    if (
      IsRegularRoute &&
      (!values.operationWeekDays || !values.operationWeekDays.length)
    ) {
      return { operationWeekDays: "Veuillez choisir un jour" };
    }
    if (!IsRegularRoute) {
      delete values.operationWeekDays;
      const dates = values.dates as Date[];
      const today = new Date();
      const invalidDate = dates?.find(
        (date) => date.getTime() < today.getTime() && !isSameDay(date, today)
      );
      if (invalidDate) {
        return { dates: "date invalide" };
      }
      dates?.map((date) => date.setHours(12, 0, 0, 0));
    }
    return {};
  };
  const save = React.useCallback(
    async (values) => {
      try {
        if (
          IsRegularRoute &&
          (!values.operationWeekDays || !values.operationWeekDays.length)
        ) {
          return { operationWeekDays: "Veuillez choisir un jour" };
        }
        if (!IsRegularRoute) {
          delete values.operationWeekDays;
          const dates = values.dates as Date[];
          dates.map((date) => date.setHours(12, 0, 0, 0));
        } else {
          delete values.dates;
        }
        await mutate(
          {
            type: "create",
            resource: "routeTemplates",
            payload: {
              data: {
                ...values,
                vehicle: vehicleId,
                organization: organization,
                status: RouteTemplateStatus.active,
                vehicleCategory: vehicleDoc.category,
                frequency: IsRegularRoute ? "regular" : "irregular",
              },
            },
          },
          { returnPromise: true }
        );
        values = undefined;
        onSuccess();
      } catch (error) {
        if ((error as any).body?.errors) {
          return (error as any).body.errors;
        }
      }
    },
    [IsRegularRoute, mutate, onSuccess, organization, vehicleId, vehicleDoc]
  );
  const { data: organizationData } = useQuery({
    resource: "organization",
    type: "getOne",
    payload: {
      id: organization,
    },
  });
  const { data: vehicleData } = useQuery({
    resource: "vehicle",
    type: "getOne",
    payload: {
      id: vehicleId,
    },
  });
  useEffect(() => {
    if (vehicleData) {
      const dataDocument = vehicleData as IVehicle;
      setVehicleDoc(dataDocument);
    }
  }, [vehicleData]);
  useEffect(() => {
    if (organizationData) {
      const dataDocument = organizationData as IOrganization;
      setOrganizationDoc(dataDocument);
    }
  }, [organizationData]);

  return (
    <Card
      style={{
        marginTop: 50,
      }}
    >
      <CardTitle size={"lg"}>Ajouter un nouveau trajet</CardTitle>
      <Create {...initProps} title=" ">
        <SimpleForm
          toolbar={
            <RouteCreateToolbar
              organization={organizationDoc}
              vehicleDeleted={vehicleDoc ? vehicleDoc.deleted : true}
              style={{
                position: "absolute",
                // background: "#f7f8fa",
                top: 60,
              }}
            />
          }
          validate={validateRouteCreation}
          save={save}
          className={classes.fullWidth}
        >
          <Grid container className={classes.fullWidth} spacing={4}>
            {vehicleDoc && vehicleDoc.deleted && (
              <Grid item sm={12}>
                <Alert variant="standard" severity="error">
                  {
                    "Le véhicule est déjà supprimé,vous ne pouvez pas créer de route avec."
                  }
                </Alert>
              </Grid>
            )}
            {organizationDoc && (
              <Grid item sm={12}>
                {organizationDoc?.approvalStatus !==
                  ApprovalStatus.Approved && (
                  <Alert variant="standard" severity="warning">
                    {
                      "Votre compte est en attente de la validation de weTrans. Même si vous pouvez ajoutez des trajets, vous ne pouvez pas accepter des expéditions."
                    }
                  </Alert>
                )}
              </Grid>
            )}
            <Grid item sm={6}>
              <CityInput
                className={classes.fullWidth}
                label="route.from"
                source="from"
                validate={[required()]}
              />
            </Grid>
            <Grid item sm={6}>
              <CityInput
                validate={[required()]}
                className={classes.fullWidth}
                label="route.to"
                source="to"
              />
            </Grid>
            <Grid item sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="medium"
                    checked={IsRegularRoute}
                    onChange={(e) => setIsRegularRoute(e.target.checked)}
                  />
                }
                label="Trajet régulier"
              />
            </Grid>
            <Grid item sm={6}>
              {!IsRegularRoute && <MultiDatePickers source="dates" />}
            </Grid>
            {IsRegularRoute && (
              <>
                <Grid item sm={6}>
                  <DaysPickerField source="operationWeekDays" />
                </Grid>
                <Grid item sm={6}></Grid>
              </>
            )}

            <Grid item sm={6}>
              <ArrayInput source="waypoints" label="Escales">
                <SimpleFormIterator
                  disableReordering
                  getItemLabel={(index) => `Ville ${index + 1}`}
                >
                  <CityInput
                    className={classes.fullWidth}
                    source=""
                    label=""
                    validate={[required()]}
                    reference="city"
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
            <Grid item sm={6}>
              {" "}
              <TimeInput
                validate={required()}
                source="departureDateTime"
                label="Heure de départ"
                defaultValue="08:00"
                className={classes.fullWidth}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 3600, // 1 hour
                }}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </Card>
  );
};
