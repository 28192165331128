import { WithAdminApproval } from "@components/common/WithAdminApproval";
import { dataProvider } from "@providers/provider";
import { AccountStatus, AdminRole, IAdmin, UserType } from "@wetrans/helpers";
import * as React from "react";
import { usePermissions } from "react-admin";
import AdminEdit from "./AdminEdit";
import { AdminEditApprovalToolbar } from "./AdminEditApprovalToolbar";
import AdminShow from "./AdminShow";

export default function AdminShowEdit(props: any) {
  const { permissions } = usePermissions();
  const checkIfTheAdminIsDeleteble = React.useCallback(
    async (record: IAdmin) => {
      try {
        const defaultFilter =
          permissions === AdminRole.super
            ? { role: AdminRole.super }
            : { type: UserType.admin, organization: record.organization };
        const { data: fetchedData } = await dataProvider.getList("users", {
          filter: defaultFilter,
          pagination: {
            page: 1,
            perPage: 5,
          },
        } as any);
        return fetchedData.length > 1;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    [permissions]
  );
  return (
    <WithAdminApproval
      {...props}
      warningMessage="admin.warningMessage"
      warningMessageTitle="admin.warningMessageTitle"
      adminApproveWarningMessage="admin.adminApproveWarningMessage"
      adminDisapproveWarningMessage="admin.adminDisapproveWarningMessage"
      enableDelete
      deleteConfirmationMessage="admin.deleteConfirmationMessage"
      isDeletable={checkIfTheAdminIsDeleteble}
      transformOnDelete={(record: IAdmin, deletedById: string) => {
        return {
          ...record,
          accountStatus: AccountStatus.suspended,
          deleted: true,
          deletedAt: new Date(),
          deletedBy: deletedById,
        };
      }}
      Toolbar={AdminEditApprovalToolbar}
      EditComponent={AdminEdit}
      ShowComponent={AdminShow}
    />
  );
}
