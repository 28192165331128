import React from "react";

export default function TrashExported(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.813"
      height="17"
      viewBox="0 0 13.813 17"
      {...props}
    >
      <defs>
        <style></style>
      </defs>
      <g id="trash" transform="translate(-48)" color="aqua">
        <path
          id="Tracé_2551"
          d="M60.484 2.125h-2.921v-.531A1.6 1.6 0 0 0 55.969 0h-2.125a1.6 1.6 0 0 0-1.594 1.594v.531h-2.922A1.33 1.33 0 0 0 48 3.453v1.86a.531.531 0 0 0 .531.531h.29l.459 9.638A1.592 1.592 0 0 0 50.872 17h8.068a1.592 1.592 0 0 0 1.592-1.518l.459-9.638h.29a.531.531 0 0 0 .531-.531v-1.86a1.33 1.33 0 0 0-1.328-1.328zm-7.172-.531a.532.532 0 0 1 .531-.531h2.125a.532.532 0 0 1 .531.531v.531h-3.186zm-4.25 1.859a.266.266 0 0 1 .266-.266h11.156a.266.266 0 0 1 .266.266v1.328H49.063zm10.409 11.979a.531.531 0 0 1-.531.506h-8.068a.531.531 0 0 1-.531-.506l-.457-9.588h10.043z"
          className="cls-1"
        />
        <path
          id="Tracé_2552"
          d="M240.531 215.969a.531.531 0 0 0 .531-.531v-6.906a.531.531 0 0 0-1.062 0v6.906a.531.531 0 0 0 .531.531z"
          className="cls-1"
          transform="translate(-185.625 -201.094)"
        />
        <path
          id="Tracé_2553"
          d="M320.531 215.969a.531.531 0 0 0 .531-.531v-6.906a.531.531 0 0 0-1.062 0v6.906a.531.531 0 0 0 .531.531z"
          className="cls-1"
          transform="translate(-262.969 -201.094)"
        />
        <path
          id="Tracé_2554"
          d="M160.531 215.969a.531.531 0 0 0 .531-.531v-6.906a.531.531 0 0 0-1.062 0v6.906a.531.531 0 0 0 .531.531z"
          className="cls-1"
          transform="translate(-108.281 -201.094)"
        />
      </g>
    </svg>
  );
}
