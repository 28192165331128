import React from "react";
import { Route, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import { DASHBOARD_TABS } from "./constants";

export const customRoutes = [
  <Route exact path="/" component={Dashboard}>
    <Redirect to="/overall" />
  </Route>,
  ...DASHBOARD_TABS.map((tab) => (
    <Route exact path={tab.path} component={Dashboard} />
  )),
];
