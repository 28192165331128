import React from "react";
import {
  Filter,
  // ReferenceInput,
  // AutocompleteInput,
  usePermissions,
} from "react-admin";

// styles
import { primaryColor } from "@theme/colors";
import useStyles from "./RouteFiltersStyles";
import classnames from "classnames";
import { RouteFrequencyInput } from "@constants/routeFrequency";

// import { withStyles } from "@material-ui/styles";
// import { useOrganization } from "@hooks/useOrganization";
import { AdminRole } from "@wetrans/helpers";
import CityInput from "@components/common/CityInput/CityInput";
import OrganizationInput from "@components/common/OrganizationInput/OrganizationInput";
import VehicleCategoryInput from "@components/common/vehicleCategoriesSelecter/VehicleCategoryInput";
// import { TimeInput } from "@components/common/TimeInput/TimeInput";

// const AutocompleteInputMod = withStyles({
//   suggestionsContainer: {
//     "& [class*='root']": {
//       backgroundColor: "white",
//       maxWidth: 400,
//       whiteSpace: "normal",
//       boxShadow: "rgb(0 0 0 / 8%) 0px 2px 5px 0px",
//     },
//   },
// })(AutocompleteInput);
const RouteFilters = (props: any) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  // const { organization } = useOrganization();
  return (
    <Filter
      {...props}
      style={{
        background: primaryColor,
        borderRadius: 4,
        marginRight: 20,
      }}
      className={classnames(classes.formRoot, "filters")}
    >
      {/* <TextInput
        label="route.id"
        resettable
        source="id"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <TextInput
        label="route.createdAt"
        resettable
        source="createdAt"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      />
      <DateInput
        label="route.date"
        resettable
        source="date"
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      /> */}
      <RouteFrequencyInput label="route.frequency" source="frequency" />
      {/* <ReferenceInput
        label="route.vehicle"
        source="vehicle"
        reference="vehicle"
        filter={organization ? { organization: organization } : {}}
      >
        <AutocompleteInputMod optionText="matricule" />
      </ReferenceInput> */}
      {/* <TimeInput
        source="departureDateTime"
        label="Heure de départ"
        // InputLabelProps={{
        //   shrink: true,
        // }}
        inputProps={{
          step: 3600, // 1 hour
        }}
        InputProps={{ disableUnderline: true }}
        hiddenLabel={true}
        focused
        disableUnderline
      /> */}
      {permissions === AdminRole.super && (
        <OrganizationInput label="route.organization" source="organization" />
      )}

      <CityInput label="route.from" source="from" />
      <CityInput label="route.to" source="to" />
      <VehicleCategoryInput
        source="vehicleCategory"
        label="vehicle.category"
        allowEmpty={false}
      />
    </Filter>
  );
};

export default RouteFilters;
