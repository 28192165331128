import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { isEmpty } from "lodash";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  useListContext,
  useTranslate,
} from "react-admin";
import { tableRowStyle } from "@theme/styles/table";
import Loader from "./Loader";

const useStyles = makeStyles(
  {
    noData: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",
      fontWeight: "bold",
      justifyContent: "center",
      paddingTop: "20px",
      fontSize: 16,
    },
  },
  { name: "GRM" }
);

export interface NoDataProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  grid?: string[];
  size?: "small" | "medium";
  hidePagination?: boolean;
  label?: string;
}

const NoData = ({
  children,
  className,
  grid,
  label,
  ...props
}: NoDataProps) => {
  const classes = useStyles();
  const { loading, ids: data } = useListContext();
  const t = useTranslate();
  const noData: any[] = [];
  return (
    <>
      {isEmpty(data) && (
        <>
          {isEmpty(grid) && (
            <>
              {loading && <Loader />}
              {!loading && (
                <div
                  className={classNames(classes.noData, className)}
                  {...props}
                >
                  {label ? label : t("general.noData")}
                </div>
              )}
            </>
          )}
          {!isEmpty(grid) && (
            <ListContextProvider
              value={{
                data: noData,
                empty: false,
                ids: noData?.map(({ id }: any) => id),
                currentSort: { field: "id", order: "DESC" },
              }}
            >
              <Datagrid empty={undefined} rowStyle={tableRowStyle}>
                {grid?.map((field) => {
                  return (
                    <FunctionField
                      label={field}
                      render={(record: any) => null}
                    />
                  );
                })}
              </Datagrid>
              {loading && <Loader />}
              {!loading && (
                <div
                  className={classNames(classes.noData, className)}
                  {...props}
                >
                  {label ? label : t("general.noData")}
                </div>
              )}
            </ListContextProvider>
          )}
        </>
      )}
    </>
  );
};

export default NoData;
