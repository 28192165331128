import keyMirror from "keymirror";

const actionsTypes = keyMirror({
  SET_VehicleCategories: null,
  SET_SETTINGS: null,
  SET_Organization: null,
  SET_MerchandiseCategories: null,
  SET_VehicleCategoryGroups: null,
  SET_CITIES: null,
  NOTIFICATIONS_CENTRAL: null,
  CLEAR_NOTIFICATIONS_CENTRAL: null,
  MENU_STATE: null,
  SET_ANALYTICS_DATE_FILTERS: null,
});

export default actionsTypes;
