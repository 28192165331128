export const getTimeDiffObject = (
  date1: Date,
  date2: Date
): { days: number; hours: number; minutes: number } => {
  const difference = Math.abs(date1.getTime() - date2.getTime());
  let daysCount = 24 * 60 * 60 * 1000,
    hoursCount = 60 * 60 * 1000,
    days = Math.floor(difference / daysCount),
    hours = Math.floor((difference - days * daysCount) / hoursCount),
    minutes = Math.round(
      (difference - days * daysCount - hours * hoursCount) / 60000
    );
  if (minutes === 60) {
    hours++;
    minutes = 0;
  }
  if (hours === 24) {
    days++;
    hours = 0;
  }
  return { days, hours, minutes };
};

export const getTimeDiffString = (date1: Date, date2: Date) => {
  // const leftPadWithZero = (n: number) => n > 10 ? `${n}` : `0${n}`
  let diff = getTimeDiffObject(date1, date2);
  const daysString = diff.days ? `${diff.days} jours` : "";
  const hoursString = diff.hours ? `${diff.hours} heures` : "";
  const minutesString = diff.minutes ? `${diff.minutes} minutes` : "";
  return [daysString, hoursString, minutesString]
    .filter((str) => str !== "")
    .join(", ");
};
