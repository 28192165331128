import { getPrice } from "@components/expedition/ExpeditionShow";
import { getDay, getLocalDate, getLocalTime } from "@utils/dateHelper";

export const transformDate = (
  data: any[],
  type: "today" | "week" | "month" | "all" | string
): any[] => {
  let newData: any[] = data;
  // if (type !== "today") {
  //   for (let i = 0; i < data.length; i++) {
  //     const item = data[i];
  //     const lastIndex = data.lastIndexOf(
  //       (element: any) => getLocalDate(element.date) === getLocalDate(item.date)
  //     );
  //     if (lastIndex < 0 || lastIndex === i) {
  //       newData.push(item);
  //     } else {
  //       i = lastIndex - 1;
  //     }
  //   }
  // } else {
  //   newData = data;
  // }
  newData = newData.map((item) => {
    const date = new Date(item.date);

    const dateFormat =
      type === "today"
        ? `${getLocalTime(date)}`
        : type === "week"
        ? `${getDay(date)}`
        : `${getLocalDate(date)}`;

    return { ...item, date: dateFormat };
  });
  const finalData: any[] = [];
  newData.forEach((item) => {
    const index = finalData.findIndex((it) => item.date === it.date);
    if (item.turnover) {
      item.turnover = getPrice(item.turnover as string);
    }
    if (index < 0) finalData.push(item);
    else {
      finalData.splice(index, 1);
      finalData.push(item);
    }
  });
  return finalData;
};
