import React from "react";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useInput } from "react-admin";
import DateFnsUtils from "@date-io/date-fns";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export const TimeInput = (props: any) => {
  const {
    input: { onChange, value },
  } = useInput(props);

  const handleChange = React.useCallback(
    (date: MaterialUiPickersDate, stringValue: string) => {
      onChange(stringValue);
    },
    [onChange]
  );
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        {...props}
        variant="inline"
        format={"HH:mm"}
        ampm={false}
        inputValue={value}
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  );
};
