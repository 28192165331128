import { WithAdminApproval } from "@components/common/WithAdminApproval";
import * as React from "react";
import OrganizationEdit from "./OrganizationEdit";
import OrganizationShow from "./OrganizationShow";

export default function OrganizationShowEdit(props: any) {
  return (
    <WithAdminApproval
      {...props}
      warningMessage="organization.warningMessage"
      warningMessageTitle="organization.warningMessageTitle"
      adminApproveWarningMessage="organization.adminApproveWarningMessage"
      adminDisapproveWarningMessage="organization.adminDisapproveWarningMessage"
      EditComponent={OrganizationEdit}
      ShowComponent={OrganizationShow}
      // disableRedirect={false}
    />
  );
}
