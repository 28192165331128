import { WithAdminApproval } from "@components/common/WithAdminApproval";
import * as React from "react";
import { AdminActivateClientAccountToolbar } from "./AdminActivateClientAccountToolbar";
import { ClientShow } from "./ClientShow";

export default function ClientShowEdit(props: any) {
  return (
    <WithAdminApproval
      {...props}
      adminApproveWarningMessage="client.adminActivateWarningMessage"
      adminDisapproveWarningMessage="client.adminDeactivateWarningMessage"
      Toolbar={AdminActivateClientAccountToolbar}
      EditComponent={ClientShow}
      ShowComponent={ClientShow}
    />
  );
}
