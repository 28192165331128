import * as React from "react";

import {
  GoogleMap,
  // DirectionsService,
  // DirectionsRenderer,
  Marker,
  Polyline,
} from "@react-google-maps/api";

import marker from "@assets/vectors/marker.svg";

import truckMarker from "@assets/vectors/truck-marker.svg";
const decodeGMapsPolylinePolyline = require("decode-google-map-polyline");

const containerStyle = {
  width: "100%",
  height: "300px",
};

export type TCoordinates = { lat: number; lng: number };
export type TCoordinatesState = { lat: number; lng: number } | null;

export interface IDirectionMapProps {
  startPointPos: TCoordinatesState;
  endPointPos: TCoordinatesState;
  currentPos?: TCoordinatesState;
  encodedPolyline?: string;
}

// const startPointPos = { lat: 33.971588, lng: -6.849813 };
// const endPointPos = { lat: 34.31028, lng: -6.3083 };
// const currentPos = { lat: 34.22982, lng: -6.574491 };

function DirectionMap(props: IDirectionMapProps) {
  const { startPointPos, endPointPos, currentPos, encodedPolyline } = props;
  const defaultMapPosition: TCoordinates = {
    lat: 33.9715904,
    lng: -6.8498129,
  };
  const [mapZoom] = React.useState<number>(10);

  // const [
  //   direcTionServiceResponse,
  //   setDirecTionServiceResponse,
  // ] = React.useState<any>(null);

  // const directionServiceCallback = React.useCallback((response: any) => {
  //   if (response !== null) {
  //     if (response.status === "OK") {
  //       setDirecTionServiceResponse(response);
  //     } else {
  //       console.log("response: ", response);
  //     }
  //   }
  // }, []);

  // const options = React.useMemo(
  //   () => ({
  // destination: `${startPointPos?.lat},${startPointPos?.lng}`,
  // origin: `${endPointPos?.lat},${endPointPos?.lng}`,
  // travelMode: "DRIVING" as google.maps.TravelMode.DRIVING,
  //   }),
  //   [endPointPos, startPointPos]
  // );
  // const directionsService = new DirectionsService({
  //   options: options,
  //   callback: (result) => {},
  // });
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={currentPos || startPointPos || defaultMapPosition}
      zoom={mapZoom}
      // onClick={(position) => {
      //   console.log(position.latLng?.lat(), "lat");
      //   console.log(position.latLng?.lng(), "lng");
      // }}
      options={{
        scrollwheel: true,
        streetViewControl: false,
        mapTypeControl: false,
      }}
    >
      {currentPos && <Marker position={currentPos} icon={truckMarker} />}
      {startPointPos && <Marker position={startPointPos} icon={marker} />}
      {endPointPos && <Marker position={endPointPos} icon={marker} />}

      {encodedPolyline && (
        <Polyline
          options={{
            path: decodeGMapsPolylinePolyline(encodedPolyline),
            strokeColor: "#319BFB",
            strokeOpacity: 0.7,
            strokeWeight: 6,
            // icons: [
            //   {
            //     offset: "0",
            //     repeat: "10px",
            //   },
            //   {
            //     offset: "0",
            //     repeat: "10px",
            //   },
            // ],
          }}
        />
      )}
      {/* {startPointPos && startPointPos && (
        <DirectionsService
          // required
          options={options}
          callback={directionServiceCallback}
        />
      )} */}
    </GoogleMap>
  );
}
export const decodePolyline = (encoded: string): any[] => {
  return decodeGMapsPolylinePolyline(encoded).map(
    ({ lat, lng }: { lat: number; lng: number }) => {
      return { latitude: lat, longitude: lng };
    }
  );
};

export default React.memo(DirectionMap);
