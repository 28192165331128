import { analyticsDateFiltersSelector } from "@state/selectors";
import React, { FC } from "react";
import { TextInputProps, useInput } from "react-admin";
import { useSelector } from "react-redux";
import Select from "react-select";
import styled from "styled-components";

// styles
import "./styles.css";

// styled
const DateFilterContainer = styled.div`
  position: relative;
`;

const MonthFilter: FC<TextInputProps> = ({
  label,
  format,
  helperText,
  onBlur,
  onFocus,
  onChange,
  options,
  parse,
  resource,
  source,
  validate,
  ...rest
}) => {
  const { input } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: "text",
    validate,
    ...rest,
  });
  const handleSelectChange = (option: any, actionMeta: any) => {
    input.onChange(option.value);
  };
  const months: monthFilterOptions[] = useSelector<any, any>(
    analyticsDateFiltersSelector
  );

  const getSelectOptions = () =>
    months.map((month) => {
      return { value: month.id, label: month.label };
    });

  // console.log(input.value);

  const PlaceHolder = () => <>Date filter</>;

  return (
    <DateFilterContainer>
      <Select
        options={getSelectOptions()}
        placeholder={<PlaceHolder />}
        onChange={handleSelectChange}
        className="date-filter-container"
        classNamePrefix="date-filter"
        isSearchable={false}
        value={getSelectOptions().find((opt) => opt.value === input.value)}
        // menuIsOpen={true}
      />
    </DateFilterContainer>
  );
};
interface monthFilterOptions {
  id: string;
  label: string;
}
export default MonthFilter;
