import React from "react";
import { Redirect } from "react-router-dom";

export default {
  name: "client",
  basePath: "/client",
  resource: "client",
};

export const customRoutes = [
  <Redirect exact from="/client/:id/show" to="/client/:id/show/info" />,
];
