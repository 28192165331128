import React from "react";
import { useTranslate } from "react-admin";
import { approvalStatus } from "./approvalStatus";
import "./style.css";

export const ApprovalStatusField = ({ value }: { value: string }) => {
  const item = approvalStatus.find((i) => i.id === value);
  const t = useTranslate();
  if (item)
    return (
      <div
        className="listApprovalStatus"
        style={{
          background: item?.color,
        }}
      >
        {t(item.label)}
      </div>
    );
  else return null;
};
