import React from "react";
import { Admin, Resource, defaultTheme } from "react-admin";
import "./App.css";
import { createMuiTheme } from "@material-ui/core/styles";
import Dashboard from "@components/dashboard";
import { AdminRole } from "@wetrans/helpers";
// generator: import files
import OrganizationList from "@components/organization/OrganizationList";
// import CityList from "@components/city/CityList";
// import CityEdit from "@components/city/CityEdit";
// import CityCreate from "@components/city/CityCreate";
import AdminList from "@components/admin/AdminList";
import AdminCreate from "@components/admin/AdminCreate";
import VehicleList from "@components/vehicle/VehicleList";
import VehicleEdit from "@components/vehicle/Edit/VehicleEdit";
import VehicleCreate from "@components/vehicle/VehicleCreate";
import DriverList from "@components/driver/DriverList";
import DriverCreate from "@components/driver/DriverCreate";
import DriverShowEdit from "@components/driver/DriverShowEdit";

// import ClientList from "@components/client/ClientList";
// import ClientEdit from "@components/client/ClientEdit";
// import CategoryList from "@components/category/CategoryList";
// import CategoryEdit from "@components/category/CategoryEdit";
// import CategoryCreate from "@components/category/CategoryCreate";
import RouteList from "@components/route/RouteList";
// import RouteEdit from "@components/route/RouteEdit";
// import RouteCreate from "@components/route/RouteCreate";
import ExpeditionList from "@components/expedition/ExpeditionList";
import ExpeditionShow from "@components/expedition/ExpeditionShow";
// endgenerator
import DialogProvider from "./providers/DialogProvider";
import { dataProvider, authProvider } from "@providers/provider";
import CustomReducer from "@state/publicInfo";
import MyLayout from "./layout/Layout";
import { merge } from "lodash";
import customTheme from "./theme";
import TruckIcon from "@assets/vectors/truck";

import i18nProvider from "./i18n";
import CustomRoutes from "./CustomRoutes";
import LoginPage from "@components/auth";
import { FormValidator } from "./components/common/FormValidator";
import Settings from "@components/settings/Settings";
import RouteExported from "@assets/vectors/RouteExported";
import ClientExported from "@assets/vectors/ClientExported";
import TurnoverExported from "@assets/vectors/TurnoverExported";
import SettingsFetcher from "@components/common/settingsFetcher/SettingsFetcher";
import { NotificationListener } from "@components/notification/NotificationListener";
import { DisableAccountListener } from "@components/disableAccount/DisableAccountListener";
import { RouteDetail } from "@components/route/RouteDetail";
import AnalyticsDateFiltersFetcher from "@components/common/AnalyticsDateFiltersFetcher";
import OptimizedCitiesFetcher from "@components/common/CitiesFetcher/OptimizedCitiesFetcher";
import ClientList from "@components/client/ClientList";
import ClientDetail from "@components/client/ClientDetail";
import { RoutesCreate } from "@components/route/RouteCreate";
import { TractorCreate, TractorEdit, TractorList } from "@components/tractor";
import OrganizationShowEdit from "@components/organization/OrganizationShowEdit";
import AdminShowEdit from "@components/admin/AdminShowEdit";
const theme = createMuiTheme(merge({}, defaultTheme, customTheme));
const App = () => {
  return (
    <DialogProvider>
      <FormValidator>
        <Admin
          title="Wetrans dashboard Panel"
          layout={MyLayout}
          theme={theme}
          dashboard={Dashboard}
          locale="fr"
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
          loginPage={LoginPage}
          authProvider={authProvider}
          customReducers={{ customReducer: CustomReducer }}
          disableTelemetry
          customRoutes={CustomRoutes}
        >
          {(role) => [
            /* generator: import resource */
            <AnalyticsDateFiltersFetcher />,
            <OptimizedCitiesFetcher />,
            <Resource
              name="expedition"
              list={ExpeditionList}
              show={ExpeditionShow}
              icon={TurnoverExported}
              options={{ label: "expedition.expeditions" }}
            />,
            <Resource
              name="routeTemplates"
              list={RouteList}
              create={RoutesCreate}
              icon={RouteExported}
              show={RouteDetail}
              // create={RouteCreate}
              options={{ label: "route.routes" }}
            />,
            <Resource
              name="vehicle"
              list={VehicleList}
              show={VehicleEdit}
              icon={TruckIcon}
              create={role !== AdminRole.super ? VehicleCreate : undefined}
              options={{ label: "organization.fleetManagement" }}
            />,
            <Resource
              name="tractor"
              list={TractorList}
              show={TractorEdit}
              icon={TruckIcon}
              create={role !== AdminRole.super ? TractorCreate : undefined}
              options={{ label: "organization.tractorManagement" }}
            />,
            <Resource
              name="driver"
              list={DriverList}
              show={DriverShowEdit}
              icon={ClientExported}
              create={role !== AdminRole.super ? DriverCreate : undefined}
              options={{ label: "driver.drivers" }}
            />,
            <Resource
              name="users"
              list={
                role === AdminRole.super || role === AdminRole.operation
                  ? AdminList
                  : undefined
              }
              show={
                role === AdminRole.super || role === AdminRole.operation
                  ? AdminShowEdit
                  : undefined
              }
              create={
                role === AdminRole.super || role === AdminRole.operation
                  ? AdminCreate
                  : undefined
              }
              options={{ label: "admin.admins" }}
              icon={ClientExported}
            />,
            <Resource
              name="client"
              list={role === AdminRole.super ? ClientList : undefined}
              show={role === AdminRole.super ? ClientDetail : undefined}
              icon={ClientExported}
              options={{ label: "client.clients" }}
            />,
            <Resource
              name="organization"
              list={role === AdminRole.super ? OrganizationList : undefined}
              show={OrganizationShowEdit}
              options={{ label: "organization.organizations" }}
            />,

            <Resource name="route" />,

            // <Resource
            //   name="client"
            //   list={role === AdminRole.super ? ClientList : undefined}
            //   show={role === AdminRole.super ? ClientEdit : undefined}
            //   options={{ label: "client.clients" }}
            // />,
            // <Resource
            //   name="category"
            //   // list={role === AdminRole.super ? CategoryList : undefined}
            //   // show={role === AdminRole.super ? CategoryEdit : undefined}
            //   // create={role === AdminRole.super ? CategoryCreate : undefined}
            //   // options={{ label: "category.categorys" }}
            // />,
            <Resource
              name="city"
              // list={role === AdminRole.super ? CityList : undefined}
              // show={role === AdminRole.super ? CityEdit : undefined}
              // create={CityCreate}
              // options={{ label: "city.cities" }}
            />,
            <Resource name="analyticsFilters" />,
            <Resource name="analytics" />,
            <Resource
              name="settings"
              edit={role === AdminRole.super ? Settings : undefined}
              options={{ label: "Paramètres" }}
            />,

            /* endgenerator */
            <SettingsFetcher />,
            <NotificationListener />,
            <DisableAccountListener />,
          ]}
        </Admin>
      </FormValidator>
    </DialogProvider>
  );
};

export default App;
