export const handleDisplayNum = (num, ifMoney, type) => {
  let displayNum = {};
  let player = {};

  displayNum.suffixes = ["K", "M", "B", "T", "Qa"];

  if (player.sciNotation)
    return Math.abs(num) < 100000
      ? ifMoney
        ? parseFloat(num).toFixed(1)
        : num
      : parseFloat(num).toPrecision(5);

  for (let i = displayNum.suffixes.length - 1; i >= 0; i--) {
    if (Math.abs(num) >= Math.pow(10, 3 * i + 3) * 0.99999) {
      return type === "count"
        ? i < 4
          ? parseFloat(num / Math.pow(10, 3 * i + 3)).toFixed(0) +
            displayNum.suffixes[i]
          : parseFloat(num / Math.pow(10, 3 * i + 3)).toFixed(0) +
            " " +
            displayNum.suffixes[i]
        : type === "decimal"
        ? i < 4
          ? parseFloat(num / Math.pow(10, 3 * i + 3)).toFixed(0) +
            displayNum.suffixes[i]
          : parseFloat(num / Math.pow(10, 3 * i + 3)).toFixed(0) +
            " " +
            displayNum.suffixes[i]
        : i < 4
        ? parseFloat(num / Math.pow(10, 3 * i + 3)).toFixed(2) +
          displayNum.suffixes[i]
        : parseFloat(num / Math.pow(10, 3 * i + 3)).toFixed(2) +
          " " +
          displayNum.suffixes[i];
    }
  }

  return ifMoney
    ? parseFloat(num).toFixed(2)
    : type === "count"
    ? num.toFixed(0)
    : type === "decimal"
    ? num.toFixed(0)
    : num.toFixed(2);
};
