// in src/Menu.js
import * as React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { makeStyles, Paper, Theme, useMediaQuery } from "@material-ui/core";
import {
  MenuItemLink,
  getResources,
  useTranslate,
  usePermissions,
} from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH } from "./Ra-Sidebar";
import WHITELOGO from "@assets/vectors/whiteLogo";
import SMALLLOGO from "@assets/vectors/smallLogo";

import "./styles.css";
import DatshboadExported from "@assets/vectors/DatshboadExported";
import { useLocation } from "react-router-dom";
import { primaryColor, secondaryTextColor } from "@theme/colors";
import { sideBarMenuItemPadding, GoogleSansMedium } from "@theme/theme";
import { DASHBOARD_TABS } from "@components/dashboard";

/* generator: import settings icon*/
import SettingsIcon from "@assets/vectors/Settings";
import { AdminRole } from "@wetrans/helpers";
import { useOrganization } from "@hooks/useOrganization";
import SupportAgentIcon from "@assets/vectors/SupportAgentIcon";
/* endgenerator */

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// import { appVersion } from "../config";
// const useStyles = makeStyles((theme) => ({
//   active: {
//     color: `${primaryColor}aa`,
//     backgroundColor: `${primaryColor}!important`,
//     "& svg": {
//       fill: `white`,
//     },
//   },
//   footer: {
//     fontSize: "0.9rem",
//     color: "rgba(0, 0, 0, 0.54)",
//     paddingBottom: "0.3 rem",
//   },
//   menuItem: {
//     fontSize: 17,
//   },
// }));

const useLinkStyles = makeStyles(
  {
    root: {
      color: secondaryTextColor,
      padding: sideBarMenuItemPadding,
      fontFamily: GoogleSansMedium,
      width: "85%",
      borderRadius: "0 7px 7px 0",
      "& svg": {
        maxWidth: 17,
        fill: secondaryTextColor,
      },
    },
    active: {
      color: "#fff",
      backgroundColor: primaryColor,
      "& svg": {
        fill: "#fff",
      },
      "&:hover": {
        color: "#fff",
        backgroundColor: primaryColor,
      },
    },
    icon: {
      minWidth: 28,
    },
  },
  { name: "MainMenu" }
);

const Menu = (props: any) => {
  const { onMenuClick, logout, hasDashboard, dense } = props;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const { organization } = useOrganization();
  const getDashBoardPath = () => {
    const path = _.get(
      _.find(DASHBOARD_TABS, ({ path }) => window.location.href.includes(path)),
      "path"
    );

    return path ? path : "/";
  };
  const open = useSelector<any>(
    (state) => state.admin.ui.sidebarOpen
  ) as boolean;
  const resources = useSelector(getResources);
  // const classes = useStyles();
  const menuLinkItemClasses = useLinkStyles(props);
  const { permissions } = usePermissions();

  let t = useTranslate();

  return (
    <Paper
      square
      elevation={3}
      className="paperContainer"
      style={{
        width: open ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH,
        position: "fixed",
        height: "100%",
        top: 0,
        background: "white",
        overflow: "auto",
      }}
      // style={{
      //   width: open ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH,
      //   position: 'fixed',
      //   height: '100%',
      //   top: 0,
      //   background: primaryColor,
      //   overflow: 'auto',
      // }}
    >
      <div
        className="logoContainer"
        style={{
          background: primaryColor,
          padding: "10px 0",
        }}
      >
        {open ? (
          <img
            src={WHITELOGO}
            alt=""
            style={{ width: "60%", paddingTop: "6px", paddingBottom: "6px" }}
          />
        ) : (
          <img
            src={SMALLLOGO}
            alt=""
            style={{ width: "40%", paddingTop: "6px", paddingBottom: "6px" }}
          />
        )}
      </div>
      <div
        style={{
          background: "white", //container of sidebar elements
        }}
      >
        {hasDashboard && (
          <MenuItemLink
            to={getDashBoardPath()}
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
            // activeClassName={classes.active}
            classes={menuLinkItemClasses}
            primaryText={open ? t("general.dashborad") : ""}
            style={{
              display: "flex",
              // color: 'white',
              justifyContent: open ? "flexStart" : "center",
              alignItems: "center",
              paddingLeft: open ? 20 : 30,
              paddingTop: 15,
              paddingBottom: 15,
            }}
            leftIcon={<DatshboadExported />}
            exact
          />
        )}

        {resources
          .filter((r) => r.hasList)
          .map((resource) => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                open &&
                ((resource.options && t(resource.options.label)) ||
                  resource.name)
              }
              leftIcon={
                resource.icon ? (
                  <resource.icon
                    style={{
                      width: 22,
                    }}
                  />
                ) : (
                  <DefaultIcon />
                )
              }
              onClick={onMenuClick}
              sidebarIsOpen={open}
              classes={menuLinkItemClasses}
              // activeClassName={classes.active}
              // className={classes.menuItem}
              style={{
                paddingTop: 15,
                // color: 'white',
                paddingBottom: 15,
                display: "flex",
                justifyContent: open ? "flexStart" : "center",
                alignItems: "center",
                paddingLeft: open ? 20 : 30,
              }}
            />
          ))}
        {/* generator: add settings */}
        <MenuItemLink
          to="/support"
          primaryText={open ? "Support" : ""}
          classes={menuLinkItemClasses}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          leftIcon={<SupportAgentIcon />}
          style={{
            paddingTop: 15,
            paddingBottom: 15,
            display: "flex",
            justifyContent: open ? "flexStart" : "center",
            alignItems: "center",
            paddingLeft: open ? 20 : 30,
          }}
        />
        {permissions !== AdminRole.super && organization && (
          <MenuItemLink
            key="settings"
            to={`/organization/${organization}/show`}
            primaryText={open ? "Profil" : ""}
            leftIcon={<SettingsIcon />}
            // leftIcon={
            //   (<SettingsIcon style={{
            //     width: 22,
            //   }}/>)
            // }
            onClick={onMenuClick}
            sidebarIsOpen={open}
            classes={menuLinkItemClasses}
            // activeClassName={classes.active}
            // className={classes.menuItem}
            style={{
              paddingTop: 15,
              // color: 'white',
              paddingBottom: 15,
              display: "flex",
              justifyContent: open ? "flexStart" : "center",
              alignItems: "center",
              paddingLeft: open ? 20 : 30,
            }}
          />
        )}
        {permissions === AdminRole.super && (
          <MenuItemLink
            key="settings"
            to={`/settings/settings/general`}
            primaryText={open ? "Paramètres" : ""}
            leftIcon={<SettingsIcon />}
            // leftIcon={
            //   (<SettingsIcon style={{
            //     width: 22,
            //   }}/>)
            // }
            onClick={onMenuClick}
            sidebarIsOpen={open}
            classes={menuLinkItemClasses}
            // activeClassName={classes.active}
            // className={classes.menuItem}
            style={{
              paddingTop: 15,
              // color: 'white',
              paddingBottom: 15,
              display: "flex",
              justifyContent: open ? "flexStart" : "center",
              alignItems: "center",
              paddingLeft: open ? 20 : 30,
            }}
          />
        )}
      </div>
      {/* endgenerator */}
      {isXSmall && logout}
    </Paper>
  );
};
export default Menu;
