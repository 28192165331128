import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TopToolbar,
  useListContext,
  CreateButton,
  // ExportButton,
  sanitizeListRestProps,
  ReferenceField,
  TextField,
  usePermissions,
  // @ts-ignore
} from "react-admin";
import {
  //  Link,
  makeStyles,
} from "@material-ui/core";

// components
// import ExportExported from "@assets/vectors/ExportExported";
import VehicleFilters from "./TractorFilters";
import StickyTableCell from "@components/common/StickyTableCell";
import DotsMenu from "@components/common/DotsMenu/DotsMenu";

import { isEmpty } from "lodash";
import { NoDataTable } from "@components/common/NoDataTable";
// styles
import { primaryColor } from "@theme/colors";
import "./styles.css";
import { tableRowStyle } from "@theme/styles/table";
import { AdminRole } from "@wetrans/helpers";
import { useOrganization } from "@hooks/useOrganization";
import { RaPrevNextPagination } from "@components/common/pagination/RaPrevNextPagination";
import { ApprovalStatusField } from "@constants/approvalStatus";
// import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    height: 84,
    width: 10,
  },
}));
const useListStyles = makeStyles({
  content: {
    padding: 0,
    background: "white",
  },
});
const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    // total,
  } = useListContext();
  const { permissions } = usePermissions();

  return (
    <TopToolbar className="topTools" {...sanitizeListRestProps(rest)}>
      {permissions !== AdminRole.super && (
        <CreateButton
          basePath={basePath}
          className="createButtonAction"
          style={{
            background: primaryColor,
          }}
          label="ra.action.add"
        />
      )}

      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};
const TractorList = (props: any) => {
  const listClasses = useListStyles();
  const { permissions } = usePermissions();
  const { organization } = useOrganization();
  const permanentFilter: any = {};
  if (organization && permissions !== AdminRole.super)
    permanentFilter.organization = organization;
  return (
    <div className="container">
      <List
        {...props}
        empty={false}
        actions={<ListActions />}
        filters={<VehicleFilters />}
        filter={permanentFilter}
        classes={listClasses}
        pagination={<RaPrevNextPagination />}
        title="tractor.tractors"
        sort={{ field: "createdAt", order: "desc" }}
      >
        <div className="list-container">
          <CustomersDataGrid />
          {permissions === AdminRole.super ? (
            <NoDataTable grid={["vehicle.matricule", "vehicle.organization"]} />
          ) : (
            <NoDataTable grid={["vehicle.matricule"]} />
          )}
        </div>
      </List>
    </div>
  );
};

const CustomersDataGrid = (props: any) => {
  const { data, loaded } = useListContext();
  const classes = useStyles();
  // const refresh = useRefresh();
  // const [deleteVehicle] = useMutation();
  const { permissions } = usePermissions();
  // const history = useHistory();
  return (
    <>
      {loaded && !isEmpty(data) && (
        <Datagrid rowClick="show" rowStyle={tableRowStyle} classes={classes}>
          <FunctionField
            render={(props: any) => {
              return <div className="listField">{props.matricule}</div>;
            }}
            sortable={false}
            source="matricule"
            label="vehicle.matricule"
          />
          <FunctionField
            sortable={false}
            render={(props: any) => {
              return <ApprovalStatusField value={props.approvalStatus} />;
            }}
            label="general.approvalStatus"
          />
          {permissions === AdminRole.super ? (
            <ReferenceField
              link="show"
              label="vehicle.organization"
              source="organization"
              reference="organization"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
          ) : null}

          <StickyTableCell>
            <FunctionField
              render={(props: any) => {
                return (
                  <DotsMenu
                    record={props}
                    // delete={props.status === "active"}
                    //delete
                    edit

                    // deleteAction={() => {
                    //   deleteVehicle({
                    //     type: "delete",
                    //     resource: "vehicle",
                    //     payload: {
                    //       id: props.id,
                    //     },
                    //   });
                    //   refresh();
                    // }}
                  />
                );
              }}
            />
          </StickyTableCell>
        </Datagrid>
      )}
    </>
  );
};

export default TractorList;
