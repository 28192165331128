import { ExpeditionStatus } from "@wetrans/helpers";

export const expeditionStatus = [
  {
    id: ExpeditionStatus.expired,
    label: "expeditionStatus.expired",
    color: "#5fd997",
  },
  {
    id: ExpeditionStatus.cancelled,
    label: "expeditionStatus.cancelled",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.pending,
    label: "expeditionStatus.pending",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.booked,
    label: "expeditionStatus.booked",
    color: "#5fd997",
  },
  {
    id: ExpeditionStatus.validated,
    label: "expeditionStatus.validated",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.started,
    label: "expeditionStatus.started",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.arrivedAtSourceLocation,
    label: "expeditionStatus.arrivedAtSourceLocation",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.loading,
    label: "expeditionStatus.loading",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.inRoute,
    label: "expeditionStatus.inRoute",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.arrivedAtDestinationLocation,
    label: "expeditionStatus.arrivedAtDestinationLocation",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.unloading,
    label: "expeditionStatus.unloading",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.delivered,
    label: "expeditionStatus.delivered",
    color: "#5fd997",
  },

  {
    id: ExpeditionStatus.confirmed,
    label: "expeditionStatus.confirmed",
    color: "#5fd997",
  },
];
