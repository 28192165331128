import React from "react";

export default function ClientExported(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0.124809 0.499923 33.4998 33.5"
    >
      <g>
        <path
          stroke="none"
          paint-order="stroke fill markers"
          fill-rule="evenodd"
          d=" M 16.8746976852417 19.34373241662979 C 22.076177775859833 19.34373241662979 26.29643601179123 15.123472154140472 26.29643601179123 9.921827912330627 C 26.29643601179123 4.7201836705207825 22.076177775859833 0.4999234080314636 16.8746976852417 0.4999234080314636 C 11.673217594623566 0.4999234080314636 7.452959358692169 4.7201836705207825 7.452959358692169 9.921827912330627 C 7.452959358692169 15.123472154140472 11.673217594623566 19.34373241662979 16.8746976852417 19.34373241662979 Z M 25.249641835689545 21.437488973140717 C 25.249641835689545 21.437488973140717 21.64434564113617 21.437488973140717 21.64434564113617 21.437488973140717 C 20.191711962223053 22.104798018932343 18.57603359222412 22.48436725139618 16.8746976852417 22.48436725139618 C 15.173361778259277 22.48436725139618 13.564181566238403 22.104798018932343 12.105049729347229 21.437488973140717 C 12.105049729347229 21.437488973140717 8.499753534793854 21.437488973140717 8.499753534793854 21.437488973140717 C 3.8736557960510254 21.437488973140717 0.1248093843460083 25.18657958507538 0.1248093843460083 29.812400698661804 C 0.1248093843460083 29.812400698661804 0.1248093843460083 30.859278976917267 0.1248093843460083 30.859278976917267 C 0.1248093843460083 32.59316039085388 1.5313652157783508 33.999913811683655 3.265191912651062 33.999913811683655 C 3.265191912651062 33.999913811683655 30.484203457832336 33.999913811683655 30.484203457832336 33.999913811683655 C 32.21803015470505 33.999913811683655 33.62458598613739 32.59316039085388 33.62458598613739 30.859278976917267 C 33.62458598613739 30.859278976917267 33.62458598613739 29.812400698661804 33.62458598613739 29.812400698661804 C 33.62458598613739 25.18657958507538 29.875739574432373 21.437488973140717 25.249641835689545 21.437488973140717 Z"
        ></path>
      </g>
    </svg>
  );
}
