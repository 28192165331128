import * as React from "react";
import {
  Edit,

  // ReferenceInput,
  // AutocompleteInput,
  SimpleForm,
  usePermissions,
  FileField,
  FunctionField,
  Labeled,
  TextField,
  DateField,
  useLocale,
  ReferenceField,
  // ArrayInput,
  // SimpleFormIterator,
  // @ts-ignore
} from "react-admin";
import MyTitle from "@components/common/View/Title";
// import { AccountStatusInput } from "@constants/accountStatus";

import { OrganizationTypeField } from "@constants/organizationType";
import { AdminRole } from "@wetrans/helpers";
import Card from "@components/common/card/Card";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { handleDisplayNum } from "@components/common/HandleDisplayNum";
import { RatingField } from "@components/common/RatingField/RatingField";
import { ApprovalStatusField } from "@constants/approvalStatus";
import { MultiFileField } from "@components/common/MultiFileField/MultiFileField";

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
}));

export default function OrganizationShow(props: any) {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const locale = useLocale();
  const { Toolbar } = props;
  return (
    <div className="editContainer">
      <Edit
        {...props}
        className="edit"
        // transform={transform}
        hasShow={false}
        title={
          permissions === AdminRole.super ? (
            <MyTitle resource={props.resource} descriptionAttribute="name" />
          ) : (
            "Profil"
          )
        }
      >
        <SimpleForm
          warnWhenUnsavedChanges
          // initialValues={}
          toolbar={
            <Toolbar
              {...props}
              mode="edit"
              style={{
                position: "absolute",
                // background: "#f7f8fa",
                top: 55,
              }}
            />
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 70,
            }}
          >
            <Card>
              <Grid container className={classes.fullWidth} spacing={4}>
                <Grid item sm={6}>
                  <Labeled label="organization.ICE">
                    <TextField
                      source="ICE"
                      className={classes.fullWidth}
                      emptyText="N/A"
                    />
                  </Labeled>
                </Grid>
                <Grid item sm={6}>
                  <Labeled label="organization.name">
                    <TextField
                      source="name"
                      className={classes.fullWidth}
                      emptyText="N/A"
                    />
                  </Labeled>
                </Grid>
                <Grid item sm={6}>
                  <Labeled label="organization.phone">
                    <TextField
                      source="phone"
                      className={classes.fullWidth}
                      emptyText="N/A"
                    />
                  </Labeled>
                </Grid>
                <Grid item sm={6}>
                  <Labeled label="organization.createdAt">
                    <DateField
                      locales={locale}
                      className={classes.fullWidth}
                      source="createdAt"
                      emptyText="N/A"
                    />
                  </Labeled>
                </Grid>

                <Grid item sm={6}>
                  <FunctionField
                    render={(props: any) => {
                      return (
                        <Labeled label="general.approvalStatus">
                          <ApprovalStatusField value={props.approvalStatus} />
                        </Labeled>
                      );
                    }}
                    label="general.approvalStatus"
                  />
                </Grid>
                <Grid item sm={6}>
                  <FunctionField
                    render={(props: any) => {
                      return (
                        <Labeled label="organization.type">
                          <OrganizationTypeField value={props.type} />
                        </Labeled>
                      );
                    }}
                    sortable={false}
                    label="organization.type"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Labeled label="Ville d'origine">
                    <ReferenceField
                      sortable={false}
                      link={false}
                      linkType=""
                      label="Ville d'origine"
                      source="cityOfOrigin"
                      reference="city"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Labeled>
                </Grid>
                <Grid item sm={6}>
                  <FunctionField
                    render={(props: any) => {
                      return (
                        <Labeled label="Fichier status entreprise">
                          {props.fileStatus ? (
                            <FileField
                              source="fileStatus.src"
                              title="fileStatus.title"
                              target="_blank"
                              sortable={false}
                              label="Fichier status entreprise"
                            />
                          ) : (
                            <span>N/A</span>
                          )}
                        </Labeled>
                      );
                    }}
                  />
                </Grid>

                <Grid item sm={6}>
                  <div className="flex flex-wrap direction-column margin-b-10">
                    <label className="margin-b-10 margin-t-10">
                      <strong>Note :</strong>
                    </label>
                    <FunctionField
                      render={(record: any) => {
                        return (
                          <>
                            <RatingField
                              value={record.rating ? record.rating : 0}
                            />{" "}
                            <span
                              style={{
                                display: "inline-flex",
                                marginLeft: 5,

                                position: "relative",
                                top: -5,
                              }}
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {`${handleDisplayNum(
                                  record.ratingCount ? record.ratingCount : 0,
                                  false,
                                  "count"
                                )} avis`}
                              </Typography>
                            </span>
                          </>
                        );
                      }}
                      sortable={false}
                      label="organization.rating"
                    />
                  </div>
                </Grid>
                <Grid item sm={12}>
                  <MultiFileField source="documents" label="Documents" />
                </Grid>
              </Grid>
            </Card>
          </div>
        </SimpleForm>
      </Edit>
    </div>
  );
}
