import React from "react";
import styled from "styled-components";

// helpers
import { DateField, useLocale } from "react-admin";

const DateIndicatorContainer = styled.div`
  white-space: nowrap;
`;

const DateItem = styled.span`
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  strong {
    font-size: inherit;
  }
`;
const TimeFieldEta = ({ source }: { source: string }) => {
  const locale = useLocale();

  return (
    <>
      <DateIndicatorContainer>
        <DateItem>
          <DateField
            showTime
            options={{
              hour12: false,
              hour: "numeric",
              minute: "numeric",
              timeZone: "GMT",
            }}
            locales={locale}
            source={source}
          />
        </DateItem>
        <DateItem>(estimé)</DateItem>
      </DateIndicatorContainer>
    </>
  );
};

export default TimeFieldEta;
